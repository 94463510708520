import React, {
    useEffect,
    useState,
    useContext,
    Fragment
} from 'react';
import {
    PageContainer,
    Subtitle,
    ContainerTwoInputs,
} from '../StylesComponents/common-styled';
import DialogAlert from '../DiaologAlert';
import Card from '../Viaticos/Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import ButtonsInformacion from '../Viaticos/ButtonsInformacion';
import FormTravels from '../Viaticos/FormTravels';
import FormAlojamiento from '../Viaticos/FormAlojamiento';
import FormMateriales from '../Viaticos/FormMateriales';
import { TextareaAutosize } from '@material-ui/core';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { getcTOBS_Id, getRol } from '../../funciones-auxiliares/viaticos';
import { useLazyQuery, useMutation } from 'react-apollo';
import { GET_EVENTO_BY_CREU_ID } from '../../query/query';
import {
    DYNAMIC_INSERT,
    DYNAMIC_UPDATE,
    DINAMIC_GET,
} from '../../mutation/mutation';
import { MyContext } from '../Context';
import ToggleButtons from '../Viaticos/ToggleButons';
import { sendEmail } from '../../services/SendEmails';
import RolSelection from '../Acuerdos/common/RolSelection';
import UploadFile from './UploadFile';
import CardListReuniones from './CardListReuniones';
import { useMediaQuery } from '@material-ui/core';
const Pendings = ({ cEMP_Id, handleComponent, title, cVIT_Avance }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const context = useContext(MyContext);
    const [isRequired, setIsRequired] = useState(true);
    const [cotizacion, setCotizacion] = useState('');
    const [data, setData] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [data2, setData2] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [value, setValue] = useState('Ida y vuelta');
    const [tramos] = useState([
        {
            titulo: 'Tramo 1',
        },
    ]);
    const [viaje, setViaje] = useState([
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
    ]);
    const [alojamiento, setAlojamiento] = useState([
        {
            hotel: '',
            urlCotizacion: '',
            fecha: '',
            precio: '',
        },
    ]);
    const [dataXLSX, setDataXLSX] = useState([]);
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [meetings, setMeetings] = useState([]);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            // sessionStorage.setItem('cREU_Titulo', getReunionBycREUID.cREU_Titulo);
            // setData({
            //     titulo: getReunionBycREUID.cREU_Titulo,
            //     texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
            //     texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
            //     texto3: getReunionBycREUID.cREU_TipoReunion,
            // });
            // setData2({
            //     titulo: getReunionBycREUID.Nombre_Empleado,
            //     texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
            //     texto2: 'Suscripción',
            //     texto3: 'Patria RE',
            // });
        },
    });

    const [insertObservations] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('resultado', dynamicInsert)
            if (dynamicInsert.statusCode === 200) {
                // handleComponent();
            }
        },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });
    const [getCotizacion] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.message === 'success') {
                const response = JSON.parse(dynamicGet.response);
                if (response.length > 0) {
                    setCotizacion(response[0].cCOTVIT_FileName)
                }
            }
        },
        onError(error) {
            console.log(error)
        }
    })
    const [updateStatus] = useMutation(DYNAMIC_UPDATE);
    const getInputUpdate = (table, columns, conditions) => {
        return {
            table,
            columns,
            conditions,
        };
    };
    const sendTicketToTraveler = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' })
        if (isRequired) {
            setShowModalErr(true)
            setMsgModalErr({
                title: 'Error',
                text: 'Obligatorio: subir archivos',
            })            
            setStyleDisabled({ });
            return;
        }
        let input;
        const observaciones = sessionStorage.getItem('obsevaciones');
        if (observaciones !== null && observaciones !== undefined) {
            input = {
                table: 'cOBS_ObservacionesViaticos',
                columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                detailValues: [
                    {
                        values: [
                            String(context.state.viaticosProps.cVIT_Id),
                            String(cEMP_Id.cEMP_Id),
                            sessionStorage.getItem('obsevaciones'),
                            String(getcTOBS_Id(cEMP_Id.profile)),
                        ]
                    },
                ],
            };
            await insertObservations({ variables: { input } });
        }
        let columns = [
            {
                setkey: 'cVIT_Avance',
                setval: '5',
            },
        ];
        let conditions = [
            {
                valone: 'cVIT_Id',
                valtwo: String(context.state.viaticosProps.cVIT_Id),
                condition: '=',
            },
        ];
        input = getInputUpdate('cVIT_Viaticos', columns, conditions);
        await updateStatus({ variables: { input } }).then(({ data }) => {
            if (data.dynamicUpdate.statusCode === 200) {
                setShowModalErr(true)
                setMsgModalErr({
                    title: 'Ticket enviado al viajero',
                    text: '',
                })
                handleComponent();
            }
        }).catch((error) => {
            setShowModalErr(true)
            setMsgModalErr({
                title: 'Error',
                text: 'No se pudo enviar el ticket',
            })
            console.log(error)
            setStyleDisabled({ })
        });
        const token = context.state.AuthComponentProps.token;
        await sendEmail({
            accessToken: token,            
            emailTo: [sessionStorage.getItem('emailToTraveler')],
            subject: 'Solicitud de viaticos',
            section: 'sectionFive'            
        });
    }

    const save = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' });
        if (isRequired) {
            setShowModalErr(true)
            setMsgModalErr({
                title: 'Error',
                text: 'Obligatorio: subir archivos',
            })            
            setStyleDisabled({ });
            return;
        }
        let input;
        const observaciones = sessionStorage.getItem('obsevaciones');
        if (observaciones !== null && observaciones !== undefined) {
            input = {
                table: 'cOBS_ObservacionesViaticos',
                columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                detailValues: [
                    {
                        values: [
                            String(context.state.viaticosProps.cVIT_Id),
                            String(cEMP_Id.cEMP_Id),
                            sessionStorage.getItem('obsevaciones'),
                            String(getcTOBS_Id(cEMP_Id.profile)),
                        ]
                    },
                ],
            };
            await insertObservations({ variables: { input } });
        }
        let columns = [
            {
                setkey: 'cVIT_Status_VIAT',
                setval: '2',
            },
            {
                setkey: 'cVIT_Avance',
                setval: '2',
            },
        ];
        let conditions = [           
            {
                valone: 'cVIT_Id',
                valtwo: String(context.state.viaticosProps.cVIT_Id),
                condition: '=',
                logic: 'AND',
            },
        ];
        input = getInputUpdate('cVIT_Viaticos', columns, conditions);
        await updateStatus({ variables: { input } }).then(({ data }) => {
            console.log('response', data)
            if (data.dynamicUpdate.statusCode === 200) {
                setShowModalErr(true)
                setMsgModalErr({
                    title: 'Solicitud guardada',
                    text: '',
                })
                handleComponent();
            }
        }).catch((error) => {
            setShowModalErr(true)
            setMsgModalErr({
                title: 'Error',
                text: 'No se pudo guardar la solicitud',
            })
            console.log(error)
            setStyleDisabled({ })
        });
        const token = context.state.AuthComponentProps.token;
        await sendEmail({
            accessToken: token,            
            emailTo: [sessionStorage.getItem('emailToTraveler')],
            subject: 'Solicitud de viaticos',
            section: 'sectionTwo'            
        });

        // else {
        //     setShowModalErr(true);
        //     setMsgModalErr({
        //         title: 'Error',
        //         text: 'Campo obligatorio: Observaciones',
        //     })
        // }

    };

    const getViaticos = () => {
        switch (context.state.viaticosProps.cTIP_Id) {
            case 1:
                setValue('Ida y vuelta');
                break;
            case 2:
                setValue('Solo ida');
                break;
            case 3:
                setValue('Múltiples destinos');
                break;
            default:
                console.log('Nada');
        }
        const viajes = context.state.viaticosProps.viajes.map((viaje) => {
            return {
                ...viaje,
                origen: viaje.cVIA_Origen,
                destino: viaje.cVIA_Destino,
                aerolinea: viaje.cVIA_Areolinea,
                vuelo: viaje.cVIA_NoVuelo,
                cotizacion: viaje.cVIA_URLVueloCot,
                fecha: viaje.cVIA_FechaVuelo.slice(0, 10),
                horario: viaje.cVIA_HorarioVuelo.slice(11, 16),
                precio: viaje.cVIA_PrecioVuelo,
            };
        });
        const alojamientos = context.state.viaticosProps.alojamientos.map(
            (alojamiento) => {
                return {
                    ...alojamiento,
                    hotel: alojamiento.cALO_Hotel,
                    urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
                    fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
                    precio: alojamiento.cALO_Precio,
                };
            }
        );
        console.log('get viajes ======', viajes);
        setViaje(viajes);
        setAlojamiento(alojamientos);
    };

    const getJsonToExcel = () => {
        const viajes = context.state.viaticosProps.viajes.map((viaje, index) => {
            return {
                Tipo: index === 0 ? 'Ida' : 'Vuelta',
                Origen: viaje.cVIA_Origen,
                Destino: viaje.cVIA_Destino,
                Aerolinea: viaje.cVIA_Areolinea,
                NumVuelo: viaje.cVIA_NoVuelo,
                Cotizacion_vuelo: viaje.cVIA_URLVueloCot,
                Fecha_vuelo: viaje.cVIA_FechaVuelo.slice(0, 10),
                Horario_vuelo: viaje.cVIA_HorarioVuelo.slice(11, 16),
                Precio_vuelo: viaje.cVIA_PrecioVuelo,
            };
        });
        const alojamientos = context.state.viaticosProps.alojamientos.map(
            (alojamiento) => {
                return {
                    Hotel: alojamiento.cALO_Hotel,
                    urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
                    Fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
                    Precio: alojamiento.cALO_Precio,
                };
            }
        );
        const materialesV = context.state.viaticosProps.materiales.map(
            (material) => {
                return {
                    Material: material.cMAT_Descripcion,
                    Precio_material: material.cMAT_Precio,
                };
            }
        );
        const promocionalesV = context.state.viaticosProps.promocionales.map(
            (promocionalV) => {
                return {
                    Promocional: promocionalV.cPRO_Descripcion,
                    Precio_promocional: promocionalV.cPRO_Precio,
                };
            }
        );
        const requerimientosTiV = context.state.viaticosProps.requerimientoTI.map(
            (requerimientoTiV) => {
                return {
                    MaterialTI: requerimientoTiV.cREQT_Descripcion,
                    PrecioTI: requerimientoTiV.cREQT_Precio,
                };
            }
        );
        // ...viajes, ...alojamientos, ...materialesV, ...promocionalesV, ...requerimientosTiV
        switch (context.state.viaticosProps.cTIP_Id) {
            case 1:
                setValue('Ida y vuelta');
                setDataXLSX([
                    ...viajes,
                    ...alojamientos,
                    ...materialesV,
                    ...promocionalesV,
                    ...requerimientosTiV,
                ]);
                break;
            case 2:
                setValue('Solo ida');
                setDataXLSX([
                    ...viajes,
                    ...alojamientos,
                    ...materialesV,
                    ...promocionalesV,
                    ...requerimientosTiV,
                ]);
                break;
            case 3:
                setValue('Múltiples destinos');
                setDataXLSX([
                    ...viajes,
                    ...alojamientos,
                    ...materialesV,
                    ...promocionalesV,
                    ...requerimientosTiV,
                ]);
                break;
            default:
                console.log('Nada');
        }
    };

    const handleChange = ({ target }) => {
        sessionStorage.setItem('obsevaciones', target.value);
    };


    useEffect(() => {
        if (
            context.state.viaticosProps.cVIT_Id !== '' &&
            context.state.viaticosProps.reuniones
        ) {
            // getJsonToExcel();
            getEventBycREU({
                variables: { cREU_Id: context.state.viaticosProps.reuniones },
            });
        }
    }, []);
    useEffect(() => {
        sessionStorage.clear();
        sessionStorage.setItem('cTIP_Id', 1);
        if (context.state.viaticosProps.cVIT_Id !== '') {
            getViaticos();            
            if (cVIT_Avance === 4) {
                getCotizacion({
                    variables: {
                        input: {
                            table: 'cCOTVIT_Cotizaciones',
                            columns: ['cCOTVIT_FileName'],
                            conditions: [
                                {
                                    valone: 'cVIT_Id',
                                    valtwo: String(context.state.viaticosProps.cVIT_Id),
                                    condition: '=',
                                }
                            ]
                        }
                    }
                })

            }
        }
    }, []);
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation">
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <Subtitle color="#000" style={{ alignItems: 'center' }}>
                {'Revisa solicitud de los viáticos de viaje' || title}
            </Subtitle>
            <CardListReuniones meetings={meetings} setEmpleado={setData2} setReunion={setData} />
            <ContainerTwoInputs
                width="100%"
                margin="20px 0px"
                id="viaticos-solicitud"
            >
                <Card data={data} img={calendario} />
                <Card data={data2} img={user} />
            </ContainerTwoInputs>
            {cVIT_Avance === 4 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: 18, fontWeight: 'bolder', margin: 0, marginRight: 10 }}>Cotizacion elegida por el viajero:</p>
                        <a style={{ fontSize: 18 }} href={`https://dev-patria.alimx.mx/api/download/files/${cotizacion}`}>{cotizacion}</a>
                    </div>
                    <div style={{ fontSize: 18, fontWeight: 'bolder' }}>
                        VIATICO AUTORIZADO POR DIRECTOR DE AREA ✅
                    </div>
                </div>
            )}
            <ButtonsInformacion
                title="Información de transporte"
                text1="Ida y vuelta"
                text2="Solo ida"
                text3="Múltiples destinos"
                cTIp_Id={context.state.viaticosProps.cTIP_Id}
            />
            {value === 'Múltiples destinos' ? null : (
                <FormTravels
                    title="Viaje de ida"
                    id={0}
                    valueInputs={viaje}
                    asistente={true}
                />
            )}
            {value === 'Ida y vuelta' ? (
                <FormTravels
                    title="Viaje de vuelta"
                    id={1}
                    valueInputs={viaje}
                    asistente={true}
                />
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {viaje.map((tramo, index) => (
                        <FormTravels
                            title={`Tramo ${index + 1}`}
                            id={index}
                            valueInputs={viaje}
                            asistente={true}
                        />
                    ))}
                </Fragment>
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {alojamiento.map((tramo, index) => (
                        <FormAlojamiento
                            title={`Alojamiento - Tramo ${index + 1}`}
                            id={index}
                            valueInputs={alojamiento}
                            asistente={true}
                        />
                    ))}
                </Fragment>
            ) : (
                <FormAlojamiento
                    title="Información de alojamiento"
                    id={0}
                    valueInputs={alojamiento}
                    asistente={true}
                />
            )}
            <FormMateriales
                title="Equipo o materiales para viaje"
                cambio={value}
                asistente={true}
            />
            {context.state.viaticosProps.observaciones.map((data, index) => {
                return (
                    <Fragment key={index}>
                        <div style={{ marginTop: '20px' }}>
                            <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                data.cTOBS_Id
                            )}`}</h3>
                        </div>
                        <TextareaAutosize
                            minRows={10}
                            style={{ width: '90%' }}
                            id="text-mensaje"
                            name="area"
                            value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                            disabled
                        />
                    </Fragment>
                );
            })}
            <div style={{ marginTop: '20px' }}>
                <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
            </div>
            <TextareaAutosize
                minRows={10}
                style={{ width: '90%' }}
                id="text-mensaje"
                name="observaciones"
                onChange={handleChange}
                value={data.cOBS_Descripcion}
                placeholder="Escribe comentarios del viaje ..."
            />
            <ToggleButtons width='90%' />
            <UploadFile name={cVIT_Avance === 4 ? 'reservaciones' : 'cotizacion'} setIsRequired={setIsRequired} />
            <div
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
            >              
                <ButtonNavigation
                    text="Guardar"
                    width={isMobile ? '100%':  "45%"}
                    backgroundColor="#3898EC"
                    border="none"
                    color="#fff"
                    justifyContent="center"
                    onClick={cVIT_Avance === 4 ? sendTicketToTraveler : save}
                    style={styleDisabled}
                />
            </div>
        </PageContainer>
    );
};

export default Pendings;
