import styled from 'styled-components';

export const ListTable = styled.div`
    height: ${(props => props.height || '100%')};
    width: ${props => props.width || '90%'};
    border: ${props => props.border || '1px solid'};
    padding: ${props => props.padding || '10px'};   
`;

export const GridContainer = styled.div`
    display: ${props => props.display || 'grid'};            
    grid-template-columns: ${props => props.gridTemplateCol || 'repeat(3,1fr)'};
    width: ${props => props.width || ''};  
    margin: ${props => props.margin || ''};  
    gap: ${props => props.gap || ''}; 
    grid-template-rows: ${props => props.gridTemplateRows || ''};
    grid-column: ${props => props.gridColumn || ''};
    margin-top:  ${props => props.marginTop || ''};
    
`;
export const InputFondoMone=styled.input`
display: ${props => props.display || 'block'}; 
font: 1rem 'Fira Sans', sans-serif;
margin: .4rem 0;}
`;
export const SubTitle = styled.div`    
    height: ${(props => props.height || '')};
    width: ${props => props.width || '100%'};
    background: ${props => props.background || ''};    
    font-size: ${props => props.fsize || '18px'};
    color: ${props => props.color || 'white'};
    font-weight: bold;
    padding: 5px;
    margin-left: ${props => props.marginLeft || ''};

`;

