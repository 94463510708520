import { TextField, NativeSelect } from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { AcordeonContainerBody } from '../StylesComponents/common-styled';
import FormTypography from './FormsTypography';
import { useQuery } from 'react-apollo';
import { GETCOUNTRIES_SIREC } from '../../query/query';
import { BootstrapInput } from '../StylesComponents/material-ui-styled';
import { useMediaQuery } from '@material-ui/core';
const InputTravel = ({
  placeholder,
  name,
  onChange,
  id,
  value,
  type,
  disabled,
}) => {  
  const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)'); 
  const style = value === ''
    ? null
    : {border: '2px solid green', borderRadius: 4, fontSize: isMobile ? 13 : null}
  return (
    <TextField    
      fullWidth
      // style={style}     
      inputProps={{
        style
      }}        
      variant="outlined"
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      id={id}
      value={value}
      type={type}
      disabled={disabled || false}
    />
  );
};

const SelectTravel = ({ id, name, onchangue, value }) => {

  const [cities, setCities] = useState([]);  
  useQuery(GETCOUNTRIES_SIREC, {
    onCompleted({ idCatCountries }) {
      let idCatCwithoutMex = idCatCountries.filter((country) => {
       
        if (
          country.cPAI_NOMBRE !== 'México' &&
          country.cPAI_NOMBRE !== 'Sin País'
        ) {
          return country;
        
        }
        return false;
      });
      let idCatCwithMex = idCatCountries.filter((country) => {
        if (
          country.cPAI_NOMBRE === 'México' ||
          country.cPAI_NOMBRE === 'Sin País'
        ) {
          return country;
        }
        return false;
      });
      let response = idCatCwithoutMex.sort((a, b) =>
        a.cPAI_NOMBRE < b.cPAI_NOMBRE ? -1 : 1
        
      );
      response = [...idCatCwithMex, ...response];
      setCities(response);
     
    },
    
  });
  
  if (id !== '0') {
    
    return (
      <NativeSelect
        input={<BootstrapInput />}
        id={id}
        name={name}
        value={value}
        className={`${value !== '' ? 'inputActive' : ''}`}
        onChange={(e) => {
          onchangue(e.target.value, e.target.name, e.target.id);
        }}
      >
        {cities &&
          cities.map((city, index) => (
            <option key={index} value={city.cPAI_NOMBRE}>
              {city.cPAI_NOMBRE}
            </option>
          ))}
      </NativeSelect>
    );
  } else return null;
  
};

const useFormTravels = ({
  title,
  text1,
  text2,
  text3,
  text4, 
  text5,
  text6,
  text7,
  text8,
  id,
  handleChangue,
  valueInputs,
  handleChangueSelect,
  asistente,
}) => {
  
  return (
    <Fragment>
      <div style={{ marginTop: '20px' }}>
        <h3 style={{ textAlign: 'center', marginRight: '10%'}}>{title}</h3>
      </div>
      {valueInputs.lenght !== 0 ? (
        <Fragment>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text1 || 'Origen'}>
              {asistente === true ? (
                <InputTravel
                  name="origen"
                  id={`origen_${id}`}
                  value={valueInputs[id].origen}
                  onChange={handleChangue}
                  disabled={true}
                />
              ) : (
                <SelectTravel
                  name="origen"
                  id={`origen_${id}`}
                  onchangue={handleChangueSelect}
                  value={valueInputs[id].origen}
                />
              )}
            </FormTypography>
            <FormTypography text={text2 || 'Destino'}>
              {asistente === true ? (
                <InputTravel
                  name="destino"
                  id={`destino_${id}`}
                  value={valueInputs[id].destino}
                  onChange={handleChangue}
                  disabled={true}
                />
              ) : (
                <SelectTravel
                  name="destino"
                  id={`destino_${id}`}
                  onchangue={handleChangueSelect}
                  value={valueInputs[id].destino}
                />
              )}
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text3 || 'Aerolínea'}>
              <InputTravel
                placeholder="Ingresa el nombre de la aerolínea"
                name="aerolinea"
                onChange={handleChangue}
                id={`aerolinea_${id}`}
                value={valueInputs[id].aerolinea}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text4 || 'No. de vuelo'}>
              <InputTravel
                placeholder="Ingresa el número de vuelo"
                name="vuelo"
                onChange={handleChangue}                
                id={`vuelo_${id}`}
                value={valueInputs[id].vuelo}
                disabled={asistente}
              />
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text5 || 'URL cotización de vuelo'}>
              <InputTravel
                placeholder="Pega la url de la cotización"
                name="cotizacion"
                onChange={handleChangue}                
                id={`cotizacion_${id}`}
                value={valueInputs[id].cotizacion}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text6 || 'Fecha de vuelo'}>
              <InputTravel
                placeholder="Agregar el horario de vuelo"
                name="fecha"
                onChange={handleChangue}                
                id={`fecha_${id}`}
                type="date"
                value={valueInputs[id].fecha}
                disabled={asistente}
              />
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text7 || 'Horario de vuelo'}>
              <InputTravel
                placeholder="Agregar el horario de vuelo"
                name="horario"
                onChange={handleChangue}                
                id={`horario_${id}`}
                type="time"
                value={valueInputs[id].horario}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text8 || 'Precio de vuelo'}>
              <InputTravel
                placeholder="Ingresa el precio del vuelo"
                name="precio"
                onChange={handleChangue}                
                id={`precio_${id}`}
                value={valueInputs[id].precio}
                disabled={asistente}
                // type="number"
              />
            </FormTypography>
          </AcordeonContainerBody>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default useFormTravels;
