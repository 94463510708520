import React from 'react';
import MaterialTable from 'material-table';
const GenericTable = ({ data = [], loading = true, columns = [], title = '', localization = {}, options = {}, width = 800 }) => {
    return (
        <MaterialTable   
            style={{width, marginRight: '20px'}}                     
            isLoading={loading}
            options={options}
            title={title}
            columns={columns}
            localization={localization}
            data={data}
        />
    );
}

export default GenericTable;