import React, { useState, Fragment, useContext } from 'react';
import { navigation } from '../funciones-auxiliares/programar-reunion';
import Homebar from './Homebar';
import {
  AcordeonContainer,
  DivNavigationButton,
  Page,
  PageContainer,
  Subtitle,
  Title,
} from './StylesComponents/common-styled';
import ButtonNavigation from './Viaticos/ButtonNavigation';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './../assets/styles/css/master.css';
import {
  Business,
  Home,
  LibraryBooks,
  PermIdentity,
  PlaylistAddCheck,
  Public,
  ArrowDropDown,
  ArrowDropUp
} from '@material-ui/icons';
import Footer from './Viaticos/Footer';
import RegistroVisita from './Acuerdos/RegistroVisita';
import Nota from './Acuerdos/Nota';
import NewReporte from './Acuerdos/NewReporte';
import NewReuProgramada from './Acuerdos/NewReuProgramada';
import Cedente from './Acuerdos/Cedente';
import Entorno from './Acuerdos/Entorno';
import NewReuCasual from './Acuerdos/NewReuCasual';
import Seguimiento from './Acuerdos/Seguimiento';
import { useHistory } from 'react-router';
import RelacionV2 from './Acuerdos/RelacionV2';
import SeguimientoRelPatria from './Acuerdos/SeguimientoRelPatria';
import Report from './Acuerdos/Report';
import SeguimientoRelacionDos from './Acuerdos/componentsRelacion/SeguimientoRelacionDos';
import SeguimientoRelPatriaDos from './Acuerdos/componentsRelacion/SeguimientoRelPatriaDos';
import GeneralReport from './GeneralReport';
import { MyContext } from './Context';
  import { AiFillCaretDown } from "react-icons/ai";
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
  },
});

export const Acuerdos = ({ session }) => {
  const { obtenerUsuario } = session;
  const history = useHistory();
  const [titleSeguimiento, setTitleSeguimiento] = useState(
    'Seguimiento Entorno'
  );
  const [temaEntorno, setTemaEntorno] = useState('');
  const [auxPLDIMP, setAuxPLDIMP] = useState('');
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [stateButton, setStateButton] = useState({
    showRegistro: false,
    first: false,
    second: false,
    third: false,
    four: false,
    five: false,
    six: false,
    sevent: false,
    eight: false,
    nine: false,
    ten: false,
    showSegRelPatria: false,
    showSegRelPatriaDos: false,
    showReport: false,
    showSegRelPaDos: false,
    arrowInicio:false,
    arrowCedente:false
  });
  const [arrowChangeColor, setArrowChangeColor] = useState(false)
  const [lastPage, setLastPage] = useState('first');
  const [tipoRelacion, setTipoRelacion] = useState('');
  const context = useContext(MyContext);

  // const [mostrarNotas, setMostrarNotas] = useState=(false)

  const handleChangue = (event, newValue) => {
    setTitleSeguimiento('Seguimiento Entorno');
    setValue(newValue);
  };

  const handleClickReuniones = () => {
    history.push('/crm/secciones/reuniones');
  };
  const handleClickDash = () => {
    history.push('/crm/secciones/reuniones/snapshots');
  };


  return (
    <Fragment>
      <Page>
        <Homebar navigation={navigation} />
        <PageContainer>
          <Title style={{ marginTop: '2%' }}>Reporte de Visitas</Title>
          <Subtitle>Consulta y registra los reportes de tus visitas.</Subtitle>
          <div style={{ display: 'flex' }}>
            <BottomNavigation
              value={value}
              onChange={handleChangue}
              className={classes.root}
              showLabels
              id="button-navigate-acuerdo"
            >{
                stateButton.first === true &&
                  stateButton.nine === false &&
                  stateButton.sevent === false &&
                  stateButton.four === false
                  ?
                  <>
                    <BottomNavigationAction
                      label="Inicio"
                      value="inicio"
                      style={{ color: '#3f51b5' }}
                      onClick={() => {
                        setStateButton({
                          first: !stateButton.first,
                          second: false,
                          third: false,
                          four: false,
                          five: false,
                          six: false,
                          sevent: false,
                          eight: false,
                          arrowInicio:true
                        });
                       
                      }}
                      icon={<Home />}
                    />
                    <div style={{ position: 'relative',cursor:'pointer' }}>
                      {stateButton.arrowInicio == true ?
                        <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                        :
                        <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                      }
                    </div>
                  </> :
                  <>
                    <BottomNavigationAction
                      label="Inicio"
                      value="inicio"
                      showLabel={true}
                      style={{ color: '#3f51b5' }}
                      onClick={() => {
                        setStateButton({
                          first: !stateButton.first,
                          second: false,
                          third: false,
                          four: false,
                          five: false,
                          six: false,
                          sevent: false,
                          eight: false,
                          arrowInicio: true
                        });

                        console.log('flecha', stateButton.first)
                      }}
                      icon={<Home />}
                    />
                    <div style={{ position: 'relative', cursor: 'pointer' }}>
                      {stateButton.arrowInicio == true ?
                        <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                        :
                        <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                      }
                    </div>
                  </>


              }
              <BottomNavigationAction
                label="Cedente"
                value="cedente"
                onClick={() => {
                  setStateButton({
                    first: !stateButton.first,
                    second: false,
                    third: false,
                    four: !stateButton.four,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    arrowCedente: true
                  });
                }

                }
                icon={<PermIdentity />}

              />

              <div style={{ position: 'relative', cursor: 'pointer' }}>
                {stateButton.arrowCedente == true ?
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                  :
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                }
              </div>
              {/* <BottomNavigationAction                 
                  onClick={() => {
                    setStateButton({
                      first: !stateButton.first,
                      second: false,
                      third: false,
                      four: !stateButton.four,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                    });
                  }}
                  style={{
                    padding: '0px',
                    marginLeft: '-45px'
                  }}
                  icon={stateButton.first && stateButton.four ? <ArrowDropUp /> : <ArrowDropDown />}
                /> */}

              <BottomNavigationAction
                label="Entorno"
                value="entorno"
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                  });
                }}
                icon={<Public />}
              />
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                {stateButton.sevent == true && stateButton.first == true ?
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                  :
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                }
              </div>
              <BottomNavigationAction
                label="Relación"
                value="relacion"
                icon={<Business />}
                onClick={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: true,
                  })
                }
              />
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                {stateButton.ten == true ?
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                  :
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                }
              </div>
              <BottomNavigationAction
                label="Seguimiento"
                value="seguimiento"
                icon={<PlaylistAddCheck />}
                style={{ marginRight: '1%' }}
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: true,
                  });
                }}
              />
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                {stateButton.nine == true ?
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                  :
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                }
              </div>
              <BottomNavigationAction
                label="Reporte"
                value="reporte"
                icon={<LibraryBooks />}
                onClick={() => {
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    showReport: true,
                  });

                }}
              />
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                {stateButton.showReport == true ?
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm', color: '#3898EC' }} />
                  :
                  <AiFillCaretDown style={{ position: 'absolute', bottom: '15px', right: '1cm' }} />
                }
              </div>
            </BottomNavigation>
          </div>
        </PageContainer>
        <AcordeonContainer
          flexDirection="row"
          justifyContent="space-around"
          id="viaticos-solicitud"
          width='100%'
        >
          <DivNavigationButton
            width="20%"
            alignSelf="end"
            id="button-navigation"
          >

          </DivNavigationButton>
          <div className="content-acuerdos">
            {stateButton.first &&
              obtenerUsuario?.cEMP_Nombre !== null &&
              !stateButton.four &&
              !stateButton.five &&
              !stateButton.six &&
              !stateButton.sevent &&
              !stateButton.eight &&
              !stateButton.nine &&
              !stateButton.ten ? (
              <DivNavigationButton
                width="20%"
                // alignSelf="start"
                id="button-navigation"
              >
                <ButtonNavigation
                  text="Registrar Visita"
                  backgroundColor={stateButton.first ? '#3898EC' : null}
                  border={stateButton.first ? '#3898EC' : null}
                  color={stateButton.first ? '#FFF' : null}
                  onClick={() => {
                    context.clearState();
                    setStateButton({ first: false, second: false, third: false, showRegistro: true });
                  }
                  }
                />
                <ButtonNavigation
                  text="Consultar Reporte"
                  backgroundColor={stateButton.second ? '#3898EC' : null}
                  border={stateButton.second ? '#3898EC' : null}
                  color={stateButton.second ? '#FFF' : null}
                  onClick={() =>
                    setStateButton({ first: false, second: true, third: false })
                  }
                />
                <ButtonNavigation
                  text="Nota Rápida"
                  backgroundColor={stateButton.third ? '#3898EC' : null}
                  border={stateButton.third ? '#3898EC' : null}
                  color={stateButton.third ? '#FFF' : null}
                  onClick={() => {
                    setStateButton({ first: false, second: false, third: true });
                  }}
                />
              </DivNavigationButton>

            ) : stateButton.showRegistro && (

              <RegistroVisita
                name={obtenerUsuario?.cEMP_Nombre}
                onClick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: true,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setValue('inicio');
                  setLastPage('first');
                }}
                onClickReuniones={handleClickReuniones}
                onClickDash={handleClickDash}
              />
            )}
            {stateButton.second ? (
              <GeneralReport user={obtenerUsuario} />
            ) : null}
            {stateButton.third && <Nota setStateButton={setStateButton} stateButton={stateButton} />}
            {stateButton.four && (
              <NewReporte
                onClick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: true,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setValue('cedente');
                  setLastPage('four');
                }}
                onClick2={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: true,
                  });
                  setLastPage('four');
                }}
                onGoBack={() => {
                  setStateButton({
                    first: true,
                    eight: false,
                    five: false,
                  });
                }}
              />
            )}
            {stateButton.five ? (
              <NewReuProgramada
                cEMP_Id={obtenerUsuario?.cEMP_Id}
                onGoBack={() => {
                  setStateButton({ first: true, four: true, five: false });
                }}
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: true,
                    sevent: false,
                    eight: false,
                  });
                  setLastPage('five');
                }}
              />
            ) : null}
            {stateButton.six ? (
              <Cedente
                irAConsulta={(val) => {
                  setStateButton({
                    first: false,
                    second: true,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                }}
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: true,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setLastPage('six');
                }}
                onclick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                  });
                  setValue('entorno');
                  setLastPage('six');
                }}
                onGoBack={() => {
                  if (lastPage === 'five') {
                    setStateButton({
                      first: true,
                      six: false,
                      five: true,
                    });
                  } else {
                    setStateButton({
                      first: true,
                      six: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.sevent ? (
              <Entorno
                session={session}
                cEMP_Id={obtenerUsuario?.cEMP_Id}
                onClick1={(data) => {
                  console.log("DATA PROP", data);
                  if (data?.cENT_Descripcion === 'Ninguno') {
                    setTemaEntorno('')
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: true,
                    })
                  }
                  else if (data?.cTEM_Descripcion === 'Continuar') {
                    setTemaEntorno(data.cTEM_Descripcion)
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: true,
                    })
                  }
                  else {
                    setTemaEntorno('')
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: true,
                    })
                  }
                }}
                onGoBack={() => {
                  if (lastPage === 'six') {
                    setStateButton({
                      first: true,
                      six: true,
                      sevent: false,
                    });
                  } else {
                    setStateButton({
                      first: true,
                      sevent: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.eight ? (
              <NewReuCasual
                onClick1={(val) => {
                  if (val === 'regresarTipoReu') {
                    setStateButton({
                      first: false,
                      second: false,
                      third: false,
                      four: true,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: false,
                    })
                  }
                  else {
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: true,
                      eight: false,
                      nine: false,
                    })
                  }}
                }
                onGoBack={() => {
                  setStateButton({ first: false, eight: true, four: false });
                }}
                user={obtenerUsuario}
              />
            ) : null}
            {stateButton.nine ? (
              <Seguimiento
                titleSeguimiento={titleSeguimiento}
                sinEntorno={(context.state.saveAcuerdos.cENT_Descripcion === 'Ninguno' || temaEntorno === 'Continuar') ? "Ninguno" : ''}
                onClick={() =>
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    ten: true,
                  })
                }
                user={obtenerUsuario}
                onClickReturnEntorno={() => {
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                    ten: false,
                  });
                  setValue('entorno');
                }}
                onClickReturnRelacionPatria={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    ten: true,
                  });
                  setValue('relacion');
                }}
                onGoBack={() => {
                  if (lastPage === 'six') {
                    setStateButton({
                      first: true,
                      six: false,
                      sevent: true,
                      nine: false,
                    });
                  } else {
                    setStateButton({
                      first: false,
                      sevent: true,
                      nine: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.ten && (
              <RelacionV2
                setAuxPLDIMP={setAuxPLDIMP}
                onClick={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: false,
                    showSegRelPatria: true,
                  })
                }
                onClickGoSegRelPa2={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: false,
                    showSegRelPatria: false,
                    showSegRelPatriaDos: true,
                  })
                }
                onClickSegRelPaDos={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: false,
                    showSegRelPatria: false,
                    showSegRelPatriaDos: false,
                    showSegRelPaDos: true,
                  })
                }
              />
              // <Relacion
              //   onClick={() =>
              //     setStateButton({
              //       first: true,
              //       second: false,
              //       third: false,
              //       four: false,
              //       five: false,
              //       six: false,
              //       sevent: false,
              //       eight: false,
              //       nine: true,
              //       ten: false,
              //     })
              //   }
              // />
            )}
            {stateButton.showSegRelPatria && (
              <SeguimientoRelPatria
                backRelacionPatria={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: true,
                    showSegRelPatria: false,
                  })
                }
                onClickGoSeguimiento={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: true,
                    showSegRelPatria: false,
                  });
                  setValue('seguimiento');
                }}
                onClickGoReport={(optionalData) => {
                  if(optionalData){
                    setTipoRelacion(optionalData.tipoRelacion);
                    console.log("optional data:", optionalData);
                  }
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    showSegRelPatria: false,
                    showReport: true,
                  });
                  setValue('reporte');
                }}
              />
            )}
            {stateButton.showSegRelPaDos && (
              <SeguimientoRelPatriaDos
                onClickGoReport={(optionalData) => {
                  if(optionalData){
                    setTipoRelacion(optionalData.tipoRelacion);
                    console.log("optional data:", optionalData);
                  }
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    showSegRelPatria: false,
                    showReport: true,
                  });
                  setValue('reporte');
                }}
                backRelacionPatria={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: true,
                    showSegRelPatria: false,
                  })
                }
              />
            )}
            {stateButton.showSegRelPatriaDos && (
              <SeguimientoRelacionDos
                optionsExpIMP={auxPLDIMP}
                onClickGoReport={(optionalData) => {
                  if(optionalData){
                    setTipoRelacion(optionalData.tipoRelacion);
                    console.log("optional data:", optionalData);
                  }
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    showSegRelPatria: false,
                    showReport: true,
                  });
                  setValue('reporte');
                }}
                backRelacionPatria={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: true,
                    showSegRelPatria: false,
                  })
                }
              />
            )}
            {stateButton.showReport && (
              <Report
                onClick={() => {
                  setValue('seguimiento');
                  setTitleSeguimiento('Seguimiento');
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: true,
                  });
                }}
                onFinish={() => {
                  setStateButton({
                    first: false,
                    second: true,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                  });
                  context.clearState();
                }}
                tipoRelacion={tipoRelacion}
              />
            )}
          </div>
        </AcordeonContainer>
      </Page>
      <Footer />
    </Fragment>
  );
};
