import React from "react";
import { ButtonStyledNavigation } from "../StylesComponents/common-styled";

const ButtonNavigation = ({
  img,
  text,
  backgroundColor,
  border,
  color,
  onClick,
  width,
  justifyContent,
  id,
  textAlign,
  marginTop='',
  style,
  ...rest
}) => {
  return (
    <ButtonStyledNavigation    
      style={{cursor: "pointer", ...style,marginTop:marginTop || '0%'}}
      borderRadius="5px"
      width={width || "100%"}
      alingItems="center"
      backgroundColor={backgroundColor}
      border={border}
      color={color}
      justifyContent={justifyContent || "space-around"}
      onClick={() => onClick()}
      id={id}
      {...rest}
    >
      {img ? <img src={img} alt="documents" style={{ width: "30px" }} /> : null}
      <span style={{ textAlign }}>{text}</span>
    </ButtonStyledNavigation>
  );
};

export default ButtonNavigation;
