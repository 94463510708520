import React, { useEffect, useContext, useState } from 'react';
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import Homebar from './Homebar';
import Copyright from './Copyright';
import reuniones from '../assets/images/images/reuniones.png';
import Viaticos500 from '../assets/images/images/Viaticos500.png';
import snapshot500 from '../assets/images/images/snapshot500.png';
import suscripción_500 from '../assets/images/images/suscripción_500-01.png';
import acuerdos500 from '../assets/images/images/acuerdos500.png';
import logo_patria from '../assets/images/images/logo_patria.png';

import {
  Page,
  PageContainer,
  PageContainerCards,
  PageCard,
  Title,
  Subtitle,
} from './StylesComponents/common-styled';
import { MyContext } from './Context';
import './components.css';

const HomeUser = ({ session }) => {
  const history = useHistory();
  const styles = {
        cursor: 'pointer'
    }
  const [userOutlook, setUserOutlook] = useState(false);
  let nameUser = session?.obtenerUsuario ? (
    `${session.obtenerUsuario?.cEMP_Nombre}`
  ) : (
    <Redirect to="/crm" />
  );
  const context = useContext(MyContext);
  const navigation = [
    {
      title: nameUser,
      route: '',
    },
  ];
  const loginOutlook = (type) => {
    console.log('loginOutlool');
    context
      .login()
      .then((data) =>
        type === 1
          ? history.push('/crm/secciones/reuniones')
          : history.push('/crm/secciones/viaticos')
      )
      .catch((error) => console.log('error=====>>>>', error));
  };
  useEffect(() => {
    console.log('login');
    if (localStorage.getItem('loginOutlook') === 'true') {
      loginOutlook(1);
      localStorage.setItem('loginOutlook', 'false');
    }
  }, [localStorage.getItem('loginOutlook')]);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    const date = new Date();
    if (user !== null && new Date(user.expiresOn) >= date) {
      user = {
        AuthComponentProps: {
          ...user,
        },
      };
      context.getDataAuthenticated(user);
      context.getDataAuthenticated(user);
      setUserOutlook(user.AuthComponentProps.isAuthenticated);
    } else {
      setUserOutlook(context.state.AuthComponentProps.isAuthenticated);
    }
  }, []);
  return (
    <Page>
      <Homebar navigation={navigation} />
      <PageContainer>
        <Title> Bienvenido </Title>
        <Subtitle> Ingresa a la sección deseada para comenzar </Subtitle>
      </PageContainer>
      <PageContainerCards>
        <PageCard
        style={{alignItems: 'center'}}>
          {userOutlook ? (
            <NavLink exact to="/crm/secciones/reuniones">
              <img style={styles} src={reuniones} alt="imagew" width={68} />
            </NavLink>
          ) : (
            <img
              style={styles}
              src={reuniones}
              alt="imagew"
              width={68}
              onClick={() => loginOutlook(1)}
            />
          )}
          <h3> Reuniones </h3>
          <p>
            {' '}
            Agenda tu cita o viaje para seguimiento y mantenimiento de las
            relaciones con cedentes y brókers.{' '}
          </p>
        </PageCard>
        <PageCard
         style={{alignItems: 'center'}}>
          {userOutlook ? (
            <NavLink exact to="/crm/secciones/viaticos">
              <img style={styles} src={Viaticos500} alt="imagew" width={68} />
            </NavLink>
          ) : (
            <img
              style={styles}
              src={Viaticos500}
              alt="imagew"
              width={68}
              onClick={() => loginOutlook(2)}
            />
          )}

          <h3> Viáticos </h3>
          <p>
            {' '}
            Revisa el expediente de los cedentes y brókers, ¡Todo en un solo
            lugar!
          </p>
        </PageCard>
        <PageCard style={{justifyContent:'flex-start',width:'100%',alignItems: 'center'}}>
          <NavLink exact to="/crm/secciones/reuniones/acuerdos-viaje">
            <img style={styles} src={acuerdos500} alt="imagew" width={68} />
          </NavLink>
          <h3> Acuerdos de viaje </h3>
          <p>
            {' '}
            Una vez concluido tu viaje registra los acuerdos y dales
            seguimiento.
          </p>
        </PageCard>
        {/* <PageCard
        style={{alignItems: 'center'}}>
          <NavLink exact to="/crm/secciones/reuniones/snapshots">
            <img style={styles} src={snapshot500} alt="imagew" width={68} />
          </NavLink>

          <h3> Snapshot </h3>
          <p>
            {' '}
            Revisa el expediente de los cedentes y brókers, ¡Todo en un solo
            lugar!
          </p>
        </PageCard> */}
        <div style={{display:  'grid ', marginTop:  '35px ', width: '100% ', gridTemplateColumns:'1fr 1fr', justifyContent:'space-between'}}>        
        {/* <PageCard style={{justifyContent:'flex-start', width:'100%',alignItems: 'center'}}>
          <img style={styles} src={suscripción_500} alt="imagew" width={68} />
          <h3> Suscripción y siniestros </h3>
          <p>
            {' '}
            Realiza el estatus de los procesos de suscripción y siniestros
            activos. <b> (Próximamente) </b>
          </p>
        </PageCard>
        <PageCard style={{width:'100%'}}/> */}
        </div>   
      </PageContainerCards>
      <Copyright color="#222" backgroundColor="#fff">
        <img src={logo_patria} alt="logo_patria" width={68} />
      </Copyright>
    </Page>
  );
};

export default HomeUser;
