import React,{useState} from 'react';
import {NavLink} from 'react-router-dom';
import {NavNavigation,NavContainer,NavSesion,NavLinkSesion,NavMenu,NavOverlay,NavLinkOverlay} from '../StylesComponents/common-styled';
import './index.css';
import IconMenu from '../IconMenu';
import logoP from '../../assets/images/images/logo_patria.png';
const Navbar=()=>{
    const [classes,setClasees]=useState('')
    const [display,setDisplay]=useState('')
    const onClick=()=>{
        classes==='active'?setClasees(''):setClasees('active')
        display==='active-display'?setDisplay(''):setDisplay("active-display")
    }
    return(
    <NavNavigation>
        <NavContainer>
            <img src={logoP} alt="logo_patria" width={68}/>
            <NavSesion>
                <NavLink exact to="/crm/login">
                    <NavLinkSesion color="#222222">
                        Iniciar sesión 
                    </NavLinkSesion>
                </NavLink>
            </NavSesion>
            <NavMenu className={classes}>
                <div style={{fontSize:"30px"}} onClick={onClick}>
                    <IconMenu/>
                </div>
            </NavMenu>
        </NavContainer>
        <NavOverlay className={display}>
            <nav>
                <NavLink  exact to="/crm/login">
                    <NavLinkOverlay>
                    Iniciar sesión
                    </NavLinkOverlay>
                </NavLink>
            </nav>
        </NavOverlay>
    </NavNavigation>
)}

export default Navbar;