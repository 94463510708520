import React, { useState, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const UserAutoComplete = ({ Employes, User, editUser }) => {
  const [value, setValue] = useState(User);
  return (
    <Fragment>
      <Autocomplete
        freeSolo
        inputValue={value}
        disabled={'disabled'}
        options={Employes}
        getOptionLabel={(option) =>
          `${option.cEMP_Nombre.trim()} ${option.cEMP_ApePaterno.trim()} ${option.cEMP_ApeMaterno.trim()}`
        }
        onChange={(event, newValue) => {
          newValue !== null
            ? setValue(
                `${newValue.cEMP_Nombre.trim()} ${newValue.cEMP_ApePaterno.trim()} ${newValue.cEMP_ApeMaterno.trim()}`
              )
            : setValue('');
          if (newValue !== null) editUser(newValue.cEMP_Id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setValue(e.target.value)}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
            id="search-invitados"
            className={`${
              value !== '' && value !== undefined ? 'inputActive' : ''
            }`}
          />
        )}
      />
    </Fragment>
  );
};

export default UserAutoComplete;
