import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import ButtonNavigation from './Viaticos/ButtonNavigation';
const Roles = {
    61: 'Viajero',
    62: 'Asistente',
    63: 'Jefe directo',
    64: 'Director',
    65: 'Contabilidad'
}
const UserRolSelection = ({ session, setNewSession }) => {
    const [options, setOptions] = useState([])
    const history = useHistory();
    
    useEffect(() => {
        if (session?.obtenerUsuario) {
            if (localStorage.getItem('userRol')) {
                history.push('/crm/secciones')
            } else {
                setOptions(session?.obtenerUsuario.profile)
            }
        }
    }, [session]) 
    if (options.length === 0) return null;
    return (
        <div style={{ display: 'flex', height: '500px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <select
                style={{ marginBottom: 20, height: 50, width: '20%', borderRadius: 8 }}
                onChange={(e) => {
                    setNewSession({
                        obtenerUsuario: {
                            ...session.obtenerUsuario,
                            profile: parseInt(e.target.value)
                        },
                    });
                    localStorage.setItem('userRol', parseInt(e.target.value))
                }}
            >
                <option>Selecciona un rol para iniciar</option>
                {options.map((rol) => (
                    <option key={rol} value={rol}>{Roles[rol]}</option>
                ))}

            </select>
            <ButtonNavigation
                text='Continuar'
                width='20%'
                backgroundColor="#3898EC"
                border="none"
                color="#fff"
                justifyContent="center"
                onClick={() => history.push('/crm/secciones')}
            />
        </div>
    )
}

export default UserRolSelection