import React, { useState, useEffect } from 'react'
import {
    CardList
} from '../StylesComponents/common-styled';
import newSolicitud from '../../assets/images/icons/documento.svg';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
const CardListReuniones = ({ meetings = [], setEmpleado, setReunion }) => {
    const [hoverButtons, setHoverButtons] = useState({});
    const handleClick = (prop, meeting) => {
        sessionStorage.setItem('cREU_Titulo', meeting.cREU_Titulo);
        sessionStorage.setItem('cREU_Titulo', meeting.cREU_Titulo)
        sessionStorage.setItem('cREU_Id', meeting.cREU_Id);
        sessionStorage.setItem('emailToTraveler', meeting.cEMP_Mail);
        setHoverButtons({
            [prop]: true,
        });
        setReunion({
            titulo: meeting.cREU_Titulo,
            texto1: `${meeting.cREU_FechaInicio}-${meeting.cREU_FechaFin}`,
            texto2: `${meeting.Pais}, ${meeting.Estado}`,
            texto3: meeting.cREU_TipoReunion,
        });
        setEmpleado({
            titulo: meeting.Nombre_Empleado,
            texto1: `No. empleado ${meeting.cEMP_Id}`,
            texto2: 'Suscripción',
            texto3: 'Patria RE',
        });
    }
    const handleOrderMeetings = () => {
         return meetings.sort((a, b) =>
            a.cREU_Id < b.cREU_Id ? -1 : 1
        )
    }
    useEffect(() => {
        if (meetings.length > 0) {
            sessionStorage.setItem('cREU_Titulo', meetings[0].cREU_Titulo);
            sessionStorage.setItem('cREU_Titulo', meetings[0].cREU_Titulo)
            sessionStorage.setItem('cREU_Id', meetings[0].cREU_Id);
            sessionStorage.setItem('emailToTraveler', meetings[0].cEMP_Mail);
            sessionStorage.setItem('nombre_Empleado', meetings[0].Nombre_Empleado)
            setReunion({
                titulo: meetings[0].cREU_Titulo,
                texto1: `${meetings[0].cREU_FechaInicio}-${meetings[0].cREU_FechaFin}`,
                texto2: `${meetings[0].Pais}, ${meetings[0].Estado}`,
                texto3: meetings[0].cREU_TipoReunion,
            });
            setEmpleado({
                titulo: meetings[0].Nombre_Empleado,
                texto1: `No. empleado ${meetings[0].cEMP_Id}`,
                texto2: 'Suscripción',
                texto3: 'Patria RE',
            });
        }
    }, [meetings]);
    return (
        <CardList>
            {handleOrderMeetings().map((meeting) => (
                <ButtonNavigation
                    width='300px'
                    text={meeting.cREU_Titulo}
                    justifyContent="space-between"
                    style={{
                        padding: '0 30px',
                        display: 'grid',
                        gridTemplateColumns: '10% 88%',
                        textAlign: 'left',
                    }}
                    img={newSolicitud}
                    backgroundColor={hoverButtons[meeting.cREU_Id] ? '#3898EC' : null}
                    border={hoverButtons[meeting.cREU_Id] ? '#3898EC' : null}
                    color={hoverButtons[meeting.cREU_Id] ? '#FFF' : null}
                    onClick={() => {
                        handleClick(meeting.cREU_Id, meeting);
                    }}
                />
            ))}
        </CardList>
    )
}

export default CardListReuniones