import React, { useEffect, useState, useContext, Fragment } from 'react';
import Homebar from './Homebar';
import {
  PageContainer,
  Title,
  Subtitle,
  DivNavigationButton,
  AcordeonContainer,
} from './StylesComponents/common-styled';
import { navigation } from '../funciones-auxiliares/programar-reunion';
import './../assets/styles/css/master.css';
import { useHistory } from 'react-router-dom';
import RequestsInProgress from './ViaticosV2/RequestsInProgress';
import { useGetViaticos } from '../helpers/hooks/useViaticos';
import { MyContext } from './Context';
import Pendings from './ViaticosV2/Pendings';
import ButtonsTraveler from './ViaticosV2/ButtonsTraveler';
import ButtonsTravelAssistant from './ViaticosV2/ButtonsTravelAssistant';
import Solicitud from './ViaticosV2/Solicitud';
import SelectionQuotesTraveler from './ViaticosV2/SelectionQuotesTraveler';
import OnlyReadVit from './ViaticosV2/OnlyReadVit';
import ButtonsDirector from './ViaticosV2/ButtonsDirector';
import DirectorReview from './ViaticosV2/DirectorReview';
import TravelUpdates from './ViaticosV2/TravelUpdates';
import ButtonsJefe from './ViaticosV2/ButtonsJefe';
import CheckingPolitica from './ViaticosV2/CheckingPolitica';
import ButtonsAccounting from './ViaticosV2/ButtonsAccounting';
import TravelerConfirmation from './ViaticosV2/TravelerConfirmation';
// componentes comprobacion
import ExpensesCheckView from './ViaticosV2/ExpensesCheckView';
import AccountingMovements from './ViaticosV2/AccountingMovements';
import CheckingTraveler from './ViaticosV2/CheckingTraveler';
const Viaticos = ({ session }) => {
  const statusComp = session.obtenerUsuario.profile === 61
    ? 3
    : session.obtenerUsuario.profile === 63
      ? 4
      : session.obtenerUsuario.profile === 65
        ? 6
        : session.obtenerUsuario.profile === 64 ? 5 : null;
  const { data: viaticos } = useGetViaticos({ cVIT_Avance: 12, cVIT_Status_COMP: statusComp });
  const history = useHistory();
  const context = useContext(MyContext);
  const [cVIT_Status_VIAT, setCVIT_Status_VIAT] = useState('')
  const [cVIT_Avance, setCVIT_Avance] = useState(0);
  const [actionBtn, setActionBtn] = useState({
    newApplication: true,
    progressSolicitud: false,
    pendingTickets: false,
    pendings: false,
    selectionQuotesTraveler: false,
    onlyReadVit: false,
    directorReview: false,
    pendingAuthorizations: false,
    travelUpdates: false,
    reviewPol: false,
    checkingPol: false,
    travelerConfirmations: false,
    expenseCheckView: false,
    cheeckingAccouting: false,
    accountingMovements: false,
    requestCheckTraveler: false,
    cheeckingTraveler: false,

  })
  const handleClick = (action, status) => {
    setActionBtn({
      newApplication: !status,
      progressSolicitud: !status,
      pendingTickets: !status,
      pendings: !status,
      selectionQuotesTraveler: !status,
      onlyReadVit: !status,
      directorReview: !status,
      pendingAuthorizations: !status,
      travelUpdates: !status,
      reviewPol: !status,
      checkingPol: !status,
      travelerConfirmations: !status,
      expenseCheckView: !status,
      cheeckingAccouting: !status,
      accountingMovements: !status,
      requestCheckTraveler: !status,
      cheeckingTraveler: !status,
      [action]: status
    })
  }
  const getAvanceByRol = () => {
    switch (session.obtenerUsuario.profile) {
      case 63:
        return 6;
      case 64:
        return 7;
      case 65:
        return 8;        
      default:
        break;
    }
  }

  useEffect(() => {
    if (!session.obtenerUsuario) {
      history.push('/crm')
    }
    if (
      context.state.AuthComponentProps.isAuthenticated === null ||
      context.state.AuthComponentProps.isAuthenticated === false
    ) {
      history.push('/crm/secciones');
    }
    if (session.obtenerUsuario.profile === 65) {
      setActionBtn({
        ...actionBtn,
        newApplication: false,
        reviewPol: true
      })
    }
  }, []);
  // if (loading) {
  //   return (
  //     <>
  //       <Homebar navigation={navigation} />
  //       <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
  //         <CircularProgress disableShrink size={100} />
  //       </div>
  //     </>
  //   )
  // }
  return (
    <Fragment>
      <Homebar navigation={navigation} />
      <PageContainer>
        <Title>Viáticos</Title>
        <Subtitle>
          Solicita, registra y autoriza los viáticos de tus viajes.
        </Subtitle>
        <Subtitle color="#000"
          style={{ fontWeight: 'bold', alignItems: 'center' }}
        >Registra los viáticos de tu viaje</Subtitle>
      </PageContainer>
      <AcordeonContainer
        width=" "
        flexDirection="row"
        justifyContent="space-around"
        id="viaticos-solicitud"
      >
        <DivNavigationButton
          width="20%"
          alignSelf="start"
          id="button-navigation"
        >
          {session.obtenerUsuario.profile === 61 && (
            <ButtonsTraveler
              actionBtn={actionBtn}
              handleClick={handleClick}
              viaticos={viaticos}
            />
          )}
          {session.obtenerUsuario.profile === 62 && (
            <ButtonsTravelAssistant
              actionBtn={actionBtn}
              handleClick={handleClick}
              setCVIT_Avance={setCVIT_Avance}
            />
          )}
          {session.obtenerUsuario.profile === 63 && (
            <ButtonsJefe
              actionBtn={actionBtn}
              handleClick={handleClick}
              viaticos={viaticos}
            />
          )}
          {session.obtenerUsuario.profile === 64 && (
            <ButtonsDirector
              actionBtn={actionBtn}
              handleClick={handleClick}
              viaticos={viaticos}
            />
          )}
          {session.obtenerUsuario.profile === 65 && (
            <ButtonsAccounting
              actionBtn={actionBtn}
              handleClick={handleClick}
              viaticos={viaticos}
            />
          )}
        </DivNavigationButton>
        {/* ######################### PANTALLAS ########################## */}
        {actionBtn.newApplication && session.obtenerUsuario ? (
          <Solicitud
            cEMP_Id={session.obtenerUsuario}
            handleComponent={() => {
              handleClick('progressSolicitud', true);
            }}
            cVIT_Status_VIAT={cVIT_Status_VIAT}
          />
        ) : null}
        {actionBtn.progressSolicitud && session.obtenerUsuario ? (
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              if (cVIT_Avance == 2 && cVIT_Status_VIAT == 2) {
                handleClick('selectionQuotesTraveler', true);
              } else if (cVIT_Avance == 5 && cVIT_Status_VIAT == 4) {
                handleClick('travelUpdates', true);
              } else if (cVIT_Avance == 9 && cVIT_Status_VIAT == 4) {
                handleClick('travelerConfirmations', true);
              } else {
                handleClick('onlyReadVit', true);
              }
              setCVIT_Status_VIAT(cVIT_Status_VIAT)
            }}
            title='Solicitudes de viáticos'
            getAllExpenses={true}
          />
        ) : null}
        {actionBtn.onlyReadVit && session.obtenerUsuario ? (
          <OnlyReadVit />
        ) : null}
        {actionBtn.pendingTickets && session.obtenerUsuario ? (
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              setCVIT_Status_VIAT(cVIT_Status_VIAT);
              setCVIT_Avance(cVIT_Avance);
              if (cVIT_Status_VIAT == 4 && cVIT_Avance == 5) {
                handleClick('onlyReadVit', true);
              } else {
                handleClick('pendings', true);
              }
            }}
            cVIT_Avance={cVIT_Avance}
            cVIT_Status_VIAT={1}
            title="Solicitudes de viáticos"
          />
        ) : null}
        {actionBtn.pendings && session.obtenerUsuario ? (
          <Pendings
            cEMP_Id={session.obtenerUsuario}
            handleComponent={() => {
              // handleClick('progressSolicitud', true);              
              handleClick('pendingTickets', true);
            }}
            cVIT_Avance={cVIT_Avance}
          />
        ) : null}
        {actionBtn.selectionQuotesTraveler && session.obtenerUsuario ? (
          <SelectionQuotesTraveler
            cEMP_Id={session.obtenerUsuario}
            handleComponent={() => {
              handleClick('progressSolicitud', true);
            }}
          />
        ) : null}
        {actionBtn.travelUpdates && session.obtenerUsuario ? (
          <TravelUpdates
            cEMP={session.obtenerUsuario}
          />
        ) : null}
        {actionBtn.directorReview && session.obtenerUsuario ? (
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              setCVIT_Status_VIAT(cVIT_Status_VIAT);
              setCVIT_Avance(cVIT_Avance);
              if (cVIT_Status_VIAT == 4 && cVIT_Avance == 7) {
                handleClick('checkingPol', true);
              } else if (cVIT_Status_VIAT == 2 && cVIT_Avance == 3) {
                handleClick('pendingAuthorizations', true);
              } else {
                handleClick('onlyReadVit', true);
              }
            }}
            cVIT_Avance={3}
            cVIT_Status_VIAT={2}
            title="Solicitudes de viáticos"
          />
        ) : null}
        {actionBtn.pendingAuthorizations && session.obtenerUsuario ? (
          <DirectorReview
            cEMP_Id={session.obtenerUsuario}
            handleComponent={() => {
              handleClick('directorReview', true);
            }}
          />
        ) : null}
        {actionBtn.reviewPol && session.obtenerUsuario ? (
          // componente reutilizado para contabilidad, director y jefe directo
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              if (cVIT_Status_VIAT == 4 && (cVIT_Avance == 6 || cVIT_Avance == 7 || cVIT_Avance == 8)) {
                handleClick('checkingPol', true);
              } else {
                handleClick('onlyReadVit', true);
              }
              setCVIT_Status_VIAT(cVIT_Status_VIAT);
              setCVIT_Avance(cVIT_Avance);
            }}
            cVIT_Avance={getAvanceByRol()}
            cVIT_Status_VIAT={4}
            title="Autorizaciones pendientes"
          />
        ) : null}
        {actionBtn.checkingPol && session.obtenerUsuario ? (
          <CheckingPolitica
            cEMP={session.obtenerUsuario}
            cVIT_Avance={cVIT_Avance}
          />
        ) : null}
        {actionBtn.travelerConfirmations && session.obtenerUsuario ? (
          <TravelerConfirmation
            cEMP={session.obtenerUsuario}
          />
        ) : null}
        {/* #################COMPROBACION DE GASTOS##################### */}
        {actionBtn.expenseCheckView && session.obtenerUsuario ? (
          <ExpensesCheckView
            cEMP_Id={session.obtenerUsuario}
            handleComponent={() => {
              // handleClick('expenseCheckView', true);                            
            }}
          />
        ) : null}
        {actionBtn.cheeckingAccouting && session.obtenerUsuario ? (
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              handleClick('accountingMovements', true);
              setCVIT_Status_VIAT(cVIT_Status_VIAT);
              setCVIT_Avance(cVIT_Avance);
            }}
            cVIT_Avance={11}
            cVIT_Status_COMP={2}
            title="Comprobación de gastos"
          />
        ) : null}
        {actionBtn.accountingMovements && session.obtenerUsuario ? (
          <AccountingMovements
            onClick={() => {
              handleClick('cheeckingAccouting', true);
            }}
          />
        ) : null}
        {actionBtn.requestCheckTraveler && session.obtenerUsuario ? (
          <RequestsInProgress
            cEMP_Id={session.obtenerUsuario}
            handleComponent={(cVIT_Status_VIAT, cVIT_Avance) => {
              handleClick('cheeckingTraveler', true);
              setCVIT_Status_VIAT(cVIT_Status_VIAT);
              setCVIT_Avance(cVIT_Avance);
            }}
            cVIT_Avance={12} // el avance puede ser 13... aunque se pase el 12
            cVIT_Status_COMP={statusComp}
            title="Comprobación de gastos"
          />
        ) : null}
        {actionBtn.cheeckingTraveler && session.obtenerUsuario ? (
          <CheckingTraveler
            cEMP={session.obtenerUsuario}
            cVIT_Status_COMP={statusComp}
          />
        ) : null}
      </AcordeonContainer>
    </Fragment>
  );
};

export default Viaticos;
