import React, {
    useEffect,
    useState,
    useContext,
    Fragment
} from 'react';
import {
    PageContainer,
    Subtitle,
    ContainerTwoInputs,
} from '../StylesComponents/common-styled';
import DialogAlert from '../DiaologAlert';
import Card from '../Viaticos/Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import ButtonsInformacion from '../Viaticos/ButtonsInformacion';
import FormTravels from '../Viaticos/FormTravels';
import FormAlojamiento from '../Viaticos/FormAlojamiento';
import FormMateriales from '../Viaticos/FormMateriales';
import { TextareaAutosize } from '@material-ui/core';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { getcTOBS_Id, getRol } from '../../funciones-auxiliares/viaticos';
import { useLazyQuery, useMutation } from 'react-apollo';
import { GET_EVENTO_BY_CREU_ID } from '../../query/query';
import {
    DYNAMIC_INSERT,
    DYNAMIC_UPDATE,
} from '../../mutation/mutation';
import { MyContext } from '../Context';
import ToggleButtons from '../Viaticos/ToggleButons';
import { sendEmail } from '../../services/SendEmails';
import RolSelection from '../Acuerdos/common/RolSelection';
import Download from './Download';
import CardListReuniones from './CardListReuniones';
import { useMediaQuery } from '@material-ui/core';
const SelectionQuotesTraveler = ({ cEMP_Id, handleComponent, title, cVIT_Status_VIAT = '' }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const context = useContext(MyContext);
    const [selectedCot, setSelectedCot] = useState('');
    const [data, setData] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [data2, setData2] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [value, setValue] = useState('Ida y vuelta');
    const [tramos] = useState([
        {
            titulo: 'Tramo 1',
        },
    ]);
    const [viaje, setViaje] = useState([
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
    ]);
    const [alojamiento, setAlojamiento] = useState([
        {
            hotel: '',
            urlCotizacion: '',
            fecha: '',
            precio: '',
        },
    ]);
    const [dataXLSX, setDataXLSX] = useState([]);
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [meetings, setMeetings] = useState([]);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            // sessionStorage.setItem('cREU_Titulo', getReunionBycREUID.cREU_Titulo);
            // setData({
            //     titulo: getReunionBycREUID.cREU_Titulo,
            //     texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
            //     texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
            //     texto3: getReunionBycREUID.cREU_TipoReunion,
            // });
            // setData2({
            //     titulo: getReunionBycREUID.Nombre_Empleado,
            //     texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
            //     texto2: 'Suscripción',
            //     texto3: 'Patria RE',
            // });
        },
    });

    const [insertObservations] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('resultado', dynamicInsert)
            if (dynamicInsert.statusCode === 200) {
                // handleComponent();
            }
        },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });
    const [updateStatus] = useMutation(DYNAMIC_UPDATE);
    const getViaticos = () => {
        switch (context.state.viaticosProps.cTIP_Id) {
            case 1:
                setValue('Ida y vuelta');
                break;
            case 2:
                setValue('Solo ida');
                break;
            case 3:
                setValue('Múltiples destinos');
                break;
            default:
                console.log('Nada');
        }
        const viajes = context.state.viaticosProps.viajes.map((viaje) => {
            return {
                ...viaje,
                origen: viaje.cVIA_Origen,
                destino: viaje.cVIA_Destino,
                aerolinea: viaje.cVIA_Areolinea,
                vuelo: viaje.cVIA_NoVuelo,
                cotizacion: viaje.cVIA_URLVueloCot,
                fecha: viaje.cVIA_FechaVuelo.slice(0, 10),
                horario: viaje.cVIA_HorarioVuelo.slice(11, 16),
                precio: viaje.cVIA_PrecioVuelo,
            };
        });
        const alojamientos = context.state.viaticosProps.alojamientos.map(
            (alojamiento) => {
                return {
                    ...alojamiento,
                    hotel: alojamiento.cALO_Hotel,
                    urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
                    fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
                    precio: alojamiento.cALO_Precio,
                };
            }
        );
        setViaje(viajes);
        setAlojamiento(alojamientos);
    };

    const handleChange = ({ target }) => {
        sessionStorage.setItem('observations', target.value);
    };
    const handleChangeCot = ({ target }) => {
        const { value } = target;
        setSelectedCot(value)
    }
    const sendToDirector = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' })
        const observations = sessionStorage.getItem('observations');
        if (selectedCot !== '') {
            if (observations !== undefined && observations !== null) {                
                const inputInsert = {
                    table: 'cOBS_ObservacionesViaticos',
                    columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                    detailValues: [
                        {
                            values: [
                                String(context.state.viaticosProps.cVIT_Id),
                                String(cEMP_Id.cEMP_Id),
                                observations,
                                String(getcTOBS_Id(cEMP_Id.profile)),
                            ],
                        },
                    ],
                }
                await insertObservations({ variables: { input: inputInsert } });
            }
            const insertSelectedCot = {
                table: 'cCOTVIT_Cotizaciones',
                columns: ['cCOTVIT_FileName', 'cVIT_Id', 'cCOTVIT_Selected'],
                detailValues: [
                    {
                        values: [selectedCot, String(context.state.viaticosProps.cVIT_Id), '1']
                    }
                ]
            }
            await insertObservations({ variables: { input: insertSelectedCot } });
            let columns = [
                {
                    setkey: 'cVIT_Avance',
                    setval: '3',
                },
            ];
            let conditions = [
                {
                    valone: 'cVIT_Id',
                    valtwo: String(context.state.viaticosProps.cVIT_Id),
                    condition: '=',
                },
            ];
            await updateStatus({
                variables: {
                    input: {
                        table: 'cVIT_Viaticos',
                        columns,
                        conditions
                    }
                }
            }).then(({ data }) => {
                if (data.dynamicUpdate.statusCode === 200) {
                    setShowModalErr(true)
                    setMsgModalErr({
                        title: 'Ticket enviado al Director',
                        text: '',
                    })
                    handleComponent();
                }
            }).catch((error) => {
                setShowModalErr(true)
                setMsgModalErr({
                    title: 'Error',
                    text: 'No se pudo procesar la solicitud',
                })
                console.log(error)
                setStyleDisabled({ })
            });
            const token = context.state.AuthComponentProps.token;
            if (sessionStorage.getItem('emailTo')) {
                await sendEmail({
                    accessToken: token,            
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionThree'            
                });
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona un correo para enviar',
                });
                setShowModalErr(true) 
                setStyleDisabled({ })               
            }
            
        } else {
            setMsgModalErr({
                title: 'Error',
                text: 'Selecciona una de las cotizaciones',
            })
            setShowModalErr(true)
            setStyleDisabled({ })

        }
    }    
    useEffect(() => {
        sessionStorage.clear();
        sessionStorage.setItem('cTIP_Id', 1);
        if (context.state.viaticosProps.cVIT_Id !== '') {
            getViaticos();
            getEventBycREU({
                variables: { cREU_Id: context.state.viaticosProps.reuniones },
            });
        }
    }, []);
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation">
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <Subtitle color="#000" style={{ alignItems: 'center' }}>
                {'Revisa solicitud de los viáticos de viaje' || title}
            </Subtitle>
            <CardListReuniones meetings={meetings} setEmpleado={setData2} setReunion={setData} />
            <ContainerTwoInputs
                width="100%"
                margin="20px 0px"
                id="viaticos-solicitud"
            >
                <Card data={data} img={calendario} />
                <Card data={data2} img={user} />
            </ContainerTwoInputs>
            <ButtonsInformacion
                title="Información de transporte"
                text1="Ida y vuelta"
                text2="Solo ida"
                text3="Múltiples destinos"
                cTIp_Id={context.state.viaticosProps.cTIP_Id}
            />
            {value === 'Múltiples destinos' ? null : (
                <FormTravels
                    title="Viaje de ida"
                    id={0}
                    valueInputs={viaje}
                    asistente={true}
                />
            )}
            {value === 'Ida y vuelta' ? (
                <FormTravels
                    title="Viaje de vuelta"
                    id={1}
                    valueInputs={viaje}
                    asistente={true}
                />
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {viaje.map((tramo, index) => (
                        <FormTravels
                            key={index}
                            title={`Tramo ${index + 1}`}
                            id={index}
                            valueInputs={viaje}
                            asistente={true}
                        />
                    ))}
                </Fragment>
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {alojamiento.map((tramo, index) => (
                        <FormAlojamiento
                            key={index}
                            title={`Alojamiento - Tramo ${index + 1}`}
                            id={index}
                            valueInputs={alojamiento}
                            asistente={true}
                        />
                    ))}
                </Fragment>
            ) : (
                <FormAlojamiento
                    title="Información de alojamiento"
                    id={0}
                    valueInputs={alojamiento}
                    asistente={true}
                />
            )}
            <FormMateriales
                title="Equipo o materiales para viaje"
                cambio={value}
                asistente={true}
            />
            {context.state.viaticosProps.observaciones.map((data, index) => {
                return (
                    <Fragment key={index}>
                        <div style={{ marginTop: '20px' }}>
                            <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                data.cTOBS_Id
                            )}`}</h3>
                        </div>
                        <TextareaAutosize
                            minRows={10}
                            style={{ width: '90%' }}
                            id="text-mensaje"
                            name="area"
                            value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                            disabled
                        />
                    </Fragment>
                );
            })}
            <div style={{ marginTop: '20px' }}>
                <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
            </div>
            <TextareaAutosize
                minRows={10}
                style={{ width: '90%' }}
                id="text-mensaje"
                name="observaciones"
                onChange={handleChange}
                value={data.cOBS_Descripcion}
                placeholder="Escribe comentarios del viaje ..."
            />
            {/* <ToggleButtons width='90%' /> */}
            <Download
                showSelectCot={true}
                handleChangeCot={handleChangeCot}
            />
            <div
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
            >
                <RolSelection profile='64' />
                <ButtonNavigation
                    text="Guardar"
                    width={isMobile ? '100%':  "45%"}
                    backgroundColor="#3898EC"
                    border="none"
                    color="#fff"
                    justifyContent="center"
                    onClick={sendToDirector}
                    style={styleDisabled}
                />
            </div>
        </PageContainer>
    );
};

export default SelectionQuotesTraveler;
