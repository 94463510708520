import React, { Fragment, useState, useEffect, useContext } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { Chip } from '@material-ui/core';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import '../components.css';
import { ArrowBackIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { MyContext } from '../Context';
import { DYNAMIC_UPDATE, GET_REUNION_ACUERDOS_AREAS } from '../../mutation/mutation';
import { useMutation } from 'react-apollo';

import DialogAlert from '../../components/DiaologAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
const Report = ({ onClick, onFinish, tipoRelacion }) => {
  const classes = useStyles();
  const context = useContext(MyContext).state;
  console.log(context.commentsAcuerdos);
  const contextSet = useContext(MyContext);
  const [currentQuestion, setcurrentQuestion] = useState('');
  const [steps, setSteps] = useState([]);
  const [disabledOne, setDisabledOne] = useState(true);
  const [disabledTwo, setDisabledTwo] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [areasSeleccionadas, setAreasSeleccionadas] = useState({
    areas: [],
    subAreas: [],
    relAreas: [],
    relSubAreas: []
  });
  const [contentModal, setContentModal] = useState({
    title: '',
    message: ''
  });
  
  const [saveComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const [getAreasAcuerdos] = useMutation(GET_REUNION_ACUERDOS_AREAS, {
    onCompleted({ getAreasAcu }) {
      console.log("Full response", getAreasAcu);
      const {seguimientoEntorno, seguimientoRelacionPatria} = ((JSON.parse(getAreasAcu.response))[0]);
      console.log("RESPONSE: ",JSON.parse(getAreasAcu.response));
      console.log("RESPONSE: ",seguimientoEntorno, seguimientoRelacionPatria);
      setAreasSeleccionadas({
        areas: seguimientoEntorno.area != undefined ? seguimientoEntorno.area : [],
        subAreas: seguimientoEntorno.subArea != undefined ? seguimientoEntorno.subArea : [],
        relAreas: seguimientoRelacionPatria.areaRelacion != undefined ? seguimientoRelacionPatria.areaRelacion : [],
        relSubAreas: seguimientoRelacionPatria.subAreaRelacion != undefined ? seguimientoRelacionPatria.subAreaRelacion : [],
      })

      setCargando(false);
    },
    onError(err) {
      setCargando(false);
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const handleCommentsAndTracing = (option) => {
    switch (option) {
      case 'Resumen de Entorno':
        setcurrentQuestion(option);
        break;
      case 'Resumen de Relación con Patria':
        setcurrentQuestion(option);
        break;
      case 'Nuevo Seguimiento':
        onClick();
        break;

      default:
        break;
    }
  };

  const handleOnClickBack = () => {
    let index = steps.indexOf(currentQuestion);
    let step = index === 0 ? index : index - 1;
    if (index === 0) {
      setcurrentQuestion('');
      steps.splice(index, 1);
    } else {
      setcurrentQuestion(steps[step]);
      steps.splice(index, 1);
    }
  };

  const getAreas = async() => {

    console.log("GET AREAS", context.saveAcuerdos.cACU_Id);
    setCargando(true);

    await getAreasAcuerdos({
      variables: {
        ids: `${context.saveAcuerdos.cACU_Id}`,
        status: ""
      }
    })

    console.log(areasSeleccionadas);
  }

  useEffect(() => {
    console.log("OBJECT AREAS", areasSeleccionadas);
  }, [areasSeleccionadas])

  useEffect(() => {

    getAreas();

    if(tipoRelacion !== ''){
      setcurrentQuestion('Resumen de Relación con Patria');
      console.log("OPCION:", tipoRelacion);
    }
    console.log("OPCION:", tipoRelacion);
  }, [tipoRelacion])

  useEffect(() => {
    if (currentQuestion !== '' && !steps.includes(currentQuestion))
      setSteps([...steps, currentQuestion]);

    console.log("CONTEXTO", context);
  }, [currentQuestion]);

  const onFinishReport = () => {
    saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify(contextSet.state.commentsAcuerdos),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + contextSet.state.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });
    onFinish();
  };

  return (
    <Fragment>
      <div onClick={() => handleOnClickBack()} style={{ cursor: 'pointer' }}>
        {/* <ArrowBackIos style={{ color: '#01539C' }} /> */}
      </div>
      { feedbackModal && (
        <DialogAlert
        title={contentModal.title}
        text={contentModal.message}
        textButtonA="Aceptar"
        open={feedbackModal}
        handleClose={onFinishReport}
        />)
      }
      {currentQuestion === '' && (
        <DivContainer
          title="Seguimiento"
          subtitle="Revisa los comentarios y seguimiento registrados."
        >
          {[
            'Resumen de Entorno',
            'Resumen de Relación con Patria',
            'Nuevo Seguimiento',
          ].map((option, index) => (
            <ButtonNavigation
              text={option}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => handleCommentsAndTracing(option)}
            />
          ))}
        </DivContainer>
      )}
      {currentQuestion === 'Resumen de Entorno' && (
        <Fragment>
          <DivContainer title="Resumen de Seguimiento sobre Entorno">
            <ImputResumen
              title="Cometarios de Entorno"
              subtitle={
                context?.saveAcuerdos.cSUBE_Descripcion ||
                context.cREGSS_Descripcion ||
                context.cSUBTS_Descripcion
              }
              disabled={disabledOne}
              setDisabled={() => setDisabledOne(!disabledOne)}
              value={context?.commentsAcuerdos.entorno}
              setValue={(value) =>
                contextSet?.setSaveAcuerdos({ cCOMA_Descripcion: value })
              }
              flexDirection="column"
              ramo={context?.saveAcuerdos.cENT_Descripcion}
            />
            <ImputResumen
              title="Acuerdo"
              status={context?.saveAcuerdos.cESTS_Descripcion}
              subtitle={
                context?.saveAcuerdos.cSUBTS_Descripcion ||
                context.saveAcuerdos.cREGSS_Descripcion
              }
              value={context?.commentsAcuerdos.segEntorno}
              setValue={(value) => {
                contextSet.setSaveAcuerdos({ sComentarios: value });
                contextSet.setCommentsAcuerdos({ segEntorno: value });
              }}
              disabled={disabledTwo}
              setDisabled={() => setDisabledTwo(!disabledTwo)}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>Ramo</h4>
              <span
                style={{
                  width: '120px',
                  height: '25px',
                  backgroundColor: '#85858585',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                {context.saveAcuerdos.cENT_Descripcion}
              </span>
            </div>
          </DivContainer>
          {(!cargando) ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'initial',
              }}
            >
              <h4 style={{ color: '#3898EC' }}>Áreas</h4>
              <div className={classes.root}>
                {/* {context?.saveSegRelPatria.areas &&
                  context?.saveSegRelPatria.areas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))} */}
                  {areasSeleccionadas.areas.length > 0 &&
                  areasSeleccionadas.areas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
                  {areasSeleccionadas.subAreas.length > 0 &&
                  areasSeleccionadas.subAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
                  {/* {areasSeleccionadas.relAreas.length > 0 &&
                  areasSeleccionadas.relAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
                  {areasSeleccionadas.relSubAreas.length > 0 &&
                  areasSeleccionadas.relSubAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))} */}
              </div>
            </div>
          ) : (
            <h4 style={{ color: '#3898EC' }}>Obteniendo áreas</h4>
          )}
          {/* {context?.saveAcuerdos?.subArea?.length > 0 ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'initial',
              }}
            >
              <h4 style={{ color: '#3898EC' }}>Áreas </h4>
              <div className={classes.root}>
                {context?.saveAcuerdos.subArea &&
                  context?.saveAcuerdos.subArea.map(
                    ({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                      status ? (
                        <Chip
                          label={cSUBA_Descripcion}
                          style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                          key={index}
                        />
                      ) : null
                  )}
              </div>
            </div>
          ) : null} */}
          <ButtonNavigation
            text="Finalizar"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => {
              setFeedbackModal(true);
              setContentModal({
                title: 'Guardado exitoso',
                message: 'Se guardado el reporte'
              })
            }}
          />
          <ButtonNavigation
            text="Registrar Nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => onClick()}
          />
        </Fragment>
      )}
      {currentQuestion === 'Resumen de Relación con Patria' && (
        <Fragment>
          <DivContainer title="Resumen de Seguimiento sobre Relación con Patria">
            {/* {context.commentsAcuerdos.segRelacion != '' && <div style={{ display: 'inline-block', flexDirection: 'column' }}>
              <h4>Relación</h4>
              <span
                style={{
                  width: 'auto',
                  height: '25px',
                  backgroundColor: '#85858585',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                {context?.relacionData.tipoRelacion}
              </span>
            </div>} */}
            <ImputResumen
              title="Comentarios de Relación con Patria:"
              subtitle={context?.relacionData.tipoRelacion}
              // ramo={}
              disabled={disabledOne}
              setDisabled={() => setDisabledOne(!disabledOne)}
              value={contextSet?.state.commentsAcuerdos.relacion}
              setValue={(value) => {
                contextSet.setCommentsAcuerdos({ relacion: value });
              }}
              flexDirection="column"
            />
            {/* {context.commentsAcuerdos.segRelacion != '' && <div style={{ display: 'inline-block', flexDirection: 'column' }}>
              <h4>Seguimiento</h4>
              <span
                style={{
                  width: 'auto',
                  height: '25px',
                  backgroundColor: '#85858585',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                {context?.relacionData.tipoSeguimiento}
              </span>
            </div>} */}
            <ImputResumen
              title={context?.relacionData.tipoSeguimiento}
              status={context?.relacionData.status ? context?.relacionData.status : context?.relacionData?.tipoRelacion != 'Impuesto Retenido en el Extranjero' ? '' : localStorage.getItem('statusEXPIMP')}
              // ramo={context?.relacionData.accion}
              // subtitle={context?.relacionData.tipoSeguimiento}
              value={contextSet?.state.commentsAcuerdos.segRelacion}
              setValue={(value) => {
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              disabled={disabledTwo}
              setDisabled={() => setDisabledTwo(!disabledTwo)}
            />
          </DivContainer>
          {(context?.relacionData.accion) && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h4>Ramo</h4>
            <span
              style={{
                width: '120px',
                height: '25px',
                backgroundColor: '#85858585',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
              }}
            >
              {context?.relacionData.accion}
            </span>
          </div>
          )}
          {(!cargando) ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'initial',
              }}
            >
              <h4 style={{ color: '#3898EC' }}>Áreas</h4>
              <div className={classes.root}>
                {/* {context?.saveSegRelPatria.areas &&
                  context?.saveSegRelPatria.areas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))} */}
                  {/* {areasSeleccionadas.areas.length > 0 &&
                  areasSeleccionadas.areas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
                  {areasSeleccionadas.subAreas.length > 0 &&
                  areasSeleccionadas.subAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))} */}
                  {areasSeleccionadas.relAreas.length > 0 &&
                  areasSeleccionadas.relAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
                  {areasSeleccionadas.relSubAreas.length > 0 &&
                  areasSeleccionadas.relSubAreas.map((area, index) => (
                    <Chip
                      label={area}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      key={index}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <h4 style={{ color: '#3898EC'}}>Obteniendo áreas</h4>
          )}
          <ButtonNavigation
            text="Finalizar"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => {
              setFeedbackModal(true);
              setContentModal({
                title: 'Guardado exitoso',
                message: 'Se guardado el reporte'
              })
            }}
          />
          <ButtonNavigation
            text="Registrar Nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => onClick()}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Report;
