import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import {
  DINAMIC_GET,
  DYNAMIC_INSERT,
  DYNAMIC_UPDATE,
} from '../../../mutation/mutation';
import DivContainer from '../../../layouts/Acuerdos/DivContainer';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';
import { ArrowBackIos } from '@material-ui/icons';
import LastStep from '../componentsRelacion/LastStep';
import ImputResumen from '../ImputResumen';
import { MyContext } from '../../Context';
import AreasInvV2 from '../common/AreasInvV2';

const SeguimientoRelPatriaDos = ({ onClickGoReport, backRelacionPatria }) => {
  const contextSet = useContext(MyContext);
  const [seguimiento, setSeguimiento] = useState([]);
  const [temasSeguimiento, setTemasSeguimiento] = useState([]);
  const [accionesOption, setAccionesOption] = useState([]);
  const [subTemParticipacion, setSubTemasParticipacion] = useState([]);
  const [subTemConfirmacion, setSubTemasConfirmacion] = useState([]);
  const [subTemasCotizacion, setSubTemasCotizacion] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setStatus] = useState('');
  const accionesSeguimiento = [
    { id: 3, description: 'Solicitud de Atención o Seguimiento de Otra Area' },
    { id: 6, description: 'Enviar Solicitud Documentos Expediente PLD' },
    {
      id: 8,
      description:
        'Enviar Solicitud Documentos para Recuperar Impuestos Retenidos Extranjero',
    },
    { id: 4, description: 'Enviar Charola o Regalo a Cedente/ Corredor' },
  ];

  const [ctrlAcciones, setCtrlAcciones] = useState({
    cACCSR_Id: '',
    cTEMSR_Id: '',
    cSUBTS_Id: ''
  })

  const [subTemaTresConfirmacionCedente, setSubTemaTresConfirmacionCedente] =
    useState([]);
  const [subTemaTresRespectCotiza, setSubTemaTresRespectCotiza] = useState([]);
  const [subTemaConfirmCedentCorredor, setSubTemaConfirmCedentCorredor] =
    useState([]);

  const [currentQuestion, setCurrentQuestion] = useState('');
  const [comments, setComments] = useState('');
  const [reportGenerado, setReportGenerado] = useState('');
  const [saveAdvanceQuestion, setSaveAdvanceQuestion] = useState([]);
  const [disabledOne, setDisabledOne] = useState(true);
  const [disabledTwo, setDisabledTwo] = useState(true);
  const [answerByQuestion, setAnswerByQuestion] = useState({
    commentOne: '',
    commentTwo: '',
    status: '',
  });

  useEffect(() => {
    console.log('CURRENT -> ', currentQuestion);
  }, []);
  useEffect(() => {
    console.log("ctrlAcciones", ctrlAcciones);
  }, [ctrlAcciones])
  useEffect(() => {
    console.log('CONTEXT RELPATRIA-> ', contextSet.state.saveSegRelPatria);
  },[contextSet])
  const [saveComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const [getSeguimiento] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getTemasSeguimiento] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setTemasSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getActions] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setAccionesOption(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSubTemasParticipacion] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemasParticipacion(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSubTemasConfirmacion] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemasConfirmacion(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSubTemasCotizacion] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemasCotizacion(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSubTemaTresConfirmacionCedente] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemaTresConfirmacionCedente(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSubTemaTresRespectCotiza] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemaTresRespectCotiza(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getSubTemaConfirmCedentCorredor] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSubTemaConfirmCedentCorredor(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getStatus] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setStatusOptions(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleOnClickBack = (currentQuestion) => {
    if (currentQuestion === '') {
      backRelacionPatria();
      sessionStorage.setItem('returnSegRelPa', 'true');
    }
    let index = saveAdvanceQuestion.indexOf(currentQuestion);
    let stepAux = index === 0 ? index : index - 1;
    if (index === 0) {
      setCurrentQuestion('');
      saveAdvanceQuestion.splice(index, 1);
    } else {
      setCurrentQuestion(saveAdvanceQuestion[stepAux]);
      saveAdvanceQuestion.splice(index, 1);
    }
  };

  const [saveREL] = useMutation(DYNAMIC_INSERT, {
    onCompleted(data) {
      console.log('INSERTED', data);
    },
    onError(err) {
      console.error('SAVE  ERROR', err);
    },
  });

  const saveRelPatria = () => {
    const { state } = contextSet;
    const { cRELP_Id, cSEGR_Id, cACCSR_Id, cARE_Id, cSUBA_Id, cESTS_Id, cTEMSR_Id, cSUBTS_Id } =
      state.saveAcuerdos;

    const { areas, subAreas } = state.saveSegRelPatria;
    console.log(
      'RELP',
      cRELP_Id,
      'SEGR',
      cSEGR_Id,
      'ACCSR',
      cACCSR_Id,
      'ARE',
      cARE_Id,
      'SUBA',
      cSUBA_Id,
      'ESTS',
      cESTS_Id,
      'cTEMSR_Id',
      cTEMSR_Id,
      'cSUBTS_Id',
      cSUBTS_Id
    );
    let columns = ['cRELPR_Id'];
    let values = [String(1)];
    if (cSEGR_Id) {
      columns.push('cSEGR_Id');
      values.push(String(cSEGR_Id));
    }
    if (cESTS_Id) {
      columns.push('cESTS_Id');
      values.push(String(cESTS_Id));
    }

    if (ctrlAcciones.cACCSR_Id !== '') {
      columns.push('cACCSR_Id');
      values.push(String(ctrlAcciones.cACCSR_Id));
      console.log("ID_SELECCIONADO ACCSR");
    }
    else if (ctrlAcciones.cTEMSR_Id != '') {
      columns.push('cTEMSR_Id');
      values.push(String(ctrlAcciones.cTEMSR_Id));
      console.log("ID_SELECCIONADO cTEMSR_Id");
    }
    else if (ctrlAcciones.cSUBTS_Id != '') {
      columns.push('cSUBTS_Id');
      values.push(String(ctrlAcciones.cSUBTS_Id));
      console.log("ID_SELECCIONADO cSUBTS_Id");
    }

    console.log("ACCIONES ID", ctrlAcciones);

    // if (cARE_Id) {
    //   columns.push('cARE_Id');
    //   values.push(String(cARE_Id));
    // }
    // if (cSUBA_Id) {
    //   columns.push('cSUBA_Id');
    //   values.push(String(cSUBA_Id));
    // }

    if (areas) {
      columns.push('areas');
      values.push(String(areas));
    }
    if (subAreas) {
      columns.push('subAreas');
      values.push(String(subAreas));
    }

    columns.push('cACU_Id');
    values.push(String(contextSet.state.saveAcuerdos.cACU_Id));
    saveREL({
      variables: {
        input: {
          table: 'cSEGRP_SeguimientoRelacionPatria2',
          columns,
          detailValues: [
            {
              values,
            },
          ],
        },
      },
    });
    saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify(contextSet.state.commentsAcuerdos),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + contextSet.state.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });
  };

  const saveAreasSubAreas = async() => {
    const { state } = contextSet;
    const { cSEGR_Id } = state.saveAcuerdos;
    const { areas, subAreas } = state.saveSegRelPatria;
    let columns = ['cRELPR_Id'];
    let values = [String(1)];
    if (cSEGR_Id) {
      columns.push('cSEGR_Id');
      values.push(String(cSEGR_Id));
    }
    if (areas) {
      columns.push('areas');
      values.push(areas.join(","));
    }
    if (subAreas) {
      columns.push('subAreas');
      values.push(subAreas.join(","));
    }
    
    columns.push('cACU_Id');
    values.push(String(contextSet.state.saveAcuerdos.cACU_Id));
    
    await saveREL({
      variables: {
        input: {
          table: 'cSEGRP_SeguimientoRelacionPatria2',
          columns,
          detailValues: [
            {
              values,
            },
          ],
        },
      },
    });
  }

  useEffect(() => {
    getSeguimiento({
      variables: {
        input: {
          table: 'cSEGR_SeguimientoRelacion',
          columns: ['cSEGR_Id', 'cSEGR_Descripcion'],
        },
      },
    });
    getTemasSeguimiento({
      variables: {
        input: {
          table: 'cTEMSR_TemasSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Tipo',
              valtwo: `2`,
              condition: '=',
            },
          ],
        },
      },
    });
    getActions({
      variables: {
        input: {
          table: 'cACCSR_AccionesSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Id',
              valtwo: `1`,
              condition: '=',
            },
          ],
        },
      },
    });
    getSubTemasParticipacion({
      variables: {
        input: {
          table: 'cSUBTS_SubtemasSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMS_Id',
              valtwo: `2`,
              condition: '=',
            },
          ],
        },
      },
    });
    getSubTemasConfirmacion({
      variables: {
        input: {
          table: 'cSUBTS_SubtemasSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMS_Id',
              valtwo: `3`,
              condition: '=',
            },
          ],
        },
      },
    });

    getSubTemasCotizacion({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: `4`,
              condition: '=',
            },
          ],
        },
      },
    });
    getSubTemaTresConfirmacionCedente({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: `7`,
              condition: '=',
            },
          ],
        },
      },
    });
    getSubTemaTresRespectCotiza({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: `8`,
              condition: '=',
            },
          ],
        },
      },
    });
    getSubTemaConfirmCedentCorredor({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['*'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: `9`,
              condition: '=',
            },
          ],
        },
      },
    });
    getStatus({
      variables: {
        input: {
          table: 'cESTS_EstatusSeguimiento',
          columns: ['*'],
        },
      },
    });
  }, []);
  useEffect(() => {
    console.log(currentQuestion);
    if (!saveAdvanceQuestion.includes(currentQuestion))
      saveAdvanceQuestion.push(currentQuestion);
  }, [currentQuestion]);
  return (
    <Fragment>
      <div
        onClick={() => handleOnClickBack(currentQuestion)}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      {currentQuestion === '' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
        >
          {seguimiento.map(({ cSEGR_Id, cSEGR_Descripcion }, index) => (
            <ButtonNavigation
              text={cSEGR_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={async() => {
                setReportGenerado(cSEGR_Descripcion);
                await contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cSEGR_Id,
                });

                console.log("TIPO SEGUIMIENTO", contextSet.state.saveAcuerdos.cSEGR_Id);

                contextSet.setRelacionData({
                  ...contextSet.state.relacionData,
                  tipoSeguimiento: cSEGR_Descripcion,
                });
                if(cSEGR_Descripcion === 'Ninguno'){
                  saveAreasSubAreas().then(() => {
                    onClickGoReport({
                      tipoRelacion: 'Niguno'
                    });
                  })
                }
                else{
                  setCurrentQuestion(cSEGR_Descripcion);
                }
              }}
            />
          ))}
        </DivContainer>
      )}
      {currentQuestion === 'Ninguno' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`Resumen de Relación con Patria`}
        >
          <LastStep
            txtBtn="Continuar a Seguimiento"
            widthBtn='90%'
            setState={setComments}
            state={comments}
            onClick={onClickGoReport}
          />
          <ButtonNavigation
            text="Volver a Relación con Patria"
            style={{ cursor: 'pointer', width:'90%' }}
            onClick={() => backRelacionPatria()}
          />
        </DivContainer>
      )}
      {(currentQuestion === 'Acuerdo' ||
        currentQuestion === 'Solicitud' ||
        currentQuestion === 'Oportunidad' ||
        currentQuestion === 'Otro') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
          >
            {temasSeguimiento.map(({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cTEMSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  setCurrentQuestion(cTEMSR_Descripcion);
                  await contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cTEMSR_Id,
                  });
                }}
              />
            ))}
          </DivContainer>
        )}
      {currentQuestion === 'Analizar Información' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
        >
          {accionesOption.map(({ cACCSR_Id, cACCSR_Descripcion }, index) => (
            <ButtonNavigation
              text={cACCSR_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={async() => {
                console.log(cACCSR_Id);
                setCurrentQuestion(cACCSR_Descripcion);
                await contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cACCSR_Id: cACCSR_Id,
                });

                setCtrlAcciones({
                  ...ctrlAcciones,
                  cACCSR_Id: cACCSR_Id,
                  cTEMSR_Id: '',
                  cSUBTS_Id: ''
                })

                await contextSet.setRelacionData({
                  ...contextSet.state.relacionData,
                  accion: cACCSR_Descripcion
                });
              }}
            />
          ))}
        </DivContainer>
      )}
      {currentQuestion === 'Cotización' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
        >
          {subTemParticipacion.map(
            ({cSUBTS_Id, cTEMS_Id, cSUBTS_Descripcion }, index) => (
              <ButtonNavigation
                text={cSUBTS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setCurrentQuestion(cSUBTS_Descripcion);
                  setCtrlAcciones({
                    ...ctrlAcciones,
                    cACCSR_Id: '',
                    cTEMSR_Id: '',
                    cSUBTS_Id: cSUBTS_Id
                  })
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {currentQuestion === 'Confirmación' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
        >
          {subTemConfirmacion.map(({cSUBTS_Id, cTEMS_Id, cSUBTS_Descripcion }, index) => (
            <ButtonNavigation
              text={cSUBTS_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setCurrentQuestion(cSUBTS_Descripcion);
                setCtrlAcciones({
                  ...ctrlAcciones,
                  cACCSR_Id: '',
                  cTEMSR_Id: '',
                  cSUBTS_Id: cSUBTS_Id
                })
              }}
            />
          ))}
        </DivContainer>
      )}
      {(currentQuestion === 'Acciones de Seguimiento' ||
        currentQuestion === 'Objetivo no cumplido') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
          >
            {accionesSeguimiento.map(({ id, description }, index) => (
              <ButtonNavigation
                text={description}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {

                  console.log("ID ACCSR", id);
                  setCurrentQuestion(description);
                  setCtrlAcciones({
                    ...ctrlAcciones,
                    cACCSR_Id: id,
                    cTEMSR_Id: '',
                    cSUBTS_Id: ''
                  })
                }}
              />
            ))}
          </DivContainer>
        )}
      {(currentQuestion === 'Mantener participación' ||
        currentQuestion === 'Aumentar participación' ||
        currentQuestion === 'Disminuir participación' ||
        currentQuestion === 'Participar en nuevo contrato') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="¿Deseas solicitar aprobación al Comité de Suscripción?"
          >
            {subTemasCotizacion.map(
              ({ cSUBTS_Id, cREGSS_Descripcion }, index) => (
                <ButtonNavigation
                  text={cREGSS_Descripcion}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setCurrentQuestion(cREGSS_Descripcion);
                  }}
                />
              )
            )}
          </DivContainer>
        )}
        {console.log(currentQuestion)}
      {
      currentQuestion === 'Envío a Cedente/ Corredor' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué siguiente paso deseas registrar para ${reportGenerado}?`}
        >
          {subTemaTresConfirmacionCedente.map(
            ({ cSUBTS_Id, cREGSS_Descripcion }, index) => (
              <ButtonNavigation
                text={cREGSS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setCurrentQuestion(cREGSS_Descripcion);
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {currentQuestion === 'Respecto a la Cotización' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué siguiente paso deseas registrar para ${reportGenerado}?`}
        >
          {subTemaTresRespectCotiza.map(
            ({ cSUBTS_Id, cREGSS_Descripcion }, index) => (
              <ButtonNavigation
                text={cREGSS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setCurrentQuestion(cREGSS_Descripcion);
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {currentQuestion === 'Enviar Información a Cedente/ Corredor' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          //subtitle={`¿Qué seguimiento deseas dar a tu ${reportGenerado}?`}
          subtitle="¿Qué información deseas enviar?"
        >
          {subTemaConfirmCedentCorredor.map(
            ({ cSUBTS_Id, cREGSS_Descripcion }, index) => (
              <ButtonNavigation
                text={cREGSS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setCurrentQuestion(cREGSS_Descripcion);
                }}
              />
            )
          )}
        </DivContainer>
      )}

      {currentQuestion ===
        'Solicitud de Atención o Seguimiento de Otra Area' && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle={`Comentarios para el ${reportGenerado} sobre Pendientes con Patria.`}
          >
            <LastStep
              onClick={setCurrentQuestion}
              paramsOnclick="nextSatatusSeg"
              txtBtn="Guardar y continuar"
              widthBtn='90%'
              setState={(value) => {
                setComments(value);
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value,
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              state={comments}
            />
            <AreasInvV2 openModal={true} />
          </DivContainer>
        )}

      {(currentQuestion === 'Enviar Solicitud Documentos Expediente PLD' ||
        currentQuestion ===
        'Enviar Solicitud Documentos para Recuperar Impuestos Retenidos Extranjero' ||
        currentQuestion === 'Enviar Charola o Regalo a Cedente/ Corredor' ||
        currentQuestion === 'Temas Pendientes' ||
        currentQuestion === 'Renovación' ||
        currentQuestion === 'Nuevo Contrato' ||
        currentQuestion === 'Sí, solicitar aprobación' ||
        currentQuestion === 'No es necesario' ||
        currentQuestion === 'Objetivo cumplido' ||
        currentQuestion === 'Más información' ||
        currentQuestion === 'Aclaraciones' ||
        currentQuestion ===
        'Posibilidad de negociación con la cedente/ corredor' ||
        currentQuestion ===
        'Dar seguimiento en la próxima reunión con la cedente/ corredor' ||
        currentQuestion === 'Relativa a Patria' ||
        currentQuestion === 'Relativa a Mercado' ||
        currentQuestion === 'Felicitación' ||
        currentQuestion === 'Condolencias' ||
        currentQuestion === 'Nota de solidaridad') && ( //|| currentQuestion === ""
          //|| currentQuestion === "" || currentQuestion === ""
          <div style={{
            width: '91%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <DivContainer
              title="Seguimiento Relación con Patria"
              subtitle={`Comentarios para el ${reportGenerado} sobre Pendientes con Patria.`}
            >
              <LastStep
                onClick={setCurrentQuestion}
                paramsOnclick="nextSatatusSeg"
                txtBtn="Guardar y continuar"
                marginTop='-1%'
                widthBtn = '90%'
                setState={(value) => {
                  setComments(value);
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    commentTwo: value,
                  });
                  contextSet.setCommentsAcuerdos({ segRelacion: value });
                }}
                state={comments}
              />
            </DivContainer>
          </div>

        )}

      {currentQuestion === 'nextSatatusSeg' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Cuál es el estatus de tu ${reportGenerado}?`}
        >
          {statusOptions.map(({ cESTS_Id, cESTS_Descripcion }, index) => (
            <ButtonNavigation
              text={cESTS_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={async() => {
                setCurrentQuestion('resumen');
                setStatus(cESTS_Descripcion);
                await contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cESTS_Id,
                });
                await contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  status: cESTS_Descripcion,
                });
                await contextSet.setRelacionData({
                  ...contextSet.state.relacionData,
                  status: cESTS_Descripcion,
                });
              }}
            />
          ))}
        </DivContainer>
      )}

      {currentQuestion === 'resumen' && (
        <>
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="Resumen de Seguimiento sobre Relación con Patria"
          >
            <ImputResumen
              title="Comentarios de Relación con Patria:"
              // subtitle={"subititlo"}
              disabled={disabledOne}
              setDisabled={() => setDisabledOne(!disabledOne)}
              value={contextSet.state.commentsAcuerdos.relacion}
              setValue={(value) => {
                setAnswerByQuestion({ ...answerByQuestion, commentOne: value });
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentOne: value,
                });
                contextSet.setCommentsAcuerdos({ relacion: value });
              }}
              flexDirection="column"
            />
            <ImputResumen
              title="Tipo de seguimiento:"
              status={status || localStorage.getItem('statusEXPIMP')}
              value={contextSet.state.commentsAcuerdos.segRelacion}
              setValue={(value) => {
                setAnswerByQuestion({ ...answerByQuestion, commentTwo: value });
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value,
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              disabled={disabledTwo}
              setDisabled={() => setDisabledTwo(!disabledTwo)}
            />
          </DivContainer>
          {/*   <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                        <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                        <div className={classes.root}>
                            <Chip label={areas} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} />
                        </div>
                    </div> */}
          <AreasInvV2 openModal={true} />

          <ButtonNavigation
            text="Guardar y continuar a Seguimiento"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => {
              onClickGoReport();
              saveRelPatria();
            }}
          />
          <ButtonNavigation
            text="Guardar y registrar nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => {
              setCurrentQuestion('');
              setSaveAdvanceQuestion([]);
              saveRelPatria();
            }}
          />
          <ButtonNavigation
            text="Volver a Relación con Patria"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => backRelacionPatria()}
          />
        </>
      )}
    </Fragment>
  );
};
export default SeguimientoRelPatriaDos;
