import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import GenericTable from "../Acuerdos/common/GenericTable";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(30, 30, 30)
  }
}));

export default function TransitionsModal({ data, setOpen, open = false, columns = [], background = '#01539c', color = '#FFF', title, mobile = false }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/*  <button type="button" onClick={handleOpen}>
        react-transition-group
      </button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{width: mobile ? '100%' : null, fontSize: mobile ? 12 : null}}>
            <div style={{display:'flex', justifyContent:'center', fontSize:'20px',fontWeight:'bold', cursor: 'pointer'}} onClick={handleClose}>
              X
            </div>
            <GenericTable
              width={'100%'}
              loading={data.length > 0 ? false : true}
              columns={columns}              
              data={data}
              options={{
                search: false,
                headerStyle: {
                  backgroundColor: background,
                  color: color
                },
                // showTitle: false
              }}
              title={title}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
