import { useState, useContext } from 'react';
import { DYNAMIC_INSERT, UPLOAD_FILE } from '../../mutation/mutation';
import { useMutation } from 'react-apollo';
import { MyContext } from '../../components/Context';

const useUploadFile = ({viaticsIds = []}) => {
    const context = useContext(MyContext);
    const [files, setFiles] = useState([]); 
    const [insertFileNames] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('insertFileNames', dynamicInsert);
        },
        onError(error) {
            console.log(error)
        },
    });
    const [uploadFile] = useMutation(UPLOAD_FILE, {
        onCompleted({ uploadFile }) {
            console.log('uploadFile', uploadFile);
            setFiles([])        
        },
        onError(err) {
            console.log(err);
            setFiles([])         
        },
    });

    const upload = () => {
        files.forEach(async (file, index) => {
            const { name } = file;
            const cVIT_Avance = parseInt(context.state.viaticosProps.cVIT_Avance) + 1;
            const cVIT_Id = viaticsIds.length > 0 ? viaticsIds[index] : context.state.viaticosProps.cVIT_Id
            const input = {
                table: 'log_file_process',
                columns: ['cVIT_Id', 'cEMP_Id', 'show', 'fileName', 'cVIT_Avance'],
                detailValues: [
                    {
                        values: [
                            String(cVIT_Id),
                            '62',
                            '1',
                            name,
                            String(cVIT_Avance)
                        ]
                    },
                ],

            }
            await insertFileNames({ variables: { input } })
            await uploadFile({
                variables: {
                    input: file,
                },
            });
        })        
        return true;
    };
    return { files, setFiles, upload };
}

export default useUploadFile;