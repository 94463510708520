import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../../mutation/mutation';
import DivContainer from '../../../layouts/Acuerdos/DivContainer';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';
import { Chip } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import LastStep from '../componentsRelacion/LastStep';
import ImputResumen from '../ImputResumen';
import { MyContext } from '../../Context';
import { makeStyles } from '@material-ui/core/styles';

import { DYNAMIC_UPDATE } from '../../../mutation/mutation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
const SeguimientoRelacionDos = ({
  optionsExpIMP = 'impuestoRetenido',
  onClickGoReport,
  backRelacionPatria,
}) => {
  const classes = useStyles();
  const contextSet = useContext(MyContext);
  const [seguimiento, setSeguimiento] = useState([]);
  const [accionesOption, setAccionesOption] = useState({
    expPLD: [],
    impuestoRetenido: [],
  });
  const [areaAtencionOption] = useState({
    expPLD: [
      { cTEMSR_Id: 1, cTEMSR_Descripcion: 'PLD / Oficial de Cumplimiento' },
      { cTEMSR_Id: 2, cTEMSR_Descripcion: 'Planeación y Coordinación' },
    ],
    impuestoRetenido: [
      { cTEMSR_Id: 3, cTEMSR_Descripcion: 'Fiscal' },
      { cTEMSR_Id: 4, cTEMSR_Descripcion: 'Otra Área' },
    ],
  });
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [comments, setComments] = useState('');
  const [reportGenerado, setReportGenerado] = useState('');
  const [saveAdvanceQuestion, setSaveAdvanceQuestion] = useState([]);
  const [disabledOne, setDisabledOne] = useState(true);
  const [disabledTwo, setDisabledTwo] = useState(true);
  const [answerByQuestion, setAnswerByQuestion] = useState({
    commentOne: '',
    commentTwo: '',
    status: '',
  });
  const [areas, setAreas] = useState('');

  const [getSeguimiento] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getActionsPLD] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setAccionesOption({
        ...accionesOption,
        expPLD: JSON.parse(dynamicGet.response),
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getActionsImpretenido] = useMutation(DINAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      setAccionesOption({
        ...accionesOption,
        impuestoRetenido: JSON.parse(dynamicGet.response),
      });
    },
    onError(error) {
      console.log(error);
    },
  });

  const [updateAcuerdos] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('update acuerdo ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error update acuerdo -->>', err);
    },
  });

  function saveRelacion() {
    const input = {
      table: 'cACU_CuerdosVieje',
      columns: saveAdvanceQuestion.filter(str => !str.includes('after') || !str).map((str, index) => ({
        setkey: `cRELP_${index + 1}`,
        setval: str,
      })),
      conditions: [
        {
          valone: 'cACU_Id',
          valtwo: '' + contextSet.state.saveAcuerdos.cACU_Id,
          condition: '=',
        },
      ],
    };
    console.log('update input : ',input)
    updateAcuerdos({ variables: { input } });
  }

  const handleOnClickBack = (currentQuestion) => {
    if (currentQuestion === '') {
      backRelacionPatria();
      sessionStorage.setItem('returnSegRelPa', 'true');
    }
    let index = saveAdvanceQuestion.indexOf(currentQuestion);
    let stepAux = index === 0 ? index : index - 1;
    if (index === 0) {
      setCurrentQuestion('');
      saveAdvanceQuestion.splice(index, 1);
    } else {
      setCurrentQuestion(saveAdvanceQuestion[stepAux]);
      saveAdvanceQuestion.splice(index, 1);
    }
  };

  useEffect(() => {
    getSeguimiento({
      variables: {
        input: {
          table: 'cSEGR_SeguimientoRelacion',
          columns: ['cSEGR_Id', 'cSEGR_Descripcion'],
        },
      },
    });
    getActionsImpretenido({
      variables: {
        input: {
          table: 'cTEMSR_TemasSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Tipo',
              valtwo: `3`,
              condition: '=',
            },
          ],
        },
      },
    });
    getActionsPLD({
      variables: {
        input: {
          table: 'cTEMSR_TemasSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Tipo',
              valtwo: `4`,
              condition: '=',
            },
          ],
        },
      },
    });
  }, [getActionsPLD, getActionsImpretenido, getSeguimiento]);
  useEffect(() => {
    if (!saveAdvanceQuestion.includes(currentQuestion))
      saveAdvanceQuestion.push(currentQuestion);
  }, [currentQuestion]);
  return (
    <Fragment>
      <div
        onClick={() => handleOnClickBack(currentQuestion)}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      {currentQuestion === '' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
        >
          {seguimiento.map(({ cSEGR_Id, cSEGR_Descripcion }, index) => (
            <ButtonNavigation
              text={cSEGR_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setReportGenerado(cSEGR_Descripcion);
                contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cSEGR_Id,
                });
                if(cSEGR_Descripcion === 'Ninguno'){
                  onClickGoReport({
                    tipoRelacion: 'Niguno'
                  });
                }
                else{
                  setCurrentQuestion(cSEGR_Descripcion);
                }
              }}
            />
          ))}
        </DivContainer>
      )}
      {currentQuestion === 'Ninguno' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`Resumen de Relación con Patria`}
        >
          <LastStep
            txtBtn="Continuar a Seguimiento"
            widthBtn='90%'
            setState={(value) =>
              contextSet.setCommentsAcuerdos({ relacion: value })
            }
            state={comments}
            onClick={onClickGoReport}
          />
          <ButtonNavigation
            width='90%'
            text="Volver a Relación con Patria"
            style={{ cursor: 'pointer',width:'90%'  }}
            onClick={() => backRelacionPatria()}
          />
        </DivContainer>
      )}
      {(currentQuestion === 'Acuerdo' ||
        currentQuestion === 'Solicitud' ||
        currentQuestion === 'Oportunidad' ||
        currentQuestion === 'Otro') && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle="¿Qué acciones deseas tomar?"
        >
          {accionesOption[optionsExpIMP].map(
            ({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cTEMSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  // setCurrentQuestion(cTEMSR_Descripcion);
                  setCurrentQuestion("Solicitud de Atención o Seguimiento de \nOtra Área");
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {(currentQuestion ===
        'Solicitud de Atención o Seguimiento de \nOtra Área') && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle="¿De qué área se necesita atención?"
        >
          {areaAtencionOption[optionsExpIMP].map(
            ({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cTEMSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setCurrentQuestion(cTEMSR_Descripcion);
                  setAreas(cTEMSR_Descripcion);
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {(currentQuestion === 'Fiscal' ||
        currentQuestion === 'Planeación y Coordinación' ||
        currentQuestion === 'Otra Área' ||
        currentQuestion === 'PLD / Oficial de Cumplimiento' ||
        currentQuestion ===
          'Enviar Solicitud Documentos para Recuperar Impuestos Retenidos Extranjero' ||
        currentQuestion === 'Enviar Solicitud Documentos Expediente PLD') && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`Comentarios para el seguimiento de ${reportGenerado} sobre ${
            optionsExpIMP === 'expPLD'
              ? 'Expediente PLD.'
              : 'El Impuesto retenido en el extranjero.'
          }`}
        >
          <LastStep
            txtBtn="Guardar y continuar"
            setState={(value) =>
              contextSet.setCommentsAcuerdos({ relacion: value })
            }
            state={comments}
            onClick={setCurrentQuestion}
            paramsOnclick="resumen"
          />
        </DivContainer>
      )}
      {currentQuestion === 'resumen' && (
        <>
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="Resumen de Seguimiento sobre Relación con Patria"
          >
            <ImputResumen
              title="Comentarios de Relación con Patria:"
              status={localStorage.getItem('statusEXPIMP')}
              // subtitle={"subititlo"}
              disabled={disabledOne}
              setDisabled={() => setDisabledOne(!disabledOne)}
              value={contextSet.state.saveSegRelPatria.commentOne}
              setValue={(value) => {
                setAnswerByQuestion({ ...answerByQuestion, commentOne: value });
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentOne: value,
                });
                contextSet.setCommentsAcuerdos({ relacion: value });
              }}
              flexDirection="column"
            />
            <ImputResumen
              title="Tipo de seguimiento:"
              value={contextSet.state.saveSegRelPatria.commentTwo}
              setValue={(value) => {
                setAnswerByQuestion({ ...answerByQuestion, commentTwo: value });
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value,
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              disabled={disabledTwo}
              setDisabled={() => setDisabledTwo(!disabledTwo)}
            />
          </DivContainer>
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'initial',
            }}
          >
            <h4 style={{ color: '#3898EC' }}>Áreas</h4>
            <div className={classes.root}>
              <Chip
                label={areas}
                style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
              />
            </div>
          </div>

          <ButtonNavigation
            text="Guardar y continuar a Seguimiento"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => onClickGoReport()}
          />
          <ButtonNavigation
            text="Guardar y registrar nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => {
              setCurrentQuestion('');
              setSaveAdvanceQuestion([]);
            }}
          />
          <ButtonNavigation
            text="Volver a Relación con Patria"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => backRelacionPatria()}
          />
        </>
      )}
    </Fragment>
  );
};
export default SeguimientoRelacionDos;
