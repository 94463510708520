import React from 'react'
import TransitionsModal from '../SnapshotComponents/TransitionsModal';
import { useMediaQuery } from '@material-ui/core';

function ModalReviewViaticos({ openDirectory, setOpenDirectory, data }) {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    return (
        <>
            <TransitionsModal
                data={data}
                open={openDirectory}
                setOpen={setOpenDirectory}
                columns={[
                    { title: 'N° de folio', field: 'cVIT_Id' },
                    { title: 'Estatus', field: 'cVIT_Status_VIAT' },                                        
                    { title: 'Fecha inicio', field: 'cREU_FechaInicio' },
                    { title: 'Fecha final', field: 'cREU_FechaFin' },
                    { title: 'Ubicación', field: 'cVIA_Destino' },                    
                ]}
                background={null}
                color='black'
                title='Revisión de viáticos'
                mobile={isMobile}
            />
        </>
    )
}

export default ModalReviewViaticos