import React from 'react';
import './index.css';
import twitter from "../../assets/images/images/twitter-icon.svg";
import linkdin from "../../assets/images/images/linkdin-icon.svg";
import email from "../../assets/images/images/email-icon.svg";
import facebook from "../../assets/images/images/facebook-icon.svg";
const Footer =()=>{
    return(
        <section className="footer">
            <div className="footer-container">
                <div className="footer-container-col-6">
                    <div className="footer-title">Reaseguradora Patria</div>
                    <div className="footer-social-bar">
                        <a href="/" className="social-icon">
                            <img src={facebook} alt="facebook"/>
                        </a>
                        <a href="/" className="social-icon">
                            <img src={twitter} alt="twitter"/>
                        </a>
                        <a href="/" className="social-icon">
                            <img src={linkdin} alt="linkdin"/>
                        </a>
                        <a href="/" className="social-icon">
                            <img src={email} alt="email"/>
                        </a>
                    </div>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Compañia</h4>
                    <a href="/" className="footer-link">Home</a>
                    <a href="/" className="footer-link">Misión y valores</a>
                    <a href="/" className="footer-link">Asociaciones</a>
                    <a href="/" className="footer-link">Sign In</a>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Producto</h4>
                    <a href="/" className="footer-link">Reaseguros</a>
                    <a href="/" className="footer-link">Estado Financieros</a>
                    <a href="/" className="footer-link">Calificaciones</a>
                    <a href="/" className="footer-link">Informe Anual</a>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Legal</h4>
                    <a href="/" className="footer-link">Aviso de Privacidad</a>
                    <a href="/" className="footer-link">Línea de ética</a>
                </div>

            </div>
        </section>
    )
}

export default Footer