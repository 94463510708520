import React, { useState } from "react";
import { Typography, Icon } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { BottonStyledSeccion } from '../StylesComponents/common-styled';
export default function AddNewInvited({
    setActiveAddInvited,
    activeAddInvited,
    form,
    handlechange,
    setCargosSirec,
    CargosSirec
}) {


    const saveInvited = () => {
        const { nombre, puesto, correo } = form
        setCargosSirec([
            ...CargosSirec,
            {
                cEje_Nombre: nombre,
                cEje_Cargo: puesto,
                cEje_Mail: correo
            }
        ])
    }
    return (
        <>
            <div style={{ marginRight: '10px' }}>
                <Typography>Nombre</Typography>
                <TextField
                    name='nombre'
                    margin="normal"
                    variant="outlined"
                    onChange={handlechange}
                    value={form.nombre}
                />
            </div>
            <div style={{ marginRight: '10px' }}>
                <Typography>Correo</Typography>
                <TextField
                    name='correo'
                    margin="normal"
                    variant="outlined"
                    onChange={handlechange}
                    value={form.correo}
                />
            </div>
            <div style={{ marginRight: '20px' }}>
                <Typography>Cargo o puesto</Typography>
                <TextField
                    styled={{ borderColor: 'darkgray' }}
                    name='puesto'
                    margin="normal"
                    variant="outlined"
                    onChange={handlechange}
                    value={form.puesto}
                />
            </div>
            <BottonStyledSeccion
                backgroundColor="#3898EC"
                color="#fff"
                width="15%"
                height="40px"
                style={{ marginTop: "58px" }}
                onClick={() => {
                    saveInvited();
                    setActiveAddInvited(false);
                }}
            >
                Guardar
            </BottonStyledSeccion>

        </>
    )
}