import React, { useEffect, useState, Fragment, useContext } from 'react';
import { DINAMIC_GET, DYNAMIC_INSERT, DYNAMIC_UPDATE } from '../../mutation/mutation';
import { useMutation } from 'react-apollo';
import { MyContext } from '../Context';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import CardsViaticos from '../Viaticos/CardsViaticos';
import MultipleSelect from '../Viaticos/MultipleSelect';
import ButtonsInformacion from '../Viaticos/ButtonsInformacion';
import SimpleSelect from '../Viaticos/SimpleSelect';
import Moment from 'moment';
import RolSelection from '../Acuerdos/common/RolSelection';
import { sendMailTravelerToAccounting } from '../../services/GraphService';
import DialogAlert from '../DiaologAlert';
import { sendEmail } from '../../services/SendEmails';
import ComprobacionViajero from '../Viaticos/ComprobacionViajero';
import { PageContainer } from '../StylesComponents/common-styled';

const lastDate = Moment().subtract(30, 'days').format('YYYY-MM-DD');
const ExpensesCheckView = ({ cEMP_Id, handleComponent }) => {
    const context = useContext(MyContext);
    const [viaticos, setViaticos] = useState([]);
    const [viatico, setViatico] = useState([])
    const [optionsReu, setOptionsReu] = useState([]);
    const [cReuSelected, setCReuSelected] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [msgModal, setMsgModal] = useState({
        title: 'Error al guardar',
        text: ''
    });
    const [card, setCard] = useState('Amex')
    const [insertMovements] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            if (dynamicInsert.statusCode === 200) {
                // handleComponent();

            } else {
                setShowModal(true);
                setMsgModal({
                    text: 'Error al enviar ticket'
                })
            }
        },
        onError(error) {
            console.log(error);
            setShowModal(true);
            setMsgModal({
                text: 'Error al enviar ticket'
            })
        },
    });
    const [updateStatusViat] = useMutation(DYNAMIC_UPDATE, {
        onCompleted({ dynamicUpdate }) {
            console.log(dynamicUpdate)
        },
        onError(error) {
            console.log(error)
        },
    });
    const [getReunionByEmp] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {            
            if (dynamicGet.response !== '') {
                let response = JSON.parse(dynamicGet.response);
                const ids = [];
                let aux = [];
                const titles = [];
                response = response.map((v) => {
                    const reuniones = formatDataViaticos(response, v);
                    return {
                        ...v,
                        cREU_FechaInicio: Moment(v.cREU_FechaInicio).format('YYYY-MM-DD'),
                        cREU_FechaFin: Moment(v.cREU_FechaFin).format('YYYY-MM-DD'),
                        reuniones
                    };
                });
                response.forEach((viatico) => {
                    aux = searchId(ids, viatico, aux);
                });
                aux.forEach(({ cREU_Id, cREU_Titulo }) => {
                    titles.push({ cREU_Id, cREU_Titulo })
                })
                setViaticos(aux);
                setOptionsReu(titles.sort((a, b) => a.cREU_Id < b.cREU_Id ? -1 : 1));
            }
        }
    })
    const formatDataViaticos = (viaticos = [], viatico) => {
        let response = [];
        const listcREU_Ids = [];
        const allVitByVit = viaticos.filter(({ cVIT_Id }) => viatico.cVIT_Id === cVIT_Id);
        allVitByVit.forEach(({ cREU_Id: idReunion }) => {
            if (listcREU_Ids.length === 0) {
                const vitListBycREU_Id = allVitByVit.filter(({ cREU_Id }) => idReunion === cREU_Id);
                const lastVit = vitListBycREU_Id[vitListBycREU_Id.length - 1];
                response.push(lastVit);
                listcREU_Ids.push(lastVit.cREU_Id);
            } else {
                const existsVit = listcREU_Ids.some((id) => idReunion === id);
                if (!existsVit) {
                    const vitListBycREU_Id = allVitByVit.filter(({ cREU_Id }) => idReunion === cREU_Id);
                    const lastVit = vitListBycREU_Id[vitListBycREU_Id.length - 1];
                    response.push(lastVit);
                    listcREU_Ids.push(lastVit.cREU_Id);
                }
            }
        })
        response = response.sort((a, b) =>
            a.cREU_Id < b.cREU_Id ? -1 : 1
        );
        return response;
    }
    const searchId = (ids, viatico, aux) => {
        let idReturn = aux;
        if (ids.length == 0) {
            ids.push(viatico.cVIT_Id);
            idReturn.push(viatico);
        } else {
            let aux = 0;
            ids.forEach((id) => {
                if (id == viatico.cVIT_Id) {
                    aux = 1;
                }
            });
            if (aux == 0) {
                ids.push(viatico.cVIT_Id);
                idReturn.push(viatico);
            }
        }
        return idReturn;
    };
    const filterReu = () => {
        if (Array.isArray(cReuSelected)) {
            let reu = [];
            cReuSelected.forEach((cREU_Id) => {
                const res = viaticos.filter((e) => e.cREU_Id == cREU_Id)
                reu.push(res[0])
            })
            if (reu.length > 0) {
                setViatico([...reu])
            }
        } else {
            const result = viaticos.filter((e) => e.cREU_Id == cReuSelected);
            sessionStorage.setItem('cREU_Titulo', result[0].cREU_Titulo);
            sessionStorage.setItem('cREU_Id', result[0].cREU_Id);
            sessionStorage.setItem('cVIT_Id', result[0].cVIT_Id);
            setViatico(result)
        }
    }
    const sendTicketToAccounting = async () => {
        const token = context.state.AuthComponentProps.token;

        const cREU_Id = String(sessionStorage.getItem('cREU_Id'));
        const cVIT_Id = String(sessionStorage.getItem('cVIT_Id'));
        const emailTo = sessionStorage.getItem('emailTo');
        let inputCheeckingCosts = {}
        let inputUpdateStatus = {
            table: 'cVIT_Viaticos',
            columns: [
                {
                    setkey: 'cVIT_Avance',
                    setval: '11',
                },
                {
                    setkey: 'cVIT_Status_COMP',
                    setval: '2',
                }
            ],
            conditions: [
                {
                    valone: 'cVIT_Id',
                    valtwo: '',
                    condition: '=',
                },
            ]
        }
        if (emailTo) {
            inputCheeckingCosts = {
                table: 'cCOMP_Mov',
                columns: ['card', 'cEMP_Id'],
                detailValues: [
                    {
                        values: [card, String(cEMP_Id.cEMP_Id)],
                    }
                ],
            }
            const cCOMPID = await insertMovements({
                variables: {
                    input: inputCheeckingCosts
                }
            }).then(({ data }) => JSON.parse(data.dynamicInsert.response));
            if (card === 'Amex') {

                viatico.forEach(async ({ cVIT_Id: id }) => {
                    await insertMovements({
                        variables: {
                            input: {
                                table: 'rCOMPXrVIT',
                                columns: ['cVIT_Id', 'cCOMP_Id'],
                                detailValues: [
                                    {
                                        values: [String(id), String(cCOMPID.cCOMP_Id)],
                                    }
                                ],
                            }
                        }
                    })
                    inputUpdateStatus.conditions[0].valtwo = String(id);
                    await updateStatusViat({
                        variables: {
                            input: inputUpdateStatus
                        }
                    })
                })
            } else {
                await insertMovements({
                    variables: {
                        input: {
                            table: 'rCOMPXrVIT',
                            columns: ['cVIT_Id', 'cCOMP_Id'],
                            detailValues: [
                                {
                                    values: [String(cVIT_Id), String(cCOMPID.cCOMP_Id)],
                                }
                            ],
                        }
                    }
                })
                inputUpdateStatus.conditions[0].valtwo = String(cVIT_Id);
                await updateStatusViat({
                    variables: {
                        input: inputUpdateStatus
                    }
                })
            }
            await sendEmail({
                accessToken: token,
                emailTo: [emailTo],
                subject: 'Comprobación de gastos',
                section: 'sectionOne_Comp'
            });
            setShowModal(true);
            setMsgModal({
                title: 'Ticket enviado a contabilidad',
                text: ''
            })
        } else {
            setShowModal(true);
            setMsgModal({
                title: 'Error',
                text: 'Seleccione un correo para enviar el ticket'
            })
        }

    }
    useEffect(() => {
        const currentDate = new Date().toISOString().slice(0, 10);
        getReunionByEmp({
            variables: {
                input: {
                    table: 'rREUXrVIT as rr, cVIT_Viaticos as cVIT, cREU_Reunion as cREU, cVIACRM_ViajesCRM as cVIA',
                    columns: ['cVIT.cVIT_Id', 'rr.cREU_Id', 'cVIT.cVIT_Status_VIAT', 'cVIT.cVIT_Status_COMP', 'cVIT.cVIT_Avance',
                        'cVIA.cVIA_Destino', 'cREU.cREU_FechaInicio', 'cREU.cREU_FechaFin', 'cREU.cREU_Titulo'],
                    conditions: [
                        {
                            valone: 'cREU.cEMP_Id',
                            valtwo: `${cEMP_Id.cEMP_Id}`,
                            condition: '=',
                        },
                        {
                            valone: 'cREU.cREU_FechaFin',
                            valtwo: lastDate,
                            condition: '>=',
                            logic: 'AND'
                        },
                        {
                            valone: 'cREU.cREU_FechaFin',
                            valtwo: currentDate,
                            condition: '<=',
                            logic: 'AND'
                        },
                        {
                            valone: 'cVIT.cVIT_Status_COMP',
                            valtwo: `1`,
                            condition: '=',
                            logic: 'AND'
                        },
                        {
                            logic: 'AND',
                            valone: 'rr.cVIT_Id',
                            condition: '=',
                            valtwo: 'cVIT.cVIT_Id ',
                            validColumn: true,
                        },
                        {
                            logic: 'AND',
                            valone: 'rr.cREU_Id',
                            condition: '=',
                            valtwo: 'cREU.cREU_Id',
                            validColumn: true,
                        },
                        {
                            logic: 'AND',
                            valone: 'rr.cVIT_Id',
                            condition: '=',
                            valtwo: 'cVIA.cVIT_Id ',
                            validColumn: true,
                        }
                    ]
                }
            }
        })
    }, [cEMP_Id.cEMP_Id]);
    // if (statusViat === 6) {
    //     return (
    //         <ComprobacionViajero
    //             totalAComprobar={totalViaticos(viajes, alojamientos, totalMateriales(materiales, promocionales, requerimientoTI))}
    //             cEMP_Id={cEMP_Id}
    //             handleComponent={handleComponent}
    //         />
    //     )
    // }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModal.title}
                text={msgModal.text}
                textButtonA="Aceptar"
                open={showModal}
                handleClose={() => setShowModal(false)}
            />
            <div style={{ minHeight: '65vh', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}>
                    <h4>Realiza la comprobación de gastos</h4>
                </div>
                <ButtonsInformacion
                    title="Tarjeta a comprobar"
                    text1="Amex"
                    text2="Tarjeta Corporativa"
                    setCard={(c) => {
                        setCReuSelected(undefined);
                        setCard(c);
                        setViatico([]);
                    }}
                />
                <div style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}>
                    {card === 'Amex'
                        ? (
                            <MultipleSelect
                                options={optionsReu}
                                values={cReuSelected}
                                selectedOptions={setCReuSelected}
                                placeholder='Reuniones'
                                width='60%'
                            />
                        )
                        : (
                            <SimpleSelect
                                marginLeft='20%'
                                width='60%'
                                options={optionsReu}
                                placeholder='Reuniones'
                                value={cReuSelected}
                                setValue={setCReuSelected}
                            />
                        )
                    }

                    <ButtonNavigation
                        text="Buscar"
                        width="60%"
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        style={{ marginLeft: '20%' }}
                        marginTop='2%'
                        onClick={() => filterReu()}
                    />
                </div>
                <CardsViaticos viaticos={viatico} handleComponent={handleComponent} cEMP_Id={cEMP_Id} showDetails={false} />
            </div>
            <RolSelection profile='65' width='75%' marginLeft='20%' />
            <ButtonNavigation
                text="Enviar"
                width="60%"
                backgroundColor="#3898EC"
                border="none"
                color="#fff"
                marginTop='2%'
                style={{ marginLeft: '20%' }}
                onClick={() => sendTicketToAccounting()}
            />
        </PageContainer>
    )

}
export default ExpensesCheckView;