import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import Swal from 'sweetalert2'
import Loader from 'rsuite/Loader';
import {
  DINAMIC_GET,
  GET_ACUERDOS_BY_REU,
  GET_ALL_CEDENTES,
  GET_ALL_REU,
  GET_REUNION_ACUERDOS_DATA,
} from '../../mutation/mutation';
import { Table } from 'antd';

const MoreView = ({
  handleMoreView,
  handleEdit,
  idREU,
  selectedOpcions,
  habilitada,
}) => {
  //console.log('datos props', data);
  const [showoptions, setShowoptions] = useState(false);


  useEffect(() => {
    setShowoptions(habilitada);
  }, [habilitada]);

  return (
    <>
      <div
        onClick={() => {
          setShowoptions(!showoptions);
          selectedOpcions(idREU);
          console.log(showoptions);
          console.log(idREU);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: 25,
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            width: 5,
            height: 5,
            backgroundColor: 'black',
            borderRadius: '50%',
          }}
        ></div>
        <div
          style={{
            width: 5,
            height: 5,
            backgroundColor: 'black',
            borderRadius: '50%',
          }}
        ></div>
        <div
          style={{
            width: 5,
            height: 5,
            backgroundColor: 'black',
            borderRadius: '50%',
          }}
        ></div>
      </div>
      {showoptions && (
        <ul
          id={idREU}
          style={{
            zIndex: 1000,
            position: 'absolute',
            background: '#F5F5F5',
            marginTop: '-25px',
            marginLeft: '30px',
            listStyle: 'none',
            padding: '5px',
            cursor: 'pointer',
          }}
        >
          <li
            onClick={() => {
              handleMoreView();
              setShowoptions(!showoptions);
            }}
          >
            Ver más
          </li>
          <li></li>
          <li
            onClick={() => {
              handleEdit();
              setShowoptions(!showoptions);
            }}
          >
            Editar
          </li>
        </ul>
      )}
    </>
  );
};

const TableReuniones = ({
  reunions,
  searchEvents,
  setReunionSelected,
  setOpenPdfPreview,
  setEdit,
}) => {
  console.log('Datos obnt: ', reunions);
  const [currentOpcions, setCurrentOpcions] = useState('');
  const [alert, setAlert] = useState(true);
  return (
    <Table
      columns={[
        { title: 'ID', dataIndex: 'cREU_Id', key: 'cREU_Id' },
        {
          title: 'Titulo',
          dataIndex: 'cREU_Titulo',
          key: 'cREU_Titulo',
          render: (text, row) => (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                searchEvents(row.cREU_Id);
                setReunionSelected(row.cREU_Id);
                setOpenPdfPreview((state) => !state);
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'Fecha de inicio',
          dataIndex: 'cREU_FechaInicio',
          key: 'cREU_FechaInicio',
        },
        // { title: 'Fecha de fin', dataIndex: 'cREU_FechaFin', key: 'cREU_FechaFin' },
        { title: 'País', dataIndex: 'Pais', key: 'Pais' },
        {
          title: 'Status',
          dataIndex: 'cREU_Id',
          key: 'cREU_Id',
          render: (text, row) => (
            <MoreView
              idREU={row.cREU_Id}
              habilitada={currentOpcions === row.cREU_Id ? true : false}
              selectedOpcions={setCurrentOpcions}
              handleMoreView={() => {
                // handler(true)
                console.log(row.cREU_Id);
                searchEvents(row.cREU_Id);
                setReunionSelected(row.cREU_Id);
                setOpenPdfPreview((state) => !state);
                // se ejecuta funcion correspondiente handleClick(obj.cREU_Id)
              }}
              handleEdit={() => {
                //    handler(true)
                console.log(row.cREU_Id);
                searchEvents(row.cREU_Id);
                setReunionSelected(row.cREU_Id);
                if (alert) {
                  let timerInterval;
                  Swal.fire({
                    title: '⌛ Cargando Arhivos ⌛',
                    html: 'por favor espere... <br/> <b style="display: none"></b>',
                    timer: 7500,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      const b = Swal.getHtmlContainer().querySelector('b');
                      timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                      console.log('I was closed by the timer');
                      setEdit(true);
                      setAlert(true)
                    }
                  });
                }
                setAlert(true);

                // se ejecuta funcion correspondiente
              }}
            />
          ),
        },
      ]}
      dataSource={reunions}
    />
    // <table style={{ width: '100%' }}>
    //     <thead style={{ borderTop: '2px solid', fontWeight: 'bolder' }}>
    //         <td>ID</td>
    //         <td>Título</td>
    //         <td>Fecha de inicio</td>
    //         <td>Fecha de fin</td>
    //         <td>Pais</td>
    //         <td>Status</td>
    //     </thead>
    //     <tbody>
    //         {reunions.map((obj) => {
    //             //console.log('objeto en table: ', obj)
    //             return (
    //                 <tr style={{ borderTop: '2px solid', fontWeight: 'bolder', height: 40 }}>
    //                     <td>{obj.cREU_Id}</td>
    //                     <td>{obj.cREU_Titulo}</td>
    //                     <td>{obj.cREU_FechaInicio}</td>
    //                     <td>{obj.cREU_FechaFin}</td>
    //                     <td>{obj.Pais}</td>
    //                     <td>
    //                         <MoreView
    //                             handleMoreView={() => {
    //                                 // handler(true)
    //                                 console.log(obj.cREU_Id)
    //                                     searchEvents(obj.cREU_Id);
    //                                     setReunionSelected(obj.cREU_Id);
    //                                     setOpenPdfPreview((state) => !state);
    //                                 // se ejecuta funcion correspondiente handleClick(obj.cREU_Id)
    //                             }}
    //                             handleEdit={() => {
    //                             //    handler(true)
    //                             console.log(obj.cREU_Id)
    //                             setEdit(true)
    //                             searchEvents(obj.cREU_Id);
    //                             setReunionSelected(obj.cREU_Id);

    //                                 // se ejecuta funcion correspondiente

    //                             }}
    //                         />
    //                     </td>
    //                 </tr>
    //             )
    //         })}

    //     </tbody>
    // </table>
  );
};

export default TableReuniones;
