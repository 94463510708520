
import React, { useState, Fragment, useEffect } from 'react';
import '../components.css';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import NotaInput from './NotaInput';
import { DYNAMIC_INSERT } from '../../mutation/mutation';
import { useMutation } from 'react-apollo';

export default function Nota(props) {
  const [check, setCheck] = useState(false)
  const [stateSave, setStateSave] = useState(false)
  const [state, setState] = useState({ title: "", nota: "" })
  const [data, setData] = useState([]);
  const [showNotes, setShowNotes] = useState(true)

  const [saveNote] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log("guardado ", JSON.parse(dynamicInsert.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const onChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value })
    setCheck(true)
  }

  const onClick = () => {
    // setState({ title: "", nota: "" })
    // setData([state, ...data])
    // setCheck(false)
    setStateSave(true)
    console.log(data)
  }

  const handleSaveNotes = () => {
    let detailValues = [];

    for (let i = 0; i < data.length; i++) {
      let values = [];
      const { title, nota } = data[i];
      // values.push('', title, nota);
      values.push("1", title, nota);
      detailValues.push({ values });
    }
    let input = {
      input: {
        table: 'cNOTA_Reunion',
        columns: ['cREU_Id', 'cNOTA_Titulo', 'cNOTA_Descripcion'],
        detailValues,
        // detailValues: [
        //   {
        //     values: []
        //   }
        // ]
      }
    }
    console.log("input ===", input)
    saveNote({
      variables: input
    })
    props.setStateButton({ ...props.stateButton, four: true })

    setData([state, ...data])
    setCheck(false)
    setStateSave(true)
    
    setState({ title: "", nota: "" })
    setShowNotes(false)
  }

  useEffect (() => {
    setShowNotes(true)
  }, [])



  return (
    <Fragment>
      { showNotes && (
        <Fragment>
          <h3 style={{ order: 2 }}>Nota rápida</h3>
          <div className='div-inputs-nota'>
          { data.length < 1 && (
            <>
              <NotaInput onChange={onChange} onClick={onClick} setState={setState} state={state} check={check} classes="order-input" />
            </> 
          )}
          {
            data.map((state2) => (
              <>
                {state2.title && (
                  <NotaInput state={state2} disabled={false} />
                )}
              </>
            ))
          }
        </div>
        </Fragment>
      ) }
      {
        stateSave && showNotes ?
          <div className="container-button-nota">
            <ButtonNavigation text='Registrar nota' backgroundColor='#3898EC' border='#3898EC' color='#FFF' onClick={() => 
              handleSaveNotes()
            } width="133px" />
          </div>
          : null
      }
    </Fragment>
  )
}
