import React, { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';

import { config } from '../config/config';
import { getUserDetails } from '../services/GraphService';
export const MyContext = createContext();

function normalizeError(error) {
  let normalizedError = {};
  if (typeof error === 'string') {
    const errParts = error.split('|');
    normalizedError =
      errParts.length > 1
        ? { message: errParts[1], debug: errParts[0] }
        : { message: error };
  } else {
    normalizedError = {
      message: error.message,
      debug: JSON.stringify(error),
    };
  }
  return normalizedError;
}

function isInteractionRequired(error) {
  if (!error.message || error.message.length <= 0) {
    return false;
  }
  return (
    error.message.indexOf('consent_required') > -1 ||
    error.message.indexOf('interaction_required') > -1 ||
    error.message.indexOf('login_required') > -1 ||
    error.message.indexOf('no_account_in_silent_request') > -1
  );
}

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

class MyProvider extends Component {
  //history=useHistory();
  state = {
    cREU_Id: null,
    cEMP_Id: null,
    cEDO_Id: null,
    cPAI_Id: null,
    cCIUD_Id: null,
    cPLA_Id: null,
    cREU_ConferenciaURL: '',
    cREU_FechaInicio: '',
    cREU_HoraInicio: '',
    cREU_FechaFin: '',
    cREU_HoraFin: '',
    cREU_Lugar: '',
    cREU_Titulo: '',
    cREU_ZonaHoraria: '',
    cREU_FechaActualPais: '',
    cREU_HoraActualPais: '',
    cREU_TipoReunion: '',
    cREU_IdOutlook: '',
    invitadosReunion: [],
    rEjexORG: [],
    AuthComponentProps: {
      error: null,
      isAuthenticated: null,
      user: null,
      token: null,
      expiresOn: null,
    },
    viaticosProps: {
      cREU_Id: '',
      cTAR_Id: '',
      cTIP_Id: '',
      cVIT_Id: '',
      cVIT_Status_COMP: '',
      cVIT_Status_VIAT: '',
      alojamiento: [],
      materiales: [],
      promocionales: [],
      requerimientoTI: [],
      viajes: [],
      observaciones: [],
      cVIT_Avance: '',
      reuniones: [],
    },
    acuerdosProps: {
      cREU_Id: '',
      cREU_HoraInicio: '',
      cREU_FechaInicio: '',
      cREU_Lugar: '',
      cREU_Titulo: '',
    },
    saveAcuerdos: {
      cACU_Id: '',
      cTIPRE_Id: '',
      CVIS_Id: '',
      cEMP_Id: '',
      cREU_Id: '',
      cENT_Id: '',
      cTEM_Id: '',
      cSUBE_Id: '',
      cTIPR_Id: '',
      cESTS_Id: '',
      cSUBTS_Id: '',
      cREGSS_Id: '',
      cSUBE_Descripcion: '',
      cCOMA_Descripcion: '',
      cENT_Descripcion: '',
      cESTS_Descripcion: '',
      cSUBTS_Descripcion: '',
      cREGSS_Descripcion: '',
      cRELP_Id: '',
      cACCSR_Id: '',
      cTEMSR_Id: '',
      cARE_Id: '',
      cSUBA_Id: '',
      sComentarios: '',
      subArea: [],
      areas: [],
      subAreas: [],
    },
    saveSegRelPatria: {
      generarReporte: '',
      commentOne: '',
      commentTwo: '',
      status: '',
      areas: [],
      subAreas: [],
    },
    commentsAcuerdos: {
      cCOMA_Id: '',
      entorno: '',
      segEntorno: '',
      relacion: '',
      segRelacion: '',
      multiComments: {
        firstComment: '',
        secondComment: '',
        thirdComment: '',
      },
    },
    relacionData: {
      tipoRelacion: '',
      cRELP_Id: '',
      tipoSeguimiento: '',
      cSEGR_Id: '',
      accion: '',
      status: ''
    },
    reuProgramadaData: {
      cREU_Id: '',
      cREU_Titulo: '',
      cREU_FechaInicio: '',
      cREU_HoraInicio: '',
    },
  };

  clearState = () => {
    this.setState({
      ...this.state,
      cREU_Id: null,
      cEMP_Id: null,
      cEDO_Id: null,
      cPAI_Id: null,
      cCIUD_Id: null,
      cPLA_Id: null,
      cREU_ConferenciaURL: '',
      cREU_FechaInicio: '',
      cREU_HoraInicio: '',
      cREU_FechaFin: '',
      cREU_HoraFin: '',
      cREU_Lugar: '',
      cREU_Titulo: '',
      cREU_ZonaHoraria: '',
      cREU_FechaActualPais: '',
      cREU_HoraActualPais: '',
      cREU_TipoReunion: '',
      cREU_IdOutlook: '',
      invitadosReunion: [],
      rEjexORG: [],
      viaticosProps: {
        cREU_Id: '',
        cTAR_Id: '',
        cTIP_Id: '',
        cVIT_Id: '',
        cVIT_Status_COMP: '',
        cVIT_Status_VIAT: '',        
        alojamiento: [],
        materiales: [],
        promocionales: [],
        requerimientoTI: [],
        viajes: [],
        observaciones: [],
        cVIT_Avance: '',
        reuniones: [],
      },
      acuerdosProps: {
        cREU_Id: '',
        cREU_HoraInicio: '',
        cREU_FechaInicio: '',
        cREU_Lugar: '',
        cREU_Titulo: '',
      },
      saveAcuerdos: {
        cTIPRE_Id: '',
        CVIS_Id: '',
        cEMP_Id: '',
        cREU_Id: '',
        cENT_Id: '',
        cTEM_Id: '',
        cSUBE_Id: '',
        cTIPR_Id: '',
        cSUBE_Descripcion: '',
        cCOMA_Descripcion: '',
        cTIPR_Descripcion: '',
        cRELP_Id: '',
        areas: [],
        subAreas: [],
      },
      saveSegRelPatria: {
        generarReporte: '',
        commentOne: '',
        commentTwo: '',
        status: '',
        areas: [],
        subAreas: [],
      },
      commentsAcuerdos: {
        cCOMA_Id: '',
        entorno: '',
        segEntorno: '',
        relacion: '',
        segRelacion: '',
        multiComments: {
          firstComment: '',
          secondComment: '',
          thirdComment: '',
        },
      },
      relacionData: {
        tipoRelacion: '',
        cRELP_Id: '',
        tipoSeguimiento: '',
        cSEGR_Id: '',
        accion: '',
        status: ''
      },
      reuProgramadaData: {
        cREU_Id: '',
        cREU_Titulo: '',
        cREU_FechaInicio: '',
        cREU_HoraInicio: '',
      },
      cancelacion: {
        message: '',
      },
    });
  };

  getData = (data) => {
    this.setState(data);
  };

  setCommentsAcuerdos = (data) => {
    this.setState({
      ...this.state,
      commentsAcuerdos: {
        ...this.state.commentsAcuerdos,
        ...data,
      },
    });
  };

  setSaveAcuerdos = (data) => {
    this.setState({
      ...this.state,
      saveAcuerdos: {
        ...this.state.saveAcuerdos,
        ...data,
      },
    });
  };
  setAcuerdosProps = (data) => {
    this.setState({
      ...this.state,
      acuerdosProps: {
        ...this.state.acuerdosProps,
        ...data,
      },
    });
  };
  setSaveSegRelPatria = (data) => {
    this.setState({
      ...this.state,
      saveSegRelPatria: {
        ...this.state.saveSegRelPatria,
        ...data,
      },
    });
  };

  setRelacionData = (data) => {
    this.setState({
      ...this.state,
      relacionData: {
        ...this.state.relacionData,
        ...data,
      },
    });
  };

  setDataReuProgramada = (data) => {
    this.setState({
      ...this.state,
      reuProgramadaData: {
        ...this.state.reuProgramadaData,
        ...data,
      },
    });
  };
  getDataAuthenticated = (data) => {
    this.setState({
      ...this.state,
      ...data,
    });
  };

  async login() {
    try {
      await publicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account',
      });
      await this.getUserProfile();
    } catch (error) {
      sessionStorage.setItem(
        'error',
        JSON.stringify({
          error: normalizeError(error),
          isAuthenticated: false,
          user: {},
          token: null,
          expiresOn: null,
        })
      );
      this.getDataAuthenticated({
        AuthComponentProps: {
          error: normalizeError(error),
          isAuthenticated: false,
          user: {},
          token: null,
          expiresOn: null,
        },
      });
      console.log(error);
    }
  }

  logout() {
    publicClientApplication.logout();
  }

  async getAccessToken(scopes) {
    try {
      const accounts = publicClientApplication.getAllAccounts();
      if (accounts.length <= 0) throw new Error('login_required');
      const silentResult = await publicClientApplication.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });
      console.log('Token_____------->>>----->>>', silentResult);
      //return silentResult.accessToken;
      return silentResult;
    } catch (error) {
      if (isInteractionRequired(error)) {
        const interactiveResult =
          await publicClientApplication.acquireTokenPopup({ scopes: scopes });
        return interactiveResult.accessToken;
      } else {
        throw error;
      }
    }
  }

  async getUserProfile() {
    try {
      const accessToken = await this.getAccessToken(config.scopes);
      console.log(accessToken);
      if (accessToken) {
        const user = await getUserDetails(accessToken.accessToken);
        localStorage.setItem(
          'user',
          JSON.stringify({
            error: null,
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              timeZone: user.mailboxSettings.timeZone,
              timeFormat: user.mailboxSettings.timeFormat,
            },
            token: accessToken.accessToken,
            expiresOn: accessToken.expiresOn,
          })
        );
        this.getDataAuthenticated({
          AuthComponentProps: {
            error: null,
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              timeZone: user.mailboxSettings.timeZone,
              timeFormat: user.mailboxSettings.timeFormat,
            },
            token: accessToken.accessToken,
            expiresOn: accessToken.expiresOn,
          },
        });
      }
    } catch (error) {
      sessionStorage.setItem(
        'error',
        JSON.stringify({
          error: normalizeError(error),
          isAuthenticated: false,
          user: {},
          token: null,
          expiresOn: null,
        })
      );
      this.getDataAuthenticated({
        AuthComponentProps: {
          error: normalizeError(error),
          isAuthenticated: false,
          user: {},
          token: null,
          expiresOn: null,
        },
      });
    }
  }

  setErrorMessage(message, debug) {
    this.setState({
      error: { message: message, debug: debug },
    });
  }

  setInfoViaticos = (data) => {    
    this.getDataAuthenticated({
      viaticosProps: {
        ...data,
      },
    });
  };

  componentDidMount() {
    const acounts = publicClientApplication.getAllAccounts();
    if (acounts && acounts.length > 0) {
      this.getUserProfile();
    }
  }

  render() {
    const {
      state,
      clearState,
      getData,
      setSaveAcuerdos,
      setSaveSegRelPatria,
      setRelacionData,
      setDataReuProgramada,
      login,
      logout,
      getAccessToken,
      getUserProfile,
      getDataAuthenticated,
      setErrorMessage,
      setInfoViaticos,
      setAcuerdosProps,
      setCommentsAcuerdos,
    } = this;
    return (
      <MyContext.Provider
        value={{
          state,
          clearState,
          getData,
          setSaveAcuerdos,
          setSaveSegRelPatria,
          setRelacionData,
          setDataReuProgramada,
          login,
          logout,
          getAccessToken,
          getUserProfile,
          getDataAuthenticated,
          setErrorMessage,
          setInfoViaticos,
          setAcuerdosProps,
          setCommentsAcuerdos,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default withRouter(MyProvider);
