import { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_TRAVEL_EXPENSES_ALL, GET_TRAVEL_EXPENSES_BY_IDUSER } from '../../query/query';

export const useGetViaticos = ({ getAllExpenses = false, cVIT_Avance = '', cVIT_Status_VIAT = 0, cEMP_Id, cVIT_Status_COMP }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [getTravelExpenses] = useLazyQuery(
        GET_TRAVEL_EXPENSES_BY_IDUSER, {
        fetchPolicy: 'no-cache',
        onCompleted({ getTravelExpensesbyUser }) {
            const aux = JSON.parse(getTravelExpensesbyUser);
            setData(aux);
            setLoading(false);
        }
    });

    const [getTravelExpensesAll] = useLazyQuery(
        GET_TRAVEL_EXPENSES_ALL, {        
        fetchPolicy: 'no-cache',                
        onCompleted({ getTravelExpensesAll }) {
            const aux = JSON.parse(getTravelExpensesAll);
            setData([...data, ...aux]);
            setLoading(false);
        }
    })
    useEffect(() => {
        const currentDate = new Date().toISOString().slice(0, 10);
        setLoading(true);
        if (getAllExpenses) {
            if (cEMP_Id.profile === 61) {
                // OBTENER TODAS LAS SOLICITUDES POR USUARIO
                console.log('OBTENER TODAS LAS SOLICITUDES POR USUARIO')
                getTravelExpenses({ variables: { cEMP_Id: cEMP_Id.cEMP_Id, cREU_FechaInicio: currentDate } });
            } else {
                // OBTENER TODAS LAS SOLICITUDES DE USUARIO DESDE ASISTENTE U OTRO
                console.log('OBTENER TODAS LAS SOLICITUDES DE USUARIO DESDE ASISTENTE U OTRO')
                getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate } });
            }
        } else {
            if (cVIT_Avance === 1 || cVIT_Avance === 3 || cVIT_Avance === 4) {
                console.log('OBTENER TODAS LAS SOLICITUDES POR AVANCE 1 o 3')
                getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate, cVIT_Status_VIAT } });
                setTimeout(() => {
                    getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate, cVIT_Status_VIAT: 4 } });
                }, 1000)
                
            } else if (cVIT_Avance === 6 || cVIT_Avance === 7 || cVIT_Avance === 8) {
                console.log('OBTENER TODAS LAS SOLICITUDES POR AVANCE', cVIT_Avance)
                getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate, cVIT_Status_VIAT, cVIT_Avance } });
            } else if (cVIT_Avance === 11 && cVIT_Status_COMP === 2) {
                console.log('OBTENER TODAS LAS COMPROBACIONES POR AVANCE 11')
                getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate, cVIT_Status_COMP } });
            } else if (cVIT_Avance === 12 && cVIT_Status_COMP >= 3) {                
                console.log('OBTENER TODAS LAS COMPROBACIONES POR AVANCE 12')
                getTravelExpensesAll({ variables: { cREU_FechaInicio: currentDate, cVIT_Status_COMP } });
            }
        }
    }, [cVIT_Avance, cVIT_Status_VIAT, getAllExpenses])

    return { data, loading }

}