import React from 'react';
import { Query } from 'react-apollo';
import { USUARIO_ACTUAL } from '../query/query';

const Session = Component => props => (
    <Query query={USUARIO_ACTUAL}>
        {function ({ loading, error, data, refetch }) {
            console.log('data=============',data);            
            if (loading) return null;
            if (localStorage.getItem('userRol')) {
                data = {
                    obtenerUsuario: {
                        ...data.obtenerUsuario,
                        profile: parseInt(localStorage.getItem('userRol'))
                    },                   
                }
            }            
            return <Component
                {...props}
                refetch={refetch}
                session={data} />
        }}
    </Query>
)

export default Session;