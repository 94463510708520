import { TextareaAutosize } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import React from 'react'
import ButtonNavigation from '../Viaticos/ButtonNavigation'
import '.././components.css';

const ImputResumen = ({title,subtitle,status,disabled,setDisabled,value,setValue,ramo,flexDirection, heightTxtArea = ''}) => {
  return (
    <div style={{width:'75%'}}>
      <div style={{display:'flex',borderBottom:'1px solid #000',justifyContent:'space-between',alignItems:'baseline',flexDirection:flexDirection||'inherit'}}>
        <h4>
          {title}
        </h4>
        {status?(
            <span style={{width:'120px', height:'30px', backgroundColor:'#ECB85E', color:'#FFFFFF',borderRadius:'4px',display:'flex',justifyContent:'center',alignItems:'center'}}>
              {status}
            </span>
          ):null}
      </div>
      <br/>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <h5>{subtitle}</h5>
        <span onClick={setDisabled}>
          {
            disabled?
            <Edit/>:
            <Check/>
          }
        </span>
      </div>
      {
        ramo?(
          <div style={{backgroundColor:'#E5E5E5E5',borderBottom:'1px solid #E5E5E5E5',display:'inline-block'}}>
            <span style={{width:'auto',height:'25px',borderRadius:'4px',backgroundColor:'#85858585',color:'#FFFFFF',display:'flex',alignItems:'center',justifyContent:'center', padding: '5px 7px'}}>
              {ramo}
            </span>
          </div>
        ):null
      }
      <TextareaAutosize style={{height: heightTxtArea}} rowsMin={5} placeholder className={`resumen-input-area ${!disabled?'active-input':''}` }disabled={disabled} value={value} onChange={({target})=>setValue(target.value)}/>
    </div>
  )
}

export default ImputResumen
