import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { PageContainer } from '../StylesComponents/common-styled';
import { getcTOBS_Id, getRol } from '../../funciones-auxiliares/viaticos';
import { useMutation } from 'react-apollo';
import MaterialTable from 'material-table';
import { MyContext } from '../Context';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE, DINAMIC_GET } from '../../mutation/mutation';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DialogAlert from '../DiaologAlert';
import RolSelection from '../Acuerdos/common/RolSelection';
import { fechaActual } from '../../funciones-auxiliares/programar-reunion';
import { sendEmail } from '../../services/SendEmails';
import { useMediaQuery } from '@material-ui/core';
const CheckingPolitica = ({ cEMP, cVIT_Avance }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const context = useContext(MyContext);
    const [columns] = useState([
        {
            title: 'Concepto',
            field: 'cGASVIT_Concepto',
            editable: 'never',
            cellStyle: {
                fontSize: 18, fontWeight: 'bolder'
            },
            headerStyle: {
                fontSize: 18, fontWeight: 'bolder'
            }
        },
        { title: 'N° Personas', field: 'cGASVIT_NPersonas', editable: 'never' },
        { title: 'N° de días', field: 'cGASVIT_nDias', editable: 'never' },
        { title: 'Importe en USD', field: 'cGASVIT_ImporteUSD', editable: 'never' },
        { title: 'Tipo de cambio*', field: 'cGASVIT_TipoCambio', editable: 'never' },
        { title: 'Importe en MXP', field: 'cGASVIT_ImporteMXP', editable: 'never' },
        { title: 'Excede límites de política', field: 'cGASVIT_ExcedePoliticas', editable: 'never' },
    ]);
    const [dataTableExpenses, setDataTableExpenses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [authorization, setAuthorization] = useState('');
    const [observations, setObservations] = useState('');
    const [exceedsLimits, setExceedsLimits] = useState(false);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [RolUser, setRolUser] = useState('65');
    const [transferDate, setTransferDate] = useState(fechaActual())
    const [getGasVit] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                let dataTable = [...response]
                let totalImporteUSD = 0
                let totalImporteMXP = 0
                let totalUSD = 0;
                let totalMXP = 0;
                const exceedsPolicies = [...response].some((expense) => expense.cGASVIT_ExcedePoliticas === 'Si' || expense.cGASVIT_ExcedePoliticas === 'Sí');
                setExceedsLimits(exceedsPolicies);
                dataTable.forEach((data) => {
                    let importeUSD = String(data.cGASVIT_ImporteUSD).replace('$', '').replace(',', '')
                    let importeMXP = String(data.cGASVIT_ImporteMXP).replace('$', '').replace(',', '')
                    totalImporteUSD += importeUSD === "" ? 0 : Number.parseFloat(importeUSD)
                    totalImporteMXP += importeMXP === "" ? 0 : Number.parseFloat(importeMXP)
                    totalUSD = '$' + Intl.NumberFormat('es-MX').format(totalImporteUSD)
                    totalMXP = '$' + Intl.NumberFormat('es-MX').format(totalImporteMXP)
                })

                setDataTableExpenses([...response, {
                    cGASVIT_Concepto: 'Total',
                    cGASVIT_NPersonas: '',
                    cGASVIT_nDias: '',
                    cGASVIT_ImporteUSD: totalUSD,
                    cGASVIT_TipoCambio: '',
                    cGASVIT_ImporteMXP: totalMXP,
                    cGASVIT_ExcedePoliticas: exceedsPolicies ? 'Sí' : 'No'
                }]);


                setIsLoading(false);
            }
        }
    });
    const [insert] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('insert', dynamicInsert);
        },
        onError(err) {
            console.log(err)
        }
    });
    const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
        onCompleted({ dynamicUpdate }) {
            if (cEMP.profile === 64) {
                // funcionalidad director de area
                if (authorization === 'Si') {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado a Contabilidad'
                    })
                    setShowModalErr(true);
                } else {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado al director'
                    });
                    setShowModalErr(true);
                }
            } else if (cEMP.profile === 65) {
                if (authorization === 'Si') {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado a viajero'
                    });
                    setShowModalErr(true);
                } else {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado a viajero'
                    });
                    setShowModalErr(true);
                }
            } else {
                if (!exceedsLimits && authorization === 'Si') {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado a Contabilidad'
                    });
                    setShowModalErr(true)
                } else if (authorization === 'Si' && exceedsLimits) {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado al director'
                    });
                    setShowModalErr(true);
                } else if (authorization === 'No') {
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: 'Ticket enviado al viajero'
                    });
                    setShowModalErr(true);
                }
            }

        },
        onError(err) {
            console.log(err)
        }
    });


    const saveObservations = async () => {
        if (observations !== '') {
            const input = {
                table: 'cOBS_ObservacionesViaticos',
                columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                detailValues: [
                    {
                        values: [
                            String(context.state.viaticosProps.cVIT_Id),
                            String(cEMP.cEMP_Id),
                            observations,
                            String(getcTOBS_Id(cEMP.profile)),
                        ]
                    },
                ]
            };
            await insert({ variables: { input } });
        } else if (authorization === 'No') {
            setMsgModalErr({
                ...msgModalErr,
                text: 'Campo obligatorio: Observaciones'
            });
            setShowModalErr(true);
            setStyleDisabled({})

        }
    };
    const updateVit = async (cVIT_Avance = '', cVIT_Status_VIAT = '') => {
        let columns = [
            {
                setkey: 'cVIT_Avance',
                setval: cVIT_Avance,
            },
        ];
        let conditions = [
            {
                valone: 'cVIT_Id',
                valtwo: String(context.state.viaticosProps.cVIT_Id),
                condition: '=',
                logic: 'AND',
            },
        ];
        if (cVIT_Status_VIAT !== '') {
            columns.push({
                setkey: 'cVIT_Status_VIAT',
                setval: cVIT_Status_VIAT,
            })
        }
        await updateStatus({
            variables: {
                input: {
                    table: 'cVIT_Viaticos',
                    columns,
                    conditions
                }
            }
        })
    }
    const sendTicket = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' })
        const token = context.state.AuthComponentProps.token;
        await saveObservations();
        if (cEMP.profile === 64) {
            // funcionalidad director de area
            if (authorization === 'Si') {
                if (sessionStorage.getItem('emailTo')) {
                    await updateVit('8');// avance 8 para la seccion 9 - contabilidad
                    await sendEmail({
                        accessToken: token,
                        emailTo: [sessionStorage.getItem('emailTo')],
                        subject: 'Solicitud de viaticos',
                        section: 'sectionEight'
                    });
                } else {
                    setMsgModalErr({
                        title: 'Error',
                        text: 'Selecciona un email'
                    });
                    setShowModalErr(true);
                    setStyleDisabled({})
                }

            } else if (authorization === 'No') {
                await updateVit('1', '1');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionEight_two'
                });
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una autorización'
                });
                setShowModalErr(true);
                setStyleDisabled({})
            }
        } else if (cEMP.profile === 65) {
            if (authorization === 'Si') {
                await updateVit('9');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionNine'
                });
            } else if (authorization === 'No') {
                await updateVit('1', '1');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionNine_two'
                });
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una autorización'
                });
                setShowModalErr(true);
                setStyleDisabled({})
            }
        } else {
            // funcionalidad para jefe directo
            if (!exceedsLimits && authorization === 'Si') {
                if (sessionStorage.getItem('emailTo')) {
                    await updateVit('8');// avance 8 para la seccion 9 - contabilidad                
                    await sendEmail({
                        accessToken: token,
                        emailTo: [sessionStorage.getItem('emailTo')],
                        subject: 'Solicitud de viaticos',
                        section: 'sectionSeven'
                    });
                } else {
                    setMsgModalErr({
                        title: 'Error',
                        text: 'Selecciona un email'
                    });
                    setShowModalErr(true);
                    setStyleDisabled({})
                }
            } else if (authorization === 'Si' && exceedsLimits) {
                if (sessionStorage.getItem('emailTo')) {
                    await updateVit('7');// avance 7 para la seccion 8 - director de area  
                    await sendEmail({
                        accessToken: token,
                        emailTo: [sessionStorage.getItem('emailTo')],
                        subject: 'Solicitud de viaticos',
                        section: 'sectionSeven_two'
                    });
                } else {
                    setMsgModalErr({
                        title: 'Error',
                        text: 'Selecciona un email'
                    });
                    setShowModalErr(true);
                    setStyleDisabled({})
                }
            } else if (authorization === 'No') {
                await updateVit('1', '1');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionSeven_three'
                });
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una autorización'
                });
                setShowModalErr(true);
                setStyleDisabled({})
            }
        }

    }
    const updateRol = () => {
        if (cEMP.profile === 64) {
            // funcionalidad director de area
            if (authorization === 'Si') {
                setRolUser('65');
            } else if (authorization === 'No') {
                setRolUser('');
            }
        } else if (cEMP.profile === 65) {
            if (authorization === 'Si') {
                setRolUser('');
            } else {
                setRolUser('');
            }
        } else {
            // funcionalidad para jefe directo
            if (!exceedsLimits && authorization === 'Si') {
                setRolUser('65');
            } else if (authorization === 'Si' && exceedsLimits) {
                setRolUser('64')
            } else if (authorization === 'No') {
                setRolUser('62');
            }
        }
    }
    useEffect(() => {
        updateRol();
    }, [exceedsLimits, authorization])
    useEffect(() => {
        getGasVit({
            variables: {
                input: {
                    table: 'cGASXVIT_Gastos',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cVIT_Id',
                            valtwo: String(context.state.viaticosProps.cVIT_Id),
                            condition: '=',
                        }
                    ]
                }
            }
        })
    }, [])
    if (isLoading) {
        return (
            <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                    <CircularProgress disableShrink size={100} />
                </div>
            </>
        )
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}

            />
            <Box m={0} marginTop={0}>
                <MaterialTable
                    title="Formulario de gastos"
                    style={{ marginTop: 20 }}
                    columns={columns}
                    data={dataTableExpenses}
                    options={{
                        search: false,
                        paging: false,
                        filtering: false,
                        exportButton: true,
                        exportFileName: 'Solicitud de viaticos'
                    }}
                />
                {cVIT_Avance == 8 && cEMP.profile === 65 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                        <div style={{ fontSize: 18, fontWeight: 'bolder' }}>
                            VIATICO AUTORIZADO POR JEFE DIRECTO✅
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <p style={{ fontSize: 16, fontWeight: 'bolder' }}>Selecciona una fecha para la transferencia correspondiente a los viáticos</p>
                            <TextField
                                style={{ width: '50%', }}
                                id="date-transfer"
                                label="Fecha transferencia"
                                type="date"
                                variant="filled"
                                value={transferDate}
                                onChange={({ target }) => setTransferDate(target.value)}
                            />
                        </div>
                    </div>
                ) : null}
                <FormControl style={{ width: '50%', marginTop: 50 }}>
                    <InputLabel id="auth-lbl">Autorización</InputLabel>
                    <Select
                        labelId="auth-lbl"
                        id="auth"
                        value={authorization}
                        label="Autorización"
                        onChange={({ target }) => setAuthorization(target.value)}
                    >
                        <MenuItem value="">--Selecciona una opción--</MenuItem>
                        <MenuItem value="Si">Sí</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </FormControl>
                {context.state.viaticosProps.observaciones.map((data, index) => {
                    if (data.cTOBS_Id == 1) {
                        sessionStorage.setItem('nombre_Empleado', data.cEMP_Nombre);
                        sessionStorage.setItem('emailToTraveler', data.cEMP_Mail);
                    }
                    return (
                        <Fragment key={index}>
                            <div style={{ marginTop: '20px' }}>
                                <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                    data.cTOBS_Id
                                )}`}</h3>
                            </div>
                            <TextareaAutosize
                                minRows={10}
                                style={{ width: '100%' }}
                                id="text-mensaje"
                                name="area"
                                value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                                disabled
                            />
                        </Fragment>
                    );
                })}
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
                </div>
                <TextareaAutosize
                    rowsMin={10}
                    style={{ width: '100%' }}
                    id="text-mensaje"
                    name="observaciones"
                    onChange={({ target }) => setObservations(target.value)}
                    value={observations}
                    placeholder="Escribe comentarios del viaje ..."
                />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {RolUser === '' ? null : (
                        <RolSelection profile={RolUser} />
                    )}
                    <ButtonNavigation
                        text="Guardar"
                        width={isMobile ? "100%" : "50%"}
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        justifyContent="center"
                        onClick={sendTicket}
                        style={styleDisabled}
                    />
                </div>
            </Box>
        </PageContainer >
    )
}

export default CheckingPolitica