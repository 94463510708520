import React, { useEffect, useState, Fragment } from 'react';
import ModalReviewViaticos from '../ViaticosV2/ModalReviewViaticos';
import CardProgress from './CardProgress';


const CardsViaticos = ({ viaticos = [], handleComponent, cEMP_Id, showDetails }) => {
    console.log(viaticos,' ARRAY VIATICOS')
    const [listReuniones, setListReuniones] = useState([])
    const [openModalReuniones, setOpenModalReuniones] = useState(false);

    if (viaticos.length > 0 ) {
        return (
            <Fragment>
                <ModalReviewViaticos openDirectory={openModalReuniones} setOpenDirectory={setOpenModalReuniones} data={listReuniones} />
                {viaticos.map(data => {
                    const { cREU_Titulo, cVIA_Destino } = data.reuniones[0];
                    const inicio = data.cREU_FechaInicio;
                    const fin = data.cREU_FechaFin;
                    return (
                        <CardProgress
                            key={data.cVIT_Id}
                            idViaticos={data.cVIT_Id}
                            nombre={cEMP_Id.cEMP_Nombre}
                            idReunion={data.cREU_Titulo}
                            fechas={`${inicio} - ${fin}`}
                            pais={data.cVIA_Destino}
                            status={data.cVIT_Status_VIAT}
                            progress={data.cVIT_Avance}
                            handleClick={() => {
                                handleComponent(data.cVIT_Status_VIAT, data.cVIT_Avance);
                            }}
                            openModal={() => {
                                setListReuniones(data.reuniones);
                                setOpenModalReuniones(true);
                            }}
                            showDetails={showDetails}
                            showListReu={data.reuniones.length > 0}
                        />
                    )
                })}
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <CardProgress />
                <CardProgress />
            </Fragment>
        )
    }
}

export default CardsViaticos;
