import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import {
  DINAMIC_GET,
  DYNAMIC_INSERT,
  DYNAMIC_UPDATE,
} from '../../mutation/mutation';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import '../components.css';
import { ArrowBackIos } from '@material-ui/icons';
import { MyContext } from '../Context';
//components
import LastStep from './componentsRelacion/LastStep';
import Comentarios from './Comentarios';
import AreasInvV2 from './common/AreasInvV2';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SeguimientoRelPatria = ({ backRelacionPatria, onClickGoReport }) => {
  const contextSet = useContext(MyContext);
  const [saveIdResponseQuestions, setSaveIdResponseQuestions] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [disabledOne, setDisabledOne] = useState(true);
  const [disabledTwo, setDisabledTwo] = useState(true);
  const [answerByQuestion, setAnswerByQuestion] = useState({
    commentOne: '',
    commentTwo: '',
    status: '',
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));
  const [generarReporte, setGenerarReporte] = useState('');
  const [tema, setTema] = useState('');
  const [areaAtencionOption] = useState({
    expPLD: [
      { cTEMSR_Id: 1, cTEMSR_Descripcion: 'PLD / Oficial de Cumplimiento' },
      { cTEMSR_Id: 2, cTEMSR_Descripcion: 'Planeación y Coordinación' },
    ],
    impuestoRetenido: [
      { cTEMSR_Id: 3, cTEMSR_Descripcion: 'Fiscal' },
      { cTEMSR_Id: 4, cTEMSR_Descripcion: 'Otra Área' },
    ],
    impuestoRetenidoAreas: [
      { cARE_Id: 5, cSUBA_Id: '', cARE_Descripcion: 'Otra Área', cSUBA_Descripcion: '' },
      { cARE_Id: 2, cSUBA_Id: 9, cARE_Descripcion: 'Finanzas', cSUBA_Descripcion: 'Fiscal' }
    ],
  });
  const [seguimiento, setSeguimiento] = useState([]);
  const [optionsSegReporte, setOptionsSegReporte] = useState([]);
  const [optionsSegDarReporte, setOptionsSegDarReporte] = useState([]);
  const [optionsTomarAcciones, setOptionsTomarAcciones] = useState([]);
  const [optionsTomarAccionesImpuestos, setOptionsTomarAccionesImpuestos] = useState([]);
  const [optionsTomarAccionesPLD, setoptionsTomarAccionesPLD] = useState([]);
  const [optStatusSeguimiento, setOptStatusSeguimiento] = useState([]);
  const [saveAdvanceQuestion, setSaveAdvanceQuestion] = useState([]);
  const [areas, setAreas] = useState();
  const [selectedArea, setSelectedArea] = useState({
    areas: [],
    subAreas: []
  });
  const classes = useStyles();

  const [saveComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });
  const [getSeguimiento] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSeguimientoReporte] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOptionsSegReporte(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSeguimientoDarReporte] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOptionsSegDarReporte(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getTomarAcciones] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOptionsTomarAcciones(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getTomarAccionesImpuestos] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOptionsTomarAccionesImpuestos(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getTomarAccionesPLD] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setoptionsTomarAccionesPLD(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getStatusSeg] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOptStatusSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });

  const [saveREL] = useMutation(DYNAMIC_INSERT, {
    onCompleted(data) {
      console.log('INSERTED', data);
    },
    onError(err) {
      console.error('SAVE  ERROR', err);
    },
  });

  const [getArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setAreas(JSON.parse(dynamicGet.response));
    },
  });

  const getDataArea = (areaName) => {
    const {cARE_Id} = (areas.filter(x => x.cARE_Descripcion === areaName))[0];
    contextSet.setSaveAcuerdos({
      ...contextSet.state.saveAcuerdos,
      cARE_Id
    });

    contextSet.setSaveSegRelPatria({
      ...contextSet.state.saveSegRelPatria,
      areas: [cARE_Id.toString()],
    });
    setSelectedArea(areaName);
    console.log("AREA:", contextSet.state.saveAcuerdos.cARE_Id, areaName)
  };

  const saveRelPatria = () => {
    const { state } = contextSet;
    const { cRELP_Id, cSEGR_Id, cACCSR_Id, cARE_Id, cSUBA_Id, cESTS_Id } =
      state.saveAcuerdos;
    const { areas, subAreas } = state.saveSegRelPatria;
    console.log(
      'RELP',
      cRELP_Id,
      'SEGR',
      cSEGR_Id,
      'ACCSR',
      cACCSR_Id,
      'ARE',
      cARE_Id,
      'SUBA',
      cSUBA_Id,
      'ESTS',
      cESTS_Id
    );
    let columns = ['cRELPR_Id'];
    let values = [String(1)];
    if (cSEGR_Id) {
      columns.push('cSEGR_Id');
      values.push(String(cSEGR_Id));
    }
    if (cACCSR_Id) {
      columns.push('cACCSR_Id');
      values.push(String(cACCSR_Id));
    }
    if (cESTS_Id) {
      columns.push('cESTS_Id');
      values.push(String(cESTS_Id));
    }
    // if (cARE_Id) {
    //   columns.push('cARE_Id');
    //   values.push(String(cARE_Id));
    // }
    // if (cSUBA_Id) {
    //   columns.push('cSUBA_Id');
    //   values.push(String(cSUBA_Id));
    // }
    if (areas) {
      columns.push('areas');
      values.push(areas.join(","));
    }
    if (subAreas) {
      columns.push('subAreas');
      values.push(subAreas.join(","));
    }
    columns.push('cACU_Id');
    values.push(String(contextSet.state.saveAcuerdos.cACU_Id));
    saveREL({
      variables: {
        input: {
          table: 'cSEGRP_SeguimientoRelacionPatria2',
          columns,
          detailValues: [
            {
              values,
            },
          ],
        },
      },
    });
    saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify(contextSet.state.commentsAcuerdos),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + contextSet.state.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });
  };

  const saveAreasSubAreas = async() => {
    const { state } = contextSet;
    const { cSEGR_Id } = state.saveAcuerdos;
    const { areas, subAreas } = state.saveSegRelPatria;
    let columns = ['cRELPR_Id'];
    let values = [String(1)];
    if (cSEGR_Id) {
      columns.push('cSEGR_Id');
      values.push(String(cSEGR_Id));
    }
    if (areas) {
      columns.push('areas');
      values.push(areas.join(","));
    }
    if (subAreas) {
      columns.push('subAreas');
      values.push(subAreas.join(","));
    }
    
    columns.push('cACU_Id');
    values.push(String(contextSet.state.saveAcuerdos.cACU_Id));
    
    await saveREL({
      variables: {
        input: {
          table: 'cSEGRP_SeguimientoRelacionPatria2',
          columns,
          detailValues: [
            {
              values,
            },
          ],
        },
      },
    });
  }

  const seguimientoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cSEGR_SeguimientoRelacion',
          columns: ['cSEGR_Id', 'cSEGR_Descripcion'],
        },
      },
    }),
    []
  );
  const segReporteInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cTEMSR_TemasSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Tipo',
              valtwo: `${1}`,
              condition: '=',
            },
          ],
        },
      },
    }),
    []
  );
  const segDeseasDarReporteInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cACCSR_AccionesSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Id',
              valtwo: `${1}`,
              condition: '=',
            },
          ],
        },
      },
    }),
    []
  );
  const tomarAccionesInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cACCSR_AccionesSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Id',
              valtwo: `${2}`,
              condition: '=',
            },
          ],
        },
      },
    }),
    []
  );
  const tomarAccionesInputPLD = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cACCSR_AccionesSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Id',
              valtwo: `${3}`,
              condition: '=',
            },
          ],
        },
      },
    }),
    []
  );
  const tomarAccionesInputImpuestos = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cACCSR_AccionesSeguimientoRelacion',
          columns: ['*'],
          conditions: [
            {
              valone: 'cTEMSR_Id',
              valtwo: `${4}`,
              condition: '=',
            },
          ],
        },
      },
    }),
    []
  );
  const statusSeguimientoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cESTS_EstatusSeguimiento',
          columns: ['*'],
        },
      },
    }),
    []
  );

  const handleOnClickBack = (currentQuestion) => {
    if (currentQuestion === '') {
      backRelacionPatria();
      sessionStorage.setItem('returnSegRelPa', 'true');
    }
    let index = saveAdvanceQuestion.indexOf(currentQuestion);
    let stepAux = index === 0 ? index : index - 1;
    if (index === 0) {
      setCurrentQuestion('');
      saveAdvanceQuestion.splice(index, 1);
    } else {
      setCurrentQuestion(saveAdvanceQuestion[stepAux]);
      saveAdvanceQuestion.splice(index, 1);
    }
  };

  useEffect(() => {
    getSeguimiento(seguimientoInput);
    getSeguimientoReporte(segReporteInput);
    getSeguimientoDarReporte(segDeseasDarReporteInput);
    getTomarAcciones(tomarAccionesInput);
    getTomarAccionesImpuestos(tomarAccionesInputImpuestos);
    getTomarAccionesPLD(tomarAccionesInputPLD);
    getStatusSeg(statusSeguimientoInput);
    getArea({
      variables: {
        input: {
          table: 'cARE_AREASCRM',
          columns: ['cARE_Id', 'cARE_Descripcion'],
        },
      },
    });
  }, []);
  useEffect(() => {
    if (!saveAdvanceQuestion.includes(currentQuestion))
      saveAdvanceQuestion.push(currentQuestion);
  }, [currentQuestion]);
  return (
    <Fragment>
      <div
        onClick={() => handleOnClickBack(currentQuestion)}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      {currentQuestion === '' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
        >
          {seguimiento.map(({ cSEGR_Id, cSEGR_Descripcion }, index) => (
            <ButtonNavigation
              text={cSEGR_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={async() => {
                setGenerarReporte(cSEGR_Descripcion);
                await contextSet.setSaveSegRelPatria({
                  generarReporte: cSEGR_Descripcion,
                });
                await contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cSEGR_Id,
                });
                setSaveIdResponseQuestions({
                  ...saveIdResponseQuestions,
                  cSEGR_Id,
                });
                await contextSet.setRelacionData({
                  ...contextSet.state.relacionData,
                  tipoSeguimiento: cSEGR_Descripcion,
                  cSEGR_Id,
                })
                if(cSEGR_Descripcion === 'Ninguno'){
                  saveAreasSubAreas().then(() => {
                    onClickGoReport({
                      tipoRelacion: 'Niguno'
                    });
                  })
                }
                else{
                  setCurrentQuestion(cSEGR_Descripcion);
                }
              }}
            />
          ))}
        </DivContainer>
      )}
      {(currentQuestion === 'Acuerdo' ||
        currentQuestion === 'Solicitud' ||
        currentQuestion === 'Oportunidad' ||
        currentQuestion === 'Otro') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle={`¿Qué seguimiento deseas dar a tu ${generarReporte}?`}
          >
            {optionsSegReporte.map(({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cTEMSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setTema(cTEMSR_Descripcion);
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cTEMSR_Id,
                  });
                  setCurrentQuestion(cTEMSR_Descripcion);
                }}
              />
            ))}
          </DivContainer>
        )}
      {currentQuestion === 'Ninguno' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`Resumen de Relación con Patria`}
        >
          <LastStep
            txtBtn="Continuar a Seguimiento"
            widthBtn='90%'
            setState={(value) => {
              contextSet.setSaveSegRelPatria({
                ...contextSet.state.saveSegRelPatria,
                commentTwo: value,
              });
              contextSet.setCommentsAcuerdos({ segRelacion: value });
            }}
            state={contextSet.state.saveSegRelPatria.commentTwo}
            onClick={onClickGoReport}
          />
          <ButtonNavigation
            width='90%'
            text="Volver a Relación con Patria"
            style={{ cursor: 'pointer', width: '90%' }}
            onClick={() => backRelacionPatria()}
          />
        </DivContainer>
      )}
      {currentQuestion === 'Analizar Información' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué seguimiento deseas dar a tu ${generarReporte}?`}
        >
          {optionsSegDarReporte.map(
            ({ cACCSR_Id, cACCSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cACCSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  console.log('cACCSR_Id', cACCSR_Id);
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cACCSR_Id,
                  });
                  setCurrentQuestion(cACCSR_Descripcion);
                  await contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cACCSR_Id,
                  });
                  await contextSet.setRelacionData({
                    ...contextSet.state.relacionData,
                    accion: cACCSR_Descripcion
                  })
                  await contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    cACCSR_Id
                  })
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {currentQuestion === 'Acciones de Seguimiento' && (contextSet.state.relacionData.cRELP_Id !== 7 && contextSet.state.relacionData.cRELP_Id !== 8) && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué acciones deseas tomar?`}
        >
          {optionsTomarAcciones.map(
            ({ cACCSR_Id, cACCSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cACCSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  console.log(cACCSR_Id, 'cACCSR_Id_2');
                  console.log('ACCIONES', optionsTomarAcciones)
                  console.log("CONTEXTO", contextSet)
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cACCSR_Id,
                  });
                  // setCurrentQuestion(cACCSR_Descripcion);
                  setCurrentQuestion(cACCSR_Descripcion);
                  console.log("CURRENT QUESTION:", cACCSR_Descripcion);
                  await contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cACCSR_Id,
                  });
                  await contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    cACCSR_Id
                  })
                  await contextSet.setRelacionData({
                    ...contextSet.state.relacionData,
                    accion: cACCSR_Descripcion
                  })
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {(currentQuestion === 'Acciones de Seguimiento' && contextSet.state.relacionData.cRELP_Id === 7) && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué acciones deseas tomar?`}
        >
          {optionsTomarAccionesImpuestos.map(
            ({ cACCSR_Id, cACCSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cACCSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  console.log(cACCSR_Id, 'cACCSR_Id_2');
                  console.log('ACCIONES', optionsTomarAcciones)
                  console.log("CONTEXTO", contextSet)
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cACCSR_Id,
                  });
                  // setCurrentQuestion(cACCSR_Descripcion);
                  setCurrentQuestion(cACCSR_Descripcion);
                  console.log("CURRENT QUESTION:", cACCSR_Descripcion);
                  await contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cACCSR_Id,
                  });
                  await contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    cACCSR_Id
                  })
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {(currentQuestion === 'Acciones de Seguimiento' && contextSet.state.relacionData.cRELP_Id === 8) && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Qué acciones deseas tomar?`}
        >
          {optionsTomarAccionesPLD.map(
            ({ cACCSR_Id, cACCSR_Descripcion }, index) => (
              <ButtonNavigation
                text={cACCSR_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  console.log(cACCSR_Id, 'cACCSR_Id_2');
                  console.log('ACCIONES', optionsTomarAcciones)
                  console.log("CONTEXTO", contextSet)
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cACCSR_Id,
                  });
                  // setCurrentQuestion(cACCSR_Descripcion);
                  setCurrentQuestion(cACCSR_Descripcion);
                  console.log("CURRENT QUESTION:", cACCSR_Descripcion);
                  await contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cACCSR_Id,
                  });
                  await contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    cACCSR_Id
                  })
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {(contextSet.state.saveAcuerdos.cRELP_Id !== 1 && 
        contextSet.state.saveAcuerdos.cRELP_Id !== 3 &&
        contextSet.state.saveAcuerdos.cRELP_Id !== 5 &&
        contextSet.state.relacionData.cRELP_Id !== 6 &&
        contextSet.state.relacionData.cRELP_Id !== 7 && currentQuestion ===
        'Solicitud de Atención o Seguimiento de Otra Area') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="¿De qué área se necesita atención?"
          >
            {areaAtencionOption.expPLD.map(
              ({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
                <ButtonNavigation
                  text={cTEMSR_Descripcion}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setCurrentQuestion('Solicitud de Atención o Seguimiento de Otra Area Comentarios');
                    // setAreas(cTEMSR_Descripcion);
                    getDataArea(cTEMSR_Descripcion);
                  }}
                />
              )
            )}
          </DivContainer>
        )}
        {(contextSet.state.relacionData.cRELP_Id === 7 && currentQuestion ===
        'Solicitud de Atención o Seguimiento de Otra Area') && (
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="¿De qué área se necesita atención?"
          >
            {areaAtencionOption.impuestoRetenidoAreas.map(
              ({ cARE_Id, cSUBA_Id, cARE_Descripcion, cSUBA_Descripcion }, index) => (
                <ButtonNavigation
                  text={cSUBA_Descripcion || cARE_Descripcion}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={async() => {
                    setCurrentQuestion('Solicitud de Atención o Seguimiento de Otra Area Comentarios');
                    // setAreas(cTEMSR_Descripcion);
                    // getDataArea(cTEMSR_Descripcion);
                    setSelectedArea(cARE_Descripcion || cSUBA_Descripcion);

                    await contextSet.setSaveAcuerdos({
                      ...contextSet.state.saveAcuerdos,
                      cARE_Id,
                      cSUBA_Id
                    });

                    await contextSet.setSaveSegRelPatria({
                      ...contextSet.state.saveSegRelPatria,
                      areas: cARE_Id.toString(),
                      subAreas: cSUBA_Id.toString()
                    });
                  }}
                />
              )
            )}
          </DivContainer>
        )}
      {currentQuestion ===
        'Solicitud de Atención o Seguimiento de Otra Area Comentarios' && (
          <DivContainer title="Seguimiento Relación con Patria">

            <LastStep
              onClick={setCurrentQuestion}
              paramsOnclick="resumenPLD"
              txtBtn="Guardar y continuar"
              widthBtn="90%"
              setState={(value) => {
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value,
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              state={contextSet.state.saveSegRelPatria.commentTwo}
            />

            {/* <DropdownAreas
                        onClick={setCurrentQuestion}
                        paramsOnclick="comments"
                        handleChange={handleChange}
                        state={formAreas}
                        txtBtn="Guardar y continuar"
                    /> */}
            {/* <Comentarios
            cSUBE_Id={2}
            setCTIPR_Id={0}
            onClick1={() => setCurrentQuestion('nextSatatusSeg')}
            setState={() => {}}
            flag={false}
            
            // preSelect={JSON.stringify({ cSUBA_Id: 3, cARE_Id: 1, cSUBA_Descripcion: "Planeación y Coordinación" })}
          /> */}
          </DivContainer>
        )}
        {(contextSet.state.saveAcuerdos.cRELP_Id === 1 ||
         contextSet.state.saveAcuerdos.cRELP_Id === 3 ||
         contextSet.state.saveAcuerdos.cRELP_Id === 5 ||
         contextSet.state.saveAcuerdos.cRELP_Id === 6) && currentQuestion === "Solicitud de Atención o Seguimiento de Otra Area" && (

        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`Comentarios para el ${generarReporte} sobre Pendientes con Patria.`}
        >
          <LastStep
            onClick={setCurrentQuestion}
            paramsOnclick="nextSatatusSeg"
            txtBtn="Guardar y continuar"
            widthBtn="90%"
            setState={(value) => {
              contextSet.setSaveSegRelPatria({
                ...contextSet.state.saveSegRelPatria,
                commentTwo: value,
              });
              contextSet.setCommentsAcuerdos({ segRelacion: value });
            }}
          />
          <AreasInvV2 openModal={true} />
        </DivContainer>

          // <DivContainer title="Seguimiento Relación con Patria">

          //   <LastStep
          //     onClick={setCurrentQuestion}
          //     paramsOnclick="resumenReaseguro"
          //     txtBtn="Guardar y continuar"
          //     setState={(value) => {
          //       contextSet.setSaveSegRelPatria({
          //         ...contextSet.state.saveSegRelPatria,
          //         commentTwo: value,
          //       });
          //       contextSet.setCommentsAcuerdos({ segRelacion: value });
          //     }}
          //     state={contextSet.state.saveSegRelPatria.commentTwo}
          //   />
          // </DivContainer>
        )}
      {(currentQuestion === 'Enviar Charola o Regalo a Cedente/ Corredor' ||
        currentQuestion === 'Enviar Solicitud Documentos para recuperar Impuestos Retenidos Extranjero' ||
        currentQuestion === 'Enviar Solicitud Documentos Expediente PLD' ||
        currentQuestion === 'Temas Pendientes' ||
        currentQuestion === 'Renovación' ||
        currentQuestion === 'Nuevo Contrato') && (
          // currentQuestion === 'comments'
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle={`Comentarios para el ${generarReporte} sobre ${tema}.`}
          >
            <LastStep
              widthBtn='90%'
              onClick={setCurrentQuestion}
              paramsOnclick="nextSatatusSeg"
              txtBtn="Guardar y continuar"
              setState={(value) => {
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value,
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              state={contextSet.state.saveSegRelPatria.commentTwo}
            />
          </DivContainer>
        )}
      {currentQuestion === 'nextSatatusSeg' && (
        <DivContainer
          title="Seguimiento Relación con Patria"
          subtitle={`¿Cuál es el estatus de tu ${generarReporte}?`}
        >
          {optStatusSeguimiento.map(
            ({ cESTS_Id, cESTS_Descripcion }, index) => (
              <ButtonNavigation
                text={cESTS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={async() => {
                  setAnswerByQuestion({
                    ...answerByQuestion,
                    status: cESTS_Descripcion,
                  });
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    status: cESTS_Descripcion,
                  });
                  await contextSet.setRelacionData({
                    ...contextSet.state.relacionData,
                    status: cESTS_Descripcion,
                  });
                  contextSet.setSaveAcuerdos({
                    ...contextSet.state.saveAcuerdos,
                    cESTS_Id,
                  });
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cESTS_Id,
                  });
                  setCurrentQuestion(cESTS_Descripcion);
                }}
              />
            )
          )}
        </DivContainer>
      )}
      {/* {(currentQuestion === 'Pendiente' || currentQuestion === 'En proceso' || currentQuestion === 'Concluido') && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                >
                    <DropdownAreas
                        onClick={setCurrentQuestion}
                        paramsOnclick="resumenSegRelPatria"
                        handleChange={handleChange}
                        state={formAreas}
                        txtBtn="Guardar y continuar"
                    />
                </DivContainer>
            )} */}
      {
        // currentQuestion === 'resumenSegRelPatria'
        (currentQuestion === 'Pendiente' ||
          currentQuestion === 'En proceso' ||
          currentQuestion === 'Concluido') && (
          <>
            <DivContainer
              title="Seguimiento Relación con Patria"
              subtitle="Resumen de Seguimiento sobre Relación con Patria"
            >
              <ImputResumen
                title="Comentarios de Relación con Patria:"
                // subtitle={"subititlo"}
                disabled={disabledOne}
                setDisabled={() => setDisabledOne(!disabledOne)}
                value={contextSet.state.commentsAcuerdos.relacion}
                setValue={(value) => {
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    commentOne: value,
                  });
                  contextSet.setCommentsAcuerdos({ relacion: value });
                }}
                flexDirection="column"
              />
              <ImputResumen
                title={generarReporte}
                status={answerByQuestion.status}
                // subtitle={"subtitle2"}
                value={contextSet.state.commentsAcuerdos.segRelacion}
                setValue={(value) => {
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    commentTwo: value,
                  });
                  contextSet.setCommentsAcuerdos({ segRelacion: value });
                }}
                disabled={disabledTwo}
                setDisabled={() => setDisabledTwo(!disabledTwo)}
              />
            </DivContainer>
            <AreasInvV2 openModal={true} />
            <ButtonNavigation
              text="Guardar y continuar a Seguimiento"
              backgroundColor="#3898EC"
              border="#3898EC"
              color="#FFFFFF"
              width="75%"
              onClick={() => {
                onClickGoReport();
                saveRelPatria();
              }}
            />
            <ButtonNavigation
              text="Guardar y registrar nuevo Seguimiento"
              backgroundColor="#FFFFFF"
              border="#FFFFFF"
              color="black"
              width="75%"
              onClick={() => {
                setCurrentQuestion('');
                setSaveAdvanceQuestion([]);
                saveRelPatria();
              }}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              backgroundColor="#FFFFFF"
              border="#FFFFFF"
              color="black"
              width="75%"
              onClick={() => backRelacionPatria()}
            />
          </>
        )
      }
      {currentQuestion === 'resumenPLD' && (
        <>
          <DivContainer
            title="Seguimiento Relación con Patria"
            subtitle="Resumen de Seguimiento sobre Relación con Patria"
          >
            <ImputResumen
              title="Comentarios de Relación con Patria:"
              status={localStorage.getItem('statusEXPIMP')}
              // subtitle={"subititlo"}
              disabled={disabledOne}
              setDisabled={() => setDisabledOne(!disabledOne)}
              value={contextSet.state.commentsAcuerdos.relacion}
              setValue={(value) => {
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentOne: value,
                });
                contextSet.setCommentsAcuerdos({ relacion: value });
              }}
              flexDirection="column"
            />
            <ImputResumen
              title="Tipo de seguimiento:"
              value={contextSet.state.commentsAcuerdos.segRelacion}
              setValue={(value) => {
                contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentTwo: value
                });
                contextSet.setCommentsAcuerdos({ segRelacion: value });
              }}
              disabled={disabledTwo}
              setDisabled={() => setDisabledTwo(!disabledTwo)}
            />
          </DivContainer>
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'initial',
            }}
          >
            <h4 style={{ color: '#3898EC' }}>Áreas</h4>
            <div className={classes.root}>
              <Chip
                label={selectedArea}
                style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
              />
            </div>
          </div>

          <ButtonNavigation
            text="Guardar y continuar a Seguimiento"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => {
              onClickGoReport()
              saveRelPatria()
            }}
          />
          <ButtonNavigation
            text="Guardar y registrar nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => {
              setCurrentQuestion('');
              setSaveAdvanceQuestion([]);
            }}
          />
          <ButtonNavigation
            text="Volver a Relación con Patria"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => backRelacionPatria()}
          />
        </>
      )}
    </Fragment>
  );
};

export default SeguimientoRelPatria;
