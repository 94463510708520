import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import { MyContext } from '../Context';
import descargar from '../../assets/images/icons/descargar.svg';
import { useMediaQuery } from '@material-ui/core';
const Download = ({ handleChangeCot, showSelectCot, restAvance }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const context = useContext(MyContext);
    const [fileNames, setFileNames] = useState([]);
    const [selectedCot, setSelectedCot] = useState('');
    const [getFileNames] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.message === 'success') {
                const response = JSON.parse(dynamicGet.response);
                if (response.length > 0) {
                    setFileNames(response)
                }
            }
        },
        onError(error) {
            console.log(error)
        }
    })
    useEffect(() => {
        const cVIT_Avance = restAvance
            ? parseInt(context.state.viaticosProps.cVIT_Avance) - restAvance
            : context.state.viaticosProps.cVIT_Avance;
        getFileNames({
            variables: {
                input: {
                    table: 'log_file_process',
                    columns: ['fileName'],
                    conditions: [
                        {
                            valone: 'cVIT_Id',
                            valtwo: String(context.state.viaticosProps.cVIT_Id),
                            condition: '=',
                        },
                        {
                            valone: 'cVIT_Avance',
                            valtwo: String(cVIT_Avance),
                            condition: '=',
                            logic: 'AND'
                        },
                    ],
                },
            }
        })
    }, [context.state.viaticosProps.cVIT_Id, context.state.viaticosProps.cVIT_Avance]);
    return (
        <div style={{ margin: 20 }}>
            <div style={{ color: '#3898Ec', display: 'flex', alignItems: 'center' }}>
                <img src={descargar} alt="download" width="100px" />
                <div style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                    {fileNames.map(({ fileName }) => (
                        <a href={`https://dev-patria.alimx.mx/api/download/files/${fileName}`} target="_blank">{`${fileName}`}</a>
                        // <a href={`http://localhost:8000/download/files/${fileName}`} target="_blank">{`Descargar cotizacion ${fileName}`}</a>
                    ))}
                </div>
            </div>
            {showSelectCot && (
                <div style={{ marginTop: 10 }}>
                    <h3>Elige una de las cotizaciones</h3>
                    <select
                        style={{ width: isMobile ? '100%':'50%', height: 50, fontSize: 18 }}
                        onChange={(e) => {
                            handleChangeCot(e);
                            setSelectedCot(e.target.value);
                        }}
                        value={selectedCot}
                    >
                        <option value=''>---Selecciona una cotización---</option>
                        {fileNames.map(({ fileName }) => {
                            const formatFileName = String(fileName).split('.')[0]
                            return (
                                <option key={fileName} value={fileName}>{formatFileName}</option>
                            );
                        })}
                    </select>
                </div>
            )}
        </div >
    );
}

export default Download;