import React, { useState } from 'react';
import { IconButton, TextareaAutosize } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import '../components.css';



export default function NotaInput({onChange,onClick,state, setState,check,disabled,classes, state2}) {

  // const [stateNota, setStateNota] = useState(state2)
  // const [checkNota, setCheckNota] = useState(check)
  // const onChangeNota = ({ target }) => {
  //   setStateNota({ ...stateNota, [target.name]: target.value })
  //   setCheckNota(true)
  //   setState(stateNota)
  // }



  return (
    <div className={`nota-area-text ${classes}`} style={{margin:"15px"}}>
        <div style={{width:"100%",display:"flex"}}>
          <input className="nota-input-title" placeholder="Título" onChange={onChange} name="title" value={state.title} 
          disabled={disabled}
          />
          {
            check?
            <IconButton aria-label="check" style={{width:"10%"}} onClick={onClick}>
              <Check/>
            </IconButton>:null
          }
        </div>
        <TextareaAutosize aria-label="minimum height" rowsMin={2} placeholder="Añade una nota" className="nota-area-content" name="nota" onChange={onChange} value={state.nota}/>
      </div>
  )
}
