import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import Acordeon from '../Acordeon';

import ButtonNavigation from '../Viaticos/ButtonNavigation';

export default function Areas_Inv({
  open,
  areas,
  subArea,
  onChangue,
  setOpen,
}) {
  const body = (
    <div style={{ width: '60%', backgroundColor: '#FFFFFF', height:' 62%',
    overflowY: 'scroll' }}>
      {areas?.map((area, index) => {
        if (area.cARE_Id === 5 || area.cARE_Id === 6 || area.cARE_Descripcion === 'PLD / Oficial de Cumplimiento' || area.cARE_Descripcion === 'Planeación y Coordinación') {
          return (
            <ButtonNavigation
              text={area.cARE_Descripcion}
              width="70%"
              onClick={() =>
                onChangue({
                  value: JSON.stringify({
                    cARE_Id: area.cARE_Id,
                    cSUBA_Descripcion: area.cARE_Descripcion,
                    cSUBA_Id: 1,
                  }),
                })
              }
            />
          );
        } else {
          return (
            <Acordeon label={area.cARE_Descripcion} key={index}>
              <FormGroup row onChange={({ target }) => onChangue(target)}>
                {subArea &&
                  subArea.map(
                    ({ cARE_Id, cSUBA_Descripcion, cSUBA_Id, status }, index) =>
                      cARE_Id === area.cARE_Id ? (
                        <FormControlLabel
                          control={<Checkbox checked={status} />}
                          label={cSUBA_Descripcion}
                          key={index}
                          value={JSON.stringify({
                            cARE_Id,
                            cSUBA_Descripcion,
                            cSUBA_Id,
                          })}
                        />
                      ) : null
                  )}
              </FormGroup>
            </Acordeon>
          );
        }
      })}
      <ButtonNavigation
        text="Regresar"
        backgroundColor="#3898EC"
        border="#3898EC"
        color="#FFFFFF"
        width="70%"
        onClick={() => setOpen()}
      />
    </div>
  );
  return (
    <Fragment>
      <Modal open={open} style={{ width: '100%', textAlign: '-webkit-center' }}>
        {body}
      </Modal>
    </Fragment>
  );
}
