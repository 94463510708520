import React, { useRef, useState, useContext } from 'react'
import { MyContext } from '../Context';
import { Button } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { DYNAMIC_INSERT, UPLOAD_FILE, DINAMIC_GET } from '../../mutation/mutation';
import { useEffect } from 'react';
import { downloadFile } from '../../helpers/downloadXlsx';
import { useMediaQuery } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
        return __val__;
    }
    return '';
};
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 'bolder'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});
const labelProps = {
    style: {
        fontSize: 13
    }
}
const ExpensesTable = ({ cEMP, setTotal, total, viaticsIds = [] }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const classes = useStyles();
    const context = useContext(MyContext);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [addRow, setAddRow] = useState(false);
    const fileInput = useRef(null);
    const [fileEx, setFile] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [clasifications, setClasifications] = useState([]);
    const [clasificationsDetails, setClasificationsDetails] = useState([]);
    const [clasification, setClasification] = useState('');
    const [clasificationDetail, setClasificationDetail] = useState('');
    const [dataInputs, setDataInputs] = useState({
        folio: '',
        clasificacion: '',
        detalleClasificacion: '',
        fecha: '',
        descripcionGasto: '',
        tipoComp: '',
        importe: '',
        iva: '',
        total: '',
        comprobante: '',
    })
    const [totalImporte, setTotalImporte] = useState(0);
    const [totalIVA, setTotalIVA] = useState(0);
    // const [total, setTotal] = useState(0);
    const [uploadFile] = useMutation(UPLOAD_FILE, {
        onCompleted({ uploadFile }) {
            console.log('uploadFile', uploadFile);
            setFile([])
        },
        onError(err) {
            console.log(err);
            setFile([])
        },
    });
    const [insertCheeckingCosts] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('insertCheeckingCosts', dynamicInsert)
        },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });
    const [getCheeckingCosts] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            console.log('RESULTADO', dynamicGet)
            if (dynamicGet.response !== '') {
                let response = JSON.parse(dynamicGet.response);
                setDataTable(response);
                let importe = 0;
                let iva = 0;
                let total = 0;
                response.forEach((element) => {
                    importe += parseFloat(element.importe);
                    iva += parseFloat(element.iva);
                    total += parseFloat(element.total);

                });
                setTotalImporte(importe)
                setTotalIVA(iva)
                setTotal(total)
            }
        }
    })
    const [getClasification] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            console.log('RESULTADO', dynamicGet)
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setClasifications(response);
            }
        }
    })
    const [getClasificationDetails] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            console.log('RESULTADO', dynamicGet)
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setClasificationsDetails(response);
            }
        }
    })
    const formatDate = (date) => {
        const dateByParts = String(date).split('-').reverse().join('/')
        return dateByParts;
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'importe') {
            const amount = String((parseFloat(dataInputs.iva) || 0) + parseFloat(value));
            setDataInputs({
                ...dataInputs,
                total: amount,
                [name]: value,
            })
        } else if (name === 'iva') {
            const amount = String((parseFloat(dataInputs.importe) || 0) + parseFloat(value));
            setDataInputs({
                ...dataInputs,
                total: amount,
                [name]: value,
            })
        } else {
            setDataInputs({
                ...dataInputs,
                [name]: value,
            })
        }


    }
    const onChangeFile = async () => {
        try {
            const fileExcel = await fileInput.current.files[0];
            let base64 = '';
            const fr = new FileReader();
            fr.readAsDataURL(fileExcel);
            fr.addEventListener('load', (e) => {
                base64 = e.target.result;
                const base64File = base64.split(';base64,').pop();
                const idViaticos = context.state.viaticosProps.cVIT_Id
                const fileName = String(fileExcel.name).split('.')
                const extencion = fileName[1]
                let objFile = {};
                if (viaticsIds.length > 0) {
                    const tempFiles = [];
                    viaticsIds.forEach((id) => {
                        objFile = {
                            base64: base64File,
                            name: `${fileName[0]}_compViajero_${id}.${extencion}`,
                            type: fileExcel.type,
                        }
                        tempFiles.push(objFile)
                    })
                    setFile([...fileEx, ...tempFiles]);
                } else {
                    objFile = {
                        base64: base64File,
                        name: `${fileName[0]}_compViajero_${idViaticos}.${extencion}`,
                        type: fileExcel.type,
                    }
                    setFile([...fileEx, objFile]);
                }
                setDataInputs({
                    ...dataInputs,
                    comprobante: `${fileName[0]}_compViajero_${idViaticos}.${extencion}`
                })
            });
        } catch (error) {
            console.log('onChangeFile ==', error)
        }
    };
    const upload = () => {
        fileEx.forEach(async (file) => {
            const { name } = file;
            await uploadFile({
                variables: {
                    input: file,
                },
            });
        })
    };
    const addRowToTable = () => {
        const { importe, iva, total: t } = dataInputs;
        setTotalImporte(totalImporte + parseFloat(importe))
        setTotalIVA(totalIVA + parseFloat(iva))
        setTotal(total + parseFloat(t))
        setDataTable([...dataTable, dataInputs]);
        setAddRow(false);
        setDataInputs({})
        upload()
        viaticsIds.forEach((id) => {
            insertCheeckingCosts({
                variables: {
                    input: {
                        table: 'cheecking_Costs',
                        columns: ['folio', 'clasificacion', 'detalleClasificacion', 'fecha', 'descripcionGasto',
                            'tipoComp', 'importe', 'iva', 'total', 'comprobante', 'cVIT_Id'],
                        detailValues: [
                            {
                                values: [dataInputs.folio, dataInputs.clasificacion, dataInputs.detalleClasificacion, dataInputs.fecha,
                                dataInputs.descripcionGasto, dataInputs.tipoComp, (dataInputs.importe || '0'), (dataInputs.iva || '0'), (dataInputs.total || '0'),
                                dataInputs.comprobante, String(id)]
                            },
                        ],
                    }
                }
            })
        })
    }
    const filterDetailsByID = () => {
        const [id, cCCG_Nombre] = clasification.split('_');
        return clasificationsDetails.filter(({ cCCG_Id }) => id == cCCG_Id);
    }
    useEffect(() => {
        getCheeckingCosts({
            variables: {
                input: {
                    table: 'cheecking_Costs',
                    columns: ['folio', 'clasificacion', 'detalleClasificacion', 'fecha', 'descripcionGasto',
                        'tipoComp', 'importe', 'iva', 'total', 'comprobante', 'cVIT_Id'],
                    conditions: [
                        {
                            valone: 'cVIT_Id',
                            valtwo: `${context.state.viaticosProps.cVIT_Id}`,
                            condition: '=',
                        }
                    ]
                }
            }
        });
        getClasification({
            variables: {
                input: {
                    table: 'cCCG_ClasificacionCompGastos',
                    columns: ['*']
                }
            }
        });
        getClasificationDetails({
            variables: {
                input: {
                    table: 'cDCCG_DetClasificacionCompGastos',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cDCCG_Estatus',
                            valtwo: '1',
                            condition: '=',
                        }
                    ]
                }
            }
        })
    }, [context.state.viaticosProps.cVIT_Id]);
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>N° de folio</StyledTableCell>
                            <StyledTableCell>Clasificación</StyledTableCell>
                            <StyledTableCell>Detalle de Clasificación</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Descripción de gasto</StyledTableCell>
                            <StyledTableCell>Tipo de comprobante</StyledTableCell>
                            <StyledTableCell>Importe</StyledTableCell>
                            <StyledTableCell>IVA</StyledTableCell>
                            <StyledTableCell>Total</StyledTableCell>
                            <StyledTableCell>Comprobante</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.map((obj, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{obj.folio ? obj.folio : ''}</StyledTableCell>
                                    <StyledTableCell>{obj.clasificacion}</StyledTableCell>
                                    <StyledTableCell>{obj.detalleClasificacion}</StyledTableCell>
                                    <StyledTableCell>{formatDate(obj.fecha)}</StyledTableCell>
                                    <StyledTableCell>{obj.descripcionGasto ? obj.descripcionGasto : ''}</StyledTableCell>
                                    <StyledTableCell>{obj.tipoComp}</StyledTableCell>
                                    <StyledTableCell>{obj.importe ? formatter.format(filterFloat(obj.importe)) : '$0'}</StyledTableCell>
                                    <StyledTableCell>{obj.iva ? formatter.format(filterFloat(obj.iva)) : '$0'}</StyledTableCell>
                                    <StyledTableCell>{obj.total ? formatter.format(filterFloat(obj.total)) : obj.total}</StyledTableCell>
                                    <StyledTableCell>{
                                        obj.comprobante
                                            ? <a href={`https://dev-patria.alimx.mx/api/download/files/${obj.comprobante}`} target="_blank">Descargar comprobante</a>
                                            : 'No se cargo un archivo'
                                    }</StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                        {addRow && (
                            <StyledTableRow>
                                <StyledTableCell>
                                    <input type='text' name='folio' value={dataInputs.folio} onChange={handleChange} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {/* <input type='text' name='clasificacion' value={dataInputs.clasificacion} onChange={handleChange} /> */}
                                    <select
                                        name='clasificacion'
                                        onChange={(e) => {
                                            const { value, name } = e.target;
                                            const val = String(value).split('_');
                                            const cCCG_Nombre = val[1]
                                            setClasification(value);
                                            setDataInputs({
                                                ...dataInputs,
                                                [name]: cCCG_Nombre,
                                            })
                                        }}
                                        value={clasification}
                                    >
                                        <option value=''>-- SELECCIONAR --</option>
                                        {clasifications.map(({ cCCG_Id, cCCG_Nombre }) => (
                                            <option key={`${cCCG_Id}_${cCCG_Nombre}`} value={`${cCCG_Id}_${cCCG_Nombre}`}>{cCCG_Nombre}</option>
                                        ))}
                                    </select>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {/* <input type='text' name='detalleClasificacion' value={dataInputs.detalleClasificacion} onChange={handleChange} /> */}
                                    <select
                                        name='detalleClasificacion'
                                        onChange={(e) => {
                                            const { value, name } = e.target;
                                            const val = String(value).split('|');
                                            const cDCCG_Nombre = val[1];
                                            setClasificationDetail(value);
                                            setDataInputs({
                                                ...dataInputs,
                                                [name]: cDCCG_Nombre,
                                            })
                                        }}
                                        value={clasificationDetail}
                                    >
                                        <option value=''>-- SELECCIONAR --</option>
                                        {filterDetailsByID().map(({ cDCCG_Id, cDCCG_Nombre }) => (
                                            <option key={`${cDCCG_Id}|${cDCCG_Nombre}`} value={`${cDCCG_Id}|${cDCCG_Nombre}`}>{cDCCG_Nombre}</option>
                                        ))}
                                    </select>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {/* <input type='text' name='fecha' placeholder='YYYY-MM-DD' value={dataInputs.fecha} onChange={handleChange} /> */}
                                    <input type="date" name='fecha' value={dataInputs.fecha} onChange={handleChange} />
                                </StyledTableCell>
                                <StyledTableCell><input type='text' name='descripcionGasto' value={dataInputs.descripcionGasto} onChange={handleChange} /></StyledTableCell>
                                <StyledTableCell>
                                    {/* <input type='text' name='tipoComp' value={dataInputs.tipoComp} onChange={handleChange} /> */}
                                    <select
                                        name='tipoComp'
                                        onChange={handleChange}
                                        value={dataInputs.tipoComp}
                                    >
                                        <option value=''>-- SELECCIONAR --</option>
                                        <option value='Nota'>Nota</option>
                                        <option value='Factura'>Factura</option>
                                    </select>
                                </StyledTableCell>
                                <StyledTableCell><input type='text' name='importe' value={dataInputs.importe} onChange={handleChange} /></StyledTableCell>
                                <StyledTableCell><input type='text' name='iva' value={dataInputs.iva} onChange={handleChange} /></StyledTableCell>
                                <StyledTableCell>
                                    {/* <input type='text' name='total' value={dataInputs.total} onChange={handleChange} /> */}
                                    {dataInputs.total}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        style={{ width: '100%' }}
                                        id="uploadImages"
                                        variant="contained"
                                        component="label"
                                    >
                                        Seleccionar archivo
                                        <input
                                            type="file"
                                            name="name"
                                            ref={fileInput}
                                            style={{ display: 'none' }}
                                            onChange={onChangeFile}
                                        />
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {cEMP.profile === 63 || cEMP.profile === 64 ? (
                <Button
                    id="export"
                    variant="contained"
                    component="label"
                    fullWidth={isMobile}
                    onClick={() => downloadFile(dataTable)}
                    style={{marginTop: 20}}
                >
                    Descargar anexo
                </Button>
            ) : (
                <div style={{ display: 'flex', marginTop: 50 }}>
                    <ButtonNavigation
                        text="Agregar otra fila"
                        width="20%"
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        justifyContent="center"
                        onClick={() => {
                            setAddRow(true);
                            setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' });
                        }}
                        style={styleDisabled}
                    />
                    <ButtonNavigation
                        text="Aceptar"
                        width="20%"
                        backgroundColor="#fff"
                        border="none"
                        color="#3898EC"
                        justifyContent="center"
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                        onClick={() => {
                            addRowToTable();
                            setStyleDisabled({});
                            setClasification('');
                            setClasificationDetail('');
                        }}
                    />
                    <ButtonNavigation
                        text="Cancelar"
                        width="20%"
                        backgroundColor="#fff"
                        border="none"
                        color="#3898EC"
                        justifyContent="center"
                        onClick={() => {
                            setAddRow(false);
                            setStyleDisabled({});
                            setClasification('');
                            setClasificationDetail('');
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default ExpensesTable


{/* <MaterialTable
title="Comprobación de gastos"
columns={[
    { title: 'N° de folio', field: 'folio' },
    {
        title: 'Clasificación',
        field: 'clasificacion',
        lookup: clasificationExpense,
        validate: ({  clasificacion }) => {
            console.log('clasificacion', clasificacion);
            setLookupDetails(detailsExpenses[clasificacion]);
            return true;
        },
    },
    { title: 'Detalle de Clasificación', field: 'detalleClasificacion', lookup: lookupDetails },
    { title: 'Fecha', field: 'fecha' },
    { title: 'Descripción de gasto', field: 'descripcionGasto' },
    { title: 'Tipo de comprobante', field: 'tipoComp' },
    { title: 'Importe', field: 'importe' },
    { title: 'IVA', field: 'iva' },
    { title: 'Total', field: 'total' },
    {
        title: 'Comprobante',
        field: 'comprobante',
        render: (rowData) => (
            <Button
                style={{ width: '100%' }}
                id="uploadImages"
                variant="contained"
                component="label"
            >
                Seleccionar archivo
                <input
                    type="file"
                    name="name"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={onChangeFile}
                />
            </Button>
        ),
    },
]}
data={dataT}

editable={{                        
    onRowAdd: newData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('newData', newData)
                setData([...dataT, newData]);

                resolve();
            }, 1000)
        }),                        
    onRowDelete: oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                const dataDelete = [...dataT];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);

                resolve()
            }, 1000)
        }),
}}
options={{
    search: false,
    paging: false,
    filtering: false,
    exportButton: true,
    exportFileName: `comprobación #${context.state.viaticosProps.cVIT_Id}`
}}
/> */}