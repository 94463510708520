import React, { useState, useContext, Fragment, useEffect, useRef } from 'react';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import { Box, TextField, CircularProgress, TextareaAutosize, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { PageContainer, ContainerTwoInputs } from '../StylesComponents/common-styled';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { MyContext } from '../Context';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE, DINAMIC_GET } from '../../mutation/mutation';
import { GET_SPENDING_LIMIT, GET_EVENTO_BY_CREU_ID, GET_CHECKING_COMP } from '../../query/query';
import { sendEmail } from '../../services/SendEmails';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import CardListReuniones from './CardListReuniones';
import DialogAlert from '../DiaologAlert';
import Card from '../Viaticos/Card';
import Download from './Download';
import ExpensesTable from './ExpensesTable';
import {
    getcTOBS_Id,
    getRol,
    totalViaticos,
    totalMateriales,
    totalViaticosPorConcepto
} from '../../funciones-auxiliares/viaticos';
import UploadFile from './UploadFile';
import RolSelection from '../Acuerdos/common/RolSelection';
import OnlyReadVit from './OnlyReadVit';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
        return __val__;
    }
    return '';
};
const formatNumber = (number) => {
    return String(number).replace('$', '').replace(',', '');
}
const stylesP = { fontSize: 18 }
const CheckingTraveler = ({ cEMP, cVIT_Status_COMP }) => {
    const context = useContext(MyContext);
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [showCards, setShowCards] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [empleado, setEmpleado] = useState([]);
    const [reunion, setReunion] = useState([]);
    const [amountToCheck, setAmountToCheck] = useState('');
    const [totalToCheck, setTotalToCheck] = useState(0);
    const [clasificationExpense, setClasificationExpense] = useState({});
    const [detailsExpenses, setDetailsExpenses] = useState({});
    const [limitExpenses, setLimitExpenses] = useState([]);
    const [infoViatic, setInfoViatic] = useState({});
    const [observations, setObservations] = useState('');
    const [checking, setChecking] = useState('');
    const [rol, setRol] = useState('');
    const [expenseReview, setExpenseReview] = useState('')
    const { loading, data } = useQuery(GET_CHECKING_COMP, {
        variables: {
            cVIT_Status_COMP
        }
    });
    const { loading: loadingLimits } = useQuery(GET_SPENDING_LIMIT, {
        variables: {
            cEMP_Id: cEMP.cEMP_Id
        },
        onCompleted({ getSpendingLimitByUser }) {
            setLimitExpenses(getSpendingLimitByUser);
            const cVIT_Id = context.state.viaticosProps.cVIT_Id;
            getExpensesViatics({
                variables: {
                    input: {
                        table: 'cGASXVIT_Gastos',
                        columns: ['cGASVIT_nDias', 'cGASVIT_ImporteUSD'],
                        conditions: [
                            {
                                valone: 'cVIT_Id',
                                valtwo: `${cVIT_Id}`,
                                condition: '=',
                            }
                        ]
                    }
                }
            })
        }
    });
    const [total, setTotal] = useState(0);
    const [getClasification, { loading: loadingClas }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            const clasifications = JSON.parse(dynamicGet?.response);
            const lookup = {};
            clasifications.forEach(({ cCCG_Id, cCCG_Nombre }) => {
                lookup[cCCG_Id] = cCCG_Nombre;
            });
            setClasificationExpense(lookup)
        }
    });
    const [getDetailsExpenses, { loading: loadingDetails }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            const details = JSON.parse(dynamicGet?.response);
            const lookup = {};
            details.forEach(({ cCCG_Id }) => {
                const lookupByDetails = {};
                const detailsById = details.filter((item) => item.cCCG_Id === cCCG_Id);
                detailsById.forEach(({ cDCCG_Nombre, cDCCG_Id }) => {
                    lookupByDetails[cDCCG_Id] = cDCCG_Nombre;
                });
                lookup[cCCG_Id] = lookupByDetails
            });
            setDetailsExpenses(lookup);
        }
    });
    const [getExpensesViatics, { loading: loadingExpenses }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            const expenses = JSON.parse(dynamicGet.response);
            const days = expenses.filter((expense) => expense.cGASVIT_nDias && expense.cGASVIT_nDias !== '');
            const existSomeUSD = expenses.some((expense) => expense.cGASVIT_ImporteUSD && expense.cGASVIT_ImporteUSD !== '');
            console.log('existSomeUSD', existSomeUSD)
            if (days.length > 0) {
                setLimitExpenses(limitExpenses.map((expenseLimit) => {
                    const amountPol = existSomeUSD ? parseInt(expenseLimit.aGAS_MontoInternacional) : parseInt(expenseLimit.aGAS_MontoNacional)                   
                    const limitTotal = parseInt(days[0].cGASVIT_nDias) * amountPol;
                    return {
                        cPOL_Nombre: expenseLimit.cPOL_Nombre,
                        limitTotal,
                        divisa: existSomeUSD ? 'USD' : 'MXP'
                    }
                }))
            }
        }
    })
    const [insert] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('insert', dynamicInsert);
        },
        onError(err) {
            console.log(err)
        }
    });
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            setShowCards(true);
        },
    });
    const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
        onError(err) {
            console.log(err)
        }
    });
    const updateVit = async (cVIT_Avance = '', cVIT_Status_COMP = '') => {
        const cVIT_Id = String(context.state.viaticosProps.cVIT_Id);
        const viaticsIds = data?.getCheckingByStatusComp[cVIT_Id].viaticos;
        viaticsIds.forEach(async (id) => {
            let columns = [
                {
                    setkey: 'cVIT_Avance',
                    setval: cVIT_Avance,
                },
                {
                    setkey: 'cVIT_Status_COMP',
                    setval: cVIT_Status_COMP,
                },
            ];
            let conditions = [
                {
                    valone: 'cVIT_Id',
                    valtwo: String(id),
                    condition: '=',
                    logic: 'AND',
                },
            ];
            await updateStatus({
                variables: {
                    input: {
                        table: 'cVIT_Viaticos',
                        columns,
                        conditions
                    }
                }
            })
        });
    }
    const insertAmountToCheck = async () => {
        const cVIT_Id = String(context.state.viaticosProps.cVIT_Id);
        const cCOMP_Id = data?.getCheckingByStatusComp[cVIT_Id].cCOMP_Id;        
        let columns = [
            {
                setkey: 'cCOMP_MontoAmex',
                setval: amountToCheck,
            }
        ];
        let conditions = [
            {
                valone: 'cCOMP_Id',
                valtwo: String(cCOMP_Id),
                condition: '=',
            }
        ];
        await updateStatus({
            variables: {
                input: {
                    table: 'cCOMP_Mov',
                    columns,
                    conditions
                }
            }
        })
    }
    const saveObservations = async () => {
        if (observations !== '') {
            const cVIT_Id = context.state.viaticosProps.cVIT_Id;
            const viaticsIds = data?.getCheckingByStatusComp[cVIT_Id].viaticos;
            viaticsIds.forEach(async(id) => {
                const input = {
                    table: 'cOBS_ObservacionesViaticos',
                    columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                    detailValues: [
                        {
                            values: [
                                String(id),
                                String(cEMP.cEMP_Id),
                                observations,
                                String(getcTOBS_Id(cEMP.profile)),
                            ]
                        },
                    ]
                };
                await insert({ variables: { input } });
            })
            return true
        } else if (checking === 'No' || expenseReview === 'No') {
            setMsgModalErr({
                ...msgModalErr,
                text: 'Campo obligatorio: Observaciones'
            });
            setShowModalErr(true);
            return false
        }
    };
    const sendTicket = async () => {
        const token = context.state.AuthComponentProps.token;
        if (cEMP.profile === 61) {
            await updateVit('13', '4');
            await insertAmountToCheck();
            await sendEmail({
                accessToken: token,
                emailTo: [sessionStorage.getItem('emailTo')],
                subject: 'Comprobación de gastos',
                section: 'sectionThree_Comp'
            });
            setMsgModalErr({
                title: 'Información guardada',
                text: 'Ticket enviado al jefe directo'
            })
            setShowModalErr(true);
        } else if (cEMP.profile === 63) {
            const amounts = limitExpenses.map(({ limitTotal }) => limitTotal);
            let totalLimit = 0
            amounts.forEach(amount => {
                totalLimit += amount;
            });
            if (checking === 'Si' && totalLimit > total) {
                await updateVit('15', '6');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFour_one_Comp'
                });
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado a contabilidad'
                })
                setShowModalErr(true);

            } else if (checking === 'Si' && totalLimit < total) {
                await updateVit('14', '5');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFour_two_Comp'
                });
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado al Director'
                })
                setShowModalErr(true);
            } else if (checking === 'No') {
                const isrequired = await saveObservations();
                if (!isrequired) return;
                await updateVit('12', '3');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFour_three_Comp'
                });
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado al viajero'
                })
                setShowModalErr(true);
                return;
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una opción de comprobación'
                })
                setShowModalErr(true);
                return
            }
            await saveObservations();
        } else if (cEMP.profile === 64) {
            if (checking === 'Si') {
                await updateVit('15', '6');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFive_one_Comp'
                });
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado a Contabilidad'
                })
                setShowModalErr(true);
            } else if (checking === 'No') {
                const isrequired = await saveObservations();
                if (!isrequired) return;
                await updateVit('12', '3');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFour_three_Comp'
                });
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado al viajero'
                })
                setShowModalErr(true);
                return
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una opción de comprobación'
                })
                setShowModalErr(true);
                return
            }
            await saveObservations();
        } else if (cEMP.profile === 65) {
            if (expenseReview === 'Si') {
                await updateVit('16', '7');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionSix_Comp'
                });
                setMsgModalErr({
                    title: 'El proceso ha finalizado',
                    text: ''
                })
                setShowModalErr(true);
            } else if (expenseReview === 'No') {
                const isrequired = await saveObservations();
                if (!isrequired) return;
                await updateVit('12', '3');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Comprobación de gastos',
                    section: 'sectionFour_three_Comp'
                });
                setMsgModalErr({
                    title: 'El proceso ha finalizado',
                    text: ''
                })
                setShowModalErr(true);
                return
            } else {
                setMsgModalErr({
                    title: 'Error',
                    text: 'Selecciona una opción de revisión'
                })
                setShowModalErr(true);
                return
            }
            await saveObservations();
        }
    }
    const validateDiff = () => {
        const cVIT_Id = context.state.viaticosProps.cVIT_Id;
        const viaticsIds = data?.getCheckingByStatusComp[cVIT_Id].viaticos;
        if (infoViatic.card === 'Amex' && amountToCheck !== '') {
            const diff = parseFloat(formatNumber(amountToCheck)) - total;
            if (diff > 0) {
                return <UploadFile name='comprobante' viaticsIds={viaticsIds} />
            }
        } else if (infoViatic.card === 'Tarjeta Corporativa') {
            const diff = totalToCheck - total;
            if (diff > 0) {
                return <UploadFile name='comprobante' viaticsIds={viaticsIds} />
            }
        }
        return null;
    }
    const updateRol = () => {
        const amounts = limitExpenses.map(({ limitTotal }) => limitTotal);
        let totalLimit = 0
        amounts.forEach(amount => {
            totalLimit += amount;
        });
        if (cEMP.profile === 63) {
            if (checking === 'Si' && totalLimit > total) {
                setRol('65');
            } else if (checking === 'Si' && totalLimit < total) {
                setRol('64');
            } else if (checking === 'No') {
                setRol('');
            }
        } else if (cEMP.profile === 64) {
            if (checking === 'Si') {
                setRol('65');
            } else if (checking === 'No') {
                setRol('');
            }
        }


    }
    useEffect(() => {
        updateRol();
    }, [checking]);
    useEffect(() => {
        const cVIT_Id = context.state.viaticosProps.cVIT_Id;
        if (cVIT_Id && !loading) {
            console.log('data?.getCheckingByStatusComp', data?.getCheckingByStatusComp)
            const reuniones = data?.getCheckingByStatusComp[cVIT_Id].reuniones;
            setInfoViatic(data?.getCheckingByStatusComp[cVIT_Id]);
            getEventBycREU({
                variables: { cREU_Id: reuniones },
            });
            getClasification({
                variables: {
                    input: {
                        table: 'cCCG_ClasificacionCompGastos',
                        columns: ['*']
                    }
                }
            });
            getDetailsExpenses({
                variables: {
                    input: {
                        table: 'cDCCG_DetClasificacionCompGastos',
                        columns: ['*']
                    }
                }
            });
            const viajes = context.state.viaticosProps.viajes;
            const alojamientos = context.state.viaticosProps.alojamientos;
            const materiales = context.state.viaticosProps.materiales;
            const promocionales = context.state.viaticosProps.promocionales;
            const requerimientoTI = context.state.viaticosProps.requerimientoTI;
            const total = totalViaticos(viajes, alojamientos, totalMateriales(materiales, promocionales, requerimientoTI))
            setTotalToCheck(total);
        }
    }, [loading]);
    if (loading && loadingClas && loadingDetails && loadingLimits && loadingExpenses) {
        return (
            <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                    <CircularProgress disableShrink size={100} />
                </div>
            </>
        )
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <Box m={0} marginTop={0}>
                <CardListReuniones meetings={meetings} setEmpleado={setEmpleado} setReunion={setReunion} />
                {(showCards) && (
                    <ContainerTwoInputs
                        width="100%"
                        margin="20px 0px"
                        id="viaticos-solicitud"
                    >

                        <Card data={reunion} img={calendario} />
                        <Card data={empleado} img={user} />
                    </ContainerTwoInputs>
                )}
                {cEMP.profile === 65 && (
                    <>
                        <p style={stylesP}>Número de anticipo:
                            <span style={{ fontWeight: 'bolder' }}>{context.state.viaticosProps.cVIT_Id}</span>
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <OnlyReadVit title='' viewForAccounting={true} />
                        </div>
                    </>
                )}
                {cEMP.profile === 61 && (
                    <>
                        <h2>Descarga de movimientos</h2>
                        <Download />
                    </>
                )}
                {cEMP.profile !== 65 && (
                    <ExpensesTable
                        cEMP={cEMP}
                        setTotal={setTotal}
                        total={total}
                        viaticsIds={data?.getCheckingByStatusComp[context.state.viaticosProps.cVIT_Id].viaticos}
                    />
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bolder', fontSize: 18, flexDirection: 'column' }}>
                    {(cEMP.profile === 63 || cEMP.profile === 64) && (
                        <FormControl style={{ width: '50%', marginTop: 50 }}>
                            <InputLabel id="auth-lbl">Comprobación</InputLabel>
                            <Select
                                labelId="auth-lbl"
                                id="auth"
                                value={checking}
                                label="Comprobación"
                                onChange={({ target }) => setChecking(target.value)}
                            >
                                <MenuItem value="">--Selecciona una opción--</MenuItem>
                                <MenuItem value="Si">Sí</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {(cEMP.profile === 65) && (
                        <FormControl style={{ width: '50%', marginTop: 50 }}>
                            <InputLabel id="auth-lbl">Revisión de gastos</InputLabel>
                            <Select
                                labelId="auth-lbl"
                                id="auth"
                                value={expenseReview}
                                label="Revisión de gastos"
                                onChange={({ target }) => setExpenseReview(target.value)}
                            >
                                <MenuItem value="">--Selecciona una opción--</MenuItem>
                                <MenuItem value="Si">Sí</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {cEMP.profile !== 65 && (
                        <p style={stylesP}>Total comprobado: <span style={{ fontWeight: 'bolder' }}>{formatter.format(filterFloat(total))}</span></p>
                    )}
                    {infoViatic.card === 'Amex' ? (
                        <>
                            {(cEMP.profile === 63 || cEMP.profile === 64) ? (
                                <>
                                    <p style={stylesP}>Total a comprobar:
                                        <span style={{ fontWeight: 'bolder' }}>
                                            {infoViatic.amountAmex}
                                        </span>
                                    </p>
                                    <p style={stylesP}>Diferencia:
                                        <span style={{ fontWeight: 'bolder' }}>
                                            $ {parseFloat(formatNumber(infoViatic.amountAmex)) - total}
                                        </span>
                                    </p>
                                </>
                            ) : cEMP.profile === 61 ? (
                                <>
                                    <TextField
                                        id="amount"
                                        type="text"
                                        label="Monto a comprobar"
                                        variant="outlined"
                                        style={{ marginTop: '20px', width: '50%' }}
                                        value={amountToCheck}
                                        onChange={({ target }) => {
                                            const formatValue = formatNumber(target.value);
                                            setAmountToCheck(formatter.format(filterFloat(formatValue)));
                                        }}
                                    />
                                    {amountToCheck !== '' && (
                                        <p style={stylesP}>Diferencia:
                                            <span style={{ fontWeight: 'bolder' }}>
                                                $ {parseFloat(formatNumber(amountToCheck)) - total}
                                            </span>
                                        </p>
                                    )}
                                </>
                            ) : null}

                        </>
                    ) : (
                        <>
                            <p style={stylesP}>Total a comprobar: <span style={{ fontWeight: 'bolder' }}>{formatter.format(filterFloat(totalToCheck))}</span></p>
                            <p style={stylesP}>Diferencia:
                                <span style={{ fontWeight: 'bolder' }}>{formatter.format(filterFloat(totalToCheck - total))}</span>
                            </p>
                            <p style={stylesP}>Límites en Política: {limitExpenses[0]?.divisa}</p>
                            <ul>
                                {limitExpenses.map(({ cPOL_Nombre, limitTotal }) => (
                                    <li>{cPOL_Nombre}: <span style={{ fontWeight: 'bolder' }}>{formatter.format(filterFloat(limitTotal))}</span></li>
                                ))}
                            </ul>
                        </>
                    )}
                    {cEMP.profile === 61 && (
                        <>
                            <UploadFile name='comprobación' viaticsIds={data?.getCheckingByStatusComp[context.state.viaticosProps.cVIT_Id].viaticos} />
                            {validateDiff()}
                        </>
                    )}
                    {(cEMP.profile === 63 || cEMP.profile === 65 || cEMP.profile === 64) && (
                        <>
                            <h2>Descarga de comprobantes</h2>
                            <Download
                                restAvance={cEMP.profile === 64 ? 1 : cEMP.profile === 65 ? 2 : null}
                            />
                            {context.state.viaticosProps.observaciones.map((data, index) => {
                                if (data.cTOBS_Id == 1) {
                                    sessionStorage.setItem('nombre_Empleado', data.cEMP_Nombre);
                                }
                                const indexLimit = context.state.viaticosProps.observaciones.length - 3;
                                if (index > indexLimit) {
                                    return (
                                        <Fragment key={index}>
                                            <div style={{ marginTop: '20px' }}>
                                                <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                                    data.cTOBS_Id
                                                )}`}</h3>
                                            </div>
                                            <TextareaAutosize
                                                minRows={10}
                                                style={{ width: '100%' }}
                                                id="text-mensaje"
                                                name="area"
                                                value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                                                disabled
                                            />
                                        </Fragment>
                                    );
                                }
                            })}
                            <div style={{ marginTop: '20px' }}>
                                <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
                            </div>
                            <TextareaAutosize
                                rowsMin={10}
                                style={{ width: '100%' }}
                                id="text-mensaje"
                                name="observaciones"
                                onChange={({ target }) => setObservations(target.value)}
                                value={observations}
                                placeholder="Escribe comentarios del viaje ..."
                            />
                        </>
                    )}
                    {cEMP.profile === 61 ? (
                        <RolSelection profile='63' width='75%' marginLeft='20%' />
                    ) : (cEMP.profile === 63 || cEMP.profile === 64) && rol !== '' ? (
                        <RolSelection profile={rol} width='75%' marginLeft='20%' />
                    ) : null}
                    <ButtonNavigation
                        text="Enviar"
                        width="60%"
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        marginTop='2%'
                        style={{ marginLeft: '20%' }}
                        onClick={() => sendTicket()}
                    />
                </div>
            </Box>
        </PageContainer >
    )
}

export default CheckingTraveler