import React, { Fragment } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';
const LastStep = ({
  onClick,
  paramsOnclick = {},
  txtBtn = 'Guardar',
  setState,
  state = '',
  widthBtn = '100%',
  marginTop='0%'
}) => {
  return (
    <Fragment>
      <div style={{ paddingBottom: 20, width: '100%' }}>
        <TextareaAutosize
          rowsMin={10}
          style={{ width: '90%' }}
          id="text-mensaje"
          name="area"
          placeholder="Comentarios"
          value={state !== '' ? state : null}
          onChange={(e) => setState(e.target.value)}
        />
      </div>
      <ButtonNavigation width={widthBtn} text={txtBtn} marginTop={marginTop} onClick={() => onClick(paramsOnclick)} />
    </Fragment>
  );
};

export default LastStep;
