import React, { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';

const Transiction = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogView = ({ open, handleClose, title, attendees }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transiction}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent>
        {attendees
          ? attendees.map((attendee) => (
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <DialogContentText style={{ textAlign: 'center' }}>
                  {attendee.emailAddress.name}
                </DialogContentText>
                {attendee.status.response === 'none' ? (
                  <CheckCircle
                    style={{
                      color: 'grey',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  />
                ) : attendee.status.response === 'accepted' ? (
                  <CheckCircle
                    style={{
                      color: 'green',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  />
                ) : attendee.status.response === 'tentativelyAccepted' ? (
                  <CheckCircle
                    style={{
                      color: 'yellow',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  />
                ) : (
                  <RemoveCircle
                    color="secondary"
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                  />
                )}
              </div>
            ))
          : null}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          onClick={handleClose}
          style={{
            color: '#3898EC',
            border: 'solid 1px   #3898EC',
            width: '45%',
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogView;
