import React, { useEffect, useState } from 'react';
import { ContainerTwoInputs } from './StylesComponents/common-styled';
import { Typography, TextField, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Autocomplete } from '@material-ui/lab';
import './components.css';
import {
  GETCOMPANI_SIREC_ById,
  GETCORREDOR_SIREC_ById,
  GETRETROCESSIONARY_SIREC_ById,
} from '../query/query';
import {
  DINAMIC_GET,
  DYNAMIC_UPDATE,
  GET_ACUERDOS_BY_REU,
  GET_ALL_CEDENTES,
  GET_ALL_REU,
  GET_REUNION_ACUERDOS_DATA,
} from '../mutation/mutation';
// import ReunionComplete from './Viaticos/ReunionComplete';
import { Button, Modal, DatePicker, Select, Input, Table } from 'antd';
import { useMutation } from 'react-apollo';
import Loader from 'react-js-loader';
import { PDFExport } from '@progress/kendo-react-pdf';

import './pdf-export.css';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import TableReuniones from './Acuerdos/TableReuniones';
import EditReunion from './Acuerdos/EditReunion';
// import SearchInvitadosSirec from './SearchInvitadosSirec';

function GeneralReport({ user }) {
  const [reunions, setReunions] = useState([]);
  const [reunionSelected, setReunionSelected] = useState(null);
  const [value, setValue] = useState(null);
  const [pdfData, setPdfData] = useState([]);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [aux_claveCorredor, setaux_claveCorredor] = useState('');
  const [Retrocessionary, setRetrocessionary] = useState([]);
  const [Company, setCompany] = useState([]);
  const [Corredor, setCorredor] = useState([]);
  const [options, setOptions] = useState([]);
  const [tituloReunion, setTituloReunion] = useState('');
  const [filtro, setFiltro] = useState('');
  const [valueFiltro, setValueFiltro] = useState('');
  const [valueIdFiltro, setValueIdFiltro] = useState('');
  const [foundReuniones, setFoundReunions] = useState(false);
  const [notFoundReuniones, setNotFoundReuniones] = useState('');
  const [aux_claveCompany, setaux_claveCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAcuerdos, setLoadingAcuerods] = useState(false);
  const [openReuniones, setOpenReuniones] = useState(false);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [status, setStatus] = useState([]);
  const [statusSelected, setStatusSelected] = useState('');
  const [edit, setEdit] = useState(false);

  const [facultativoSubtitles, setFacultativoSubtitles] = useState({
    suscripcion: ['Políticas de suscripción', 'Exclusiones', 'Restricciones'],
    apetitoRiesgo: ['Giros/Carteras', 'Coberturas', 'Límites'],
    necesidades: ['Capacidad', 'Precio', 'Términos y Condiciones']
  });

  const pdfExportComponent = React.useRef(null);

  const { Option } = Select;

  const [getRetrocessionarySirec_ById] = useLazyQuery(
    GETRETROCESSIONARY_SIREC_ById,
    {
      onCompleted({ idRetrocessionarySirecById }) {
        setRetrocessionary([idRetrocessionarySirecById]);
      },
    }
  );

  const [getEjecutivos] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let ejecutivos = JSON.parse(dynamicGet.response);
        let response = ejecutivos.sort((a, b) =>
          a.cEje_Nombre < b.cEje_Nombre ? -1 : 1
        );
        ejecutivos = response.map((ejec) => ({
          id: ejec.cEje_Id,
          name: `${ejec.cEje_Nombre}`,
          cTORG_Id: `${ejec.cTORG_Id}`,
          cORG_Id: `${ejec.cORG_Id}`,          
        }));
        setOptions(ejecutivos);
      }
    },
  });

  const [getCedentes] = useMutation(GET_ALL_CEDENTES, {
    onCompleted({ getAllCedentes }) {
      let response = getAllCedentes.sort((a, b) =>
        a.cCIA_RazSocial < b.cCIA_RazSocial ? -1 : 1
      );
      setOptions(
        response.map((ced) => ({
          id: ced.cCIA_Id,
          name: ced.cCIA_RazSocial,
        }))
      );
    },
  });

  const [getCorredores] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let corredores = JSON.parse(dynamicGet.response);
        let response = corredores.sort((a, b) =>
          a.cCOR_RazSocial < b.cCOR_RazSocial ? -1 : 1
        );
        corredores = response.map((cor) => ({
          id: cor.cCOR_Id,
          name: cor.cCOR_RazSocial,
        }));
        setOptions(corredores);
      }
    },
  });

  const [getPaises] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let paises = JSON.parse(dynamicGet.response);
        let response = paises.sort((a, b) =>
          a.cPAI_Nombre < b.cPAI_Nombre ? -1 : 1
        );
        paises = response.map((pais) => ({
          id: pais.cPAI_Id,
          name: pais.cPAI_Nombre,
        }));
        setOptions(paises);
        console.log(paises);
        console.log(dynamicGet)
      }
    },
  });

  const [getAreas] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let areas = JSON.parse(dynamicGet.response);
        let response = areas.sort((a, b) =>
          a.cARE_Descripcion < b.cARE_Descripcion ? -1 : 1
        );
        areas = response.map((area) => ({
          id: area.cARE_Id,
          name: area.cARE_Descripcion,
        }));
        setOptions(areas);
      }
    },
  });

  const [getStatuses] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let status = JSON.parse(dynamicGet.response);
        status = status.map((st) => ({
          id: st.cESTS_Id,
          name: st.cESTS_Descripcion,
        }));
        setStatus(status);
      }
    },
  });

  const exportPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  // const onChangeCompany = ({ target }) => {
  //   getCompanySirec_ById({
  //     variables: { cCIA_Id: parseInt(target.value, 10) },
  //   });
  //   setaux_claveCompany(parseInt(target.value, 10));
  // };

  const changeval1 = () => {
    return '--Seleccionar--';
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case 'clave-reunion-corredor':
        getRetrocessionarySirec_ById({
          variables: { cRET_Id: parseInt(e.target.value, 10) },
        });
        getCorredorSirec_ById({
          variables: { cCOR_Id: parseInt(e.target.value, 10) },
        });
        setaux_claveCorredor(parseInt(e.target.value, 10));
        break;
      default:
        break;
    }
  };

  const onChangeDate1 = (value) => {
    setFechaInicio(value.format('YYYY-MM-DD'));
    console.log(value);
  };
  const onChangeDate2 = (value) => {
    setFechaFin(value.format('YYYY-MM-DD'));
  };

  function onChangeFiltro(value) {
    setFiltro(value);
    if (value !== '') {
      switch (value) {
        case 'persona':
          getEjecutivos({
            variables: {
              input: {
                table: 'rEjexORG',
                columns: ['*'],
              },
            },
          });
          // getEjecutivos({
          //   variables: {
          //     input: {
          //       table: 'cEJE_Ejec',
          //       columns: [
          //         'cEJE_Id',
          //         'cEJE_Nombre',
          //         'cEJE_ApePat',
          //         'cEJE_ApeMat',
          //       ],
          //     },
          //   },
          // });
          break;
        case 'cedente':
          getCedentes();
          break;
        case 'corredor':
          getCorredores({
            variables: {
              input: {
                table: 'cCOR_Corredor',
                columns: ['cCOR_Id', 'cCOR_RazSocial'],
              },
            },
          });
          break;
        case 'pais':
          getPaises({
            variables: {
              input: {
                table: 'cPAI_Pais',
                columns: ['cPAI_Id', 'cPAI_Nombre'],
              },
            },
          });
          break;
        case 'area':
          getAreas({
            variables: {
              input: {
                table: 'cARE_AREASCRM',
                columns: ['cARE_Id', 'cARE_Descripcion'],
              },
            },
          });
          break;
        case 'reasegurador':
          getReasegurador({
            variables: {
              input: {
                table: 'cRET_Retrocesionario',
                columns: ['cRET_Id', 'cRET_RazSocial'],
              },
            },
          });
          break;
        case 'consultor':
        case 'autoridad':
          getAutoridadConsultor({
            variables: {
              input: {
                table: 'cAUTCONS_AutoridadConsultor',
                columns: ['cAUTCONS_Id', 'cAUTCONS_Nombre'],
              },
            },
          });
          break;
        default:
          break;
      }
    } else {
      setOptions([]);
    }
  }

  function onSelectFiltro(value) {
    if (filtro != '') {
      setValueFiltro(value);
    }
  }

  const searchEvents = (cREU_Id) => {
    setLoadingAcuerods(true);
    let variables = {
      cREU_Id: String(cREU_Id),
    };
    if (filtro === 'area') {
      variables.cARE_Id = valueFiltro;
    }
    getAcuerdos({
      variables,
    });
  };

  const [getAllReunions] = useMutation(GET_ALL_REU, {
    onCompleted({ getAllReunions }) {
      if (!getAllReunions || getAllReunions.length === 0) {
        setFoundReunions(false);
        setNotFoundReuniones(
          'No se encontró ninguná reunión con ese criterio de busqueda'
        );
        setTimeout(() => setNotFoundReuniones(''), 5000);
      } else setFoundReunions(true);
      console.log(getAllReunions);
      console.log(reunions);
      setLoading(false);
      setReunions(getAllReunions);
    },
    onError({ message, stack, name }) {
      console.error('Error General Report Reunion ->', stack, message, name);
    },
  });

  const [getCorredorSirec_ById] = useLazyQuery(GETCORREDOR_SIREC_ById, {
    onCompleted({ idCorredorSirecById }) {
      console.log(idCorredorSirecById);
      setCorredor([idCorredorSirecById]);
    },
  });

  const [getCompanySirec_ById] = useLazyQuery(GETCOMPANI_SIREC_ById, {
    onCompleted({ idCompanySirecById }) {
      console.log(idCompanySirecById);
      setCompany([idCompanySirecById]);
    },
  });

  const [getReasegurador] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log('getReaseguradores Executed', dynamicGet);
      if (dynamicGet.statusCode === 200) {
        let reaseguradores = JSON.parse(dynamicGet.response);
        let response = reaseguradores.sort((a, b) =>
          a.cRET_RazSocial < b.cRET_RazSocial ? -1 : 1
        );
        reaseguradores = response.map((rea) => ({
          id: rea.cRET_Id,
          name: rea.cRET_RazSocial,
        }));
        setOptions(reaseguradores);
      }
    },
    onError(err) {
      console.log('getReasegurador Error', err.message);
    },
  });

  const [getAutoridadConsultor] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log('getAutoridadConsultor Executed -->', dynamicGet);
      if (dynamicGet.statusCode === 200) {
        let autoridadConsultor = JSON.parse(dynamicGet.response);
        let response = autoridadConsultor.sort((a, b) =>
          a.cAUTCONS_Nombre < b.cAUTCONS_Nombre ? -1 : 1
        );
        autoridadConsultor = response.map((rea) => ({
          id: rea.cAUTCONS_Id,
          name: rea.cAUTCONS_Nombre,
        }));
        setOptions(autoridadConsultor);
      }
    },
    onError(err) {
      console.log('getAutoridadConsultor Error : ', err.message);
    },
  });

  const [getAcuerdos] = useMutation(GET_ACUERDOS_BY_REU, {
    onCompleted({ getAcuerdos }) {
      console.log('getAcuerdos Executed', getAcuerdos);
      if (getAcuerdos) {
        const acuerdos = getAcuerdos || [];
        getAcuerdosData({
          variables: {
            ids: [...acuerdos],
            status: statusSelected + '',
          },
        });
        setLoadingAcuerods(false);
        setOpenReuniones(false);
      } else {
        setLoadingAcuerods(false);
        setOpenReuniones(false);
      }
    },
    onError(err) {
      console.log(err.name, err.message);
      console.error('Error General Report ACU ->', err);
    },
  });

  const [getAcuerdosData, { loading: loadingDataPdf }] = useMutation(GET_REUNION_ACUERDOS_DATA, {
    onCompleted({ getReuAcuData }) {
      console.log('getReuData Executed', getReuAcuData);
      if (getReuAcuData.statusCode === 200) {
        const response = JSON.parse(getReuAcuData.response);

        console.log(response);

        setPdfData(response);
      }
    },
    onError(err) {
      console.error('Error General Report Acuerdos Data', err);
    },
  });


  const onSearch = () => {
    setFoundReunions(false);
    const input = {};
    input.fechaInicio = fechaInicio;
    input.fechaFin = fechaFin;
    input.queryType = filtro;
    input.status = statusSelected.toString();
    // input.queryValue = valueFiltro.toString();
    input.queryValue = valueIdFiltro;
    input.reuTitle = tituloReunion;
    if (filtro === 'autoridad') {
      input.queryValue = '4';
    }
    if (filtro === 'consultor') {
      input.queryValue = '5';
    }
    if (filtro === 'persona' || filtro === 'cedente' || filtro === 'corredor' || filtro === 'reasegurador') {
      input.queryValue = JSON.stringify(valueFiltro)
    }
    setLoading(true);
    getAllReunions({ variables: { input } });
  };

  useEffect(() => {
    getStatuses({
      variables: {
        input: {
          table: 'cESTS_EstatusSeguimiento',
          columns: ['cESTS_Id', 'cESTS_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div style={{ width: 500, marginBottom: 20 }}>
        <h2>Reporte General</h2>
        <Typography>Título de la Reunion</Typography>
        <Input
          placeholder="Reunion"
          type="text"
          onChange={(e) => setTituloReunion(e.target.value)}
          value={tituloReunion}
          style={{ width: '72%' }}
        />
        <div style={{ display: 'flex', width: 900 }}>
          {/* <div style={{ width: '100%' }}>
            <ContainerTwoInputs flexDirection="column" width="80%">
              <Typography>Clave</Typography>
              <Input
                id="clave-reunion-corredor"
                placeholder="234"
                onChange={onChange}
                type="number"
                value={aux_claveCorredor}
                disabled={false}
                // disabled={disabledCorredor}
                className={`${
                  aux_claveCorredor !== '' && !isNaN(aux_claveCorredor)
                    ? 'inputActive'
                    : ''
                }`}
              />
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column" width="80%">
              <Typography>Corredor</Typography>
              <Input />
            </ContainerTwoInputs>
          </div> */}

          {/* <div style={{ width: '100%' }}>
            <ContainerTwoInputs flexDirection="column" width="80%">
              <Typography>Clave</Typography>
              <Input
                id="clave-reunion-company"
                placeholder="234"
                onChange={onChangeCompany}
                type="number"
                value={aux_claveCompany}
                disabled={false}
                className={`${
                  aux_claveCompany !== '' && !isNaN(aux_claveCompany)
                    ? 'inputActive'
                    : ''
                }`}
              />
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column" width="80%">
              <Typography>Compañía</Typography>
              <Input />
            </ContainerTwoInputs>
          </div> */}
        </div>
        <br />

        <div style={{ display: 'flex', width: 900 }}>
          <div style={{ width: '100%' }}>
            <ContainerTwoInputs
              flexDirection="column"
              width="80%"
              style={{ marginBottom: 20 }}
            >
              <Typography>Fecha de Inicio</Typography>
              <DatePicker
                value={fechaInicio !== '' ? moment(fechaInicio) : ''}
                placeholder=""
                format="YYYY-MM-DD"
                onChange={onChangeDate1}
              />
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column" width="80%">
              <Typography>Buscar por...</Typography>
              <Input.Group>
                <Select
                  value={filtro}
                  onChange={onChangeFiltro}
                  style={{ width: '100%' }}
                  id="select-grup"
                >
                  {' '}
                  textxt
                  <Option value="persona">Persona</Option>
                  <Option value="cedente">Cedente</Option>
                  <Option value="pais">País</Option>
                  <Option value="corredor">Corredor</Option>
                  <Option value="area">Área Relacionada</Option>
                  <Option value="reasegurador">Reasegurador</Option>
                  <Option value="autoridad">Autoridad</Option>
                  <Option value="consultor">Consultor</Option>
                </Select>
              </Input.Group>
              {/* {filtro !== '' && (
                <Input.Group style={{marginTop: 30}}>
                {' '}
                <Typography>{filtro.toLocaleUpperCase()}</Typography>
                <Select
                  defaultValue=""
                  onChange={onSelectFiltro}
                  style={{ width: '100%' }}
                >
                  {options.map((option) => (
                    <Option value={option.id} key={option.id + Math.random()}>
                      {option.name}
                    </Option>
                  ))}
                </Select>{' '}
              </Input.Group>
              )} */}
              {filtro !== '' && filtro != '--Seleccionar--' && (
                <>
                  <Autocomplete
                    style={{ width: '100%' }}
                    freeSolo
                    selectOnFocus
                    inputValue={valueFiltro.name}
                    options={options}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        if (filtro === 'persona' || filtro === 'cedente' || filtro === 'corredor' || filtro === 'reasegurador') {
                          console.log('newValue', newValue)
                          setValueFiltro(newValue);
                        } else {
                          onSelectFiltro(newValue.name);
                          setValueIdFiltro(newValue.id.toString());
                        }
                        console.log(newValue.id);
                        console.log(newValue.name);
                        console.log(event.target.value);
                        console.log(event.target);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          console.log('PARAM', params);
                          console.log(e.target.value);
                          console.log(e.target);
                          setValueFiltro(e.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          endAdornment: valueFiltro && (
                            <IconButton onClick={() => setValueFiltro('')}>
                              <HighlightOffIcon />
                            </IconButton>
                          ),
                        }}
                        id="search-filter"
                      />
                    )}
                  />
                </>
              )}
              <br></br>
              {(valueFiltro != '' ||
                fechaInicio != '' ||
                fechaFin != '' ||
                tituloReunion != '' ||
                (statusSelected !== '--Seleccionar--' &&
                  statusSelected !== '') ||
                (filtro !== '--Seleccionar--' && filtro !== '')) && (
                  <>
                    <Button
                      type="primary"
                      style={{ width: 236 }}
                      onClick={() => {
                        setValueFiltro('');
                        setFechaFin('');
                        setFechaInicio('');
                        setStatusSelected('--Seleccionar--');
                        setFiltro('--Seleccionar--');
                        setTituloReunion('');
                        console.log(typeof statusSelected);
                      }}
                      size="large"
                    >
                      Limpiar campos
                    </Button>
                  </>
                )}
            </ContainerTwoInputs>
          </div>

          <div style={{ width: '100%' }}>
            <ContainerTwoInputs
              flexDirection="column"
              width="80%"
              style={{ marginBottom: 20 }}
            >
              <Typography>Fecha de Fin</Typography>
              <DatePicker
                value={fechaFin !== '' ? moment(fechaFin) : ''}
                placeholder=""
                format="YYYY-MM-DD"
                onChange={onChangeDate2}
              />
            </ContainerTwoInputs>

            {status && (
              <ContainerTwoInputs flexDirection="column" width="80%">
                <Typography>Estado</Typography>
                <Input.Group>
                  <Select
                    value={statusSelected}
                    defaultValue={changeval1}
                    onChange={(value) => setStatusSelected(value)}
                    style={{ width: '100%' }}
                  >
                    {status.map((st) => (
                      <Option value={st.id} key={st.id + '-909z'}>
                        {st.name}
                      </Option>
                    ))}
                  </Select>
                </Input.Group>
              </ContainerTwoInputs>
            )}
          </div>
        </div>
        {loading && (
          <Loader
            type="bubble-top"
            bgColor={'#1890ff'}
            title={'- cargando reuniones -'}
            color={'#1890ff'}
            size={30}
          />
        )}
        {notFoundReuniones && (
          <h3 style={{ color: 'red' }}>{notFoundReuniones}</h3>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          width: 400,
          marginTop: 20,
        }}
      >
        <div>
          <Button
            type="primary"
            style={{ width: 236 }}
            onClick={onSearch}
            size="large"
          >
            Buscar
          </Button>
          {/* <div>
            <div>
              <Button
                type="primary"
                style={{ width: 236, marginTop: 20 }}
                onClick={() => {
                  setValueFiltro('');
                  setTituloReunion('');
                  let getinfsel1 = document.getElementsByClassName("ant-select-selection-item")[0];
                  let getinfsel2 = document.getElementsByClassName("ant-select-selection-item")[1];
                  console.log(getinfsel1);
                  getinfsel1.innerHTML = ' '
                  getinfsel2.innerHTML = ' '
                  console.log(getinfsel2);
                }}
                size="large"
              >
                Limpiar todos los campos
              </Button>
            </div>
          </div> */}
        </div>

        <div style={{ marginLeft: 10 }}>
          {/* <Button
            type="primary"
            onClick={() => setOpenReuniones(true)}
            size="large"
            disabled={!foundReuniones}
          >
            Ver Reuniones Encontradas
          </Button> */}
        </div>
        {/* <div style={{ marginLeft: 10 }}>
          <Button
            onClick={() => setOpenPdfPreview((state) => !state)}
            size="large"
          >
            Ver Vista Previa
          </Button>
        </div> */}
        {/* {pdfData.length > 0 && (
          <div style={{ marginLeft: 10 }}>
            <Button
              // type="primary"
              onClick={() => setOpenPdfPreview((state) => !state)}
              size="large"
            >
              Descargar Reporte
            </Button>
          </div>
        )} */}
      </div>
      <Modal
        visible={openPdfPreview}
        width={'24.6cm'}
        closable
        onCancel={() => setOpenPdfPreview((state) => !state)}
        okText="Descargar"
        style={{ maxHeight: 600 }}
        onOk={() => exportPDF()}
      >
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A5"
          scale={0.8}
          fileName={`Reporte_Reunion_${reunionSelected}`}
          author="Patria"
          margin="0.5cm"
        >
          <div className="pdf-div">
            <h1>Reporte General</h1>
            {console.log('datos del pdf despues: ', pdfData)}
            {pdfData.length > 0 ? (
              pdfData.map((report) => (
                <div key={report.cACU_Id}>
                  {console.log('reporte gral: ', pdfData)}
                  <h2>Acuerdo - {report.cACU_Id}</h2>
                  <strong>
                    Titulo:{' '}
                    <span style={{ fontWeight: 200 }}>
                      {report.reunionNombre}
                    </span>
                  </strong>
                  <br />
                  <strong>
                    Lugar:{' '}
                    <span style={{ fontWeight: 200 }}>{report.lugar}</span>
                  </strong>
                  <br />
                  <strong>
                    Fecha:{' '}
                    <span style={{ fontWeight: 200 }}>
                      {/* {fechamod = report.fecha(fechamod.getMinutes() + fechamod.getTimezoneOffset())}
                      {console.log('fechamodificada: ', fechamod)} */}
                     
                      {/* {moment(report.fecha).format('YYYY-MM-DD')} */}
                      {report.fecha.substr(0,10)}
                      
                      {}
                    </span>
                  </strong>
                  <br />
                  <strong>
                    Tipo: <span style={{ fontWeight: 200 }}>{report.tipo}</span>
                  </strong>
                  <br />
                  <strong>Tipo de Reunion</strong>
                  <div>
                    <label>{report.tipoReunion}</label>
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div>
                      <strong>Entorno:</strong>
                      <div>
                        <ul>
                          <li>{report.entorno.step1}</li>
                          <li>{report.entorno.step2 === 'Continuar' ? '' : report.entorno.step2}</li>
                          <li>{report.entorno.step3}</li>
                        </ul>
                      </div>
                      <strong>Comentario Entorno:</strong>
                      <div>
                        {/* <label>{report.comentarios.entorno}</label> */}
                        <p style={{ whiteSpace: "pre-wrap" }}>{report.comentarios?.entorno}</p>
                      </div>
                      <strong>Seguimiento Entorno:</strong>
                      <div>
                        <ul>
                          {report.seguimientoEntorno.ramoCRM && (
                            <li>{report.seguimientoEntorno.ramoCRM}</li>
                          )}
                          {report.seguimientoEntorno.temaSeguimiento && (
                            <li>{report.seguimientoEntorno.temaSeguimiento}</li>
                          )}
                          {report.seguimientoEntorno.subTemaSeguimiento && (
                            <li>
                              {report.seguimientoEntorno.subTemaSeguimiento}
                            </li>
                          )}
                        </ul>
                        <strong>Estatus Seguimiento:</strong>
                        <label>
                          {' '}
                          {report.seguimientoEntorno.estatusSeguimiento}
                        </label>
                        <br />
                        <strong>Reporte Seguimiento:</strong>
                        <label>
                          {' '}
                          {report.seguimientoEntorno.reporteSeguimiento}
                        </label>
                        <br />
                        <strong>Area:</strong>
                        <label> {report.seguimientoEntorno.area}</label>
                        <br />
                        <strong>SubArea:</strong>
                        <label> {report.seguimientoEntorno.subArea}</label>
                        <br />
                        <strong>Comentario Entorno Seguimiento:</strong>
                        <p style={{ whiteSpace: "pre-wrap" }}> {report.comentarios?.segEntorno}</p>
                      </div>
                    </div>
                    <div>
                      <strong>Relacion: </strong>
                      <div>
                        <ul>
                          {report.relacion.cRELP_1 && (
                            <li>{report.relacion.cRELP_1}</li>
                          )}
                          {report.relacion.cRELP_2 && (
                            <li>{report.relacion.cRELP_2}</li>
                          )}
                          {report.relacion.cRELP_3 && (
                            <li>{report.relacion.cRELP_3}</li>
                          )}
                          {report.relacion.cRELP_4 && (
                            <li>{report.relacion.cRELP_4}</li>
                          )}
                          {report.relacion.cRELP_5 && (
                            <li>{report.relacion.cRELP_5}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {report.relacion.cRELP_1 == 'Facultativo' && (
                      <div>
                        <div>
                          <strong>{facultativoSubtitles[(report.relacion.cRELP_2 === 'Apetito de Riesgo' ? "apetitoRiesgo" : (report.relacion.cRELP_2).toLowerCase())][0]}</strong>
                          <div>
                            <p style={{ whiteSpace: "pre-wrap" }}>{report.comentarios?.multiComments.firstComment}</p>
                          </div>
                        </div>
                        <div>
                          <strong>{facultativoSubtitles[(report.relacion.cRELP_2 === 'Apetito de Riesgo' ? "apetitoRiesgo" : (report.relacion.cRELP_2).toLowerCase())][1]}</strong>
                          <div>
                            <p style={{ whiteSpace: "pre-wrap" }}>{report.comentarios?.multiComments.secondComment}</p>
                          </div>
                        </div>
                        <div>
                          <strong>{facultativoSubtitles[(report.relacion.cRELP_2 === 'Apetito de Riesgo' ? "apetitoRiesgo" : (report.relacion.cRELP_2).toLowerCase())][2]}</strong>
                          <div>
                            <p style={{ whiteSpace: "pre-wrap" }}>{report.comentarios?.multiComments.thirdComment}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <strong>Comentario Relacion:</strong>
                      <div>
                        <p style={{ whiteSpace: "pre-wrap" }}>{report.comentarios?.relacion}</p>
                      </div>
                    </div>
                    <div>
                      <strong>Seguimiento Relación: </strong>
                      <div>
                        {/* <label style={{ fontWeight: 'bold' }}>
                          Tipo de Registro:
                        </label>
                        <label>
                          {' '}
                          {
                            report.seguimientoRelacionPatria
                              .relacionPatriaRegistro
                          }
                        </label>
                        <br /> */}
                        <label style={{ fontWeight: 'bold' }}>
                          Tipo de Seguimiento:
                        </label>
                        <label>
                          {' '}
                          {report.seguimientoRelacionPatria.seguimientoRelacion}
                        </label>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>Acción:</label>
                        <label>
                          {' '}
                          {report.seguimientoRelacionPatria.accionesSeguimiento}
                        </label>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          Estatus Seguimiento:
                        </label>
                        <label>
                          {' '}
                          {
                            report.seguimientoRelacionPatria
                              .estatusSeguimientoPatria
                          }
                        </label>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>Area:</label>
                        <label>
                          {' '}
                          {report.seguimientoRelacionPatria.areaRelacion}
                        </label>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>SubArea:</label>
                        <label>
                          {' '}
                          {report.seguimientoRelacionPatria.subAreaRelacion}
                        </label>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          Comentarios Seguimiento Relacion:
                        </label>
                        <p style={{ whiteSpace: "pre-wrap" }}> {report.comentarios?.segRelacion}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))
            ) : loadingDataPdf ? (
              <Loader
                type="bubble-top"
                bgColor={'#1890ff'}
                title={'- cargando información -'}
                color={'#1890ff'}
                size={30}
              />
            ) :
              (
                <p>No existen acuerdos actualmente en esta reunion</p>
              )}
            <br />
          </div>
        </PDFExport>
      </Modal>
      <Modal
        visible={openReuniones}
        width={1000}
        closable
        onCancel={() => setOpenReuniones((state) => !state)}
        okText="Cerrar"
        onOk={() => setOpenReuniones((state) => !state)}
      >
        <div>
          <h3>Selecciona la reunión de tu interés para descargar el reporte</h3>
        </div>
        {loadingAcuerdos ? (
          <Loader
            type="bubble-top"
            bgColor={'#1890ff'}
            title={'- cargando acuerdos -'}
            color={'#1890ff'}
            size={30}
          />
        ) : (
          <Table
            columns={[
              { title: 'ID', dataIndex: 'cREU_Id', key: 'cREU_Id' },
              {
                title: 'Titulo',
                dataIndex: 'cREU_Titulo',
                key: 'cREU_Titulo',
                render: (text, row) => (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      searchEvents(row.cREU_Id);
                      setReunionSelected(row.cREU_Id);
                      setOpenPdfPreview((state) => !state);
                    }}
                  >
                    {text}
                  </span>
                ),
              },
              { title: 'Pais', dataIndex: 'Pais', key: 'Pais' },
            ]}
            dataSource={reunions}
          />
        )}
      </Modal>
      <br></br>
      <br></br>
      
      {edit && pdfData.length > 0 ? (
        <EditReunion pdfData={pdfData} setEdit={setEdit} edit={edit} reunions={reunions} onSearch={onSearch} reunionSeleccionada={reunionSelected} />
      ) : (
        <TableReuniones
          reunions={reunions}
          searchEvents={searchEvents}
          setReunionSelected={setReunionSelected}
          setOpenPdfPreview={setOpenPdfPreview}
          setEdit={setEdit}
          pdfData={pdfData}
        />
      )}
    </div>
  );
}

export default GeneralReport;
