const URL = 'https://dev-patria.alimx.mx/';
const URL_REDIRECT = 'https://dev-patria.alimx.mx/login'

// nameReunion : Nombre de la reunión o viaje)
// nameDirectBoss : Nombre del jefe directo)
// nameAsistent : Nombre asistente de viajes
// nameAccounting : Nombre del usuario de contabilidad)
// areaDirector : Nombre del director del área
// namePayrollUser : Nombre del usuario del área de nómina
// fechIncTravel : Fecha de inicio del viaje
// fechFinTravel : Fecha Fin del viaje
// nameOfficeUser : Nombre del usuario del área de servicios de oficina
// materialRequirement : requerimiento de material que se define en el requerimiento de solicitud de viajes, sección 1.7
// nameTIuser : Nombre del usuario del área de T.I
// materialRequirementTI : requerimiento de material que se define en el requerimiento de solicitud de viajes, sección 1.9
// namePlanningArea : Nombre del usuario del área de planeación
// requiPlanningArea : requerimiento de material que se define en el requerimiento de solicitud de viajes, sección 1.8

export const meetingRecord = () => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;"> <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              ¡Has creado una nueva reunión!</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 42px !important;">
          <td style="height: 42px !important;font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;" align="center">
            <h4 style="margin: 0;">Revisa los detalles de tu visita</h4>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Titulo</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">Seguimiento a resultados segundo semestre</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">03 - Noviembre - 2020</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Visita a</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">HDI Seguros S.A. de C.V. </h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px; margin: 0;">Lugar</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px; margin: 0;">Monterrey, Nuevo León</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 94px !important;" align="center">
            <button style="border-radius: 4px;
             background: #3898EC;
             color: white;
             height: 38px;
             width: 199.5px;
             font-family: 'Raleway'; 
             font-weight: 700;
             size: 14px;
             border: none;">
              Ver reunión</button>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
export const meetingUpdated = () => {
  return `
  <!DOCTYPE html>
<html
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
  lang="es">

<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <<table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
        </td>
      </tr>
    </table>
    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
      <tr style="border-collapse:collapse;height: 42px !important;">
        <td style="height: 42px !important;" align="center">
          <span
            style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
            Has actualizado tu reunión</span>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 42px !important;">
        <td style="height: 42px !important;font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;" align="center">
          <h4 style="margin: 0;">Revisa los cambios en tu visita</h4>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Titulo</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">Seguimiento a resultados segundo semestre</h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">03 - Noviembre - 2020</h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Visita a</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">HDI Seguros S.A. de C.V. </h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px; margin: 0;">Lugar</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px; margin: 0;">Monterrey, Nuevo León</h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 94px !important;" align="center">
          <button style="border-radius: 4px;
           background: #3898EC;
           color: white;
           height: 38px;
           width: 199.5px;
           font-family: 'Raleway'; 
           font-weight: 700;
           size: 14px;
           border: none;">
          Revisar cambios</button>
        </td>
      </tr>
    </table>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
      <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
    </table>
    </td>
    </tr>
    </table>
  </div>
</body>

</html>
  `
}

export const meetingInvitation = () => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
            <<table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
            <tr style="border-collapse:collapse;">
                <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                  style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                        <td>
                        <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                        src="${URL}image/plantillas/header.png">
                        </td>
                        <td>
                              <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                              style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                          </td>
                        <td style="display:flex;">
                            <td>
                                <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                    style="top: 59px; right: 46%; position: absolute;" />
                                    </img>
                            </td>
                          
                            
                        </td>
                        
                    </td>
                </table>
              </td>
            </tr>
          </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Invitación a reunión</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 42px !important;">
          <td style="height: 42px !important;font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;" align="center">
            <h4 style="margin: 0;">Has sido invitado a una reunión</h4>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Titulo</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">Seguimiento a resultados segundo semestre</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">03 - Noviembre - 2020</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Visita a</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">HDI Seguros S.A. de C.V. </h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px; margin: 0;">Lugar</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px; margin: 0;">Monterrey, Nuevo León</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <hr style="background: #E5E5E5; width: 90%; opacity: 0.2;">
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%;">
              Hola.
              <br>
              <br>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tincidunt felis. Maecenas diam est,
              molestie ut vulputate eu, bibendum sed turpis. Aliquam ultricies quam a tempus rhoncus. Cras consectetur
              erat venenatis diam condimentum consectetur.
              <br>
              <br>
              Saludos </p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <hr style="background: #E5E5E5; width: 90%; opacity: 0.2;">
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 94px !important;" align="center">
            <button style="border-radius: 4px;
             background: #3898EC;
             color: white;
             height: 38px;
             width: 199.5px;
             font-family: 'Raleway'; 
             font-weight: 700;
             size: 14px;
             border: none;">
              Enviar confirmación</button>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}

export const invitationExternalMeeting = () => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Invitación a reunión</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 42px !important;">
          <td style="height: 42px !important;font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;" align="center">
            <h4 style="margin: 0;">Has sido invitado a una reunión</h4>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Titulo</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">Seguimiento a resultados segundo semestre</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">03 - Noviembre - 2020</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px;margin: 0;">Visita a</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px;margin: 0;">HDI Seguros S.A. de C.V. </h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 20px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="font-size: 10px; margin: 0;">Lugar</p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 22px !important;" align="center">
            <h5 style="font-size: 10px; margin: 0;">Monterrey, Nuevo León</h5>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <hr style="background: #E5E5E5; width: 90%; opacity: 0.2;">
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%;">
              Hola.
              <br>
              <br>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tincidunt felis. Maecenas diam est,
              molestie ut vulputate eu, bibendum sed turpis. Aliquam ultricies quam a tempus rhoncus. Cras consectetur
              erat venenatis diam condimentum consectetur.
              <br>
              <br>
              Saludos </p>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <hr style="background: #E5E5E5; width: 90%; opacity: 0.2;">
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 94px !important;" align="center">
            <button style="border-radius: 4px;
             background: #3898EC;
             color: white;
             height: 38px;
             width: 199.5px;
             font-family: 'Raleway'; 
             font-weight: 700;
             size: 14px;
             border: none;">
              Enviar confirmación</button>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
export const perdiEmRequest = () => {
  return `
   <!DOCTYPE html>
<html
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
  lang="es">

<head>
  <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'>
  <link href="http://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Margin:0; height: 113px;">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
        </td>
      </tr>
    </table>
    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
      <tr style="border-collapse:collapse;height: 42px !important;">
        <td style="height: 42px !important;" align="center">
          <span
            style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: 800; font-size: 20px; color: #000000; top: 20%;">
            Has creado una nueva solicitud de viáticos</span>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 42px !important;">
        <td style="height: 42px !important;font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;" align="center">
          <h4 style="margin: 0;">Revisa la información general de tu solicitud</h4>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 15px !important;" align="center">
          <table style="width: 70%;border-bottom: 1px solid #c8c8c8;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <h5 style="margin: 0;
                font-weight: 600; 
                font-family: 'segoe-ui-4';
                font-size: 16px;
                line-height: 21.28px;
                color: black">
                  Reunión No.345
                </h5>
              </td>
              <td align="center">
                <table width="100%">
                  <tr style="border-collapse:collapse;">
                    <td align="right">
                      <h5 style="margin: 0;
                      font-weight: 600; 
                      font-family: 'segoe-ui-4';
                      font-size: 16px;
                      line-height: 21.28px;
                      color: black;">
                        En Revisión
                      </h5>
                    </td>
                    <td align="left">
                      <img src="../../assets/images/plantillas/warning.png" alt="revision" width="15px" style="margin: 0;">
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <img src="../../assets/images/plantillas/avion.png" alt="avion2" width="110" style="margin-top: 5%; margin-bottom: 2%;">
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style=" 
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;">
                  Seguimiento resultados del primer semestre del 2020
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="../../assets/images/plantillas/calendario.png" alt="calendar" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        23 Sept 2020 - 28 Sept 2020
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Londres, Gran Bretaña
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Comida
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style="
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;"">
                  Edgar Sánchez Lara
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="../../assets/images/plantillas/usuario.png" alt="user" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        No. empleado 200 234567
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;"">
                        Suscripción
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Patria Re
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 94px !important;" align="center">
          <button style="border-radius: 4px;
           background: #3898EC;
           color: white;
           height: 38px;
           width: 199.5px;
           font-family: 'Raleway'; 
           font-weight: 700;
           size: 14px;
           border: none;">
            Revisar solicitud</button>
        </td>
      </tr>
    </table>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
      <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
    </table>
    </td>
    </tr>
    </table>
  </div>
</body>

</html>
   `
}
export const assistantTravelApp = (NumReu, data, data2) => {
  const { texto1: fechas, texto2: lugar, texto3: tipoReu } = data;
  const { titulo: nombreEmp, texto1: numEmp, } = data2;
  const date = new Date();
  const currentYear = date.getFullYear();
  return `
<!DOCTYPE html>
<html
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
  lang="es">

<head>
  <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'>
  <link href="http://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js" integrity="sha512-aVKKRRi/Q/YV+4mjoKBsE4x3H+BkegoM/em46NNlCqNTmUYADjBbeNefNxYV7giUp0VxICtqdrbqU7iVaeZNXA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.js" integrity="sha512-CX7sDOp7UTAq+i1FYIlf9Uo27x4os+kGeoT7rgwvY+4dmjqV0IuE/Bl5hVsjnQPQiTOhAX1O2r2j5bjsFBvv/A==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.slim.js" integrity="sha512-1cF8XUz5U3BlnRVqNFn+aPNwwSr/FPtrmKvM1g4dJJ9tg8kmqRUzqbSOvRRAMScDnTkOcOnnfwF3+jRA/nE2Ow==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.slim.min.js" integrity="sha512-yBpuflZmP5lwMzZ03hiCLzA94N0K2vgBtJgqQ2E1meJzmIBfjbb7k4Y23k2i2c/rIeSUGc7jojyIY5waK3ZxCQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
</head>

<body
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Marginpapim:0; height: 113px;">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <tr style="border-collapse:collapse; ">
              <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                src="${URL}image/plantillas/header.png">
              <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="" width="27px" height="43.5px"
                style="top: 23px; right:62%; z-index: 1; position: absolute;" />
              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="89px"
                style="top: 59px; right: 46%; position: absolute;" />
              </img>
              <table width="100%" cellspacing="0" cellpadding="0"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;">
                <tr style="border-collapse:collapse;">
                  <td width="100%" valign="top" align="center" style="padding:0;Margin:0; max-width: 540px;">
                    <table width="100%" cellspacing="0" cellpadding="0" role="presentation"
                      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px; max-width: 540px;">
                      <tr style="border-collapse:collapse;">
                        <td align="center" style="padding:0;Margin:0;padding-bottom:30px;">
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
        </td>
      </tr>
    </table>
    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
      <tr style="border-collapse:collapse;height: 42px !important;">
        <td style="height: 42px !important;" align="center">
          <span
            style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: 800; font-size: 20px; color: #000000; top: 20%;">
            Se ha creado una nueva solicitud de viáticos</span>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 42px !important;">
        <td style="height: 42px !important;font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;" align="center">
          <h4 style="margin: 0;">Tienes una solicitud de viáticos pendiente para revisar</h4>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 15px !important;" align="center">
          <table style="width: 70%;border-bottom: 1px solid #c8c8c8;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <h5 style="margin: 0;
                font-weight: 600; 
                font-family: 'segoe-ui-4';
                font-size: 16px;
                line-height: 21.28px;
                color: black">
                  Reunión No.${NumReu}
                </h5>
              </td>
              <td align="center">
                <table width="100%">
                  <tr style="border-collapse:collapse;">
                    <td align="right">
                      <h5 style="margin: 0;
                      font-weight: 600; 
                      font-family: 'segoe-ui-4';
                      font-size: 16px;
                      line-height: 21.28px;
                      color: black;">
                        En Revisión
                      </h5>
                    </td>
                    <td align="left">
                      <img src="${URL}image/plantillas/warning.png" alt="revision" width="15px" style="margin: 0;">
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <img src="${URL}image/plantillas/avion.png" alt="avion2" width="110" style="margin-top: 5%; margin-bottom: 2%;">
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style=" 
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;">
                  Seguimiento resultados del primer semestre del ${currentYear}
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="${URL}image/plantillas/calendario.png" alt="calendar" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        ${fechas || '23 Sept 2020 - 28 Sept 2020'}
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        ${lugar || 'Londres, Gran Bretaña'}
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        ${tipoReu || 'Comida'}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style="
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;"">
                  ${nombreEmp || 'Edgar Sánchez Lara'}
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="${URL}image/plantillas/usuario.png" alt="user" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        No. empleado ${numEmp || '200 234567'}
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;"">
                        Suscripción
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Patria Re
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 94px !important;" align="center">
          <button 
          onclick="window.open('${URL_REDIRECT}', '_blank');"
          style="border-radius: 4px;
           background: #3898EC;
           color: white;
           height: 38px;
           width: 199.5px;
           font-family: 'Raleway'; 
           font-weight: 700;
           size: 14px;
           border: none;"        
           >
            Revisar solicitud
          </button>
        </td>
      </tr>
    </table>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
      <tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-content-body" width="120px" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color: transparent;">
            <img width="550px" height="137px" style=" margin-bottom: -17%;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;">
              <td width="33.4%" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="33.4%" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="33.4%" align="center">
                <img src="${URL}image/plantillas/youTube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    </td>
    </tr>
    </table>
  </div>
<script>
jQuery(document).ready(function($) {
  $(".clickable-row").click(function() {
      window.location = $(this).data("href");
  });
});
</script>
</body>

</html>`
}

export const travelAppUpdate = () => {
  return `
   <!DOCTYPE html>
<html
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
  lang="es">

<head>
  <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'>
  <link href="http://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <<table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
        </td>
      </tr>
    </table>
    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
      <tr style="border-collapse:collapse;height: 42px !important;">
        <td style="height: 42px !important;" align="center">
          <span
            style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: 800; font-size: 20px; color: #000000; top: 20%;">
            Tu solicitud de viáticos se ha actualizado</span>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 42px !important;">
        <td style="height: 42px !important;font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;" align="center">
          <h4 style="margin: 0;">Revisa los cambios que se han realizado ingresando a tu cuenta</h4>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 15px !important;" align="center">
          <table style="width: 70%;border-bottom: 1px solid #c8c8c8;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <h5 style="margin: 0;
                font-weight: 600; 
                font-family: 'segoe-ui-4';
                font-size: 16px;
                line-height: 21.28px;
                color: black">
                  Reunión No.345
                </h5>
              </td>
              <td align="center">
                <table width="100%">
                  <tr style="border-collapse:collapse;">
                    <td align="right">
                      <h5 style="margin: 0;
                      font-weight: 600; 
                      font-family: 'segoe-ui-4';
                      font-size: 16px;
                      line-height: 21.28px;
                      color: black;">
                        En Revisión
                      </h5>
                    </td>
                    <td align="left">
                      <img src="../../assets/images/plantillas/warning.png" alt="revision" width="15px" style="margin: 0;">
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse:collapse;">
              <td align="center">
                <img src="../../assets/images/plantillas/avion.png" alt="avion2" width="110" style="margin-top: 5%; margin-bottom: 2%;">
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style=" 
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;">
                  Seguimiento resultados del primer semestre del 2020
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="../../assets/images/plantillas/calendario.png" alt="calendar" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        23 Sept 2020 - 28 Sept 2020
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Londres, Gran Bretaña
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Comida
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
            <tr style="border-collapse: collapse;">
              <td align="left">
                <h5 style="
                font-weight: 600; 
                margin: 0;
                font-family: 'segoe-ui-4';
                font-size: 14px;
                line-height: 18.62px;
                color: black;"">
                  Edgar Sánchez Lara
                </h5>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse;">
        <td align="center">
          <table style="width: 70%;">
            <tr style="border-collapse: collapse;">
              <td style="width: 40%;" align="center">
                <img src="../../assets/images/plantillas/usuario.png" alt="user" width="39">
              </td>
              <td style="width: 60%; align-items: center;">
                <table style="width: auto;">
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        No. empleado 200 234567
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;"">
                        Suscripción
                      </p>
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <td align="left" style="height: 15px;">
                      <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                        Patria Re
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 94px !important;" align="center">
          <button style="border-radius: 4px;
           background: #3898EC;
           color: white;
           height: 38px;
           width: 199.5px;
           font-family: 'Raleway'; 
           font-weight: 700;
           size: 14px;
           border: none;">
            Revisar solicitud</button>
        </td>
      </tr>
    </table>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
      <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
    </table>
    </td>
    </tr>
    </table>
  </div>
</body>

</html>
   `
}

export const appViaticalReview = () => {
  return `
  <!DOCTYPE html>
<html
 style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
 lang="es">

<head>
 <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'>
 <link href="http://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet">
 <meta charset="UTF-8">
 <meta name="viewport" content="width=device-width, initial-scale=1.0">
 <title>Document</title>
</head>

<body
 style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
 <div style="width: 100%; background-color: #fff;max-width: 540px;
 transform: scale(0.37963, 0.37963);
 transform-origin: top;">
   <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
     style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
     <tr style="border-collapse:collapse;">
       <td valign="top" style="padding:0;Margin:0; height: 113px;">
         <<table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
         style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
         <tr style="border-collapse:collapse;">
             <table class="es-header" cellspacing="0" cellpadding="0" align="center"
               style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                     <td>
                     <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                     src="${URL}image/plantillas/header.png">
                     </td>
                     <td>
                           <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                           style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                       </td>
                     <td style="display:flex;">
                         <td>
                             <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                 style="top: 59px; right: 46%; position: absolute;" />
                                 </img>
                         </td>
                       
                         
                     </td>
                     
                 </td>
             </table>
           </td>
         </tr>
       </table>
       </td>
     </tr>
   </table>
   <table class="es-content" cellspacing="0" cellpadding="0" align="center"
     style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
     <tr style="border-collapse:collapse;height: 42px !important;">
       <td style="height: 42px !important;" align="center">
         <span
           style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: 800; font-size: 20px; color: #000000; top: 20%;">
           Tu solicitud de viáticos se ha actualizado</span>
       </td>
     </tr>
     <tr style="border-collapse:collapse; height: 42px !important;">
       <td style="height: 42px !important;font-family: 'Raleway';
       font-style: normal;
       font-weight: 700;
       font-size: 14px;
       line-height: 16px;
       text-align: center;" align="center">
         <h4 style="margin: 0;">Revisa los cambios que se han realizado ingresando a tu cuenta</h4>
       </td>
     </tr>
     <tr style="border-collapse:collapse; height: 20px !important;">
       <td style="height: 15px !important;" align="center">
         <table style="width: 70%;border-bottom: 1px solid #c8c8c8;">
           <tr style="border-collapse:collapse;">
             <td align="center">
               <h5 style="margin: 0;
               font-weight: 600; 
               font-family: 'segoe-ui-4';
               font-size: 16px;
               line-height: 21.28px;
               color: black">
                 Reunión No.345
               </h5>
             </td>
             <td align="center">
               <table width="100%">
                 <tr style="border-collapse:collapse;">
                   <td align="right">
                     <h5 style="margin: 0;
                     font-weight: 600; 
                     font-family: 'segoe-ui-4';
                     font-size: 16px;
                     line-height: 21.28px;
                     color: black;">
                       En Revisión
                     </h5>
                   </td>
                   <td align="left">
                     <img src="../../assets/images/plantillas/warning.png" alt="revision" width="15px" style="margin: 0;">
                   </td>
                 </tr>
               </table>
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse;">
       <td align="center">
         <table style="width: 70%;">
           <tr style="border-collapse:collapse;">
             <td align="center">
               <img src="../../assets/images/plantillas/avion.png" alt="avion2" width="110" style="margin-top: 5%; margin-bottom: 2%;">
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse;">
       <td align="center">
         <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
           <tr style="border-collapse: collapse;">
             <td align="left">
               <h5 style=" 
               font-weight: 600; 
               margin: 0;
               font-family: 'segoe-ui-4';
               font-size: 14px;
               line-height: 18.62px;
               color: black;">
                 Seguimiento resultados del primer semestre del 2020
               </h5>
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse;">
       <td align="center">
         <table style="width: 70%;">
           <tr style="border-collapse: collapse;">
             <td style="width: 40%;" align="center">
               <img src="../../assets/images/plantillas/calendario.png" alt="calendar" width="39">
             </td>
             <td style="width: 60%; align-items: center;">
               <table style="width: auto;">
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                       23 Sept 2020 - 28 Sept 2020
                     </p>
                   </td>
                 </tr>
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                       Londres, Gran Bretaña
                     </p>
                   </td>
                 </tr>
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                       Comida
                     </p>
                   </td>
                 </tr>
               </table>
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse;">
       <td align="center">
         <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
           <tr style="border-collapse: collapse;">
             <td align="left">
               <h5 style="
               font-weight: 600; 
               margin: 0;
               font-family: 'segoe-ui-4';
               font-size: 14px;
               line-height: 18.62px;
               color: black;"">
                 Edgar Sánchez Lara
               </h5>
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse;">
       <td align="center">
         <table style="width: 70%;">
           <tr style="border-collapse: collapse;">
             <td style="width: 40%;" align="center">
               <img src="../../assets/images/plantillas/usuario.png" alt="user" width="39">
             </td>
             <td style="width: 60%; align-items: center;">
               <table style="width: auto;">
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                       No. empleado 200 234567
                     </p>
                   </td>
                 </tr>
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;"">
                       Suscripción
                     </p>
                   </td>
                 </tr>
                 <tr style="border-collapse: collapse;">
                   <td align="left" style="height: 15px;">
                     <p style="font-size: 14px; margin: 0; font-family:'segoe-ui-4'; font-weight: 400;">
                       Patria Re
                     </p>
                   </td>
                 </tr>
               </table>
             </td>
           </tr>
         </table>
       </td>
     </tr>
     <tr style="border-collapse:collapse; height: 22px !important;">
       <td style="height: 94px !important;" align="center">
         <button style="border-radius: 4px;
          background: #3898EC;
          color: white;
          height: 38px;
          width: 199.5px;
          font-family: 'Raleway'; 
          font-weight: 700;
          size: 14px;
          border: none;">
           Revisar solicitud</button>
       </td>
     </tr>
   </table>
   <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
     style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
     <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
   </table>
   </td>
   </tr>
   </table>
 </div>
</body>

</html>
  `
}

//Anexo 4
export const meetingRegistration = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
<!DOCTYPE html>
<html
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
  lang="es">

<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body
  style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Margin:0; height: 113px;">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
        </td>
      </tr>
    </table>
    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
      <tr style="border-collapse:collapse;height: 42px !important;">
        <td style="height: 42px !important;" align="center">
          <span
            style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
            Solicitud de viaje</span>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 42px !important;">
        <td style="height: 42px !important;font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;" align="center">
          <h4 style="margin: 0;">Revisa los detalles de la solicitud</h4>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Titulo</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">Seguimiento a resultados segundo semestre</h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">03 - Noviembre - 2020</h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px;margin: 0;">Visita a</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px;margin: 0;">HDI Seguros S.A. de C.V. </h5>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 20px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="font-size: 10px; margin: 0;">Lugar</p>
        </td>
      </tr>
      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 22px !important;" align="center">
          <h5 style="font-size: 10px; margin: 0;">Monterrey, Nuevo León</h5>
        </td>
      </tr>

      <tr style="border-collapse:collapse; height: 22px !important;">
        <td style="height: 20px !important;" align="center">
          <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%;  margin-bottom: 6%;">
            Le informamos que el usuario XXXX  ${traveler} ha solicitado realizar un viaje, favor de ingresar a
            la Plataforma de Gestión de Clientes dando clic ( https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la
            aplicación de la tienda correspondiente, Play Store o App store).
          </p>
        </td>
      </tr>
    </table>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
      <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
    </table>
    </td>
    </tr>
    </table>
  </div>
</body>

</html>
`
}
// Anexo 5
export const quotes = (traveler) => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Cotizaciónes</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${traveler}
              <br>
              <br>
              Le informamos que se encuentra lista la propuesta para su viaje, les pedimos por favor ingrese a la Plataforma de Gestión de Clientes dando clic aquí ( https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store ) y seleccione la opción de su preferencia.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}

// Anexo 6
export const reviewQuotes = () => {
  const userName = JSON.parse(localStorage.getItem('user'))?.user.displayName || 'Viajero';
  const directorName = sessionStorage.getItem('nameRol') || 'Director';
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Revisión de cotización</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${directorName}
              <br>
              <br>
              Le informamos que ${userName} realizará un viaje, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta https://dev-patria.alimx.mx/login.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>

  `
}
// Anexo 7
export const proposalStatus = () => {
  const traveler = sessionStorage.getItem('nameRol');
  const status = sessionStorage.getItem('statusVIt');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Estatus de propuesta</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado  ${traveler}

              <br>
              <br>
              Le informamos que la propuesta ha sido ${status}, por lo que agradeceremos coteje en la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta ( https://dev-patria.alimx.mx/login. y link para descargar la aplicación de la tienda correspondiente, Play Store o App store).
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 8 y Anexo 16
export const proposalComments = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comentarios de propuesta</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${traveler}
              <br>
              <br>
              Le informamos que se han hecho comentarios a su propuesta por lo que agradeceremos los coteje 
              ingresando en la Plataforma de Gestión de Clientes dando clic aquí ( https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la
                 aplicación de la tienda correspondiente, Play Store o App store).
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 9
export const commentsDirector = () => {
  const nameAsistent = sessionStorage.getItem('nameRol');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comentarios de propuesta</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${nameAsistent}
              <br>
              <br>
              Le informamos que se han hecho comentarios a su propuesta por lo que agradeceremos los coteje 
              ingresando en la Plataforma de Gestión de Clientes dando clic aquí ( https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la
                 aplicación de la tienda correspondiente, Play Store o App store).
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}

// anexo 10 
export const asistentToTraveler = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
            Solicitud de viajes</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${traveler}
              <br>
              <br>
              Le informamos que ya se hizo la gestión correspondiente a su viaje, por favor ingrese a la Plataforma de Gestión de Clientes dando clic aquí (https://dev-patria.alimx.mx/login para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store) para descargar la información correspondiente.              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 11
export const directBossAuthorization = () => {
  const nameDirectBoss = sessionStorage.getItem('nameRol')
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Revisión de cotización</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameDirectBoss}
              <br>
              <br>
              Le informamos que ${traveler} realizará un viaje, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta https://dev-patria.alimx.mx/login.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>

  `
}
// Anexo 12
export const annexTwelve = () => {
  const nameAsistent = sessionStorage.getItem('nameRol')  
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comentarios de propuesta</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${nameAsistent}
              <br>
              <br>
              Le informamos que se han hecho comentarios a su propuesta por lo que agradeceremos los coteje 
              ingresando en la Plataforma de Gestión de Clientes dando clic aquí ( https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la
                 aplicación de la tienda correspondiente, Play Store o App store).
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}

// Anexo 13
export const noticeCancellation = () => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Cancelación de viaje</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimados
              <br>
              <br>
              Le informamos que se ha cancelado el viaje: 
              <br>
              Agradeceré se realicen las gestiones correspondientes.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 14
export const accountantAuthorization = () => {
  const nameAccounting = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Revisión de cotización</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameAccounting}
              <br>
              <br>
              Le informamos que ${traveler} realizará un viaje, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta https://dev-patria.alimx.mx/login.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>

  `
}
// Anexo 15
export const directAreaAuthorization = () => {
  const areaDirector = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Solicitud de viaje</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${areaDirector}
              <br>
              <br>
              Le informamos que ${traveler} realizará un viaje, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta https://dev-patria.alimx.mx/login.
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>

  `
}
// Anexo 17
export const stuns = () => {
  const nameAccounting = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Solicitud de viaje</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${nameAccounting}, 
              <br>
              <br>
              Le informamos que XXXXX ${traveler} realizará un viaje, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta (https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store).
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 18
export const passed = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Solicitud de viaje</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${traveler}, 
              <br>
              <br>
              Le informamos que se ha aprobado su solicitud de viáticos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí para aprobar la propuesta (https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store).              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 19
export const rejected = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
          <td valign="top" style="padding:0;Margin:0; height: 113px;">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
          <tr style="border-collapse:collapse;">
              <table class="es-header" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                      <td>
                      <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                      src="${URL}image/plantillas/header.png">
                      </td>
                      <td>
                            <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                            style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                        </td>
                      <td style="display:flex;">
                          <td>
                              <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                  style="top: 59px; right: 46%; position: absolute;" />
                                  </img>
                          </td>
                        
                          
                      </td>
                      
                  </td>
              </table>
            </td>
          </tr>
        </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Solicitud de viajes</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
            "Estimado ${traveler}, 
              <br>
              <br>
              Le informamos que la propuesta ha sido rechazada, por lo que agradeceremos lo coteje ingresando a la Plataforma de Gestión de Clientes dando clic (https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store).              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <<tr style="border-collapse: collapse;">
        <td width="100%" style="background-color: #1D539C;" align="center">
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
          style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
          <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
            <tr style="border-collapse: collapse;display: flex;
            justify-content: center;">
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
              </td>
              <td width="38px" align="center">
                <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
              </td>
            </tr>
            <tr style="border-collapse: collapse;">
              <table style="width: 100%;">
                <tr style="border-collapse: collapse;">
                  <td align="center">
                    <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
  `
}
// Anexo 20
export const travelerToAccountingCheecking = () => {
  const nameAccounting = sessionStorage.getItem('nameRol');
  const traveler = JSON.parse(localStorage.getItem('user'))?.user.displayName || 'Viajero';
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos de ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameAccounting}
              <br>
              <br>
              Le informamos que ${traveler} quiere realizar su comprobación de gastos, agradeceremos coteje ingresando a la Plataforma de Gestión de Clientes dando clic (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 21
export const accountingToTravelerCheecking = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado')
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              Estimado ${traveler}
              <br>
              <br>
              Le informamos que ya puede consultar su información para realizar su comprobación de gastos, agradeceremos coteje ingresando a la Plataforma de Gestión de Clientes dando clic (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias.
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 22
export const travelerToDirectorCheecking = () => {
  const nameDirectBoss = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>  
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameDirectBoss}
              <br>
              <br>
              Le informamos que ${traveler} realizó su comprobación de gastos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 23
export const travelerToAccountingFinished = () => {
  const nameAccounting = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>  
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameAccounting}
              <br>
              <br>
              Le informamos que ${traveler} realizó su comprobación de gastos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí  (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 24
export const travelerToAreaDirectFinished = () => {
  const areaDirector = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>  
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${areaDirector}
              <br>
              <br>
              Le informamos que ${traveler} realizó su comprobación de gastos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí  (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 25 , Anexo 27, Anexo 29
export const commentsTraveler = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>  
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${traveler}
              <br>
              <br>
              Le informamos que se realizaron comentarios a su comprobación de gastos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí (https://dev-patria.alimx.mx/login. para ir al portal web y link para descargar la aplicación de la tienda correspondiente, Play Store o App store).              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 26
export const directorToAccountingCheecking = () => {
  const accounting = sessionStorage.getItem('nameRol');
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos  ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${accounting}
              <br>
              <br>
              Le informamos que ${traveler} realizó su comprobación de gastos, por lo que agradeceremos ingrese a la Plataforma de Gestión de Clientes dando clic aquí  (https://dev-patria.alimx.mx/)
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 28
export const authorizedExpenses = () => {
  const traveler = sessionStorage.getItem('nombre_Empleado');
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Comprobación de gastos  ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${traveler}
              <br>
              <br>
              Le informamos que su comprobación de gastos ha sido aceptada.           
              <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 30
export const travelAdvisory = (namePayrollUser, traveler, fechIncTravel, fechFincTravel) => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Aviso de viaje ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${namePayrollUser}
              <br>
              <br>
              Le informamos que el usuario ${traveler}, se encontrará de viaje por temas laborales el periodo del ${fechIncTravel} al XXXX ${fechFincTravel}, favor de considerarlo para el control de asistencia de este.                <br>
              <br>
              Muchas gracias."
  
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
//Anexo 31
export const materialRequirement = (nameOfficeUser, traveler, fechIncTravel, fechFincTravel, materialRequirement) => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Aviso de viaje ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameOfficeUser}
              <br>
              <br>
              Le informamos que el usuario ${traveler}, se encontrará de viaje por temas laborales el periodo del ${fechIncTravel} al ${fechFincTravel}, razón por la cual solicita de su soporte con los siguientes recursos:             
              <br>
              <br>
             ${materialRequirement}
             <br>
             <br>
             Por lo que agradeceremos proporcionar dichos recursos 2 días hábiles antes del ${fechIncTravel}.
             <br>
              Muchas gracias."
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}

// Anexo 32
export const materialRequirTI = (nameTIuser, traveler, fechIncTravel, fechFincTravel, materialRequirementTI) => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Aviso de viaje ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${nameTIuser}
              <br>
              <br>
              Le informamos que el usuario ${traveler}, se encontrará de viaje por temas laborales el periodo del ${fechIncTravel} al ${fechFincTravel}, razón por la cual solicita de su soporte con los siguientes recursos:             
              <br>
              <br>
             ${materialRequirementTI}
             <br>
             <br>
             Por lo que agradeceremos proporcionar dichos recursos 2 días hábiles antes del ${fechIncTravel}.
             <br>
              Muchas gracias."
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}
// Anexo 33
export const planningArea = (namePlanningArea, traveler, fechIncTravel, fechFincTravel, requiPlanningArea) => {
  return `
  <!DOCTYPE html>
  <html
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;"
    lang="es">
  
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body
    style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;">
        <tr style="border-collapse:collapse;">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center"
              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
                    <td>
                    <img style="width: 540px; height:137px ; padding:0;Margin:0;" align="center"
                    src="${URL}image/plantillas/header.png">
                    </td>
                    <td>
                          <img src="${URL}image/plantillas/LogoPatriaPlantilla.png" alt="logo" width="27px" height="auto"
                          style="top: 23px; right:62%; z-index: 12; position: absolute;" />
                      </td>
                    <td style="display:flex;">
                        <td>
                            <img src="${URL}image/plantillas/sobrePlantilla.png" alt="" width="83px" height="auto"
                                style="top: 59px; right: 46%; position: absolute;" />
                                </img>
                        </td>
                      
                        
                    </td>
                    
                </td>
            </table>
          </td>
        </tr>
      </table>
      <table class="es-content" cellspacing="0" cellpadding="0" align="center"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important;top: 177px;">
        <tr style="border-collapse:collapse;height: 42px !important;">
          <td style="height: 42px !important;" align="center">
            <span
              style="margin: 0; font-style:normal; font-family: 'Raleway'; font-weight: bold; font-size: 20px; color: #000000; top: 20%;">
              Aviso de viaje ${traveler}</span>
          </td>
        </tr>
        <tr style="border-collapse:collapse; height: 22px !important;">
          <td style="height: 20px !important;" align="center">
            <p style="text-align: justify; font: 400 10px/11.75px 'Raleway';  width: 90%; margin-bottom: 30%;">
              "Estimado ${namePlanningArea}
              <br>
              <br>
              Le informamos que el usuario ${traveler}, se encontrará de viaje por temas laborales el periodo del ${fechIncTravel} al ${fechFincTravel}, razón por la cual solicita de su soporte con los siguientes recursos:             
              <br>
              <br>
             ${requiPlanningArea}
             <br>
             <br>
             Por lo que agradeceremos proporcionar dichos recursos 2 días hábiles antes del ${fechIncTravel}.
             <br>
              Muchas gracias."
            </p>
          </td>
        </tr>
      </table>
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;">
        <tr style="border-collapse: collapse;">
          <td width="100%" style="background-color: #1D539C;" align="center">
            <table class="es-header" cellspacing="0" cellpadding="0" align="center" cellspacing="0" cellpadding="0" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;">
            <img width: 540px; height:137px ; padding:0;Margin:0;" align="center"; z-index: -1;" src="${URL}image/plantillas/footer.png">
              <tr style="border-collapse: collapse;display: flex;
              justify-content: center;">
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/facebook.png" alt="facebook" width="10px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/twitter.png" alt="twitter" width="22px">
                </td>
                <td width="38px" align="center">
                  <img src="${URL}image/plantillas/youtube.png" alt="youtube" width="22px">
                </td>
              </tr>
              <tr style="border-collapse: collapse;">
                <table style="width: 100%;">
                  <tr style="border-collapse: collapse;">
                    <td align="center">
                      <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </td>
      </tr>
      </table>
    </div>
  </body>
  
  </html>
                                        
`
}