const graph = require('@microsoft/microsoft-graph-client');
const { assistantTravelApp, reviewQuotes, quotes, travelerToAccountingCheecking,
     travelerToDirectorCheecking, directorToAccountingCheecking, accountingToTravelerCheecking } = require('../components/Notificaciones/PlantillasNotify')

const getAuthenticatedClient = (accessToken) => {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken);
        }
    });
    return client;
}

export const getUserDetails = async (accessToken) => {
    const client = getAuthenticatedClient(accessToken);

    const user = await client
        .api('/me')
        .select('displayName,mail,mailboxSettings,userPrincipalName')
        .get();
    return user;
}

export const createEvent = async (accessToken, newEvent) => {
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api('me/events')
        .post(newEvent);
}

export const updateEvent = async (accessToken, newEvent, idEvent) => {
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .update(newEvent);
}

export const deleteEvent = async (accessToken, idEvent) => {
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .delete();
}

export const getEvent = async (accessToken, idEvent) => {
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .select('attendees')
        .get();
}

export const sendNotifación = async (accessToken, correo) => {
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/`)
        .post(correo)
}

export const sendMailAttendee = async ({ accessToken, meeting, emailTo, attendeeName, userName, data, data2, numReu }) => {
  

    try {
        const sendMailToAttendee = {
            message: {
                subject: `Solicitud de viajes / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: assistantTravelApp(numReu, data, data2)
                },
                toRecipients: [
                    {
                        emailAddress: {
                            address: emailTo
                        }
                    }
                ],
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToAttendee)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}

export const sendMailDirectorAndAccounting = async ({ accessToken, meeting, emailTo = [], directorName, userName }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Solicitud de viajes / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: reviewQuotes(directorName, userName)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}

export const sendMailAccountingToTraveler = async ({ accessToken, meeting, emailTo = [], traveler, accounting }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Solicitud de viajes / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: quotes(traveler, accounting)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}
export const sendMailTravelerToAccounting = async ({ accessToken, emailTo = [], traveler, accounting }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Comprobación de gastos / ${traveler}`,
                body: {
                    contentType: 'HTML',
                    content: travelerToAccountingCheecking(accounting, traveler)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}
export const sendMailTravelerToDirector = async ({ accessToken, meeting, emailTo = [], traveler, directorName }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Comprobación de gastos  / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: travelerToDirectorCheecking(directorName, traveler)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}
export const sendMailDirectorToAccounting = async ({ accessToken, meeting, emailTo = [], accounting, traveler }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Comprobación de gastos  / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: directorToAccountingCheecking(accounting, traveler)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}
export const sendMailAccountingToTravelerCheecking = async ({ accessToken, meeting, emailTo = [], accounting, traveler }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMailToDirector = {
            message: {
                subject: `Comprobación de gastos  / ${meeting}`,
                body: {
                    contentType: 'HTML',
                    content: accountingToTravelerCheecking(accounting, traveler)                    
                },
                toRecipients,
                //   attachments: [
                //     {
                //       '@odata.type': '#microsoft.graph.fileAttachment',
                //       name: 'attachment.txt',
                //       contentType: 'text/plain',
                //       contentBytes: 'SGVsbG8gV29ybGQh'
                //     }
                //   ]
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMailToDirector)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}