import React, { Fragment } from 'react';
import DivContainer from '../../../layouts/Acuerdos/DivContainer';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';
const stylesDivs = {
  display: 'flex',
  marginLeft: -175
}
const styleP = {
  marginRight: 20,
  fontSize: 18,
  display: 'flex',
  alignItems: 'center'
}
const ResumenReuCasual = ({
  datReu,
  nameCedente,
  eventClick,
  setShowResumen,
  setValue,
  onSave,
}) => {
  return (
    <Fragment>
      <DivContainer title="Reunión Casual" subtitle="Resumen">
        <div style={stylesDivs}>
          <p style={styleP}>Título de la reunión:</p>
          <ButtonNavigation text={datReu.cREU_Titulo} width="25%" />
        </div>
        <div style={stylesDivs}>
          <p style={{...styleP, marginRight: 51}}>Fecha y horario:</p>
          <ButtonNavigation
            text={`${datReu.cREU_FechaInicio} -> [${datReu.cREU_HoraInicio} - ${datReu.cREU_HoraFin}]`}
            width="25%"
          />
        </div>
        <div style={stylesDivs}>
          <p style={{...styleP, marginRight: 48}}>Tipo de reunión:</p>
          <ButtonNavigation text={datReu.cREU_TipoReunion} width="25%" />
        </div>

        {datReu?.cREU_Lugar && (
          <div style={stylesDivs}>
            <p style={{...styleP, marginRight: 94}}>Ubicación:</p>
            <ButtonNavigation text={datReu.cREU_Lugar} width="25%" />
          </div>
        )}
        <div style={stylesDivs}>
          <p style={{...styleP, marginRight: 134}}>Sede:</p>
          <ButtonNavigation text={datReu.cREU_Sede} width="25%" />
        </div>
        <div style={stylesDivs}>
          <p style={{...styleP, marginRight: 70}}>Participantes:</p>          
          <ButtonNavigation text={nameCedente} width="25%" />
        </div>
        <br />
        <br />
        <ButtonNavigation
          text="Continuar"
          width="28.5%"
          backgroundColor="#3898EC"
          color="#FFF"
          border="#3898EC"
          onClick={() => {
            onSave();
          }}
        />
        <ButtonNavigation
          text="Editar información"
          width="28.5%"
          backgroundColor="#FFF"
          color="#3898EC"
          border="#3898EC"
          onClick={() => {
            setValue('');
            setShowResumen(false);
          }}
        />
      </DivContainer>
    </Fragment>
  );
};

export default ResumenReuCasual;
