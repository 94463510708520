import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  AcordeonContainerBody,
  ContainerTwoInputs,
} from '../StylesComponents/common-styled';
import { TextField } from '@material-ui/core';
import FormTypography from './FormsTypography';
import { AddCircle, Delete } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import { MyContext } from '../Context';
import { useMediaQuery } from '@material-ui/core';

const InputTravel = ({
  placeholder,
  name,
  onChange,
  id,
  value,
  type,
  marginLeft = '',
  disabled,
  fullWidth = true,
  width = '',
}) => {
  const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)'); 
  const style = value === '' || value == undefined
    ? null
    : { border: '2px solid green', borderRadius: 4, fontSize: isMobile ? 13 : null }
  return (
    <TextField
      fullWidth={fullWidth}
      style={{ width, marginLeft }}
      inputProps={{
        style
      }}
      variant="outlined"
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      id={`${name}-${id}`}
      value={value}
      type={type}
      disabled={disabled || false}
    />
  );
};

const useFormMateriales = ({
  title,
  text1,
  text2,
  text3,
  cambio,
  asistente,
}) => {
  const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)'); 
  const [materiales, setMateriales] = useState([
    {
      cMAT_Descripcion: '',
      cMAT_Precio: '',
      cMAT_Cantidad: '',
    },
  ]);
  const [requerimientoTI, setRequerimientoTI] = useState([
    {
      cREQT_Descripcion: '',
      cREQT_Precio: '',
      cREQT_Cantidad: '',
    },
  ]);

  const [promocionales, setPromocionales] = useState([
    {
      cPRO_Descripcion: '',
      cPRO_Precio: '',
      cPRO_Cantidad: '',
    },
  ]);
  const context = useContext(MyContext);
  const addPapeleria = () => {
    setMateriales([
      ...materiales,
      { cMAT_Descripcion: '', cMAT_Precio: '', cMAT_Cantidad: '' },
    ]);
  };
  const deletePapeleria = () => {
    materiales.splice(materiales.length - 1, 1);
    setMateriales([...materiales]);
  };
  const addPromocional = () => {
    setPromocionales([
      ...promocionales,
      { cPRO_Descripcion: '', cPRO_Precio: '', cPRO_Cantidad: '' },
    ]);
  };
  const deletePromocional = () => {
    promocionales.splice(promocionales.length - 1, 1);
    setPromocionales([...promocionales]);
  };
  const addRequerimiento = () => {
    setRequerimientoTI([
      ...requerimientoTI,
      { cREQT_Descripcion: '', cREQT_Precio: '', cREQT_Cantidad: '' },
    ]);
  };
  const deleteRequerimiento = () => {
    requerimientoTI.splice(requerimientoTI.length - 1, 1);
    setRequerimientoTI([...requerimientoTI]);
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return '';
  };
  const onChanguePape = (e) => {
    const idElement = String(e.target.id).split('-')[1];
    setMateriales(
      materiales.map((data, index) => {
        if (index === parseInt(idElement)) {
          if (e.target.name === 'cMAT_Precio') {
            let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
            val = formatter.format(filterFloat(val));

            data = { ...data, [e.target.name]: val };
          } else {
            data = { ...data, [e.target.name]: e.target.value };
          }
        }
        return data;
      })
    );
    sessionStorage.setItem(
      'materials',
      JSON.stringify(
        materiales.map((data, index) => {
          if (index === parseInt(idElement)) {
            if (e.target.name === 'cMAT_Precio') {
              let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
              val = formatter.format(filterFloat(val));

              data = { ...data, [e.target.name]: val };
            } else {
              data = { ...data, [e.target.name]: e.target.value };
            }
          }
          return data;
        })
      )
    );
  };

  const onChangueProm = (e) => {
    const idElement = String(e.target.id).split('-')[1]
    setPromocionales(
      promocionales.map((data, index) => {
        if (index === parseInt(idElement)) {
          if (e.target.name === 'cPRO_Precio') {
            let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
            val = formatter.format(filterFloat(val));

            data = { ...data, [e.target.name]: val };
          } else {
            data = { ...data, [e.target.name]: e.target.value };
          }
        }
        return data;
      })
    );
    sessionStorage.setItem(
      'promotionals',
      JSON.stringify(
        promocionales.map((data, index) => {
          if (index === parseInt(idElement)) {
            if (e.target.name === 'cPRO_Precio') {
              let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
              val = formatter.format(filterFloat(val));

              data = { ...data, [e.target.name]: val };
            } else {
              data = { ...data, [e.target.name]: e.target.value };
            }
          }
          return data;
        })
      )
    );
  };

  const onChangueReq = (e) => {
    const idElement = String(e.target.id).split('-')[1]
    setRequerimientoTI(
      requerimientoTI.map((data, index) => {
        if (index === parseInt(idElement)) {
          if (e.target.name === 'cREQT_Precio') {
            let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
            val = formatter.format(filterFloat(val));

            data = { ...data, [e.target.name]: val };
          } else {
            data = { ...data, [e.target.name]: e.target.value };
          }
        }
        return data;
      })
    );
    sessionStorage.setItem(
      'ITrequiremen',
      JSON.stringify(
        requerimientoTI.map((data, index) => {
          if (index === parseInt(idElement)) {
            if (e.target.name === 'cREQT_Precio') {
              let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
              val = formatter.format(filterFloat(val));

              data = { ...data, [e.target.name]: val };
            } else {
              data = { ...data, [e.target.name]: e.target.value };
            }
          }
          return data;
        })
      )
    );
  };

  useEffect(() => {
    setMateriales([
      {
        cMAT_Descripcion: '',
        cMAT_Precio: '',
      },
    ]);
    setRequerimientoTI([
      {
        cREQT_Descripcion: '',
        cREQT_Precio: '',
      },
    ]);

    setPromocionales([
      {
        cPRO_Descripcion: '',
        cPRO_Precio: '',
      },
    ]);
    if (context.state.viaticosProps.cREU_Id !== '') {
      const materialesV = context.state.viaticosProps.materiales.map(
        (material) => {
          return {
            ...material,
            cMAT_Descripcion: material.cMAT_Descripcion,
            cMAT_Precio: material.cMAT_Precio,
            cMAT_Cantidad: material.cMAT_Materiales
          };
        }
      );
      const promocionalesV = context.state.viaticosProps.promocionales.map(
        (promocionalV) => {
          return {
            ...promocionalV,
            cPRO_Descripcion: promocionalV.cPRO_Descripcion,
            cPRO_Precio: promocionalV.cPRO_Precio,
            cPRO_Cantidad:promocionalV.cPRO_Promocional,
          };
        }
      );
      const requerimientosTiV = context.state.viaticosProps.requerimientoTI.map(
        (requerimientoTiV) => {
          return {
            ...requerimientoTiV,
            cREQT_Descripcion: requerimientoTiV.cREQT_Descripcion,
            cREQT_Precio: requerimientoTiV.cREQT_Precio,
            cREQT_Cantidad: requerimientoTiV.cREQT_Requerimiento
          };
        }
      );
      setMateriales(materialesV);
      setPromocionales(promocionalesV);
      setRequerimientoTI(requerimientosTiV);
    }
  }, [cambio]);
  console.log('isMobile', isMobile)
  const stylesAcordeon = isMobile ? {} : { marginLeft: '-17%'}
  return (
    <Fragment>
      <div style={{ marginTop: '20px', width: '90%' }}>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>{title}</h3>
      </div>
      <AcordeonContainerBody flexDirection="column" id="inputs-viaticos" style={{ width: '113%', ...stylesAcordeon }}>
        {materiales.map((material, index) => (
          <AcordeonContainerBody key={index} width="100%" flexWrap="wrap">
            <Fragment>
              <FormTypography text={text1 || 'Cantidad'} width="10%">
                <InputTravel
                
                  width={isMobile?"88%":"85%"}
                  fullWidth={false}
                  placeholder="0"
                  name="cMAT_Cantidad"
                  id={index}
                  onChange={onChanguePape}
                  value={material.cMAT_Cantidad}
                  disabled={asistente}
                  type="number"
                />
              </FormTypography>
              <FormTypography
                text={text1 || 'Materiales y/o papelería'}
                width="40%"
              >
                <InputTravel
                  placeholder="Ej, Pizarrón, papeleria, Tag, etc."
                  name="cMAT_Descripcion"
                  id={index}
                  onChange={onChanguePape}
                  value={material.cMAT_Descripcion}
                  disabled={asistente}
                  width={isMobile?"88%":"90%"}
                />
              </FormTypography>
              <FormTypography
                text={text1 || ' Precio materiales y/o papelería'}
                width="40%"
              >
                <InputTravel
                  // marginLeft='-3%'
                  placeholder="Ingresa el precio del Material"
                  name="cMAT_Precio"
                  id={index}
                  onChange={onChanguePape}
                  value={material.cMAT_Precio}
                  width={isMobile?"88%":"90%"}
                  disabled={asistente}
                />
              </FormTypography>
            </Fragment>
          </AcordeonContainerBody>
        ))}
        {asistente === true ? null : (
          <ContainerTwoInputs width="70%" justifyContent="start">
            <ContainerTwoInputs
              width="22%"
              justifyContent="start"
              onClick={addPapeleria}
              style={{ marginTop: '2%' }}
            >
              <AddCircle style={{ color: green[500] }} />
              <p>Agregar material</p>
            </ContainerTwoInputs>
            {materiales.length > 1 && (
              <ContainerTwoInputs
                width="22%"
                justifyContent="start"
                onClick={deletePapeleria}
                style={{ marginTop: '2%' }}
              >
                <Delete style={{ color: red[500] }} />
                <p>Eliminar material</p>
              </ContainerTwoInputs>
            )}
          </ContainerTwoInputs>
        )}
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection="column" id="inputs-viaticos" style={{ width: '113%', ...stylesAcordeon }}>
        {promocionales.map((prom, index) => (
          <AcordeonContainerBody key={index} width="100%" flexWrap="wrap">
            <Fragment>
              <FormTypography text={text2 || 'Cantidad'} width="10%">
                <InputTravel
                  width={isMobile?"88%":"90%"}
                  fullWidth={false}
                  placeholder="0"
                  name="cPRO_Cantidad"
                  id={index}
                  onChange={onChangueProm}
                  value={prom.cPRO_Cantidad}
                  disabled={asistente}
                  type="number"
                />
              </FormTypography>
              <FormTypography text={text2 || 'Promocionales'} width="40%">
                <InputTravel
                  marginLeft='0%'
                  placeholder="Ingresa el tipo de promocional"
                  name="cPRO_Descripcion"
                  id={index}
                  onChange={onChangueProm}
                  value={prom.cPRO_Descripcion}
                  disabled={asistente}
                  width={isMobile?"88%":"90%"}
                />
              </FormTypography>
              <FormTypography text={text2 || 'Precio promocional'} width="40%">
                <InputTravel
                //  marginLeft='-2%'
                  placeholder="Ingresa el precio del promocional"
                  name="cPRO_Precio"
                  id={index}
                  onChange={onChangueProm}
                  value={prom.cPRO_Precio}
                  // type='number'
                  disabled={asistente}
                  width={isMobile?"88%":"90%"}
                />
              </FormTypography>
            </Fragment>
          </AcordeonContainerBody>
        ))}
        {asistente === true ? null : (
          <ContainerTwoInputs width="70%" justifyContent="start">
            <ContainerTwoInputs
              width="22%"
              justifyContent="start"
              onClick={addPromocional}
              style={{ marginTop: '2%' }}
            >
              <AddCircle style={{ color: green[500] }} />
              <p>Agregar material</p>
            </ContainerTwoInputs>
            {promocionales.length > 1 && (
              <ContainerTwoInputs
                width="22%"
                justifyContent="start"
                onClick={deletePromocional}
                style={{ marginTop: '2%' }}
              >
                <Delete style={{ color: red[500] }} />
                <p>Eliminar material</p>
              </ContainerTwoInputs>
            )}
          </ContainerTwoInputs>
        )}
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection="column" id="inputs-viaticos" style={{ width: '113%', ...stylesAcordeon }}>
        {requerimientoTI.map((req, index) => (
          <AcordeonContainerBody key={index} width="100%" flexWrap="wrap">
            <Fragment>
              <FormTypography text={text2 || 'Cantidad'} width="10%">
                <InputTravel
                  width={isMobile?"88%":"90%"}
                  fullWidth={false}
                  placeholder="0"
                  name="cREQT_Cantidad"
                  id={index}
                  onChange={onChangueReq}
                  value={req.cREQT_Cantidad}
                  disabled={asistente}
                  type="number"
                />
              </FormTypography>
              <FormTypography text={text3 || 'Requerimiento TI'} width="40%">
                <InputTravel
                  marginLeft='0%'
                  placeholder="Paquete celular, cañon, proyector, etc."
                  name="cREQT_Descripcion"
                  id={index}
                  onChange={onChangueReq}
                  value={req.cREQT_Descripcion}
                  disabled={asistente}
                  width={isMobile?"88%":"90%"}
                />
              </FormTypography>
              <FormTypography
                text={text3 || 'Precio requerimiento TI'}
                width="40%"
              >
                <InputTravel
                  placeholder="Ingresa el precio del requerimiento TI"
                  name="cREQT_Precio"
                  id={index}
                  onChange={onChangueReq}
                  value={req.cREQT_Precio}
                  // type='number'
                  // marginLeft='-1%'
                  width={isMobile?"88%":"90%"}
                  disabled={asistente}
                />
              </FormTypography>
            </Fragment>
          </AcordeonContainerBody>
        ))}
        {asistente === true ? null : (
          <ContainerTwoInputs width="70%" justifyContent="start">
            <ContainerTwoInputs
              width="22%"
              justifyContent="start"
              onClick={addRequerimiento}
              style={{ marginTop: '2%' }}
            >
              <AddCircle style={{ color: green[500] }} />
              <p>Agregar material</p>
            </ContainerTwoInputs>
            {requerimientoTI.length > 1 && (
              <ContainerTwoInputs
                width="22%"
                justifyContent="start"
                onClick={deleteRequerimiento}
                style={{ marginTop: '2%' }}
              >
                <Delete style={{ color: red[500] }} />
                <p>Eliminar material</p>
              </ContainerTwoInputs>
            )}
          </ContainerTwoInputs>
        )}
      </AcordeonContainerBody>
    </Fragment>
  );
};

export default useFormMateriales;
