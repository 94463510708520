import React, { Fragment, useState } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';

const Comments = ({ titles = [], onClick, paramsOnclick = {} }) => {
    const [text, setText] = useState({})
    const [multiComments, setMultiComments] = useState(['','',''])
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
            }}
        >
            {titles.length > 0 ? (
                titles.map((title, index) => (
                    <Fragment>
                        <span style={{ padding: '15px 0' }}>{title}</span>
                        <TextareaAutosize   
                            onChange={({target}) => {
                                setText({
                                    ...text,
                                    [title]: target.value
                                })
                                multiComments[index] = target.value;
                                setMultiComments(multiComments);
                            }}                     
                            rowsMin={6}
                            style={{ width: '90%' }}
                            id="text-mensaje"
                            name="area"
                            placeholder="Escribe tus comentarios"
                        />
                    </Fragment>
                ))
            ) : null}
            {Object.keys(text).length > 0 && (
                <ButtonNavigation
                backgroundColor='#3898EC'
                border='#3898EC'
                text="Guardar y continuar"
                width="90%"
                style={{marginTop: 20}}
                onClick={() => {
                    onClick({paramsOnclick, multiComments})
                }}
                />
            )}
           
        </div>
    );
}

export default Comments;