import React, { useEffect, useState, Fragment } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import '../components.css';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MultipleSelect = ({ width = '', placeholder = '', values = [], options = [], selectedOptions = [] }) => {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        selectedOptions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <FormControl style={{ width }}>
            <InputLabel id="creu-emp">{placeholder}</InputLabel>
            <Select
                labelId="creu-emp"
                id="creu-multiple-checkbox"
                multiple
                value={values}
                onChange={handleChange}
                input={<OutlinedInput label="Reuniones" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {options.map(({ cREU_Id, cREU_Titulo }) => (
                    <MenuItem key={cREU_Id} value={cREU_Id}>
                        <Checkbox checked={values.indexOf(cREU_Id) > -1} />
                        <ListItemText primary={cREU_Titulo}  primaryTypographyProps={{ style: {fontSize: 12}}} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultipleSelect;
