import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import { PageContainer, ContainerTwoInputs } from '../StylesComponents/common-styled';
import { getcTOBS_Id, getRol } from '../../funciones-auxiliares/viaticos';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import { GET_SPENDING_LIMIT, GET_EVENTO_BY_CREU_ID, GET_TIPO_CAMBIO } from '../../query/query';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import MaterialTable from 'material-table';
import { MyContext } from '../Context';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE } from '../../mutation/mutation';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DialogAlert from '../DiaologAlert';
import RolSelection from '../Acuerdos/common/RolSelection';
import { sendEmail } from '../../services/SendEmails';
import CardListReuniones from './CardListReuniones';
import Card from '../Viaticos/Card';
import EditableTableExpenses from './EditableTableExpenses';
import { useMediaQuery } from '@material-ui/core';
const ROLESVSRESPONSE = {
    'Sin cambio': '63',
    'Cambio': '62',
    Cancelacion: '62'
}
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
        return __val__;
    }
    return '';
};
const TravelUpdates = ({ cEMP }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const context = useContext(MyContext);
    const [meetings, setMeetings] = useState([]);
    const [empleado, setEmpleado] = useState([]);
    const [reunion, setReunion] = useState([]);
    const [showCards, setShowCards] = useState(false);    
    const [dataTable, setDataTable] = useState([
        { concepto: 'Hospedaje', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
        { concepto: 'Renta de automóvil', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
        { concepto: 'Lavandería', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
        { concepto: 'Alimentación', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
        { concepto: 'Propinas', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
        { concepto: 'Comisión por disposición de efectivo', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: '' },
        { concepto: 'Gastos de representación', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No hay limite' },
        { concepto: 'Otros (especificar)', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No hay limite' },
        { concepto: 'Total', personas: '', nDias: '', importeUSD: 0, tipoCambio: '', importeMXP: 0, limit: 'No' },
    ]);
    const [changes, setChanges] = useState('');
    const [reasons, setReasons] = useState('');
    const [observations, setObservations] = useState('');
    const [showModalErr, setShowModalErr] = useState(false);
    const [reasonOfExit, setReasonOfExit] = useState('')
    const [styleDisabled, setStyleDisabled] = useState({})
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const { loading, data } = useQuery(GET_SPENDING_LIMIT, {
        variables: {
            cEMP_Id: cEMP.cEMP_Id
        }
    });
    const { loading: isLoading, data: tipoCambio } = useQuery(GET_TIPO_CAMBIO, {
        variables: {
            currentDate: "202205",
            country: "Estados Unidos"
        }
    });

    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            setShowCards(true);
        },
    });
    const [insertExpenses] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            console.log('insert', dynamicInsert);
        },
        onError(err) {
            console.log(err)
        }
    })
    const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
        onCompleted({ dynamicUpdate }) {
            if (changes === 'Sin cambio') {
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado a jefe directo'
                });
                setShowModalErr(true);
            } else {
                setMsgModalErr({
                    title: 'Información guardada',
                    text: 'Ticket enviado al asistente'
                })
                setShowModalErr(true);
            }
        },
        onError(err) {
            console.log(err)
        }
    });

    const filterPolNational = (pol, importeMXP, days = '') => {
        days = days === '' ? 1 : days;
        const { getSpendingLimitByUser } = data;
        const result = getSpendingLimitByUser.filter(({ cPOL_Nombre }) => cPOL_Nombre === pol);
        if (result.length > 0) {
            const nationalLimit = parseInt(days) * result[0].aGAS_MontoNacional;
            return importeMXP > nationalLimit;
        }
        return false;
    }
    const filterPolInter = (pol, importeUSD, days = '') => {
        days = days === '' ? 1 : days;
        const { getSpendingLimitByUser } = data;
        const result = getSpendingLimitByUser.filter(({ cPOL_Nombre }) => cPOL_Nombre === pol);
        if (result.length > 0) {
            const internationalLimit = parseInt(days) * result[0].aGAS_MontoInternacional;
            return importeUSD > internationalLimit;
        }
        return false;
    }
    const saveExpenses = async () => {
        const detailValues = [];
        dataTable.forEach((row) => {
            const { concepto, personas, importeUSD, tipoCambio, importeMXP, limit, nDias } = row;
            if (concepto !== 'Total') {
                detailValues.push({
                    values: [concepto, personas, importeUSD, String(tipoCambio), importeMXP, limit, String(context.state.viaticosProps.cVIT_Id), String(nDias)]
                })
            }
        })
        await insertExpenses({
            variables: {
                input: {
                    table: 'cGASXVIT_Gastos',
                    columns: ['cGASVIT_Concepto', 'cGASVIT_NPersonas', 'cGASVIT_ImporteUSD', 'cGASVIT_TipoCambio', 'cGASVIT_ImporteMXP',
                        'cGASVIT_ExcedePoliticas', 'cVIT_Id', 'cGASVIT_nDias'],
                    detailValues,
                }
            }
        })
        return true;
    }
    const saveObservations = async () => {
        if (observations !== '') {
            const input = {
                table: 'cOBS_ObservacionesViaticos',
                columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
                detailValues: [
                    {
                        values: [
                            String(context.state.viaticosProps.cVIT_Id),
                            String(cEMP.cEMP_Id),
                            observations,
                            String(getcTOBS_Id(cEMP.profile)),
                        ]
                    },
                ]
            };
            await insertExpenses({ variables: { input } });
            return true;
        } else if (changes === 'Cambio' || changes === 'Cancelacion') {
            setMsgModalErr({
                ...msgModalErr,
                text: 'Campo obligatorio: Observaciones'
            })
            setShowModalErr(true);
            setStyleDisabled({})
            return false;
        }
        return true
    }
    const saveReasons = async () => {
        if (reasons !== '') {
            const input = {
                table: 'cCAM_CambiosViatico',
                columns: ['cCAM_Cambio', 'cCAM_Motivo'],
                detailValues: [
                    {
                        values: [changes, reasons]
                    },
                ],
            }
            await insertExpenses({ variables: { input } });
            return true;
        } else {
            setMsgModalErr({
                ...msgModalErr,
                text: 'Selecciona un motivo de cambio/cancelación'
            })
            setShowModalErr(true);
            return false;
        }
    }
    const updateVit = async (isCanceled = false) => {
        let columns = [
            {
                setkey: 'cVIT_Avance',
                setval: '6',
            },
        ];
        let conditions = [
            {
                valone: 'cVIT_Id',
                valtwo: String(context.state.viaticosProps.cVIT_Id),
                condition: '=',
                logic: 'AND',
            },
        ];
        if (isCanceled) {
            columns.push({
                setkey: 'cVIT_Status_VIAT',
                setval: '3',
            })
        }
        await updateStatus({
            variables: {
                input: {
                    table: 'cVIT_Viaticos',
                    columns,
                    conditions
                }
            }
        })
    }
    const sendTicket = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' });
        const isSavedObservations = await saveObservations();
        if (isSavedObservations) {
            const token = context.state.AuthComponentProps.token;
            let isSavedReasons = false;
            let isSavedExpenses = false;
            if (changes === 'Sin cambio') {
                isSavedExpenses = saveExpenses();
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionSix'
                });
            } else if (changes === 'Cambio' || changes === 'Cancelacion') {
                isSavedReasons = await saveReasons();
            } else {
                setMsgModalErr({
                    ...msgModalErr,
                    text: 'Selecciona Cambios/cancelación'
                });
                setShowModalErr(true);
                setStyleDisabled({})
                return;
            }
            if (isSavedReasons || isSavedExpenses) {
                if (isSavedObservations) updateVit();
                if (changes === 'Cambio') {
                    await sendEmail({
                        accessToken: token,
                        emailTo: [sessionStorage.getItem('emailTo')],
                        subject: 'Solicitud de viaticos',
                        section: 'sectionSix_two'
                    });
                } else if (changes === 'Cancelacion') {
                    const isCanceled = true;
                    updateVit(isCanceled);
                    await sendEmail({
                        accessToken: token,
                        emailTo: [sessionStorage.getItem('emailTo')],
                        subject: 'Solicitud de viaticos',
                        section: 'sectionSix_three'
                    });
                }
            }
        }
    }   
    useEffect(() => {
        if (
            context.state.viaticosProps.cVIT_Id !== '' &&
            context.state.viaticosProps.reuniones
        ) {
            getEventBycREU({
                variables: { cREU_Id: context.state.viaticosProps.reuniones },
            });
        }
    }, []);
    if (loading && isLoading) {
        return (
            <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                    <CircularProgress disableShrink size={100} />
                </div>
            </>
        )
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <Box m={0} marginTop={0}>
                <CardListReuniones meetings={meetings} setEmpleado={setEmpleado} setReunion={setReunion} />
                <br />
                {(showCards) && (
                    <ContainerTwoInputs
                        width="100%"
                        margin="20px 0px"
                        id="viaticos-solicitud"
                    >

                        <Card data={reunion} img={calendario} />
                        <Card data={empleado} img={user} />
                    </ContainerTwoInputs>
                )}
                <p style={{ fontSize: 18 }}>Número de anticipo: <span style={{ fontWeight: 'bolder' }}>{context.state.viaticosProps.cVIT_Id}</span></p>
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ textAlign: 'left' }}>Motivo de salida</h3>
                </div>
                <TextareaAutosize
                    minRows={10}
                    style={{ width: '100%' }}
                    id="text-mensaje-motivo"
                    name="MotivoSalida"
                    onChange={({ target }) => setReasonOfExit(target.value)}
                    value={reasonOfExit}
                    placeholder="Escribe el motivo del viaje ..."
                />
                <FormControl fullWidth>
                    <InputLabel id="changes">Cambios/cancelación</InputLabel>
                    <Select
                        labelId="changes"
                        id="changesSelect"
                        value={changes}
                        label="Cambios/Cancelación"
                        onChange={({ target }) => setChanges(target.value)}
                    >
                        <MenuItem value=''>--Selecciona una opción--</MenuItem>
                        <MenuItem value='Sin cambio'>Sin cambio</MenuItem>
                        <MenuItem value='Cambio'>Cambio</MenuItem>
                        <MenuItem value='Cancelacion'>Cancelación</MenuItem>
                    </Select>
                </FormControl>
                {(changes === 'Cambio' || changes === 'Cancelacion') && (
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <InputLabel id="changes">Motivos de cambio/cancelación</InputLabel>
                        <Select
                            labelId="changes"
                            id="changesSelect"
                            value={reasons}
                            label="Cambios/Cancelación"
                            onChange={({ target }) => setReasons(target.value)}
                        >
                            <MenuItem value=''>--Selecciona una opción--</MenuItem>
                            <MenuItem value='Asuntos personales'>Asuntos personales</MenuItem>
                            <MenuItem value='Asuntos del cliente'>Asuntos del cliente</MenuItem>
                            <MenuItem value='Asuntos de la aerolínea'>Asuntos de la aerolínea</MenuItem>
                            <MenuItem value='Asuntos de Patria'>Asuntos de Patria</MenuItem>
                        </Select>
                    </FormControl>
                )}
                {changes === 'Sin cambio' && (
                    <EditableTableExpenses
                        dollar={tipoCambio}                        
                        filterPolInter={filterPolInter}
                        filterPolNational={filterPolNational}
                        dataTable={dataTable}
                        setDataTable={setDataTable}
                    />                    
                )}
                {context.state.viaticosProps.observaciones.map((data, index) => {
                    if (data.cTOBS_Id == 1) {
                        sessionStorage.setItem('nombre_Empleado', data.cEMP_Nombre);
                    }
                    return (
                        <Fragment key={index}>
                            <div style={{ marginTop: '20px' }}>
                                <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                    data.cTOBS_Id
                                )}`}</h3>
                            </div>
                            <TextareaAutosize
                                minRows={10}
                                style={{ width: '100%' }}
                                id="text-mensaje"
                                name="area"
                                value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                                disabled
                            />
                        </Fragment>
                    );
                })}
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
                </div>
                <TextareaAutosize
                    rowsMin={10}
                    style={{ width: '100%' }}
                    id="text-mensaje"
                    name="observaciones"
                    onChange={({ target }) => setObservations(target.value)}
                    value={observations}
                    placeholder="Escribe comentarios del viaje ..."
                />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <RolSelection profile={ROLESVSRESPONSE[changes]} />
                    <ButtonNavigation
                        text="Guardar"
                        width={isMobile ? "100%" : "50%"}
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        justifyContent="center"
                        onClick={sendTicket}
                        style={styleDisabled}
                    />
                </div>
            </Box>
        </PageContainer >
    )
}

export default TravelUpdates;