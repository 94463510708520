import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import {
  DINAMIC_GET,
  DYNAMIC_UPDATE,
  GENERIC_QUERY,
} from '../../mutation/mutation';
import DatePicker from 'react-date-picker';
import { NativeSelect, Chip } from '@material-ui/core';
import '../components.css';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

//components
import LastStep from './componentsRelacion/LastStep';
import LastStepWithStatus from './componentsRelacion/LastStepWithStatus';
import LastStepWithLabel from './componentsRelacion/LastStepWithLabel';
import Questions from './componentsRelacion/Questions';
import Comments from './componentsRelacion/Comments';
import QuestionsOptions from './componentsRelacion/QuestionsOptions';
import Comentarios from './Comentarios';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import AreasInvV2 from './common/AreasInvV2';
import LastStepWithLabelV2 from './componentsRelacion/LastStepWithLabelV2';
import { LabelCard } from '../StylesComponents/common-styled';
import { TextareaAutosize } from '@material-ui/core';
import { MyContext } from '../Context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
const RelacionV2 = ({
  onClick,
  onClickGoSegRelPa2,
  setAuxPLDIMP,
  onClickSegRelPaDos,
}) => {
  const classes = useStyles();
  const contextSet = useContext(MyContext);
  const [currentQuestion, setCurrentQuestion] = useState('tema');
  const [status, setStatus] = useState('');
  const [step, setStep] = useState(1);
  const [showTitle, setShowTitle] = useState(true);
  const [options, setOptions] = useState([]);
  const [saveAdvanceQuestion, setAdvanceQuestions] = useState([]);
  const [saveAdvanceQuestion2, setAdvanceQuestions2] = useState([]);
  const [limitDate, setLimitDate] = useState(new Date());
  const [disabledOne, setDisabledOne] = useState(true);
  const [formAreas, setFormAreas] = useState({
    Direccion: '',
    Finanzas: '',
    Operaciones: '',
    Suscripcion: '',
  });
  const [relacionDatos, setRelacionDatos] = useState({
    tipoRelacion: '',
    cRELP_Id: ''
  })
  const [areas, setAreas] = useState([]);
  const [questionComplete, setQuestionComplete] = useState('');
  const [commentPresentPatria, setCommentPresentPatria] = useState(contextSet.state.saveSegRelPatria.commentOne);
  const [arrayQuestions] = useState({
    tema: '¿De qué tema deseas hacer tu registro?',
    programaReaseguro: 'Fecha límite establecida para entrega de la propuesta.',
    facultativo: '¿Qué deseas registrar sobre Facultativo?',
    siniestros: 'Escribe lo comentado sobre Siniestros',
    pendientesPatria: '¿Sobre qué son los Pendientes con Patria?',
    presentacionPatria: '¿Qué deseas registrar sobre Presentación Patria',
    capacitacionSeminarios:
      '¿Qué deseas registrar sobre Capacitación / Seminarios?',
    impuestoRetenidoExt:
      '¿Cuál es el estatus del Impuesto retenido en el extranjero?',
    expedientePLD: '¿Cuál es el estatus del Expediente PLD?',

    afterLimitDate: '¿Qué deseas registrar sobre Programa de Reaseguro?',
    resultados: 'Escribe lo comentado sobre Presentación Patria',
    estrategia: '¿Sobre qué tema de Estrategia?',
    fortalezasAndAreasMejora: '',
    platicaPresentacion: '¿Sobre qué tema es la Plática/Presentación?',
    considerarlosProxSemAndOtro: '',
    statusImpuestoRetenidoExt: '',
    statusExpedientePLD: 'Escribe lo comentado sobre Expediente PLD',
    afterSobrePendientesPatria:
      'Escribe lo comentado sobre Pendientes con Patria',
    sinCambios: 'Escribe lo comentado sobre Programa de Reaseguro',
    cambios: '¿Sobre qué tema son los cambios?',
    oportunidadesPatria: '¿Sobre qué son las Oportunidades para Patria?',
    liderYOtros: 'Escribe lo comentado sobre Programa de Reaseguro',
    afterMultComments: 'Escribe lo comentado sobre Facultativo',
    afterDropdownStep3: 'Escribe lo comentado sobre Pendientes con Patria',
    afterTemaPlaticaPresent: 'Escribe lo comentado sobre Plática/Presentación',

    afterResultadosComments: 'Resumen de Relación con Patria ',
    afterQuestionsEstrategia: 'Escribe lo comentado sobre Presentación Patria',
    afterQuestFortalAndAreasMejora:
      'Escribe lo comentado sobre Presentación Patria',

    afterEstrategiaComments: 'Resumen de Relación con Patria ',
    resumenConsideacionAndOtro: 'Resumen de Relación con Patria',
    afterDesplegPlaticaPrese: 'Resumen de Relación con Patria',
    cambiosComments: 'Escribe lo comentado sobre Programa de Reaseguro',
    afterCampoAbiertoPlaticaPresent: 'Resumen de Relación con Patria',
    afterCampoAbiertoConsideracion: 'Resumen de Relación con Patria',

    contratosActuales: 'Contratos Actuales',
    despuesContratosAct: 'Escribe lo comentado sobre Programa de Reaseguro',
    ContratoPatriaNoParticipa: '',
    despuesNuevosContratos: 'Escribe lo comentado sobre Programa de Reaseguro',
    despuesNuevosContratosStep5:
      'Escribe lo comentado sobre Programa de Reaseguro',
    nuevosContratos: '',
  });
  const [titles] = useState({
    suscripcion: ['Políticas de suscripción', 'Exclusiones', 'Restricciones'],
    apetitoRiesgos: ['Giros/Carteras', 'Coberturas', 'Límites'],
    necesidades: ['Capacidad', 'Precio', 'Términos y Condiciones'],
  });

  const input = useMemo(() => ({
    table: 'cRELP_RelacionPatria',
    columns: ['cRELP_Id', 'cRELP_Descripcion'],
  }));

  const [contratos, setContratos] = useState([]);

  const [saveComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const [updateAcuerdos] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('update acuerdo ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error update acuerdo -->>', err);
    },
  });

  const [getRelacion] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        setOptions(JSON.parse(dynamicGet.response));
      }
    },
  });
  const [getContratos] = useMutation(GENERIC_QUERY, {
    onCompleted({ genericQuery }) {
      console.log('genericQuery', genericQuery);
      let { recordset } = JSON.parse(genericQuery.response);
      setContratos(recordset);
    },
  });

  const handleChange = ({ target }) => {
    console.log('handleChange', target.value);
    const { name, value } = target;
    setFormAreas({
      ...formAreas,
      [name]: value,
    });
  };

  const handleOnClickBack = (currentQuestion) => {
    // let index = saveAdvanceQuestion2.indexOf(currentQuestion);
    // let stepAux = index === 0 ? index : index - 1;
    // console.log('stepAux', stepAux);

    console.log("QUESTION", saveAdvanceQuestion2[(step - 1) - 1], "PASO", step - 1);
    console.log("ARRAY RELP", saveAdvanceQuestion);

    setCurrentQuestion(saveAdvanceQuestion2[(step - 1) - 1]);
    // saveAdvanceQuestion2[saveAdvanceQuestion2.length].pop()
    saveAdvanceQuestion.pop()
    saveAdvanceQuestion2.pop()
    
    setAdvanceQuestions(saveAdvanceQuestion)
    setAdvanceQuestions2(saveAdvanceQuestion2)
    setStep(step - 1)

    if (step - 1 <= 1) {
      setCurrentQuestion('tema');
      setAdvanceQuestions2(['tema']);
      setStep(1);
    }
    // if (index <= 0) {
    //   setCurrentQuestion('tema');
    //   setStep(step - 1);
    //   saveAdvanceQuestion2.splice(index, 1);
    // } else {
    //   setCurrentQuestion(saveAdvanceQuestion2[stepAux]);
    //   if (
    //     currentQuestion === 'suscripcion' ||
    //     currentQuestion === 'apetitoRiesgos' ||
    //     currentQuestion === 'necesidades' ||
    //     currentQuestion === 'afterCampoAbiertoConsideracion'
    //   ) {
    //     setShowTitle(true);
    //   }
    //   setStep(step - 1);
    //   saveAdvanceQuestion2.splice(index, 1);
    // }
  };

  // const handleOnClickBack = (currentQuestion) => {
  //   if (currentQuestion === '') {
  //     backRelacionPatria();
  //     sessionStorage.setItem('returnSegRelPa', 'true');
  //   }
  //   let index = saveAdvanceQuestion.indexOf(currentQuestion);
  //   let stepAux = index === 0 ? index : index - 1;
  //   if (index === 0) {
  //     setCurrentQuestion('');
  //     saveAdvanceQuestion.splice(index, 1);
  //   } else {
  //     setCurrentQuestion(saveAdvanceQuestion[stepAux]);
  //     saveAdvanceQuestion.splice(index, 1);
  //   }
  // };

  const backRelacionPatria = () => {
    setFormAreas({});
    setAreas([]);
    setCurrentQuestion('tema');
    setAdvanceQuestions([]);
  };

  const deleteSubarea = (area) => {
    setAreas(areas.filter((item) => item !== area));
  };

  //funciones para el avance de preguntas
  const handleQuestionsAdvanceStep1 = ({ idOption = 0, description = '' }) => {
    setStep(2);

    if (currentQuestion !== '' && currentQuestion !== undefined)
      setAdvanceQuestions2([currentQuestion])
    else
      setAdvanceQuestions2(['tema'])

    switch (idOption) {
      case 1:
        setCurrentQuestion('programaReaseguro');
        setAdvanceQuestions(['Programa Reaseguro']);
        break;
      case 2:
        setCurrentQuestion('facultativo');
        setAdvanceQuestions(['Facultativo']);
        break;
      case 3:
        setCurrentQuestion('siniestros');
        setAdvanceQuestions(['Siniestros']);
        break;
      case 4:
        setCurrentQuestion('pendientesPatria');
        setAdvanceQuestions(['Pendientes Patria']);
        break;
      case 5:
        setCurrentQuestion('presentacionPatria');
        setAdvanceQuestions(['Presentacion Patria']);
        break;
      case 6:
        setCurrentQuestion('capacitacionSeminarios');
        setAdvanceQuestions(['Capacitacion/Seminarios']);
        break;
      case 7:
        setCurrentQuestion('impuestoRetenidoExt');
        setAdvanceQuestions(['Impuesto Retenido Ext']);
        break;
      case 8:
        setCurrentQuestion('expedientePLD');
        setAdvanceQuestions(['Expediente PLD']);
        break;
      default:
        break;
    }
  };
  const handleQuestionsAdvanceStep2 = ({ idOption = 0, description = '', descStep = '' }) => {
    setStep(3);
    setShowTitle(true);
    let idOptAux = idOption;
    if (idOptAux === 4 || idOptAux === 5)
      idOptAux = 'afterSobrePendientesPatria';
    if (idOptAux === 10 || idOptAux === 11) {
      arrayQuestions.fortalezasAndAreasMejora = `¿Sobre qué ${idOptAux === 10 ? 'Fortaleza' : 'Área de mejora'
        } se comentó?`;
      idOptAux = 'fortalezasAndAreasMejora'; //Fortaleza/Area de mejora
    }
    if (idOptAux === 12 || idOptAux === 14) {
      arrayQuestions.considerarlosProxSemAndOtro = `Escribe lo comentado sobre ${description}`;
      idOptAux = 'considerarlosProxSemAndOtro';
    }
    if (idOptAux === 15 || idOptAux === 16) {
      arrayQuestions.statusImpuestoRetenidoExt =
        idOptAux === 15
          ? `Escribe lo comentado sobre Impuesto Retenido en el Extranjero`
          : `Documentos Requeridos`;
      setStatus(description);
      setAuxPLDIMP('impuestoRetenido');
      localStorage.setItem('statusEXPIMP', description);
      idOptAux = 'statusImpuestoRetenidoExt';
    }
    if (idOptAux === 17 || idOptAux === 18 || idOptAux === 19) {
      setStatus(description);
      setAuxPLDIMP('expPLD');
      localStorage.setItem('statusEXPIMP', description);
      idOptAux = 'statusExpedientePLD';
    }
    switch (idOptAux) {
      case 122:
        setCurrentQuestion('suscripcion');
        setShowTitle(false);
        setAdvanceQuestions([...saveAdvanceQuestion, 'suscripcion']);
        break;
      case 22:
        setCurrentQuestion('apetitoRiesgos');
        setShowTitle(false);
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      case 32:
        setCurrentQuestion('necesidades');
        setShowTitle(false);
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      case 'afterLimitDate':
        setCurrentQuestion('afterLimitDate');
        setAdvanceQuestions([...saveAdvanceQuestion, 'afterLimitDate']);
        break;
      case 'afterSobrePendientesPatria':
        setCurrentQuestion('afterSobrePendientesPatria');
        setAdvanceQuestions([
          ...saveAdvanceQuestion,
          description,
        ]);
        break;
      case 6:
        setCurrentQuestion('resultados');
        setAdvanceQuestions([...saveAdvanceQuestion, 'Resultados']);

        break;
      case 7:
        setCurrentQuestion('estrategia');
        setAdvanceQuestions([...saveAdvanceQuestion, 'Estrategia']);
        break;
      case 8:
        setCurrentQuestion('resultados');
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      case 9:
        setCurrentQuestion('resultados');
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      case 'fortalezasAndAreasMejora':
        setCurrentQuestion('fortalezasAndAreasMejora');
        setAdvanceQuestions([
          ...saveAdvanceQuestion,
          description,
        ]);
        break;
      case 'considerarlosProxSemAndOtro':
        setCurrentQuestion('considerarlosProxSemAndOtro');
        setAdvanceQuestions([
          ...saveAdvanceQuestion,
          description,
        ]);
        break;
      case 13:
        setCurrentQuestion('platicaPresentacion');
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      case 'statusImpuestoRetenidoExt':
        setCurrentQuestion('statusImpuestoRetenidoExt');
        setAdvanceQuestions([
          ...saveAdvanceQuestion,
          description,
        ]);
        break;
      case 'statusExpedientePLD':
        setCurrentQuestion('statusExpedientePLD');
        setAdvanceQuestions([...saveAdvanceQuestion, description]);
        break;
      default:
        break;
    }

    setAdvanceQuestions2([...saveAdvanceQuestion2, currentQuestion])

  };

  const handleQuestionsAdvanceStep3 = ({ idOption = 0, description = '' }) => {
    console.log('handleQuestionsAdvanceStep3', idOption, description);
    setStep(4);
    setShowTitle(true);
    let idOptAux = idOption;
    if (idOptAux >= 401 && idOptAux <= 404)
      idOptAux = 'afterQuestionsEstrategia';
    if (idOptAux >= 405 && idOptAux <= 409)
      idOptAux = 'afterQuestFortalAndAreasMejora';
    if (idOptAux >= 410 && idOptAux <= 412)
      idOptAux = 'afterTemaPlaticaPresent';

    setAdvanceQuestions([...saveAdvanceQuestion, description]);
    setAdvanceQuestions2([...saveAdvanceQuestion2, currentQuestion]);
    switch (idOptAux) {
      case 133:
        setCurrentQuestion('sinCambios');

        break;
      case 134:
        setCurrentQuestion('cambios');
        break;
      case 135:
        setCurrentQuestion('oportunidadesPatria');
        break;
      case 136:
        setCurrentQuestion('liderYOtros');
        break;
      case 'afterMultComments':
        setCurrentQuestion('afterMultComments');
        break;
      case 'afterDropdownStep3':
        setCurrentQuestion('afterDropdownStep3');
        break;
      case 'afterResultadosComments':
        setCurrentQuestion('afterResultadosComments');
        break;
      case 'afterQuestionsEstrategia':
        setCurrentQuestion('afterQuestionsEstrategia');
        break;
      case 'afterQuestFortalAndAreasMejora':
        setCurrentQuestion('afterQuestFortalAndAreasMejora');
        break;
      case 'afterCampoAbiertoConsideracion':
        setCurrentQuestion('afterCampoAbiertoConsideracion');
        setShowTitle(false);
        break;
      case 'afterTemaPlaticaPresent':
        setCurrentQuestion('afterTemaPlaticaPresent');
        break;

      default:
        break;
    }
    setAdvanceQuestions2([...saveAdvanceQuestion2, currentQuestion]);
  };

  const handleQuestionsAdvanceStep4 = ({ idOption = 0, description = '' }) => {
    setStep(5);
    setShowTitle(true);
    let idOptAux = idOption;
    if (idOptAux >= 201 && idOptAux <= 208) {
      idOptAux = 'cambiosComments';
    }
    setAdvanceQuestions([...saveAdvanceQuestion, description]);
    setAdvanceQuestions2([...saveAdvanceQuestion2, currentQuestion]);
    switch (idOptAux) {
      case 'cambiosComments':
        setCurrentQuestion('cambiosComments');

        break;
      case 'afterEstrategiaComments':
        setCurrentQuestion('afterEstrategiaComments');
        break;
      case 'desplegableAfterCampoAbierto':
        setCurrentQuestion('resumenConsideacionAndOtro');
        break;
      case 'resumenConsideacionAndOtro':
        setCurrentQuestion('resumenConsideacionAndOtro');
        break;
      case 'afterCampoAbiertoPlaticaPresent':
        setCurrentQuestion('afterCampoAbiertoPlaticaPresent');
        break;
      case 209:
        setCurrentQuestion('contratosActuales');
        break;
      case 210:
        setCurrentQuestion('nuevosContratos');
        break;
      default:
        break;
    }
  };

  const handleQuestionsAdvanceStep5 = ({ idOption = 0, description = '' }) => {
    setStep(6);
    setShowTitle(true);
    setAdvanceQuestions2([...saveAdvanceQuestion2, currentQuestion]);
    switch (description) {
      case 'afterDesplegPlaticaPrese':
        setCurrentQuestion('afterDesplegPlaticaPrese');
        break;
      case 'despuesContratosAct':
        setCurrentQuestion('despuesContratosAct');
        break;

      case 'ContratoPatriaNoParticipa':
        setCurrentQuestion('ContratoPatriaNoParticipa');
        setAdvanceQuestions([
          ...saveAdvanceQuestion,
          'Contrato Patria No Participa',
        ]);
        break;
      default:
        break;
    }
  };

  function saveRelacion() {
    // console.log("PRE ADVANCED", saveAdvanceQuestion);

    for (let i = 0; i < (5 - saveAdvanceQuestion.length); i++) {
      saveAdvanceQuestion.push('');
    }

    // console.log("SET ADVANCED", saveAdvanceQuestion);
    setAdvanceQuestions(saveAdvanceQuestion);

    const input = {
      table: 'cACU_CuerdosVieje',
      columns: saveAdvanceQuestion.filter(str => !str.includes('after') || !str).map((str, index) => ({
        setkey: `cRELP_${index + 1}`,
        setval: str,
      })),
      conditions: [
        {
          valone: 'cACU_Id',
          valtwo: '' + contextSet.state.saveAcuerdos.cACU_Id,
          condition: '=',
        },
      ],
    };
    console.log('update input : ', input)
    contextSet.setRelacionData(relacionDatos)
    updateAcuerdos({ variables: { input } });
  }

  useEffect(() => {
    setAdvanceQuestions([]);
    setAdvanceQuestions2([]);
    // console.log("ADVANCEQUESTIONS RESET");
    getRelacion({ variables: { input } });
    contextSet.setSaveSegRelPatria({
      ...contextSet.state.saveSegRelPatria,
      areas: [],
      subAreas: [],
    })
  }, []);

  useEffect(() => {
    const valuesObj = Object.values(formAreas);
    setAreas(valuesObj.filter((val) => val !== ''));
  }, [formAreas]);

  useEffect(() => {
    // console.log('saveee', JSON.stringify([...saveAdvanceQuestion]));

    console.log("VALORES PREVIOS", saveAdvanceQuestion2, step);
    console.log("sAQ", saveAdvanceQuestion);

    // if (sessionStorage.getItem('returnSegRelPa') !== 'true') {
    //   sessionStorage.setItem(
    //     'saveAdvanceQuestion',
    //     JSON.stringify(saveAdvanceQuestion)
    //   );
    // } else {
    //   let auxAdvance = JSON.parse(
    //     sessionStorage.getItem('saveAdvanceQuestion')
    //   );
    //   setAdvanceQuestions(auxAdvance);
    //   setStep(4);
    //   setCurrentQuestion(auxAdvance[auxAdvance.length - 1]);
    //   sessionStorage.setItem('returnSegRelPa', 'false');
    // }
  }, [saveAdvanceQuestion2, saveAdvanceQuestion]);

  useEffect(() => {
    getContratos({
      variables: {
        query: `SELECT DISTINCT TOP 4 aHTNP.aHTNP_NoContr ,aHTP.aHTP_NoContr FROM aHTNP_HojDesTNP AS aHTNP inner JOIN aHTP_HojDesTP AS aHTP
                ON aHTNP.aHTNP_NoContr = aHTP.aHTP_NoContr ORDER BY aHTNP.aHTNP_NoContr`,
      },
    });
  }, []);

  useEffect(() => {
    console.log('STEP ==', step, 'currentQuestion', currentQuestion);
    setQuestionComplete(`${arrayQuestions[currentQuestion]}`);
    console.log(saveAdvanceQuestion2);
    console.log("RELACION DATOS:", relacionDatos);
    console.log("Comments:", commentPresentPatria);
  }, [step, currentQuestion, saveAdvanceQuestion2, relacionDatos, commentPresentPatria]);

  const saveCommentsRel = async (value) => {
    await saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify({
                ...contextSet.state.commentsAcuerdos,
                relacion: value,
              }),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + contextSet.state.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });
    onClick();
  };

  return (
    <Fragment>
      <div
        onClick={() => handleOnClickBack(currentQuestion)}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <h2>Relación Patria</h2>
      {showTitle ? <Questions questionTxt={questionComplete} /> : null}
      <div
        style={{
          width: '25%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {currentQuestion === 'tema' &&
          options.map(({ cRELP_Id, cRELP_Descripcion }) => (
            <ButtonNavigation
              text={cRELP_Descripcion}
              key={cRELP_Id}
              style={{ cursor: 'pointer' }}
              onClick={async() => {
                handleQuestionsAdvanceStep1({
                  idOption: cRELP_Id,
                  description: cRELP_Descripcion,
                });
                await contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cRELP_Id,
                });
                setRelacionDatos({
                  tipoRelacion: cRELP_Descripcion,
                  cRELP_Id
                })
              }}
            />
          ))}
        {/* #################STEP 2 ######### */}
        {currentQuestion === 'programaReaseguro' && step === 2 && (
          <Fragment>
            <DatePicker
              value={limitDate}
              onChange={(date) => setLimitDate(date)}
              className="calendar-button react-date-picker__wrapper"
            />
            <ButtonNavigation
              text="Guardar"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                handleQuestionsAdvanceStep2({ idOption: 'afterLimitDate' })
              }
            />
          </Fragment>
        )}
        {step === 2 &&
          QuestionsOptions[currentQuestion] && //cuando son preguntas de opciones entra aqui dependiendo tambien del step
          QuestionsOptions[currentQuestion].map(
            ({ description, id }, index) => (
              <ButtonNavigation
                text={description}
                style={{ cursor: 'pointer' }}
                key={index}
                onClick={() =>
                  handleQuestionsAdvanceStep2({ idOption: id, description })
                }
              />
            )
          )}
        {step === 2 && currentQuestion === 'siniestros' && (
          <LastStep
            onClick={async () => {
              await contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              await contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
              saveRelacion();
              await saveCommentsRel(commentPresentPatria);
            }}
            setState={(data) => {
              setCommentPresentPatria(data);
              console.log(contextSet.state.commentsAcuerdos);
            }}
            state={commentPresentPatria}
          />
        )}
        {/* ############STEP 3############  */}
        {step === 3 &&
          (currentQuestion === 'suscripcion' ||
            currentQuestion === 'apetitoRiesgos' ||
            currentQuestion === 'necesidades') && (
            <Comments
              titles={titles[currentQuestion]}
              onClick={(data) => {
                console.log("data", data);
                contextSet.setCommentsAcuerdos({
                  ...contextSet.state.commentsAcuerdos,
                  multiComments: {
                    firstComment: data.multiComments[0],
                    secondComment: data.multiComments[1],
                    thirdComment: data.multiComments[2]
                  },
                })
                handleQuestionsAdvanceStep3(data.paramsOnclick);
              }}
              paramsOnclick={{ idOption: 'afterMultComments', description: '' }}
            />
          )}
        {step === 3 &&
          QuestionsOptions[currentQuestion] && //currentQuestion !== 'tema' && currentQuestion !== 'programaReaseguro' && QuestionsOptions[currentQuestion] &&
          QuestionsOptions[currentQuestion].map(
            ({ description, id }, index) => (
              <ButtonNavigation
                text={description}
                style={{ cursor: 'pointer' }}
                key={index}
                onClick={() =>
                  handleQuestionsAdvanceStep3({ idOption: id, description })
                }
              />
            )
          )}
        {step === 3 && currentQuestion === 'afterSobrePendientesPatria' && (
          // <DropdownAreas
          //     onClick={handleQuestionsAdvanceStep3}
          //     paramsOnclick={{ idOption: 'afterDropdownStep3', description: '' }}
          //     handleChange={handleChange}
          //     state={formAreas}
          // />
          // <Comentarios
          //   cSUBE_Id={2}
          //   setCTIPR_Id={0}
          //   onClick1={() => {
          //     onClickSegRelPaDos()
          //     saveRelacion()
          //   }}
          //   flag={false}
          //   setState={(value) => {
          //     contextSet.setSaveSegRelPatria({
          //       ...contextSet.state.saveSegRelPatria,
          //       commentOne: value,
          //     });
          //     contextSet.setCommentsAcuerdos({ relacion: value });
          //   }}
          // // onClick1={() => handleQuestionsAdvanceStep3({ idOption: 'afterDropdownStep3', description: '' })}
          // // preSelect={JSON.stringify({ cSUBA_Id: 3, cARE_Id: 1, cSUBA_Descripcion: "Planeación y Coordinación" })}
          // />
          <>
            <LastStep
              onClick={async () => {
                await contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                await contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                // await saveCommentsRel(commentPresentPatria);
                saveRelacion();
                onClickSegRelPaDos();
              }}
              setState={(data) => {
                setCommentPresentPatria(data);
              }}
              state={commentPresentPatria}
            />

            <AreasInvV2 openModal={true} />
          </>

        )}
        {step === 3 && currentQuestion === 'resultados' && (
          <LastStep
            onClick={(params) => {
              handleQuestionsAdvanceStep3(params);
              contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
            }}
            paramsOnclick={{
              idOption: 'afterResultadosComments',
              description: '',
            }}
            setState={setCommentPresentPatria}
            state={commentPresentPatria}
          />
        )}
        {/* guardar comentarios */}
        {step === 3 && currentQuestion === 'considerarlosProxSemAndOtro' && (
          <LastStepWithLabelV2
            state={contextSet.state.commentOne}
            setState={setCommentPresentPatria}
            onClick={() => {
              contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
              // saveCommentsRel(commentPresentPatria);
              setCommentPresentPatria(commentPresentPatria);
              console.log("DATA CONSIDERADOS:", commentPresentPatria);
              setCurrentQuestion('resumenConsideacionAndOtro')
              setStep(5)
            }}
            value={commentPresentPatria}
          />
        )}
        {step === 3 &&
          (currentQuestion === 'statusImpuestoRetenidoExt' ||
            currentQuestion === 'statusExpedientePLD') && (
            <LastStepWithStatus
              status={status}
              onClick={async () => {
                await contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                await contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                await saveCommentsRel(commentPresentPatria)
                saveRelacion()
              }}
              setState={setCommentPresentPatria}
              value={commentPresentPatria}
            />
          )}

        {/* ############# STEP 4 ############ */}

        {/* {(step === 4 && currentQuestion === 'afterDropdownStep3') &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                        />
                        {
                            areas.length > 0 ?
                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                    <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                    <div className={classes.root}>
                                        {
                                            areas &&
                                            areas.map((area, index) =>
                                                <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                            )
                                        }
                                    </div>
                                </div> : null
                        }
                    </Fragment>
                } */}
        {step === 4 &&
          QuestionsOptions[currentQuestion] && //currentQuestion !== 'tema' && currentQuestion !== 'programaReaseguro' && QuestionsOptions[currentQuestion] &&
          QuestionsOptions[currentQuestion].map(
            ({ description, id }, index) => (
              <ButtonNavigation
                text={description}
                style={{ cursor: 'pointer' }}
                key={index}
                onClick={() =>
                  handleQuestionsAdvanceStep4({ idOption: id, description })
                }
              />
            )
          )}
        {step === 4 && currentQuestion === 'afterResultadosComments' && (
          <Fragment>
            <LastStep
              onClick={() => {
                contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                saveCommentsRel(commentPresentPatria);

                saveRelacion();
              }}
              txtBtn="Continuar a Seguimiento"
              style={{ width: '100%' }}
              state={commentPresentPatria}
              setState={setCommentPresentPatria}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              style={{ cursor: 'pointer' }}
              onClick={() => backRelacionPatria()}
            />
          </Fragment>
        )}
        {step === 4 &&
          (currentQuestion === 'afterQuestionsEstrategia' ||
            currentQuestion === 'afterQuestFortalAndAreasMejora') && (
            <LastStep
              onClick={(params) => {
                handleQuestionsAdvanceStep4(params);
                contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
              }}
              paramsOnclick={{
                idOption: 'afterEstrategiaComments',
                description: '',
              }}
              setState={setCommentPresentPatria}
              state={commentPresentPatria}
            />
          )}
        {step === 4 && currentQuestion === 'afterCampoAbiertoConsideracion' && (
          // <DropdownAreas
          //     onClick={handleQuestionsAdvanceStep4}
          //     paramsOnclick={{ idOption: 'desplegableAfterCampoAbierto', description: '' }}
          //     handleChange={handleChange}
          //     state={formAreas}
          // />
          <Fragment>
            {/* <Comentarios
              cSUBE_Id={2}
              setCTIPR_Id={0}
              onClick1={() =>
                handleQuestionsAdvanceStep4({
                  idOption: 'desplegableAfterCampoAbierto',
                  description: '',
                })
              }
              preSelect={JSON.stringify({
                cSUBA_Id: 3,
                cARE_Id: 1,
                cSUBA_Descripcion: 'Planeación y Coordinación',
              })}
            /> */}
            <LastStep
              onClick={() => {
                handleQuestionsAdvanceStep4({
                  idOption: 'desplegableAfterCampoAbierto',
                  description: '',
                });
                contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                // saveCommentsRel(commentPresentPatria);
                saveRelacion();
              }}
              txtBtn="Continuar a Seguimiento"
              state={commentPresentPatria}
              setState={setCommentPresentPatria}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              style={{ cursor: 'pointer' }}
              onClick={() => backRelacionPatria()}
            />
          </Fragment>
        )}
        {step === 4 && currentQuestion === 'afterTemaPlaticaPresent' && (
          // <LastStepWithLabel
          //   saveFunction={handleQuestionsAdvanceStep4}
          //   params={{
          //     idOption: 'afterCampoAbiertoPlaticaPresent',
          //     description: '',
          //   }}
          //   state={contextSet.state.commentOne}
          //   setState={setCommentPresentPatria}
          //   onClick={() => {
          //     contextSet.setSaveSegRelPatria({
          //       commentOne: commentPresentPatria,
          //     });
          //     contextSet.setCommentsAcuerdos({
          //       relacion: commentPresentPatria,
          //     });
          //     // saveCommentsRel(commentPresentPatria);
          //     // console.log("DATA CONSIDERADOS:", commentPresentPatria);
          //     // setCurrentQuestion('resumenConsideacionAndOtro')
          //     // setStep(5)
          //   }}
          // />

          <LastStepWithLabelV2
            value={contextSet.state.commentOne}
            setState={setCommentPresentPatria}
            onClick={() => {
              console.log("VALOR 1:", commentPresentPatria);
              contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
              handleQuestionsAdvanceStep4({
                idOption: 'afterCampoAbiertoPlaticaPresent',
                description: '',
              });
            }}
          />
        )}

        {/* ###########STEP 5###################### */}
        {step === 5 && currentQuestion === 'contratosActuales' && (
          <Fragment>
            {contratos.map(({ aHTNP_NoContr, aHTP_NoContr }, index) => (
              <ButtonNavigation
                text={aHTP_NoContr}
                style={{ cursor: 'pointer' }}
                key={index}
                onClick={() =>
                  handleQuestionsAdvanceStep5({
                    idOption: aHTNP_NoContr,
                    description: 'despuesContratosAct',
                  })
                }
              />
            ))}
            <ButtonNavigation
              text="Contrato en el que Patria no participa"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                handleQuestionsAdvanceStep5({
                  idOption: 0,
                  description: 'ContratoPatriaNoParticipa',
                })
              }
            />
          </Fragment>
        )}

        {(step === 5 && currentQuestion === 'resumenConsideacionAndOtro') && (
          <>
            <DivContainer
              title="Resumen de relación con patria"
              width="75%"
            >
              <ImputResumen
                title="Comentarios de Relación con Patria:"
                // subtitle={"subititlo"}
                disabled={disabledOne}
                setDisabled={() => setDisabledOne(!disabledOne)}
                value={contextSet.state.commentsAcuerdos.relacion}
                setValue={(value) => {
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    commentOne: value,
                  });
                  contextSet.setCommentsAcuerdos({ relacion: value });
                }}
                flexDirection="column"
              />
              {/* <ImputResumen
                title={generarReporte}
                status={answerByQuestion.status}
                // subtitle={"subtitle2"}
                value={contextSet.state.commentsAcuerdos.segRelacion}
                setValue={(value) => {
                  contextSet.setSaveSegRelPatria({
                    ...contextSet.state.saveSegRelPatria,
                    commentTwo: value,
                  });
                  contextSet.setCommentsAcuerdos({ segRelacion: value });
                }}
                disabled={disabledTwo}
                setDisabled={() => setDisabledTwo(!disabledTwo)}
              /> */}
            </DivContainer>
            <AreasInvV2 openModal={true} />
            <ButtonNavigation
              text="Guardar y continuar a Seguimiento"
              backgroundColor="#3898EC"
              border="#3898EC"
              color="#FFFFFF"
              width="75%"
              onClick={() => {
                onClickSegRelPaDos();
                saveRelacion();
              }}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              backgroundColor="#FFFFFF"
              border="#FFFFFF"
              color="black"
              width="75%"
              onClick={() => backRelacionPatria()}
            />
          </>
        )}

        {(step === 5 && currentQuestion === 'afterEstrategiaComments') && (
          <Fragment>
            <LastStep
              onClick={() => {
                contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                saveCommentsRel(commentPresentPatria);
                saveRelacion();
              }}
              txtBtn="Continuar a Seguimiento"
              state={commentPresentPatria}
              setState={setCommentPresentPatria}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              style={{ cursor: 'pointer' }}
              onClick={() => backRelacionPatria()}
            />
            {areas.length > 0 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'initial',
                }}
              >
                <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                <div className={classes.root}>
                  {areas &&
                    areas.map((area, index) => (
                      <Chip
                        label={area}
                        style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                        onDelete={() => deleteSubarea(area)}
                        deleteIcon={<Clear />}
                        key={index}
                      />
                    ))}
                </div>
              </div>
            ) : null}
          </Fragment>
        )}
        {step === 5 && currentQuestion === 'nuevosContratos' && (
          <Fragment>
            <h3>Contratos Objetivo</h3>
            <NativeSelect
              style={{ width: '100%', marginTop: 10 }}
              name="Direccion"
              // value={state['Direccion']}
              onChange={handleChange}
            >
              <option value="">pendient</option>
            </NativeSelect>
            <h3>Tipo de contrato/ ramo</h3>
            <NativeSelect
              style={{ width: '100%', marginTop: 10 }}
              name="Direccion"
              // value={state['Direccion']}
              onChange={handleChange}
            >
              <option value="">pendiente</option>
            </NativeSelect>
            <br />
            <ButtonNavigation
              text="Pedir información para cotizar"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentQuestion('despuesNuevosContratosStep5');
                // setAdvanceQuestions([
                //   ...saveAdvanceQuestion,
                //   'Pedir información para cotizar'
                // ]);
                setStep(6);
              }}
            />
            <ButtonNavigation
              text="Continuar"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentQuestion('despuesNuevosContratosStep5');
                // setAdvanceQuestions([
                //   ...saveAdvanceQuestion,
                //   'despuesNuevosContratosStep5',
                // ]);
                setStep(6);
              }}
            />
          </Fragment>
        )}
        {step === 5 && currentQuestion === 'afterCampoAbiertoPlaticaPresent' && (
          <Fragment>
            <Comentarios
              seccion='relacion'
              cSUBE_Id={2}
              setCTIPR_Id={0}
              onClick1={saveRelacion}
              preSelect={JSON.stringify({
                cSUBA_Id: 3,
                cARE_Id: 1,
                cSUBA_Descripcion: 'Planeación y Coordinación',
              })}
              value={commentPresentPatria}
              setState={(value) => {
                setCommentPresentPatria(value)
              }}
            />
            <ButtonNavigation
              text="Continuar a Seguimiento"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                saveCommentsRel(commentPresentPatria);
                saveRelacion();
              }}
            />
            <ButtonNavigation
              text="Volver a Relación con Patria"
              style={{ cursor: 'pointer' }}
              onClick={() => backRelacionPatria()}
            />
          </Fragment>
        )}
        {/* #########STEP 6 ########### */}
        {step === 6 &&
          (currentQuestion === 'despuesContratosAct' ||
            currentQuestion === 'despuesNuevosContratosStep5') && (
            <LastStep
              onClick={async () => {
                await contextSet.setSaveSegRelPatria({
                  commentOne: commentPresentPatria,
                });
                contextSet.setCommentsAcuerdos({
                  relacion: commentPresentPatria,
                });
                saveCommentsRel(commentPresentPatria);
                saveRelacion();
              }}
              // paramsOnclick={{ idOption: 'afterEstrategiaComments', description: '' }}
              setState={setCommentPresentPatria}
              state={commentPresentPatria}
            />
          )}

        {step === 6 && currentQuestion === 'ContratoPatriaNoParticipa' && (
          <Fragment>
            <h3>Contratos Objetivo</h3>
            <NativeSelect
              style={{ width: '100%', marginTop: 10 }}
              name="Direccion"
              // value={state['Direccion']}
              onChange={handleChange}
            >
              <option value="">pendient</option>
            </NativeSelect>
            <h3>Tipo de contrato/ ramo</h3>
            <NativeSelect
              style={{ width: '100%', marginTop: 10 }}
              name="Direccion"
              // value={state['Direccion']}
              onChange={handleChange}
            >
              <option value="">pendiente</option>
            </NativeSelect>
            <br />
            <ButtonNavigation
              text="Pedir información para cotizar"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentQuestion('despuesNuevosContratos');
                setAdvanceQuestions([
                  ...saveAdvanceQuestion,
                  'despuesNuevosContratos',
                ]);
                setStep(7);
              }}
            />
            <ButtonNavigation
              text="Continuar"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentQuestion('despuesNuevosContratos');
                setAdvanceQuestions([
                  ...saveAdvanceQuestion,
                  'despuesNuevosContratos',
                ]);
                setStep(7);
              }}
            />
          </Fragment>
        )}
        {/*#################### STEP 7 #################### */}
        {step === 7 && currentQuestion === 'despuesNuevosContratos' && (
          <LastStep
            onClick={() => {
              contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
              saveCommentsRel(commentPresentPatria);
            }}
            // paramsOnclick={{ idOption: 'afterEstrategiaComments', description: '' }}
            setState={setCommentPresentPatria}
            state={commentPresentPatria}
          />
        )}
        {/* {step === 6 && currentQuestion === 'afterDesplegPlaticaPrese' &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                            txtBtn='Continuar a Seguimiento'
                            state={commentPresentPatria}
                            setState={setCommentPresentPatria}
                        />
                        <ButtonNavigation
                            text='Volver a Relación con Patria'
                            style={{ cursor: 'pointer' }}
                            onClick={() => backRelacionPatria()}
                        />
                        {
                            areas.length > 0 ?
                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                    <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                    <div className={classes.root}>
                                        {
                                            areas &&
                                            areas.map((area, index) =>
                                                <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                            )
                                        }
                                    </div>
                                </div> : null
                        }
                    </Fragment>
                } */}
      </div>
      <div
        style={{
          width: '82%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        {step === 4 &&
          (currentQuestion === 'sinCambios' ||
            currentQuestion === 'liderYOtros' ||
            currentQuestion === 'afterMultComments') && (
            <Comentarios
              seccion='relacion'
              cSUBE_Id={2}
              setCTIPR_Id={0}
              onClick1={async () => {
                await contextSet.setSaveSegRelPatria({
                  ...contextSet.state.saveSegRelPatria,
                  commentOne: commentPresentPatria,
                });
                await contextSet.setCommentsAcuerdos({ relacion: commentPresentPatria });
                onClickSegRelPaDos()
                saveRelacion()
              }}
              flag={false}
              setState={setCommentPresentPatria}
              value={commentPresentPatria}
            // onClick1={() => handleQuestionsAdvanceStep3({ idOption: 'afterDropdownStep3', description: '' })}
            // preSelect={JSON.stringify({ cSUBA_Id: 3, cARE_Id: 1, cSUBA_Descripcion: "Planeación y Coordinación" })}
            />
          )}
      </div>
      <div style={{
        width: '91%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
        {step === 5 && currentQuestion === 'cambiosComments' && (
          <LastStep
            widthBtn="90%"
            onClick={async () => {
              await contextSet.setSaveSegRelPatria({
                commentOne: commentPresentPatria,
              });
              contextSet.setCommentsAcuerdos({
                relacion: commentPresentPatria,
              });
              saveCommentsRel(commentPresentPatria);
              saveRelacion();
            }}
            // paramsOnclick={{ idOption: 'afterEstrategiaComments', description: '' }}
            setState={setCommentPresentPatria}
            state={commentPresentPatria}
          />
        )}
      </div>
    </Fragment>
  );
};

export default RelacionV2;
