import React, { useContext } from 'react';
import revision from '../../assets/images/icons/revision.svg';
import aprobada from '../../assets/images/icons/aprobada.svg';
import cancelada from '../../assets/images/icons/cancelada.svg';
import autorizacion from '../../assets/images/icons/autorizacion.svg';
import avion5 from '../../assets/images/icons/avion5.svg';
import avion4 from '../../assets/images/icons/avion4.svg';
import avion3 from '../../assets/images/icons/avion3.svg';
import avion2 from '../../assets/images/icons/avion2.svg';
import avion1 from '../../assets/images/icons/avion1.svg';
import avionG from '../../assets/images/icons/avionG.svg';
import { GET_TRAVEL_EXPENSES_BY_ID } from '../../query/query';
import { useLazyQuery } from 'react-apollo';
import { MyContext } from '../Context';



const CardProgress = ({ status, idViaticos, nombre, idReunion, fechas, pais, handleClick, progress, showDetails = true, openModal, showListReu }) => {
  // console.log('CardProgress', { status, idViaticos, nombre, idReunion, fechas, pais, handleClick, progress })
  const [getInfoViatico] = useLazyQuery(GET_TRAVEL_EXPENSES_BY_ID, {
    onCompleted({ getTravelExpenses }) {
      let data = JSON.parse(getTravelExpenses)
      context.setInfoViaticos(data);
      handleClick();
    }
  })

  const infoViatico = (idViaticos) => {
    getInfoViatico({ variables: { cVIT_Id: idViaticos } });
  }

  const context = useContext(MyContext);
  if (idViaticos) {
    return (
      <div style={{ width: '350px', height: 'max-content', border: '1px solid #e5e5e5:borderRadius:5px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ width: '95%', height: '50px', borderBottom: '1px solid #C8C8C8', display: 'flex' }}>
          <div style={{ width: '50%', display: 'flex', alignItems: 'start' }}>
            <p style={{ marginRight: '10px' }}><strong>{status === '1' || status === '0' ? 'En revisión' : status === '2' ? 'En autorización' : status === '3' ? 'Rechazada' : status === '4' ? 'Autorizada' : null}</strong></p>
            <img src={
              status === '1' || status === '0'
                ? revision
                : status === '2'
                  ? autorizacion
                  : status === '3'
                    ? cancelada
                    : status === '4'
                      ? aprobada
                      : null
            } width='21px' height='21px' alt='alert' />
          </div>
          <div style={{ width: '50%', display: 'flex', alignItems: 'end', justifyContent: 'space-around' }} onClick={() => openModal()}>
            <p><strong>{`No.${idViaticos}`}</strong></p>
            {showListReu ? <p style={{ textAlign: 'center', color: '#3898EC', cursor: 'pointer' }}>Ver lista de reuniones</p>:null}
          </div>
        </div>
        <div style={{ width: '95%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '60%' }}>
            {nombre}<br />
            {`Reunión: ${idReunion}`}<br />
            {fechas}<br />
            {pais}<br />
            {showDetails &&
              <div style={{ cursor: 'pointer' }} onClick={() => infoViatico(idViaticos)}>
                <p style={{ textAlign: 'center', color: '#3898EC' }}>Ver Detalles</p>
              </div>
            }
          </div>
          <div style={{ width: '40%', borderLeft: '1px solid #c8c8c8', marginTop: '10px', marginBottom: '10px' }}>
            <img src={
              progress === 1
                ? avion1
                : progress === 2
                  ? avion2
                  : progress === 3
                    ? avion3
                    : progress === 4
                      ? avion4
                      : progress >= 5
                        ? avion5
                        : null} width='100%' alt='avion' />
          </div>
        </div>
      </div>
    )
  }

  else {
    return (
      <div style={{ width: '337px', height: 'max-content', border: '1px solid #e5e5e5:borderRadius:5px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ width: '95%', height: '32px', borderBottom: '1px solid #C8C8C8', display: 'flex' }}>
          <p style={{ marginRight: '10px', color: '#c8c8c8' }}>Aqui podras ver tus solicitudes</p>
        </div>
        <div style={{ width: '95%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '60%' }}>
            <p style={{ textAlign: 'center', color: '#c8c8c8' }}>No tienes solicitudes activas</p>
          </div>
          <div style={{ width: '40%', borderLeft: '1px solid #c8c8c8', marginTop: '10px', marginBottom: '10px' }}>
            <img src={avionG} width='100%' alt='avion' />
          </div>
        </div>
      </div>
    )
  }
}
export default CardProgress;