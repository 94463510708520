import styled from "styled-components";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
} from "@material-ui/core";
//Navbar-------------------------------------------------------------------

export const DivSelects = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
width: 80%;
justify-content:center;
`;
export const NavNavigation = styled.div`
  width: 100%;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: ${(props) => props.backgroundColor || "white"};
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0px 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  width: ${(props) => props.width || "70%"};
  @media (max-width: 991px) {
    width: 90%;
  }
`;
export const NavSesion = styled.nav`
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-right: ${(props) => props.marginRight || "0px"};
  @media (max-width: 991px) {
    display: none;
  }
`;

export const NavLinkSesion = styled.div`
  display: ${(props) => props.display || "inline-block"};
  vertical-align: top;
  text-decoration: none;
  color: ${(props) => props.color || ""};
  text-align: ${(props) => props.textAlign || "left"};
  margin-left: auto;
  margin-right: auto;
`;
export const NavMenu = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    padding: ${(props) => props.padding || "15px"};
  }
`;

export const NavOverlay = styled.div`
  display: none;
  background-color: ${(props) => props.backgroundColor || "black"};
  color: ${(props) => props.color || "white"};
  min-height: 80px;
  height: ${(props) => props.height || "auto"};
  align-items: ${(props) => props.alignItems || "center"};
  width: ${(props) => props.width || "100%"};
  padding-left: ${(props) => props.paddingLeft || "30px"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
  }
`;

export const NavLinkOverlay = styled.div`
  display: ${(props) => props.display || "inline-block"};
  vertical-align: ${(props) => props.verticalAlign || "top"};
  text-align: ${(props) => props.textAlign || "left"};
  margin-left: ${(props) => props.marginLeft || "auto"};
  margin-right: ${(props) => props.marginRight || "auto"};
  text-decoration: none;
`;

export const ButtonStyled = styled.button`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background-color: ${(props) => props.backgroundColor || "white"};
  color: ${(props) => props.color || "#000"};
  border-radius: ${(props) => props.borderRadius || "0px"};
  border: 0px;
  @media (max-width: 991px) {
    display: none;
  }
`;

//User-Containers----------------------------------------------------------

export const Page = styled.div`
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.maxWidth || "100%"};
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-direction: ${(props) => props.flexDirection || "column"};
`;

export const PageContainer = styled.div`
    position: ${(props) => props.position || "relative"};
    display: ${(props) => props.display || "flex"};
    flex-direction: ${(props) => props.flexDirection || "column"};
    width: ${(props) => props.width || "91%"};
    max-width:${(props) => props.maxWidth || "91%"};
    margin-top: ${(props) => props.marginTop || "100px"};
    margin-bottom:${(props) => props.marginBottom || "0px"}
    align-content: ${(props) => props.alignContent || "center"};
    z-index:100;
    background-color:#fff
`;
export const PageContainerCards = styled.div`
  display: ${(props) => props.display || "flex"};
  margin-top: ${(props) => props.marginTop || "35px"};
  width: ${(props) => props.width || "80%"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  @media (max-width: 771px) {
    justify-content: center;
  }
`;
export const PageCard = styled.div`
  width: ${(props) => props.width || "30%;"};
  height: ${(props) => props.height || "300px;"};
  max-height: ${(props) => props.maxHeight || "300px;"};
  float: ${(props) => props.float || "left;"};
  display: ${(props) => props.display || "flex;"};
  flex-direction: ${(props) => props.flexDirection || "column;"};
  align-items: ${(props) => props.alignItems || "flex-start;"};
  text-align: ${(props) => props.textAlign || "left;"};
  min-height: ${(props) => props.minHeight || "1px;"};
  padding-left: ${(props) => props.paddingLeft || "10px;"};
  padding-right: ${(props) => props.paddingRight || "10px;"};
  margin-bottom: ${(props) => props.marginBottom || "30px;"};
  @media (max-width: 771px) {
    width: 45%;
  }
  @media (max-width: 550px) {
    width: 45%;
  }
`;

export const AcordeonContainer = styled.div`
  width: ${(props) => props.width || "90%"};
  display: ${(props) => props.display || "flex"};
  flex-direction: ${(props) => props.flexDirection || "column"};
  margin-bottom: ${(props) => props.marginBottom || "25px"};
  justify-content: ${(props) => props.justifyContent};
`;

export const AcordeonContainerBody = styled.div`
  display: ${(props) => props.display || "flex"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  width: ${(props) => props.width || "90%"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  margin-top: ${(props) => props.marginTop || "0px"};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const ContainerTwoInputs = styled.div`
  width: ${(props) => props.width || "45%"};
  display: ${(props) => props.display || "flex"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  margin: ${(props) => props.margin || "0px"};
  margin-left: ${(props) => props.marginLeft || "0px"};
  cursor: ${(props) => props.cursor || "pointer"};
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    order: ${(props) => props.order || null};
  }
`;

//User-Fonts---------------------------------------------------------------
export const Title = styled.h1`
  text-align: ${(props) => props.textAlign || "center"};
  color: ${(props) => props.color || "#333333"};
  margin: ${(props) => props.margin || "0"};
`;

export const Subtitle = styled.p`
  text-align: ${(props) => props.textAlign || "center"};
  color: ${(props) => props.color || "#6A6A6A"};
`;

//StyledComponents--------------------------------------------------------------------------

export const ExpansionPanelHeader = styled(ExpansionPanelSummary)`
  background-color: ${(props) => props.backgroundColor || "#F4F4F4"};
`;

export const ExpansionPanelBody = styled(ExpansionPanelDetails)`
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-direction: ${(props) => props.flexDirection || "column"};
`;

export const IconPanel = styled.img`
  height: ${(props) => props.height || "32px"};
  margin: ${(props) => props.margin || "0px"};
`;
export const InputTwo = styled(TextField)`
  width: ${(props) => props.width || "48%"};
`;
export const BottonStyledSeccion = styled.button`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background-color: ${(props) => props.backgroundColor || "white"};
  color: ${(props) => props.color || "#000"};
  border-radius: ${(props) => props.borderRadius || "0px"};
  border: 0px;
  font-size: 1rem;
`;

export const Div = styled.div`
  width: ${(props) => props.w || "auto"};
  height: ${(props) => props.h || "auto"};
  display: ${(props) => props.display || "block"};
  text-align: ${(props) => props.text || "left"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
`;

export const DivNavigationButton = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-direction: ${(props) => props.flexDirection || "column"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  align-self: ${(props) => props.alignSelf};
`;

export const ButtonStyledNavigation = styled.div`
  display: flex;
  flex-direction: wrap;
  text-align: center;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  background-color: ${(props) => props.backgroundColor || "#FFF"};
  color: ${(props) => props.color || "auto"};
  width: ${(props) => props.width || "338px"};
  height: ${(props) => props.height || "55px"};
  left: ${(props) => props.left || "15px"};
  top: ${(props) => props.top || "258px"};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "10px"};
  border: ${(props) => props.border || "solid #E5E5E5"};
  box-sizing: ${(props) => props.boxSizing || "border-box"};
  box-shadow: ${(props) => props.boxShadow || "0px 4px 4px rgba(0,0,0,0.25)"};
  border-radius: ${(props) => props.borderRadius || "5px"};
  &:hover,
  &:focus {
    background-color:#3898EC;
    color:#FFF;
  }
  &:active {
    color:#FFF ;
  }
`;

export const LabelCard = styled.div`
    display: flex;
    flex-direction: wrap;
    text-align: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor || "#FFF"};
    color: auto;
    width: ${(props) => props.width || "338px"};
    height: ${(props) => props.height || "55px"};
    margin-bottom: 10px;
    border: solid #E5E5E5;
    box-sizing: border-box
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
`;

export const CardList = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
`;