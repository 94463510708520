import { useMutation } from 'react-apollo';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import {
  DINAMIC_GET,
  DYNAMIC_INSERT,
  DYNAMIC_UPDATE,
} from '../../mutation/mutation';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import { TextareaAutosize, Chip } from '@material-ui/core';
import ToggleButtons from '../Viaticos/ToggleButons';
import Areas_Inv from './Areas_Inv';
import ImputResumen from './ImputResumen';
import { MyContext } from '../Context';
import { LabelCard } from '../StylesComponents/common-styled';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
export default function Seguimiento({
  onClick,
  onClickReturnEntorno,
  onClickReturnRelacionPatria,
  onGoBack,
  titleSeguimiento = 'Seguimiento Entorno',
  sinEntorno,
  user,
}) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [valueEntorno, setValueEntorno] = useState('');
  const [seguimiento, setSeguimiento] = useState([]);
  const [ramo, setRamo] = useState([]);
  const [seguimientoEntor, setSeguimientoEntor] = useState([]);
  const [subtemaSeguimiento, setSubtemaSeguimiento] = useState([]);
  const [registroSolicitud, setRegistroSolicitud] = useState([]);
  const [statusSeguimiento, setStatusSeguimiento] = useState([]);
  const [cREP_Descripcion, setCREP_Descripcion] = useState('');
  const [cRAMC_Descripcion, setCRAMC_Descripcion] = useState('');
  const [cREGSS_Descripcion, setCREGSS_Descripcion] = useState('');
  const [cTEMS_Id, setCTEMS_Id] = useState('0');
  const [cSUBTS_Id, setCSUBTS_Id] = useState('0');
  const [cESTS_Descripcion, setCESTS_Descripcion] = useState('');
  const [cSUBTS_Descripcion, setCSUBTS_Descripcion] = useState('');
  const [sComentarios, setSComentarios] = useState('');

  const [areas, setAreas] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [open, setOpen] = useState(false);
  const [preSelectArea, setPreSelectArea] = useState('');

  const [disabledRelacion, setDisabledRelacion] = useState(true);
  const [disabledAcuerdo, setDisabledAcuerdos] = useState(true);
  const [steps, setSteps] = useState([]);
  
  const context = useContext(MyContext).state;
  const contextSet = useContext(MyContext);
  const clearContext = useContext(MyContext);

  const [saveIdResponseQuestions, setSaveIdResponseQuestions] = useState({
    areas: context.saveAcuerdos.areas,
    subAreas: context.saveAcuerdos.subAreas,
  });
  console.log('SEGUIMIENTO CONTEXT - SEGUIMIENTO ->', context);

  useEffect(() => {
    console.log("SIN ENTORNO", sinEntorno);
    if(sinEntorno === 'Ninguno'){
      setValue(sinEntorno)
    }
    else{
      setValue('')
    }

    console.log("VALOR ENTORNO", value);
  }, [sinEntorno])

  useEffect(() => {
    console.log("VALUE CHANGED", value);
  }, [value])

  const seguimientoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cREPS_ReporteSeguimiento',
          columns: ['cREP_Id', 'cREP_Descripcion'],
        },
      },
    }),
    []
  );

  const ramoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cRAMC_RamoCRM',
          columns: ['cRAMC_Id', 'cRAMC_Descripcion'],
        },
      },
    }),
    []
  );

  const seguimientoEntorInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cTEMS_TemasSeguimiento',
          columns: ['cTEMS_Id', 'cTEMS_Descripcion'],
        },
      },
    }),
    []
  );

  const subSeguimiento = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cSUBTS_SubtemasSeguimiento',
          columns: ['cSUBTS_Id', 'cTEMS_Id', 'cSUBTS_Descripcion'],
          conditions: [
            {
              valone: 'cTEMS_Id',
              valtwo: '' + cTEMS_Id,
              condition: '=',
            },
          ],
        },
      },
    }),
    [cTEMS_Id]
  );
  const registroSol = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['cREGSS_Id', 'cSUBTS_Id', 'cREGSS_Descripcion'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: '' + cSUBTS_Id,
              condition: '=',
            },
          ],
        },
      },
    }),
    [cSUBTS_Id]
  );

  const status = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cESTS_EstatusSeguimiento',
          columns: ['cESTS_Id', 'cESTS_Descripcion'],
        },
      },
    }),
    []
  );

  const area = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cARE_AREASCRM',
          columns: ['cARE_Id', 'cARE_Descripcion'],
        },
      },
    }),
    []
  );

  const subAre = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cSUBA_SubAreasCRM',
          columns: ['cSUBA_Id', 'cARE_Id', 'cSUBA_Descripcion'],
        },
      },
    }),
    []
  );

  useEffect(() => {
    getSeguimiento(seguimientoInput);
    getRamos(ramoInput);
    getSeguimientoEntor(seguimientoEntorInput);
    getArea(area);
    getSubArea(subAre);
    getStatus(status);
  }, []);
  useEffect(() => {
    getSubSeguimiento(subSeguimiento);
  }, [subSeguimiento]);
  useEffect(() => {
    getRegistroSolicitud(registroSol);
  }, [registroSol]);

  const [saveComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const [saveIdResponse] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log('guardado ', JSON.parse(dynamicInsert.response));
    },
    onError(error) {
      console.log('Error Save Id Response', error);
    },
  });
  const [getSeguimiento] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        setSeguimiento(JSON.parse(dynamicGet.response));        
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getRamos] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setRamo(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getSeguimientoEntor] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSeguimientoEntor(JSON.parse(dynamicGet.response));
    },
  });

  const [getSubSeguimiento] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if(dynamicGet.message != 'Not found') {
        setSubtemaSeguimiento(JSON.parse(dynamicGet.response));
      }
    },
  });

  const [getRegistroSolicitud] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if(dynamicGet.message != 'Not found') {
        setRegistroSolicitud(JSON.parse(dynamicGet.response));
      }
    },
  });

  const [getStatus] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {      
        setStatusSeguimiento(JSON.parse(dynamicGet.response));
      }
    },
  });

  const [getArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        setAreas(JSON.parse(dynamicGet.response));
      }
    },
  });

  const [getSubArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        let response = JSON.parse(dynamicGet.response);
        let areasEntorno = context.saveAcuerdos.areas;
        let subAreasEntorno = context.saveAcuerdos.subAreas;

        let arraySubAreas = response.map((subAreas, index) => {
          if(subAreasEntorno.indexOf(subAreas.cSUBA_Id) != -1){
            return {...subAreas, status: true}
          }
          else{
            return {...subAreas, status: false };
          }
        });
        setSubArea([
          ...arraySubAreas,
          {
            cARE_Id: 5,
            cSUBA_Descripcion: 'Otra Área',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(5) != -1 ? true : false,
          },
          {
            cARE_Id: 6,
            cSUBA_Descripcion: 'Ninguna',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(6) != -1 ? true : false,
          },
          {
            cARE_Id: 7,
            cSUBA_Descripcion: 'PLD / Oficial de Cumplimiento',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(7) != -1 ? true : false,
          },
          {
            cARE_Id: 8,
            cSUBA_Descripcion: 'Planeación y Coordinación',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(8) != -1 ? true : false,
          }
        ]);
        
      }
    },
  });

  const saveInfoByIds = (nextStep) => {
    const {
      cREP_Id: cRES_Id = 0,
      cRAMC_Id = 0,
      cTEMS_Id = 0,
      cSUBTS_Id = 0,
      cREGSS_Id = 0,
      cESTS_Id = 0,
      cARE_Id = 0,
      cSUBA_Id = 0,
      areas = [],
      subAreas = []
    } = saveIdResponseQuestions;

    console.log("AREAS Y SUBAREAS", areas, subAreas);

    let input = {
      table: 'cSEGE_SeguimientoEntorno',
      columns: [],
      detailValues: [
        {
          values: [],
        },
      ],
    };
    if (cRES_Id !== 0) {
      input.columns.push('cRES_Id');
      input.detailValues[0].values.push(String(cRES_Id));
    }
    if (cRAMC_Id !== 0) {
      input.columns.push('cRAMC_Id');
      input.detailValues[0].values.push(String(cRAMC_Id));
    }
    if (cTEMS_Id !== 0) {
      input.columns.push('cTEMS_Id');
      input.detailValues[0].values.push(String(cTEMS_Id));
    }
    if (cSUBTS_Id !== 0) {
      input.columns.push('cSUBTS_Id');
      input.detailValues[0].values.push(String(cSUBTS_Id));
    }
    if (cREGSS_Id !== 0) {
      input.columns.push('cREGSS_Id');
      input.detailValues[0].values.push(String(cREGSS_Id));
    }
    if (cESTS_Id !== 0) {
      input.columns.push('cESTS_Id');
      input.detailValues[0].values.push(String(cESTS_Id));
    }
    if (cARE_Id !== 0) {
      input.columns.push('cARE_Id');
      input.detailValues[0].values.push(String(cARE_Id));
    }
    if (cSUBA_Id !== 0) {
      input.columns.push('cSUBA_Id');
      input.detailValues[0].values.push(String(cSUBA_Id));
    }
    if (areas.length > 0) {
      input.columns.push('areas');
      input.detailValues[0].values.push(String(areas));
    }
    if (subAreas.length > 0) {
      input.columns.push('subAreas');
      input.detailValues[0].values.push(String(subAreas));
    }
    if (context.saveAcuerdos.cACU_Id) {
      input.columns.push('cACU_Id');
      input.detailValues[0].values.push(String(context.saveAcuerdos.cACU_Id));
    }
    console.log('input save', input);
    saveIdResponse({
      variables: { input },
    });
    saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify(context.commentsAcuerdos),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + context.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });

    if (nextStep === 'relaciónPatria') onClickReturnRelacionPatria();
    else {
      setValue('');
      setSteps([]);
      // setSaveIdResponseQuestions({});
      // clearContext.clearState();
    }
  };

  const onchange = ({ value }) => {
    const { cARE_Id, cSUBA_Id, cSUBA_Descripcion } = JSON.parse(value);
    console.log("AREA, SUBAREA", cARE_Id, cSUBA_Id);
    console.log("AREAS Y SUBAREAS", saveIdResponseQuestions.areas, saveIdResponseQuestions.subAreas);

    if(saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id) === -1){
      saveIdResponseQuestions.subAreas.push(cSUBA_Id);
      saveIdResponseQuestions.areas.push(cARE_Id);
      setSaveIdResponseQuestions({
        ...saveIdResponseQuestions,
        cSUBA_Id,
        subAreas: saveIdResponseQuestions.subAreas,
        areas: saveIdResponseQuestions.areas
      });
    }
    else{
      if (cSUBA_Descripcion === 'Otra Área' || cSUBA_Descripcion === 'Ninguna' || cSUBA_Descripcion === 'PLD / Oficial de Cumplimiento' || cSUBA_Descripcion === 'Planeación y Coordinación') {
        if(saveIdResponseQuestions.areas.indexOf(cARE_Id) === -1){
          saveIdResponseQuestions.subAreas.push(cSUBA_Id);
          saveIdResponseQuestions.areas.push(cARE_Id);
          setSaveIdResponseQuestions({
            ...saveIdResponseQuestions,
            cSUBA_Id,
            subAreas: saveIdResponseQuestions.subAreas,
            areas: saveIdResponseQuestions.areas
          });
        }
        else{
          let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
          let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
        
          saveIdResponseQuestions.subAreas.splice(i, 1);
          saveIdResponseQuestions.areas.splice(i1, 1);
        }
      }
      else{
        let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
        let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
      
        saveIdResponseQuestions.subAreas.splice(i, 1);
        saveIdResponseQuestions.areas.splice(i1, 1);
      }
    }

    console.log("areas", saveIdResponseQuestions.areas, "subAreas", saveIdResponseQuestions.subAreas);

    contextSet.setSaveAcuerdos({
      ...contextSet.state.saveAcuerdos,
      cARE_Id,
      cSUBA_Id,
      areas: saveIdResponseQuestions.areas,
      subAreas: saveIdResponseQuestions.subAreas
    });

    console.log("acuerdos:", contextSet.state.saveAcuerdos);

    // const index = saveIdResponseQuestions.areas.indexOf(cARE_Id);
    // const index2 = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);

    // console.log("INDEXES", index, index2);

    // if(saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id) === -1){
    //   saveIdResponseQuestions.subAreas.push(cSUBA_Id);
    //   setSaveIdResponseQuestions({
    //     ...saveIdResponseQuestions,
    //     cSUBA_Id,
    //     subAreas: saveIdResponseQuestions.subAreas
    //   });
    // }
    
    // if(saveIdResponseQuestions.areas.indexOf(cARE_Id) === -1){
    //   saveIdResponseQuestions.areas.push(cARE_Id);
    //   setSaveIdResponseQuestions({
    //     ...saveIdResponseQuestions,
    //     cARE_Id,
    //     areas: saveIdResponseQuestions.areas
    //   });
    // }
    // else if(index > -1){
    //   saveIdResponseQuestions.areas.splice(index, 1);
    //   saveIdResponseQuestions.subAreas.splice(index2, 1);

    //   console.log("REMOVER AMBAS");

    //   setSaveIdResponseQuestions({
    //     ...saveIdResponseQuestions,
    //     areas: saveIdResponseQuestions.areas,
    //     subAreas: saveIdResponseQuestions.subAreas
    //   })
    // }
    // else if(index2 >- 1){
    //   saveIdResponseQuestions.subAreas.splice(index2, 1);

    //   console.log("REMOVER SUBAREA");

    //   setSaveIdResponseQuestions({
    //     ...saveIdResponseQuestions,
    //     subAreas: saveIdResponseQuestions.subAreas
    //   })
    // }
    // else if(index2 > -1){
      
    // }
    
    console.log("areas", saveIdResponseQuestions.areas, "subAreas", saveIdResponseQuestions.subAreas);
    contextSet.setSaveAcuerdos({ ...contextSet.state.saveAcuerdos, cARE_Id });
    let auxSubAreas = subArea.map((sArea) => {
      if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
        return { ...sArea, status: !sArea.status };
      } else if (cARE_Id === 5 && cSUBA_Id === 5) {
        return { ...sArea, status: !sArea.status };
      } else {
        return { ...sArea };
      }
    });
    // contextSet.setSaveAcuerdos({
    //   ...contextSet.state.saveAcuerdos,
    //   subArea: auxSubAreas,
    // });
    setSubArea(auxSubAreas);
    console.log("AREAS Y SUBAREAS 2", saveIdResponseQuestions.areas, saveIdResponseQuestions.subAreas);

  };

  const deleteSubarea = (cSUBA_Id, cARE_Id) => {
    console.log("DATA AREA A ELIMINAR", cSUBA_Id, cARE_Id);
    const index = saveIdResponseQuestions.areas.indexOf(cARE_Id);
    const index2 = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
    if (index > -1) { // only splice array when item is found
      saveIdResponseQuestions.areas.splice(index, 1); // 2nd parameter means remove one item only
    }

    if (index2 > -1) { // only splice array when item is found
      saveIdResponseQuestions.subAreas.splice(index2, 1); // 2nd parameter means remove one item only
    }

    console.log("ELIMINANDO", saveIdResponseQuestions.areas, saveIdResponseQuestions.subAreas);
    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );
  };

  const previousQuestion = () => {
    let index = steps.indexOf(value);
    let step = index === 0 ? index : index - 1;
    if (index === 0) {
      setValue('');
      steps.splice(index, 1);
    } else {
      setValue(steps[step]);
      steps.splice(index, 1);
    }
  };
  useEffect(() => {
    if (value !== '' && !steps.includes(value)) setSteps([...steps, value]);
  }, [value]);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      {steps.length > 0 ? (
        <div onClick={() => previousQuestion()} style={{ cursor: 'pointer' }}>
          <ArrowBackIos style={{ color: '#01539C' }} />
        </div>
      ) : (
        <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
          <ArrowBackIos style={{ color: '#01539C' }} />
        </div>
      )}
      {value === '' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
        >
          {seguimiento.map(({ cREP_Id, cREP_Descripcion }, index) => (
            <ButtonNavigation
              text={cREP_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                if (cREP_Descripcion !== 'Ninguno') {
                  setCREP_Descripcion(cREP_Descripcion);
                  setValue('ramo');
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cREP_Id,
                  });
                } else {
                  setValue('ramo_ninguno');
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cREP_Id,
                  });
                }
              }}
            />
          ))}
        </DivContainer>
      )}
      {value === 'ramo_ninguno' && (
        <DivContainer
          title="Resumen de Seguimiento sobre Entorno "
          subtitle="Comentarios de Entorno"
        >
          <div style={{ width: 380 }}>
            <hr />
          </div>
          <p>
            {context.saveAcuerdos.cTIPR_Descripcion
              ? context.saveAcuerdos.cTIPR_Descripcion
              : context.saveAcuerdos.cSUBE_Descripcion
              ? context.saveAcuerdos.cSUBE_Descripcion
              : 'Sin Ultimo Ramo Elegido'}
          </p>
          <div style={{ backgroundColor: '#E5E5E5', width: 380 }}>
            <LabelCard
              style={{
                marginTop: 10,
                backgroundColor: '#858585',
                color: '#FFF',
                paddingLeft: 10,
              }}
              width="150px"
              height="35px"
            >
              {context.saveAcuerdos.cENT_Descripcion
                ? context.saveAcuerdos.cENT_Descripcion
                : 'Sin Ramo Elegido'}
            </LabelCard>

            <p style={{ paddingLeft: 10 }}>
              {context.commentsAcuerdos.entorno
                ? context.commentsAcuerdos.entorno
                : 'Sin Comentarios'}
            </p>
          </div>
          <ButtonNavigation
            text="Guardar y Continuar con Relación"
            width="25%"
            id="button-casual"
            backgroundColor="#3898EC"
            color="white"
            onClick={() => onClick()}
          />
          <ButtonNavigation
            text="Volver a Entorno"
            width="25%"
            id="button-casual"
            onClick={() => onClickReturnEntorno()}
          />
        </DivContainer>
      )}
      {value === 'ramo' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle="¿A qué ramo aplica tu seguimiento?"
        >
          {ramo.map(({ cRAMC_Id, cRAMC_Descripcion }, index) => (
            <ButtonNavigation
              text={cRAMC_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setValue('seguimiento');
                setCRAMC_Descripcion(cRAMC_Descripcion);
                setSaveIdResponseQuestions({
                  ...saveIdResponseQuestions,
                  cRAMC_Id,
                });
              }}
            />
          ))}
        </DivContainer>
      )}
      {value === 'seguimiento' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={`¿Qué seguimiento deseas dar a tu ${cREP_Descripcion}?`}
        >
          {seguimientoEntor.map(({ cTEMS_Id, cTEMS_Descripcion }, index) => {
            return (
              <ButtonNavigation
                text={cTEMS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  if (cTEMS_Id !== 5) {
                    setCTEMS_Id('' + cTEMS_Id);
                    setSaveIdResponseQuestions({
                      ...saveIdResponseQuestions,
                      cTEMS_Id,
                    });
                    setValue('subTema');
                  } else {
                    setValue('comentarios');
                    setCREGSS_Descripcion(cTEMS_Descripcion);
                    contextSet.setSaveAcuerdos({
                      cREGSS_Descripcion: cTEMS_Descripcion,
                    });
                    setSaveIdResponseQuestions({
                      ...saveIdResponseQuestions,
                      cTEMS_Id,
                    });
                  }
                }}
              />
            );
          })}
        </DivContainer>
      )}
      {value === 'subTema' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={
            cTEMS_Id === '4'
              ? '¿Qué acciones deseas tomar?'
              : cTEMS_Id === '3'
              ? `¿Qué siguiente paso deseas registrar para tu ${cREP_Descripcion}?`
              : `¿Qué seguimiento deseas dar a tu ${cREP_Descripcion}?`
          }
        >
          {subtemaSeguimiento.filter(r => r.cSUBTS_Descripcion !== 'Enviar Solicitud Documentos Expediente PLD' && r.cSUBTS_Descripcion !== 'Enviar Solicitud Documentos para Recuperar Impuestos Retenidos Extranjero').map(
            ({ cSUBTS_Id, cSUBTS_Descripcion }, index) => {
              return (
                <ButtonNavigation
                  text={cSUBTS_Descripcion}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    if (cSUBTS_Id >= 3) {
                      setCSUBTS_Id(cSUBTS_Id);
                      setValue(
                        cSUBTS_Id >= 3 && cSUBTS_Id <= 11
                          ? 'registro'
                          : 'comentarios'
                      );
                      setPreSelectArea(
                        cSUBTS_Id === 10
                          ? 'Planeación y Coordinación'
                          : cSUBTS_Id === 11
                          ? ''
                          : cSUBTS_Id === 12
                          ? 'Fiscal'
                          : ''
                        // cSUBTS_Id === 12  ? 'Fiscal' : cSUBTS_Id === 10 ? 'Planeación y Coordinación' : ''
                      );
                      // setOpen(true);
                      setOpen(cSUBTS_Id >= 3 && cSUBTS_Id <= 11 ? false : true);
                      setCSUBTS_Descripcion(cSUBTS_Descripcion);
                      contextSet.setSaveAcuerdos({
                        cSUBTS_Descripcion,
                        cSUBTS_Id,
                      });
                      setSaveIdResponseQuestions({
                        ...saveIdResponseQuestions,
                        cSUBTS_Id,
                      });
                    } else {
                      setSaveIdResponseQuestions({
                        ...saveIdResponseQuestions,
                        cSUBTS_Id,
                      });
                      contextSet.setSaveAcuerdos({
                        cSUBTS_Descripcion,
                        cSUBTS_Id,
                      });
                      setCSUBTS_Id(cSUBTS_Id);
                      setCSUBTS_Descripcion(cSUBTS_Descripcion);
                      setValue('comentarios');
                    }
                  }}
                />
              );
            }
          )}
        </DivContainer>
      )}
      {value === 'registro' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={
            cSUBTS_Id === 3
              ? '¿Deseas solicitar aprobación al Comité de Suscripción?'
              : cSUBTS_Id >= 4 && cSUBTS_Id <= 8
              ? '¿Deseas solicitar aprobación al Comité de Suscripción?'
              : /* `¿Qué siguiente paso deseas registrar para ${cRAMC_Descripcion}?` */
              cSUBTS_Id === 9
              ? '¿Qué información deseas enviar?'
              : cSUBTS_Id === 10
              ? '¿Qué siguiente paso deseas registrar para Confirmación?'
              : cSUBTS_Id === 11
              ? '¿Que información deseas enviar?'
              : ''
          }
        >
          {
            cSUBTS_Id === 3 ||
            (cSUBTS_Id >= 4 && cSUBTS_Id <= 8) ||
            cSUBTS_Id === 9 ||
            cSUBTS_Id === 11
              ? (registroSolicitud.filter(x => (cSUBTS_Id !== 9 && (x.cREGSS_Id < 35 || x.cREGSS_Id > 36)) || (cSUBTS_Id === 9 && (x.cREGSS_Descripcion === 'Objetivo cumplido' || x.cREGSS_Descripcion === 'Objetivo no cumplido')))).map(
                  ({ cREGSS_Id, cSUBTS_Id, cREGSS_Descripcion }, index) => (
                    <ButtonNavigation
                      text={cREGSS_Descripcion}
                      width="25%"
                      id="button-casual"
                      key={index}
                      onClick={() => {
                        setValue('comentarios');
                        setOpen(cREGSS_Id >= 17 && cREGSS_Id <= 18);
                        setCREGSS_Descripcion(cREGSS_Descripcion);
                        contextSet.setSaveAcuerdos({
                          cREGSS_Descripcion,
                          cREGSS_Id,
                        });
                        setSaveIdResponseQuestions({
                          ...saveIdResponseQuestions,
                          cREGSS_Id,
                        });
                      }}
                    />
                  )
                )
              : null
            // <div>
            //   <label>Direccion General</label>
            //   <NativeSelect input={<BootstrapInput />} fullWidth>
            //     <option value="">Control Interno</option>
            //     <option value="">Director General</option>
            //     <option value="">Planeación y Coordinación</option>
            //     <option value="">Transformación Operativa</option>
            //   </NativeSelect>
            //   <label>Finanzas</label>
            //   <NativeSelect input={<BootstrapInput />} fullWidth>
            //     <option value="">Actuaría</option>
            //     <option value="">Contabilidad</option>
            //     <option value="">Fiscal</option>
            //     <option value="">Modelación / Pricing</option>
            //     <option value="">Tesorería</option>
            //   </NativeSelect>
            //   <label>Operaciones</label>
            //   <NativeSelect input={<BootstrapInput />} fullWidth>
            //     <option value="">Administración Técnica</option>
            //     <option value="">Pull de Asistentes</option>
            //     <option value="">Servicios de Oficina</option>
            //     <option value="">Siniestros</option>
            //     <option value="">Retrocesiones</option>
            //   </NativeSelect>
            //   <label>Suscripción</label>
            //   <NativeSelect input={<BootstrapInput />} fullWidth>
            //     <option value="">Contratos Daños</option>
            //     <option value="">Facultativo Daños</option>
            //     <option value="">Fianzas</option>
            //     <option value="">Lineas Especiales</option>
            //     <option value="">Oficina Chile</option>
            //     <option value="">Personas</option>
            //     <option value="">Ultramar</option>
            //   </NativeSelect>
            //   <ButtonNavigation
            //     text="Otra Area"
            //     width="25%"
            //     style={{ marginTop: 30 }}
            //     id="button-casual"
            //     onClick={() => {
            //       setValue('comentarios');
            //     }}
            //   />
            // </div>
          }
          {
            cSUBTS_Id === 10
              ? (registroSolicitud.filter(x => x.cSUBTS_Id === 10)).map(
                  ({ cREGSS_Id, cSUBTS_Id, cREGSS_Descripcion }, index) => (
                    <ButtonNavigation
                      text={cREGSS_Descripcion}
                      width="25%"
                      id="button-casual"
                      key={index}
                      onClick={() => {
                        setValue('comentarios');
                        setOpen(false);
                        setCREGSS_Descripcion(cREGSS_Descripcion);
                        contextSet.setSaveAcuerdos({
                          cREGSS_Descripcion,
                          cREGSS_Id,
                        });
                        setSaveIdResponseQuestions({
                          ...saveIdResponseQuestions,
                          cREGSS_Id,
                        });
                      }}
                    />
                  )
                )
              : null
          }
        </DivContainer>
      )}
      {value === 'comentarios' && (
        <>
          <DivContainer
            title={titleSeguimiento}
            subtitle={`Comentarios para el seguimiento de ${cREP_Descripcion} sobre ${
              cREGSS_Descripcion || cSUBTS_Descripcion
            } `}
          >
            <TextareaAutosize
              rowsMin={10}
              style={{ width: '75%' }}
              placeholder="Escribe tus comentarios"
              value={sComentarios}
              onChange={({ target }) => {
                setSComentarios(target.value);
                contextSet.setSaveAcuerdos({
                  sComentarios: target.value,
                });
                contextSet.setCommentsAcuerdos({ segEntorno: target.value });
              }}
            />
            <ToggleButtons />
            <ButtonNavigation
              text="Guardar y continuar"
              backgroundColor="#3898EC"
              border="#3898EC"
              color="#FFFFFF"
              width="75%"
              onClick={() => setValue('status')}
            />
          </DivContainer>
          {(cREGSS_Descripcion === 'Condolencias' ||
            cREGSS_Descripcion === 'Felicitación' ||
            (cSUBTS_Id >= 10 && cSUBTS_Id <= 12)) &&  cSUBTS_Id !== 11 &&
          subArea.length > 0 ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'initial',
              }}
              onClick={() => setOpen(true)}
            >
              <h4 style={{ color: '#3898EC' }}>Áreas</h4>
              <div className={classes.root}>
                {subArea &&
                  subArea.map(
                    (
                      { cSUBA_Descripcion, cSUBA_Id, cARE_Id, status },
                      index
                    ) => {
                      console.log(
                        'mapeo ==',
                        cSUBA_Descripcion,
                        cSUBA_Id,
                        cARE_Id,
                        status
                      );
                      if (cSUBA_Descripcion === preSelectArea) {
                        return (
                          <Chip
                            label={cSUBA_Descripcion}
                            style={{
                              backgroundColor: '#3898EC',
                              color: '#FFFF',
                            }}
                            key={index}
                          />
                        );
                      } else {
                        return status ? (
                          <Chip
                            label={cSUBA_Descripcion}
                            style={{
                              backgroundColor: '#3898EC',
                              color: '#FFFF',
                            }}
                            onDelete={() => deleteSubarea(cSUBA_Id, cARE_Id)}
                            deleteIcon={<Clear />}
                            key={index}
                          />
                        ) : null;
                      }
                    }
                  )}
              </div>
            </div>
          ) : null}
        </>
      )}
      {value === 'status' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={`¿Cuál es el estado de tu ${cREP_Descripcion}?`}
        >
          {statusSeguimiento.map(({ cESTS_Id, cESTS_Descripcion }, index) => (
            <ButtonNavigation
              text={cESTS_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setValue(() =>{
                  return 'resumen'
                });
                console.log("VALOR PREVIO", value);
                setOpen(true);
                setCESTS_Descripcion(cESTS_Descripcion);
                contextSet.setSaveAcuerdos({
                  ...contextSet.state.saveAcuerdos,
                  cESTS_Descripcion,
                  cESTS_Id,
                });
                setSaveIdResponseQuestions({
                  ...saveIdResponseQuestions,
                  cESTS_Id,
                });
              }}
            />
          ))}
        </DivContainer>
      )}
      {(value === 'resumen' || value === 'Ninguno') && (
        <>
          <DivContainer
            title={titleSeguimiento}
            subtitle="Resumen de seguimiento sobre Entorno"
          >
            <ImputResumen
              title="Cometarios de Entorno"
              subtitle={
                context?.saveAcuerdos.cSUBE_Descripcion ||
                cREGSS_Descripcion ||
                cSUBTS_Descripcion
              }
              disabled={disabledRelacion}
              setDisabled={() => setDisabledRelacion(!disabledRelacion)}
              value={context?.commentsAcuerdos.entorno}
              setValue={(value) =>
                contextSet.setCommentsAcuerdos({ entorno: value })
              }
              flexDirection="column"
              ramo={context?.saveAcuerdos.cENT_Descripcion || cRAMC_Descripcion}
            />
            <ImputResumen
              title="Acuerdo"
              status={cESTS_Descripcion}
              subtitle={cSUBTS_Descripcion || cREGSS_Descripcion}
              value={contextSet.state.commentsAcuerdos.segEntorno}
              setValue={(value) => {
                setSComentarios(value);
                contextSet.setCommentsAcuerdos({ segEntorno: value });
              }}
              disabled={disabledAcuerdo}
              setDisabled={() => setDisabledAcuerdos(!disabledAcuerdo)}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>Ramo</h4>
              <span
                style={{
                  width: '120px',
                  height: '25px',
                  backgroundColor: '#85858585',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                {context.saveAcuerdos.cENT_Descripcion || cRAMC_Descripcion}
              </span>
            </div>
          </DivContainer>
          {subArea.length > 0 ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'initial',
              }}
              onClick={() => setOpen(true)}
            >
              <h4 style={{ color: '#3898EC' }}>Áreas</h4>
              <div className={classes.root}>
                {subArea &&
                  subArea.map(
                    ({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                      status ? (
                        <Chip
                          label={cSUBA_Descripcion}
                          style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                          onDelete={() => deleteSubarea(cSUBA_Id, cARE_Id)}
                          deleteIcon={<Clear />}
                          key={index}
                        />
                      ) : null
                  )}
              </div>
            </div>
          ) : null}
          <ButtonNavigation
            text="Guardar y continuar a Relación con Patria"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => saveInfoByIds('relaciónPatria')}
          />
          <ButtonNavigation
            text="Guardar y registrar nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => saveInfoByIds('nuevoSeguimiento')}
          />
          <ButtonNavigation
            text="Volver a Entorno"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => onClickReturnEntorno()}
          />
        </>
      )}
      <Areas_Inv
        areas={areas}
        subArea={subArea}
        open={open}
        setOpen={() => setOpen(false)}
        onChangue={onchange}
      />
    </div>
  );
}
