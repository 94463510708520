import React, { useContext, useEffect, useState } from 'react';
import { MyContext } from '../Context';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import CedenteCancelar from './CedenteCancelar';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import { ArrowBackIos } from '@material-ui/icons';
import DatosReuProgramada from './common/DatosReuProgramada';
import {GET_TIENE_ACUERDO, GET_ACUERDOS_BY_REU} from '../../mutation/mutation';
import DialogAlert from '../../components/DiaologAlert';

export default function Cedente({ onClick, onclick1, onGoBack, irAConsulta }) {
  const context = useContext(MyContext);
  const [cancelacion, setCancelacion] = useState(false);
  const [showComments, setShowComments] = useState(false);
  // const [editReuProgram, setEditReuProgram] = useState(false);
  const [statusReunion, setStatusReunion] = useState([]);
  const [tieneAcuerdo, setTieneAcuerdo] = useState(false);
  const [contentModal, setContentModal] = useState({
    title: '',
    message: '',
    open: false
  });

  const [getInfo] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setStatusReunion(JSON.parse(dynamicGet.response));
    },
  });

  const [getAcuerdos] = useMutation(GET_ACUERDOS_BY_REU, {
    onCompleted({ getAcuerdos }) {
      console.log('getAcuerdos Executed', getAcuerdos);
      if (getAcuerdos) {
        const acuerdos = getAcuerdos || [];
        if(acuerdos.length > 0){
            getTieneAcuerdos({
                variables: {
                    ids: [...acuerdos],
                    status: '',
                },
            });
        }
        else{
          // sin acuerdos ir a entorno
          onclick1();
        }
      } else {
        // ir a entorno
        onclick1();
      }
    },
    onError(err) {
      console.log(err.name, err.message);
      console.error('Error ACU ->', err);
    },
  });

  const [getTieneAcuerdos] = useMutation(GET_TIENE_ACUERDO, {
    onCompleted({ getTieneAcuerdo }) {
      console.log('RESPONSE STATUS REUNION ----->>>', getTieneAcuerdo);
      setTieneAcuerdo(JSON.parse(getTieneAcuerdo.response));
      setContentModal({...contentModal, 
        open: (getTieneAcuerdo.response === 'true' ? true : false),
        title: 'Ya existe un acuerdo para esta reunión',
        message: '¿deseas ir a consulta de reportes para editar?'
    })
    },
    onError(err) {
      console.log('Error', err);
    },
  });

  const setStatus = async(CVIS_Id, index) => {
    context.setSaveAcuerdos({
      ...context.state.saveAcuerdos,
      CVIS_Id: CVIS_Id,
    });
    if (index === 0) {
      console.log("Contexto 1", context.state);
      await getAcuerdos({
        variables: {
        cREU_Id: String(context.state.reuProgramadaData.cREU_Id),
      }})
      // onclick1();
    } else if (index === 1) {
      // setEditReuProgram(true);
      console.log("Contexto 2", context.state);
      setCancelacion(true);
    } else if (index === 2) {
      setShowComments(true);
      setCancelacion(true);
    }
  };

  useEffect(() => {
    getInfo({
      variables: {
        input: {
          table: 'CVIS_VisitaAcuerdo',
          columns: ['CVIS_Id', 'CVIS_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <DialogAlert
        title={contentModal.title}
        text={contentModal.message}
        textButtonA="Cerrar"
        textButtonB='Aceptar'
        open={contentModal.open}
        handleClose={() => {
          // cierra modal
          setContentModal({ ...contentModal, open: false });
        }}
        enableConfirmBtn={true}
        handleConfirm={() => {
          // redireccionar a edicion
          context.clearState();
          irAConsulta(true);
        }}
      />
      <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <div style={{ display: 'flex' }}>
        <h4 style={{ color: '#01539C' }}>Nuevo Reporte de Visita </h4>
        <h4 style={{ color: '#01539C' }}>` &gt; Reunión Programada`</h4>
        <h4> &gt; {context.state.reuProgramadaData.cREU_Titulo} </h4>
      </div>
      <div
        style={{
          backgroundColor: '#E5E5E5',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <h2>{context.state.reuProgramadaData.cREU_Titulo} </h2>
        <h4>{`[${context.state.reuProgramadaData.cREU_Lugar},${context.state.reuProgramadaData.cREU_FechaInicio},personas]`}</h4>
      </div>
      {!cancelacion ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2>¿Tu visita se llevó a cabo conforme a la agenda?</h2>
          {statusReunion.map((status, index) => (
            <ButtonNavigation
              width="70%"
              text={status.CVIS_Descripcion}
              style={{ cursor: 'pointer' }}
              onClick={() => setStatus(status.CVIS_Id, index)}
            />
          ))}
        </div>
      ) : showComments ? (
        <CedenteCancelar onClick={onClick} />
      ) : (
        <DatosReuProgramada seguirEntorno={onclick1} irEdicion={(val) => {irAConsulta(val)}}/>
      )}
    </div>
  );
}
