import React, {
    useState,
    useContext,
    useRef,
} from 'react';
import Button from '@material-ui/core/Button';
import useUploadFile from '../../helpers/hooks/useUploadFile';
import { MyContext } from '../Context';
import DialogAlert from '../DiaologAlert';
import { useMediaQuery } from '@material-ui/core';
const UploadFile = ({ name, setIsRequired, viaticsIds = [] }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');    
    const { files, setFiles, upload } = useUploadFile({viaticsIds});
    const context = useContext(MyContext);
    const fileInput = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [msgModal, setMsgModal] = useState({
        title: 'Archivo cargado exitosamente',
        text: ''
    });

    const onChangeFile = async () => {
        try {
            const fileExcel = await fileInput.current.files[0];
            let base64 = '';
            const fr = new FileReader();
            fr.readAsDataURL(fileExcel);
            fr.addEventListener('load', (e) => {
                base64 = e.target.result;
                const base64File = base64.split(';base64,').pop();
                const idViaticos = context.state.viaticosProps.cVIT_Id ? context.state.viaticosProps.cVIT_Id : ''
                const fileName = String(fileExcel.name).split('.')
                const extencion = fileName[1];
                let objFile = {};
                if (viaticsIds.length > 0) {
                    const tempFiles = [];
                    viaticsIds.forEach((id) => {
                        objFile = {
                            base64: base64File,
                            // name: `${fileName[0]}_${name}_${idViaticos}.${extencion}`,
                            name: `${fileName[0]}_${name}_${id}.${extencion}`,
                            type: fileExcel.type,
                        }
                        tempFiles.push(objFile)
                    })
                    setFiles([...files, ...tempFiles]);
                } else {
                    objFile = {
                        base64: base64File,                        
                        name: `${fileName[0]}_${name}_${idViaticos}.${extencion}`,
                        type: fileExcel.type,
                    }
                    setFiles([...files, objFile]);
                }                              
            });
        } catch (error) {
            console.log(error)
        }
    };
    const deleteFile = (fileName) => {
        const filterWithoutFile = files.filter(({ name }) => name !== fileName);
        setFiles(filterWithoutFile);

    }
    return (
        <div style={{ display: 'flex' }}>
            <DialogAlert
                title={msgModal.title}
                text={msgModal.text}
                textButtonA="Aceptar"
                open={showModal}
                handleClose={() => setShowModal(false)}
            />
            <div
                style={{
                    color: '#3898Ec',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: isMobile ? '100%' : '50%',
                    marginLeft: 15,
                    justifyContent: isMobile ? 'center' : null 
                }}
            >
                <h3 style={{ marginTop: '25px' }}>
                    <strong>Subir archivos de {name}</strong>
                </h3>
                <Button
                    style={{ width: '100%' }}
                    id="uploadImages"
                    variant="contained"
                    component="label"
                >
                    Seleccionar archivo
                    <input
                        type="file"
                        name="name"
                        ref={fileInput}
                        style={{ display: 'none' }}
                        onChange={onChangeFile}
                    />
                </Button>
                <br />
                {files.length > 0 && (
                    <Button
                        fullWidth
                        style={{ background: '#3898EC', color: '#FFFF' }}
                        onClick={() => {
                            setShowModal(upload());
                            if (setIsRequired) {
                                setIsRequired(false);
                            }
                        }}
                    >
                        Subir {name}
                    </Button>
                )}
                <ul style={{ marginTop: 10, width: '100%', padding: 0 }}>
                    {files.map(({ name }) => {
                        return (
                            <li key={name} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {name}
                                <span style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => deleteFile(name)}>X</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default UploadFile;