import React, { useContext } from 'react'
import ButtonNavigation from "../Viaticos/ButtonNavigation";
import newSolicitud from '../../assets/images/icons/documento.svg';
import processSolicitud from '../../assets/images/icons/lupa.svg';
import ticket from '../../assets/images/icons/tickets.svg';
import { MyContext } from '../Context';

const ButtonsTravelAssistant = ({ actionBtn, handleClick, setCVIT_Avance }) => {
    const context = useContext(MyContext);
    return (
        <>
            <ButtonNavigation
                text="Nueva solicitud"
                justifyContent="space-between"
                style={{
                    padding: '0 30px',
                    display: 'grid',
                    gridTemplateColumns: '50fr 50fr',
                    textAlign: 'left',
                }}
                img={newSolicitud}
                backgroundColor={actionBtn.newApplication ? '#3898EC' : null}
                border={actionBtn.newApplication ? '#3898EC' : null}
                color={actionBtn.newApplication ? '#FFF' : null}
                onClick={() => {
                    handleClick('newApplication', true);
                    context.clearState();
                }}
            />

            <ButtonNavigation
                text="Todas las solicitudes"
                justifyContent="space-between"
                style={{
                    padding: '0 30px',
                    display: 'grid',
                    textAlign: 'right',
                    gridTemplateColumns: '10fr 15fr',
                }}
                img={processSolicitud}
                backgroundColor={actionBtn.progressSolicitud ? '#3898EC' : null}
                border={actionBtn.progressSolicitud ? '#3898EC' : null}
                color={actionBtn.progressSolicitud ? '#FFF' : null}
                onClick={() => {
                    handleClick('progressSolicitud', true);
                }}
            />
            <ButtonNavigation
                text="Tickets pendientes"
                img={ticket}
                style={{
                    padding: '0 30px',
                    display: 'grid',
                    gridTemplateColumns: '50fr 50fr',
                    textAlign: 'left',
                }}
                justifyContent="space-between"
                backgroundColor={actionBtn.pendingTickets ? '#3898EC' : null}
                border={actionBtn.pendingTickets ? '#3898EC' : null}
                color={actionBtn.pendingTickets ? '#FFF' : null}
                onClick={() => {
                    handleClick('pendingTickets', true);
                    setCVIT_Avance(1);
                }}
            />
        </>
    );
}

export default ButtonsTravelAssistant;