import { useMutation } from 'react-apollo';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { DYNAMIC_UPDATE } from '../../mutation/mutation';
import { useEffect } from 'react';
import moment from 'moment';
import DialogAlert from '../DiaologAlert';
import {
  useGetOptionsSeguimiento,
  useGetAnswersSeg,
  useGetOptionsRelacion,
  useGetAnswersSegRel,
} from '../../helpers/hooks/useAcuerdos';

const EditReunion = ({
  pdfData,
  setEdit,
  reunions,
  onSearch,
  reunionSeleccionada,
  setInfoIsLoaded,
}) => {
  const cACU_Id = pdfData[0].cACU_Id;
  const { optionsStatus, optionsTipoSeg } = useGetOptionsSeguimiento();
  const {
    optionsActionsSeg,
    optionsSegRel,
    optionsStatusSeg,
    optionsTemasSeg,
  } = useGetOptionsRelacion();
  const { loadingAnswers, answers } = useGetAnswersSeg({ cACU_Id });
  const { loadingAnswers: loadingAnswersSegRel, answers: answersSegRel } =
    useGetAnswersSegRel({ cACU_Id });
  console.log('answers', optionsActionsSeg);
  let dateClear = pdfData[0].fecha;
  let datesub = dateClear.substr(0, 9);
  console.log(datesub);
  console.log('data obtenida en editreunion: ', reunions);
  console.log('creuid obtenido: ', reunions[0].cREU_Id);
  let cEntorno = '';
  let cSegEntorno = '';
  let cRelacion = '';
  let cSegRelacion = '';
  if (pdfData[0].comentarios != undefined) {
    cEntorno = pdfData[0].comentarios.entorno;
    cSegEntorno = pdfData[0].comentarios.segEntorno;
    cRelacion = pdfData[0].comentarios.relacion;
    cSegRelacion = pdfData[0].comentarios.segRelacion;
  }
  const [coments, setComents] = useState();

  const [contentModal, setContentModal] = useState({
    title: '',
    message: '',
  });
  const [feedbackModal, setFeedbackModal] = useState(false);

  //setComents(pdfData[0].comentarios)
  const [updateTit] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updatetit response: ', response);
      }
    },
  });

  const [updateLug] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateLug response: :', response);
      }
    },
  });

  const [updateDate1] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateLug response: :', response);
      }
    },
  });

  const [typeDG] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('UPDATE TYPEDG: ', response);
      }
    },
  });
  const [updateInfo] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO UPDATE', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log(response);
      }
    },
  });

  const [entornoE1] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [entornoE2] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [entornoE3] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [segEnt1] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [segEnt2] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [segEnt3] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [cREL1] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [cREL2] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  const [cREL3] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  //segEntSubArea

  const [segEntArea] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateSegEntArea response: ', response);
      }
    },
  });

  const [segEntSubArea] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateSegEntSubArea response: ', response);
      }
    },
  });

  const [upComents] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESULTADO', dynamicUpdate);
      if (dynamicUpdate.response !== '') {
        const response = JSON.parse(dynamicUpdate.response);
        console.log('updateENT1 response: ', response);
      }
    },
  });

  //cRAMC_Id, cTEMS_Id, cSUBTS_Id

  const [updateValues, setUpdateValues] = useState(false);

  const [values, setValues] = useState([
    [{ title: 'Datos generales', inputType: '' }],

    [
      {
        title: 'Titulo de la reunion',
        inputType: 'text',
        value: `${pdfData[0].reunionNombre}`,
      },
      { title: 'Lugar', inputType: 'text', value: `${pdfData[0].lugar}` },
      {
        title: 'Fecha',
        inputType: 'date',
        value: `${pdfData[0].fecha.substr(0, 10)}`,
      },
      { title: 'Tipo', inputType: 'text', value: `${pdfData[0].tipo}` },
      {
        title: 'Tipo Reunion',
        inputType: 'text',
        value: `${pdfData[0].tipoReunion}`,
      },
    ],

    [{ title: 'Información entorno', inputType: `` }],
    [
      {
        title: 'Entorno step1',
        id: 'no',
        inputType: 'text',
        value: `${pdfData[0].entorno.step1}`,
      },
      {
        title: 'Entorno step2',
        id: 'no',
        inputType: 'text',
        value: `${pdfData[0].entorno.step2}`,
      },
      {
        title: 'Entorno step3',
        id: 'no',
        inputType: 'text',
        value: `${pdfData[0].entorno.step3}`,
      },
      {
        title: 'Seguimiento entorno step1',
        inputType: 'text',
        value: `${pdfData[0].seguimientoEntorno.ramoCRM}`,
      },
      {
        title: 'Seguimiento entorno step2',
        inputType: 'text',
        value: `${pdfData[0].seguimientoEntorno.temaSeguimiento}`,
      },
      {
        title: 'Seguimiento entorno step3',
        inputType: 'text',
        value: `${pdfData[0].seguimientoEntorno.subTemaSeguimiento}`,
      },
    ],

    //ent sdfiujhiuda

    // [{ title: 'Información entorno2', inputType: `` }],
    // [
    //   {
    //     title: 'Entorno step12',
    //     id: 'no',
    //     inputType: 'text',
    //     value: ``,
    //   },
    //   {
    //     title: 'Entorno step22',
    //     id: 'no',
    //     inputType: 'text',
    //     value: ``,
    //   },
    //   {
    //     title: 'Entorno step32',
    //     id: 'no',
    //     inputType: 'text',
    //     value: ``,
    //   },
    //   { title: 'Seguimiento entorno step12', inputType: 'text', value: `` },
    //   { title: 'Seguimiento entorno step22', inputType: 'text', value: `` },
    //   { title: 'Seguimiento entorno step32', inputType: 'text', value: `` },
    // ],

    [{ title: 'Información seguimiento entorno', inputType: `` }],
    [
      // {
      //   title: 'Estatus de seguimiento',
      //   inputType: 'Select',
      //   value: answers[0]?.cESTS_Id,
      // },
      {
        title: 'Reporte de seguimiento',
        inputType: 'Select',
        value: answers[0]?.cRES_Id,
      },
      {
        title: 'Área',
        inputType: 'text',
        value: `${pdfData[0].seguimientoEntorno.area}`,
      },
      {
        title: 'SubÁrea',
        inputType: 'text',
        value: `${pdfData[0].seguimientoEntorno.subArea}`,
      },
    ],
    [
      {
        title: 'Estatus de seguimiento',
        inputType: 'Select',
        value: answers[0]?.cESTS_Id,
      },
    ],
    [{ title: 'Información seguimiento relación', inputType: `` }], //7
    [
      { title: 'Reporte de seguimiento', inputType: 'Select', value: `` },
      { title: 'Seguimiento', inputType: 'Select', value: `` },
      {
        title: 'Área',
        inputType: 'text',
        value: `${pdfData[0].seguimientoRelacionPatria.areaRelacion}`,
      },
      {
        title: 'SubÁrea',
        inputType: 'text',
        value: `${pdfData[0].seguimientoRelacionPatria.subAreaRelacion}`,
      },
    ],
    [
      {
        title: 'Estatus de seguimiento',
        inputType: 'Select',
        value: ``,
      },
      {
        title: 'Acción de seguimiento',
        inputType: 'Select',
        value: ``,
      },
    ],

    [{ title: 'Información relacion', inputType: `` }],
    [
      {
        title: 'Relacion step1',
        id: 'no',
        value: `${pdfData[0].relacion.cRELP_1}`,
      },
      {
        title: 'Relacion step2',
        id: 'no',
        inputType: 'text',
        value: `${pdfData[0].relacion.cRELP_2}`,
      },
      {
        title: 'Relacion step3',
        id: 'no',
        inputType: 'text',
        value: `${pdfData[0].relacion.cRELP_3}`,
      },
    ],

    [{ title: 'Comentarios', inputType: `` }],
    [
      {
        title: 'Comentarios entorno',
        id: 'ComentariosEntorno',
        name: 'ComentariosEntorno',
        rows: '5',
        cols: '33',
        value: `${cEntorno}`,
      },
      {
        title: 'Comentarios seguimiento',
        id: 'ComentariosSeguimiento',
        name: 'ComentariosSeguimiento',
        rows: '5',
        cols: '33',
        value: `${cSegEntorno}`,
      },
      {
        title: 'Comentarios relacion',
        id: 'ComentariosSeguimiento',
        name: 'ComentariosSeguimiento',
        rows: '5',
        cols: '33',
        value: `${cRelacion}`,
      },
      {
        title: 'Comentarios seguimiento relación',
        id: 'ComentariosSegRelación',
        name: 'ComentariosRelación',
        rows: '5',
        cols: '33',
        value: `${cSegRelacion}`,
      },
    ],
  ]);
  const updateSeguimiento = () => {
    const cRES_Id = String(values[5][0]?.value);
    const areas = String(values[5][1]?.value) || '';
    const subAreas = String(values[5][2]?.value) || '';
    // const cRES_Id = values[6][0].value
    const cESTS_Id = String(values[6][0].value);
    console.log(
      'SEGUIMIENTGO OBTENIDO seg=========: ',
      cRES_Id,
      areas,
      subAreas,
      cESTS_Id
    );
    let columns = [
      {
        setkey: 'cESTS_Id',
        setval: cESTS_Id,
      },
      {
        setkey: 'cRES_Id',
        setval: cRES_Id,
      },
      {
        setkey: 'areas',
        setval: areas,
      },
      {
        setkey: 'subAreas',
        setval: subAreas,
      },
    ];
    let conditions = [
      {
        valone: 'cACU_Id',
        valtwo: String(cACU_Id),
        condition: '=',
        logic: 'AND',
      },
    ];
    updateInfo({
      variables: {
        input: {
          table: 'cSEGE_SeguimientoEntorno',
          columns,
          conditions,
        },
      },
    });
  };
  const updateSegRelacion = () => {
    const cSEGR_Id = String(values[8][0].value);
    const cTEMSR_Id = String(values[8][1].value);
    const areas = String(values[8][2].value) || '';
    const subAreas = String(values[8][3].value) || '';
    const cESTS_Id = String(values[9][0].value);
    const cACCSR_Id = String(values[9][1].value);

    console.log(
      'SEGUIMIENTGO OBTENIDO segrel =========: ',
      cSEGR_Id,
      cTEMSR_Id,
      areas,
      subAreas,
      cESTS_Id,
      cACCSR_Id
    );
    let columns = [
      {
        setkey: 'cSEGR_Id',
        setval: cSEGR_Id,
      },
      {
        setkey: 'cTEMSR_Id',
        setval: cTEMSR_Id,
      },
      {
        setkey: 'areas',
        setval: areas,
      },
      {
        setkey: 'subAreas',
        setval: subAreas,
      },
      {
        setkey: 'cESTS_Id',
        setval: cESTS_Id,
      },
      {
        setkey: 'cACCSR_Id',
        setval: cACCSR_Id,
      },
    ];
    let conditions = [
      {
        valone: 'cACU_Id',
        valtwo: String(cACU_Id),
        condition: '=',
        logic: 'AND',
      },
    ];
    updateInfo({
      variables: {
        input: {
          table: 'cSEGRP_SeguimientoRelacionPatria2',
          columns,
          conditions,
        },
      },
    });
  };
  useEffect(() => {
    console.log('titulo obtenido useefect: ', values[1][0].value);
    //   //en tabla CREU_REUNION
    if (updateValues == true) {
      updateSeguimiento();
      updateSegRelacion();
      updateTit({
        variables: {
          input: {
            table: 'cREU_Reunion',
            columns: { setkey: 'cREU_Titulo', setval: `${values[1][0].value}` },
            conditions: [
              {
                valone: 'cREU_Id',
                valtwo: `${reunionSeleccionada}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });
      //   //en tabla CREU_REUNION
      updateLug({
        variables: {
          input: {
            table: 'cREU_Reunion',
            columns: { setkey: 'cREU_Lugar', setval: `${values[1][1].value}` },
            conditions: [
              {
                valone: 'cREU_Id',
                valtwo: `${reunionSeleccionada}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });
      // en tabla CREU_REUNION
      updateDate1({
        variables: {
          input: {
            table: 'cREU_Reunion',
            columns: {
              setkey: 'cREU_FechaInicio',
              setval: `${values[1][2].value}`,
            },
            conditions: [
              {
                valone: 'cREU_Id',
                valtwo: `${reunionSeleccionada}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      // en tabla CREU_REUNION
      typeDG({
        variables: {
          input: {
            table: 'cREU_Reunion',
            columns: {
              setkey: 'cREU_TipoReunion',
              setval: `${values[1][3].value}`,
            },
            conditions: [
              {
                valone: 'cREU_Id',
                valtwo: `${reunionSeleccionada}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      entornoE1({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cENT_Id', setval: `${values[3][0].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      entornoE2({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cTEM_Id', setval: `${values[3][1].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      entornoE3({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cSUBE_Id', setval: `${values[3][2].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      entornoE3({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cTIPRE_Id', setval: `${values[1][4].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      segEnt1({
        variables: {
          input: {
            table: 'cSEGE_SeguimientoEntorno',
            columns: { setkey: 'cRAMC_Id', setval: `${values[3][3].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      segEnt2({
        variables: {
          input: {
            table: 'cSEGE_SeguimientoEntorno',
            columns: { setkey: 'cTEMS_Id', setval: `${values[3][4].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      segEnt3({
        variables: {
          input: {
            table: 'cSEGE_SeguimientoEntorno',
            columns: { setkey: 'cSUBTS_Id', setval: `${values[3][5].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      //cREL1
      cREL1({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cRELP_1', setval: `${values[11][0].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      cREL2({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cRELP_2', setval: `${values[11][1].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      cREL3({
        variables: {
          input: {
            table: 'cACU_CuerdosVieje',
            columns: { setkey: 'cRELP_3', setval: `${values[11][2].value}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      //segEntArea

      segEntArea({
        variables: {
          input: {
            table: 'cSEGE_SeguimientoEntorno',
            columns: { setkey: 'cARE_Id', setval: `${values[5][1]}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      segEntSubArea({
        variables: {
          input: {
            table: 'cSEGE_SeguimientoEntorno',
            columns: { setkey: 'cSUBA_Id', setval: `${values[5][2]}` },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
              // {
              //     valone: 'cPAI_Id',
              //     valtwo: id,
              //     condition: '=',
              //     logic: 'AND'
              // },
            ],
          },
        },
      });

      upComents({
        variables: {
          input: {
            table: 'cCOMA_ComentariosAcuerdo',
            columns: {
              setkey: 'cCOMA_Descripcion',
              setval: `${JSON.stringify(coments)}`,
            },
            conditions: [
              {
                valone: 'cACU_Id',
                valtwo: `${pdfData[0].cACU_Id}`,
                condition: '=',
              },
            ],
          },
        },
      });
    }
  }, [updateValues]);
  useEffect(() => {
    if (!loadingAnswers && answers.length > 0) {
      // values[5][0].value = answers[0]?.cESTS_Id;
      values[5][0].value = answers[0]?.cRES_Id;
      values[5][1].value = answers[0]?.areas || '';
      values[5][2].value = answers[0]?.subAreas || '';
      values[6][0].value = answers[0]?.cESTS_Id;
      setValues([...values]);
    }
    if (!loadingAnswersSegRel && answersSegRel.length > 0) {
      values[8][0].value = answersSegRel[0]?.cSEGR_Id;
      values[8][1].value = answersSegRel[0]?.cTEMSR_Id;
      values[8][2].value = answersSegRel[0]?.areas || '';
      values[8][3].value = answersSegRel[0]?.subAreas || '';
      values[9][0].value = answersSegRel[0]?.cESTS_Id;
      values[9][1].value = answersSegRel[0]?.cACCSR_Id;
      setValues([...values]);
    }
  }, [answers, answersSegRel]);
  const handleChange = (e, index, indexInputs) => {
    const { value } = e.target;
    console.log(value, e.target, index, indexInputs);
    values[indexInputs][index].value = value;
    setValues([...values]);
    console.log('valores de los input: ', values);
    console.log('valores del pdf: ', pdfData);
    console.log('ID del rep seleccionado: ', pdfData[0].cACU_Id);
    console.log('titulo obtenido: ', values[1][0].value);
    {
      console.log(`VALOR DEL ENTORNO STEP1: ${values[3][0].value}`);
    }
    console.log('centid en pdfdata: :', pdfData[0].centid);
    console.log('COMENTARIOS: ', pdfData[0].comentarios);
    console.log('DATOS SEGENT: ', pdfData[0].seguimientoEntorno.subArea);
    setComents(pdfData[0].comentarios);
    console.log('comentarios sethandle:', coments);
    coments.entorno = values[13][0].value;
    coments.segEntorno = values[13][1].value;
    coments.relacion = values[13][2].value;
    coments.segRelacion = values[13][3].value;
    console.log('comentarios sethandle2:', coments);
    console.log(typeof coments);
    console.log('VALOR [9][1]: ', answersSegRel[0]?.cACCSR_Id);
  };
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\,/;
    if (!regex.test(key)) {
      e.preventDefault();
    } else {
      console.log('You pressed a key: ' + key);
    }
  }
  if (loadingAnswers && loadingAnswersSegRel) return null;
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {feedbackModal && (
        <DialogAlert
          title={contentModal.title}
          text={contentModal.message}
          textButtonA="Aceptar"
          open={feedbackModal}
          handleClose={() => {
            onSearch();
            setUpdateValues(false);
            setEdit(false);
          }}
        />
      )}
      {console.log(pdfData[0])}
      {console.log(cEntorno)}

      {console.log('ID a pasar al dynamicupdate: ', pdfData[0].cACU_Id)}
      {values.map((arrayInputs, indexInputs) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {arrayInputs.map(
            ({ id, title, inputType, value, name, rows, cols }, index) =>
              inputType == '' ? (
                <p
                  style={{
                    height: 40,
                    fontSize: 23,
                    fontWeight: 'bolder',
                    marginTop: '2%',
                  }}
                >
                  {`${title}`}
                </p>
              ) : id == 'ComentariosEntorno' ||
                id == 'ComentariosSeguimiento' ||
                id == 'ComentariosRelación' ||
                id == 'ComentariosSegRelación' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <label
                    for={id}
                    style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}
                  >
                    {title}
                  </label>
                  {index === 0 ? (
                    <textarea
                      id={id}
                      onChange={(e) => handleChange(e, index, indexInputs)}
                      name={name}
                      cols={cols}
                      value={value}
                      placeholder={`${cEntorno}`}
                      rows={rows}
                      style={{ height: 120, width: '100%' }}
                    />
                  ) : index === 1 ? (
                    <textarea
                      id={id}
                      onChange={(e) => handleChange(e, index, indexInputs)}
                      name={name}
                      cols={cols}
                      value={value}
                      placeholder={`${cSegEntorno}`}
                      rows={rows}
                      style={{ height: 120, width: '100%' }}
                    />
                  ) : index === 2 ? (
                    <textarea
                      id={id}
                      onChange={(e) => handleChange(e, index, indexInputs)}
                      name={name}
                      cols={cols}
                      value={value}
                      placeholder={`${cRelacion}`}
                      rows={rows}
                      style={{ height: 120, width: '100%' }}
                    />
                  ) : index === 3 ? (
                    <textarea
                      id={id}
                      onChange={(e) => handleChange(e, index, indexInputs)}
                      name={name}
                      cols={cols}
                      value={value}
                      placeholder={`${cSegRelacion}`}
                      rows={rows}
                      style={{ height: 120, width: '100%' }}
                    />
                  ) : null}
                  {/* <textarea
                    id={id}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    name={name}
                    cols={cols}
                    placeholder={`${pdfData[0].comentarios.entorno}`}
                    rows={rows}
                    style={{ height: 120, width: '100%' }}
                  /> */}
                </div>
              ) : title == 'Tipo Reunion' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].ctipreid}
                    >{`Seleccionado: ${pdfData[0].tipoReunion}`}</option>
                    <option value={1}>{`Reunion Casual`}</option>
                    <option value={2}>{`Reunion Programada`}</option>
                  </select>
                </div>
              ) : title == 'Entorno step1' ||
                title == 'Seguimiento entorno step1' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    {title == 'Entorno step1' ? (
                      <option
                        selected
                        value={pdfData[0].centid}
                      >{`Seleccionado: ${pdfData[0].entorno.step1}`}</option>
                    ) : (
                      <option
                        selected
                        value={pdfData[0].cramsid}
                      >{`Seleccionado: ${pdfData[0].seguimientoEntorno.ramoCRM}`}</option>
                    )}

                    <option value={1}>{`Daños`}</option>
                    <option value={2}>{`Personas`}</option>
                    <option value={3}>{`Fianzas`}</option>
                    <option value={4}>{`Todos los Ramos`}</option>
                  </select>
                </div>
              ) : title == 'Entorno step2' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].ctemid}
                    >{`Seleccionado: ${pdfData[0].entorno.step2}`}</option>
                    <option value={1}>{`Cambios Normativos / Legales`}</option>
                    <option
                      value={2}
                    >{`Entorno Economico / Politico / Social`}</option>
                    <option value={3}>{`Competencia`}</option>
                    <option value={4}>{`Mercado Local`}</option>
                    <option value={5}>{`Mercado de Reaseguro`}</option>
                    <option
                      value={6}
                    >{`Participacion Cedente / Corredor en el Mercado`}</option>
                    <option
                      value={7}
                    >{`Estrategia de la Cedente / Corredor`}</option>
                  </select>
                </div>
              ) : title == 'Relacion step1' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].relacion.cRELP_1}
                    >{`Seleccionado: ${pdfData[0].relacion.cRELP_1}`}</option>
                    <option value={'Programa de reaseguro'}>
                      Programa de reaseguro
                    </option>
                    <option value={'Siniestros'}>Siniestros</option>
                    <option value={'Pendientes Con Patria'}>
                      Pendientes Con Patria
                    </option>
                    <option value={'Presentacion Patria'}>
                      Presentacion Patria
                    </option>
                    <option value={'Capacitacion/Seminarios'}>
                      Capacitacion/Seminarios
                    </option>
                    <option value={'Impuesto Retenido en el Extranjero'}>
                      Impuesto Retenido en el Extranjero
                    </option>
                    <option value={'Expediente PLD'}>Expediente PLD</option>
                  </select>
                </div>
              ) : title == 'Relacion step2' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].relacion.cRELP_2}
                    >{`Seleccionado: ${pdfData[0].relacion.cRELP_2}`}</option>
                    <option value={'Se mantiene sin cambios'}>
                      Se mantiene sin cambios
                    </option>
                    <option value={'Cambios'}>Cambios</option>
                    <option value={'Oportunidades para Patria'}>
                      Oportunidades para Patria
                    </option>
                    <option value={'Líder y Otros Participantes'}>
                      Líder y Otros Participantes
                    </option>
                    <option value={'Suscripción'}>Suscripción</option>
                    <option value={'Apetito de Riesgo'}>
                      Apetito de Riesgo
                    </option>
                    <option value={'Necesidades'}>Necesidades</option>
                    <option value={'Contratos Automáticos'}>
                      Contratos Automáticos
                    </option>
                    <option value={'Facultativo'}>Facultativo</option>
                    <option value={'Resultados'}>Resultados</option>
                    <option value={'Estrategia'}>Estrategia</option>
                    <option value={'Calificación Financiera'}>
                      Calificación Financiera
                    </option>
                    <option value={'Cambios Organizacionales'}>
                      Cambios Organizacionales
                    </option>
                    <option value={'Fortalezas Patria'}>
                      Fortalezas Patria
                    </option>
                    <option value={'Áreas de Mejora'}>Áreas de Mejora</option>
                    <option value={'Considerarlos para Próximo Seminario'}>
                      Considerarlos para Próximo Seminario
                    </option>
                    <option value={'Plática/Presentación'}>
                      Plática/Presentación
                    </option>
                    <option value={'Otro'}>Otro</option>
                    <option value={'Al corriente'}>Al corriente</option>
                    <option value={'Incompleto'}>Incompleto</option>
                    <option value={'Completo'}>Completo</option>
                    <option value={'Incompleto'}>Incompleto</option>
                    <option value={'Actualización'}>Actualización</option>
                  </select>
                </div>
              ) : title == 'Relacion step3' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].relacion.cRELP_3}
                    >{`Seleccionado: ${pdfData[0].relacion.cRELP_3}`}</option>
                    <option value={'Calendario Renovación'}>
                      Calendario Renovación
                    </option>
                    <option value={'Límites'}>Límites</option>
                    <option value={'Estructura'}>Estructura</option>
                    <option value={'Primas Estimadas'}>Primas Estimadas</option>
                    <option value={'Cúmulos Catastróficos'}>
                      Cúmulos Catastróficos
                    </option>
                    <option value={'Clausulado'}>Clausulado</option>
                    <option value={'Aceptaciones Especiales'}>
                      Aceptaciones Especiales
                    </option>
                    <option value={'Contratos actuales'}>
                      Contratos actuales
                    </option>
                    <option value={'Nuevos contratos'}>Nuevos contratos</option>
                    <option value={'Apetito de Riesgo'}>
                      Apetito de Riesgo
                    </option>
                    <option value={'Crecimiento'}>Crecimiento</option>
                    <option value={'Diversificación'}>Diversificación</option>
                    <option value={'Otros'}>Otros</option>
                    <option value={'Disponibilidad'}>Disponibilidad</option>
                    <option value={'Pago de Siniestros'}>
                      Pago de Siniestros
                    </option>
                    <option value={'Comunicación/Presencia'}>
                      Comunicación/Presencia
                    </option>
                    <option value={'Tiempo de Respuesta'}>
                      Tiempo de Respuesta
                    </option>
                    <option value={'Otro'}>Otro</option>
                    <option value={'Disponibilidad'}>Disponibilidad</option>
                    <option value={'Pago de Siniestros'}>
                      Pago de Siniestros
                    </option>
                    <option value={'Comunicación/Presencia'}>
                      Comunicación/Presencia
                    </option>
                    <option value={'Otro'}>Otro</option>
                    <option value={'Suscripción/Reaseguro'}>
                      Suscripción/Reaseguro
                    </option>
                    <option value={'Siniestros'}>Siniestros</option>
                    <option value={'Riesgos/Actuaría/Pricing'}>
                      Riesgos/Actuaría/Pricing
                    </option>
                  </select>
                </div>
              ) : title == 'Seguimiento entorno step2' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].ctemsid}
                    >{`Seleccionado: ${pdfData[0].seguimientoEntorno.temaSeguimiento}`}</option>
                    <option value={1}>{`Analizar Informacion`}</option>
                    <option value={2}>{`Cotizacion`}</option>
                    <option value={3}>{`Confirmacion`}</option>
                    <option value={4}>{`Acciones de Seguimiento`}</option>
                    <option value={5}>{`Temas Pendientes`}</option>
                    <option
                      value={6}
                    >{`Participacion Cedente / Corredor en el Mercado`}</option>
                    <option
                      value={7}
                    >{`Estrategia de la Cedente / Corredor`}</option>
                  </select>
                </div>
              ) : //AREA SEGENT
              title == 'Área' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <input
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                  <abbr
                    title="1 - Direccion General &#10;
                     2 - Finanzas &#10;
                      3 - Operaciones &#10;
                       4 - Suscripcion &#10;
                        5 - Otra Area &#10;
                         6 - Ninguna &#10; 
                         7 - PLD/Oficial de cumplimiento &#10;
                          8 - Planeacion y Coordinacion"
                  >
                    Ayuda <strong>?</strong>{' '}
                  </abbr>
                </div>
              ) : title == 'SubÁrea' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <input
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                  <abbr
                    title="1 - Direccion General &#10;
                    2 - Planeacion y Coordinacion Operativa &#10;
                    3 - Transformacion Operativa &#10;
                    5 - Control Interno &#10;
                    6 - Direccion Finanzas &#10;
                    7 - Contabilidad &#10;
                    8 - Tesoreria &#10;
                    9 - Fiscal &#10;
                    10 - Actuaria &#10;
                    11 - Modelacion y Pircing &#10;
                    12 - Direccion Operaciones &#10;
                    13 - Administracion Tecnica &#10;
                    14 - Retrocesiones &#10;
                    15 - Siniestros &#10;
                    16 - Servicios de Oficina &#10;
                    17 - Pool de Asistentes &#10;
                    18 - Direccion Suscripcion &#10;
                    19 - Da;os Contratos &#10;
                    20 - Da;os Facultativos &#10;
                    21 - Da;os Cono Sur &#10;
                    22 - Fianzas &#10;
                    23 - Personas &#10;
                    24 - Ultramar Mexico &#10;
                    25 - Ultramar Londres &#10;
                    26 - Lineas Especiales &#10;
                    "
                  >
                    Ayuda <strong>?</strong>{' '}
                  </abbr>
                </div>
              ) : title == 'Seguimiento entorno step3' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].csubts}
                    >{`Seleccionado: ${pdfData[0].seguimientoEntorno.subTemaSeguimiento}`}</option>
                    <option value={3}>{`Renovacion`}</option>
                    <option value={4}>{`Nuevo Contrato`}</option>
                    <option value={5}>{`Mantener Participacion`}</option>
                    <option value={6}>{`Aumentar Participacion`}</option>
                    <option value={7}>{`Disminuir participacion`}</option>
                    <option value={8}>{`Participar en nuevo contrato`}</option>
                    <option value={9}>{`Envio a Cedente/ Corredor`}</option>
                    <option value={10}>{`Respecto a la Cotizacion`}</option>
                    <option
                      value={11}
                    >{`Enviar Informacion a Cedente/ Corredor`}</option>
                    <option
                      value={12}
                    >{`Solicitud de Atencion o Seguimientp de Otra Area`}</option>
                    <option
                      value={13}
                    >{`Enviar Solicitud Documentos Expediente PLD`}</option>
                    <option
                      value={14}
                    >{`Enviar Solicitud Documentos para Recuperar Impuestos Retenidos Extranjero`}</option>
                  </select>
                </div>
              ) : title == 'Entorno step3' ? ( //CAMBIOS NORM, ENTORNO EC, COMPETENCIA
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    <option
                      selected
                      value={pdfData[0].csubeid}
                    >{`Seleccionado: ${pdfData[0].entorno.step3}`}</option>
                    <option value={1}>{`Implicaciones`}</option>
                    <option value={2}>{`Eventos Proximos`}</option>
                    <option value={3}>{`Acciones`}</option>
                    <option value={4}>{`Coaseguro entre Campañas`}</option>
                    <option
                      value={5}
                    >{`Novedades / Eventos relevantes`}</option>
                    <option
                      value={6}
                    >{`Agentes y Corredores del Directo`}</option>
                    <option value={7}>{`Otros Canales de Distribucion`}</option>
                    <option value={8}>{`Novedades /Eventos Relevantes`}</option>
                    <option
                      value={9}
                    >{`Noticias de otros Reaseguradores`}</option>
                    <option value={10}>{`Corredores`}</option>
                    <option value={11}>{`Primas Total`}</option>
                    <option value={12}>{`Primas No Vida`}</option>
                    <option value={13}>{`Primas Finanzas`}</option>
                    <option value={14}>{`Resultado Tecnico`}</option>
                    <option value={15}>{`Apetito de Riesgo`}</option>
                    <option
                      value={16}
                    >{`Crecimiento  / Expansion / Foco`}</option>
                    <option value={17}>{`Resultados / Presupuesto`}</option>
                    <option value={18}>{`Diversificacion`}</option>
                    <option value={19}>{`Programa de Reaseguro`}</option>
                    <option value={20}>{`Cambios Organizacionales`}</option>
                    <option value={21}>{`Presentacion a Patria`}</option>
                  </select>
                </div>
              ) : inputType === 'Select' ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {title}
                  </p>
                  <select
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  >
                    {title === 'Estatus de seguimiento' ? (
                      <>
                        <option>--SELECCIONAR--</option>
                        {optionsStatus.map(
                          ({ cESTS_Id, cESTS_Descripcion }) => (
                            <option value={cESTS_Id}>
                              {cESTS_Descripcion}
                            </option>
                          )
                        )}
                      </>
                    ) : title === 'Reporte de seguimiento' ||
                      title === 'Tipo de seguimiento' ? (
                      <>
                        <option>--SELECCIONAR--</option>
                        {optionsTipoSeg.map(({ cRES_Id, cRES_Descripcion }) => (
                          <option value={cRES_Id}>{cRES_Descripcion}</option>
                        ))}
                      </>
                    ) : title === 'Acción de seguimiento' ? (
                      //answersSegRel[0]?.cACCSR_Id
                      <>
                        <option>--SELECCIONAR--</option>
                        {optionsActionsSeg.map(
                          ({ cACCSR_Id, cACCSR_Descripcion }) => (
                            <option value={cACCSR_Id}>
                              {cACCSR_Descripcion}
                            </option>
                          )
                        )}
                      </>
                    ) : title === 'Reporte de seguimiento' ? (
                      <>
                        <option>--SELECCIONAR--</option>
                        {optionsSegRel.map(
                          ({ cSEGR_Id, cSEGR_Descripcion }) => (
                            <option value={cSEGR_Id}>
                              {cSEGR_Descripcion}
                            </option>
                          )
                        )}
                      </>
                    ) : title === 'Seguimiento' ? (
                      <>
                        <option>--SELECCIONAR--</option>
                        {optionsTemasSeg.map(
                          ({ cTEMSR_Id, cTEMSR_Descripcion }) => (
                            <option value={cTEMSR_Id}>
                              {cTEMSR_Descripcion}
                            </option>
                          )
                        )}
                      </>
                    ) : null}
                  </select>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 10,
                  }}
                >
                  {/* titulos de cada campo */}
                  <p style={{ height: 40, fontSize: 17, fontWeight: 'bolder' }}>
                    {`${title}`}
                  </p>
                  <input
                    type={inputType}
                    onChange={(e) => handleChange(e, index, indexInputs)}
                    value={value}
                    style={{ height: 40, width: '100%' }}
                  />
                </div>
              )
          )}
        </div>
      ))}
      <div>
        {' '}
        <br></br>
        <br></br>
        <Button
          type="primary"
          style={{
            marginRight: 20,
            width: 236,
            color: '#fff',
            backgroundColor: '#3898EC',
          }}
          size="large"
          onClick={() => {
            setUpdateValues(true);
            setTimeout(() => {
              setFeedbackModal(true);
              setContentModal({
                title: 'Registro guardado',
                message: '',
              });
            }, 3000);
            console.log(updateValues);
          }}
        >
          Actualizar y guardar
        </Button>
        <Button
          type="primary"
          style={{ width: 236, color: '#fff', backgroundColor: '#93BCFF' }}
          size="large"
          onClick={() => {
            setEdit(false);
            
          }}
        >
          Volver
        </Button>
      </div>
    </div>
  );
};

export default EditReunion;
