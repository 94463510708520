import React, { useEffect, useState, useContext } from 'react';
import { DivNavigationButton } from '../StylesComponents/common-styled';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import { MyContext } from '../Context';

import '../components.css';
import { ArrowBackIos } from '@material-ui/icons';

export default function NewReporte({ onClick1, onClick2, onGoBack }) {
  const [tipoReunion, setTipoReunion] = useState([]);

  const [getInfo] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setTipoReunion(JSON.parse(dynamicGet.response));
    },
  });

  const context = useContext(MyContext);

  const getTipo = (index, cTIPRE_Id) => {
    context.setSaveAcuerdos({ cTIPRE_Id: cTIPRE_Id });
    if (index === 0) {
      onClick2();
    } else {
      onClick1();
    }
  };

  useEffect(() => {
    getInfo({
      variables: {
        input: {
          table: 'cTIPRE_TipoReunion',
          columns: ['cTIPRE_Id', 'cTIPRE_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <h2>Nuevo reporte de visita</h2>
      <h3>Tu reporte es sobre una ...</h3>
      <DivNavigationButton
        className="navigation-button-registro"
        style={{ alignItems: 'flex-start', width: '40%' }}
      >
        {tipoReunion.map((reunion, index) => (
          <ButtonNavigation
            text={reunion.cTIPRE_Descripcion}
            backgroundColor={index === 0 ? '#3898EC' : null}
            color={index === 0 ? '#FFF' : null}
            border={index === 0 ? '#3898EC' : null}
            key={index}
            onClick={
              async() => {
                await context.clearState();
                index === 0
                ? getTipo(index, reunion.cTIPRE_Id)
                : getTipo(index, reunion.cTIPRE_Id)
              }
            }
            style={{ cursor: 'pointer' }}
          />
        ))}
      </DivNavigationButton>
    </div>
  );
}
