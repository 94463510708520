import { Chip, TextareaAutosize } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import ToggleButtons from '../Viaticos/ToggleButons.js';
import upload from '../../assets/images/icons/upload.svg';
import { Clear, PhotoCamera } from '@material-ui/icons';
import ButtonNavigation from '../Viaticos/ButtonNavigation.js';
import '../components.css';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import Areas_Inv from './Areas_Inv.js';
import { MyContext } from '../Context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function doClick() {
  const formUpload = document.getElementsByClassName('form-file');
  if (formUpload) {
    formUpload[0].click();
  }
}

export default function Comentarios({
  cSUBE_Id,
  setCTIPR_Id,
  onClick1,
  preSelect = {},
  flag = true,
  setState,
  value='',
  seccion='entorno'
}) {
  const [areas, setAreas] = useState();
  const [subArea, setSubArea] = useState([]);
  const [open, setOpen] = useState(cSUBE_Id === 2 ? true : false);
  const [countQuery, setCountQuery] = useState(0);
  const [countAux, setCountAux] = useState(false);
  const classes = useStyles();

  const context = useContext(MyContext);

  const [saveIdResponseQuestions, setSaveIdResponseQuestions] = useState({
    areas: [],
    subAreas: [],
  });

  useEffect(() => {
    console.log("CAMBIO SECCION", seccion);
    if(seccion === 'entorno'){
      setSaveIdResponseQuestions({
        ...context.state.saveIdResponseQuestions,
        areas: context.state.saveAcuerdos.areas,
        subAreas: context.state.saveAcuerdos.subAreas,
      })
    }
    else{
      setSaveIdResponseQuestions({
        ...context.state.saveIdResponseQuestions,
        areas: context.state.saveSegRelPatria.areas,
        subAreas: context.state.saveSegRelPatria.subAreas,
      })

      getArea({
        variables: {
          input: {
            table: 'cARE_AREASCRM',
            columns: ['cARE_Id', 'cARE_Descripcion'],
          },
        },
      });
      getSubArea({
        variables: {
          input: {
            table: 'cSUBA_SubAreasCRM',
            columns: ['cSUBA_Id', 'cARE_Id', 'cSUBA_Descripcion'],
          },
        },
      });
    }

    console.log("contexto areas", context.state.saveSegRelPatria, context.state.saveAcuerdos);
  },[seccion])

  const [getArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        setAreas(JSON.parse(dynamicGet.response));
      }
    },
  });

  const [getSubArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        let response = JSON.parse(dynamicGet.response);
        let areasEntorno = [];
        let subAreasEntorno = [];
        if(seccion === 'entorno'){
          areasEntorno = context.state.saveAcuerdos.areas;
          subAreasEntorno = context.state.saveAcuerdos.subAreas;
        }
        else{
          areasEntorno = context.state.saveSegRelPatria.areas;
          subAreasEntorno = context.state.saveSegRelPatria.subAreas;
        }

        let arraySubAreas = response.map((subAreas, index) => {
          if(subAreasEntorno.indexOf(subAreas.cSUBA_Id) != -1){
            return {...subAreas, status: true}
          }
          else{
            return {...subAreas, status: false };
          }
        });
        setSubArea([
          ...arraySubAreas,
          {
            cARE_Id: 5,
            cSUBA_Descripcion: 'Otra Área',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(5) != -1 ? true : false,
          },
          {
            cARE_Id: 6,
            cSUBA_Descripcion: 'Ninguna',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(6) != -1 ? true : false,
          },
          {
            cARE_Id: 7,
            cSUBA_Descripcion: 'PLD / Oficial de Cumplimiento',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(7) != -1 ? true : false,
          },
          {
            cARE_Id: 8,
            cSUBA_Descripcion: 'Planeación y Coordinación',
            cSUBA_Id: 1,
            status: areasEntorno.indexOf(8) != -1 ? true : false,
          }
        ]);
        setCountQuery(1);
        setCountAux(true);
      }
    },
  });

  const onchange = async({ value }) => {
    const { cARE_Id, cSUBA_Descripcion, cSUBA_Id } = JSON.parse(value);

    console.log("INPUT AREAS", value, saveIdResponseQuestions.areas.indexOf(cARE_Id));
    
    // saveIdResponseQuestions.areas.push(cARE_Id);

    // setSaveIdResponseQuestions({
    //   ...saveIdResponseQuestions,
    //   cARE_Id,
    //   areas: saveIdResponseQuestions.areas
    // });
    
    if(saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id) === -1){
      saveIdResponseQuestions.subAreas.push(cSUBA_Id);
      saveIdResponseQuestions.areas.push(cARE_Id);
      setSaveIdResponseQuestions({
        ...saveIdResponseQuestions,
        cSUBA_Id,
        subAreas: saveIdResponseQuestions.subAreas,
        areas: saveIdResponseQuestions.areas
      });
    }
    else{
      if (cSUBA_Descripcion === 'Otra Área' || cSUBA_Descripcion === 'Ninguna' || cSUBA_Descripcion === 'PLD / Oficial de Cumplimiento' || cSUBA_Descripcion === 'Planeación y Coordinación') {
        if(saveIdResponseQuestions.areas.indexOf(cARE_Id) === -1){
          saveIdResponseQuestions.subAreas.push(cSUBA_Id);
          saveIdResponseQuestions.areas.push(cARE_Id);
          setSaveIdResponseQuestions({
            ...saveIdResponseQuestions,
            cSUBA_Id,
            subAreas: saveIdResponseQuestions.subAreas,
            areas: saveIdResponseQuestions.areas
          });
        }
        else{
          let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
          let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
        
          saveIdResponseQuestions.subAreas.splice(i, 1);
          saveIdResponseQuestions.areas.splice(i1, 1);
        }
      }
      else{
        let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
        let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
      
        saveIdResponseQuestions.subAreas.splice(i, 1);
        saveIdResponseQuestions.areas.splice(i1, 1);
      }
    }
    
    console.log("areas", saveIdResponseQuestions.areas, "subAreas", saveIdResponseQuestions.subAreas);

    if(seccion==='entorno'){
      await context.setSaveAcuerdos({
        ...context.state.saveAcuerdos,
        cARE_Id,
        cSUBA_Id,
        areas: saveIdResponseQuestions.areas,
        subAreas: saveIdResponseQuestions.subAreas
      });
    }
    else{
      await context.setSaveSegRelPatria({
        ...context.state.saveSegRelPatria,
        areas: saveIdResponseQuestions.areas,
        subAreas: saveIdResponseQuestions.subAreas
      });
    }

    // console.log('cARE_Id', cARE_Id);
    // console.log('cSUBA_Id', cSUBA_Id);
    // context.setSaveAcuerdos({
    //   ...context.state.saveAcuerdos,
    //   cARE_Id,
    //   cSUBA_Id,
    // });
    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else if (cARE_Id === 5 && cSUBA_Id === 5) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );


    
    console.log("RELPATRIA AREAS:", context.state.saveSegRelPatria.areas, context.state.saveSegRelPatria.subAreas);
  };

  const deleteSubarea = async(cSUBA_Id, cARE_Id, cSUBA_Descripcion) => {
    
    const index = saveIdResponseQuestions.areas.indexOf(cARE_Id);
    const index2 = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);

    if (cSUBA_Descripcion === 'Otra Área' || cSUBA_Descripcion === 'Ninguna' || cSUBA_Descripcion === 'PLD / Oficial de Cumplimiento' || cSUBA_Descripcion === 'Planeación y Coordinación') {
      
      const indexS = saveIdResponseQuestions.areas.indexOf(cARE_Id);
      const indexS2 = saveIdResponseQuestions.subAreas.indexOf(1);
      
      if (index > -1) { // only splice array when item is found
        saveIdResponseQuestions.areas.splice(indexS, 1); // 2nd parameter means remove one item only
      }
  
      if (index2 > -1) { // only splice array when item is found
        saveIdResponseQuestions.subAreas.splice(indexS2, 1); // 2nd parameter means remove one item only
      }
    }
    else{
      if (index > -1) { // only splice array when item is found
        saveIdResponseQuestions.areas.splice(index, 1); // 2nd parameter means remove one item only
      }
  
      if (index2 > -1) { // only splice array when item is found
        saveIdResponseQuestions.subAreas.splice(index2, 1); // 2nd parameter means remove one item only
      }
    }

    if(seccion === 'entorno'){
      await context.setSaveAcuerdos({
        ...context.state.saveAcuerdos,
        cARE_Id,
        cSUBA_Id,
        areas: saveIdResponseQuestions.areas,
        subAreas: saveIdResponseQuestions.subAreas
      });
    }
    else{
      await context.setSaveSegRelPatria({
        ...context.state.saveSegRelPatria,
        areas: saveIdResponseQuestions.areas,
        subAreas: saveIdResponseQuestions.subAreas
      });
    }

    setSaveIdResponseQuestions({
      ...saveIdResponseQuestions,
      areas: saveIdResponseQuestions.areas,
      subAreas: saveIdResponseQuestions.subAreas
    })

    console.log("AREAS SUBAREAS AFTER DELETED", saveIdResponseQuestions.areas, saveIdResponseQuestions.subAreas);

    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );
  };

  const save = async () => {
    let input = {
      table: 'cACU_CuerdosVieje',
      columns: [],
      detailValues: [
        {
          values: [],
        },
      ],
    };
    if (context.state.saveAcuerdos.cTIPRE_Id !== '') {
      input.columns.push('cTIPRE_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cTIPRE_Id
      );
    }
    if (context.state.saveAcuerdos.CVIS_Id !== '') {
      input.columns.push('cVIS_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.CVIS_Id
      );
    }
    if (context.state.saveAcuerdos.cEMP_Id !== '') {
      input.columns.push('cEMP_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cEMP_Id
      );
    }
    if (context.state.saveAcuerdos.cREU_Id !== '') {
      input.columns.push('cREU_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cREU_Id
      );
    }
    if (context.state.saveAcuerdos.cENT_Id !== '') {
      input.columns.push('cENT_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cENT_Id
      );
    }
    if (context.state.saveAcuerdos.cTEM_Id !== '') {
      input.columns.push('cTEM_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cTEM_Id
      );
    }
    if (context.state.saveAcuerdos.cSUBE_Id !== '') {
      input.columns.push('cSUBE_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cSUBE_Id
      );
    }
    if (context.state.saveAcuerdos.cTIPR_Id !== '') {
      input.columns.push('cTIPR_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cTIPR_Id
      );
    }
    if (context.state.saveAcuerdos.cSUBE_Descripcion !== '') {
      input.columns.push('cSUBE_Descripcion');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cSUBE_Descripcion
      );
    }
    if(context.state.saveAcuerdos.areas !== '') {
      input.columns.push('areas');
      input.detailValues[0].values.push(context.state.saveAcuerdos.areas);
    }
    if(context.state.saveAcuerdos.subAreas !== '') {
      input.columns.push('subAreas');
      input.detailValues[0].values.push(context.state.saveAcuerdos.subAreas);
    }

    console.log("areas", context.state.saveAcuerdos.areas,"subAreas", context.state.saveAcuerdos.subAreas);
    onClick1();
  };
  useEffect(() => {
    if (countQuery === 1 && flag) {
      onchange({ value: preSelect });
      setCountQuery(2);
    }
  }, [countAux]);

  useEffect(() => {
    getArea({
      variables: {
        input: {
          table: 'cARE_AREASCRM',
          columns: ['cARE_Id', 'cARE_Descripcion'],
        },
      },
    });
    getSubArea({
      variables: {
        input: {
          table: 'cSUBA_SubAreasCRM',
          columns: ['cSUBA_Id', 'cARE_Id', 'cSUBA_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div
      style={{ width: '100%', textAlign: '-webkit-center' }}
      className="registro-container"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '25px',
          width: '100%'
        }}
      >
        <TextareaAutosize
          rowsMin={10}
          placeholder="Escribe tus comentarios"
          onChange={({ target }) => {
            context.setSaveAcuerdos({ cCOMA_Descripcion: target.value });
            setState(target.value);
          }}
          defaultValue={value}
        />
        <ToggleButtons width='100%' />
      </div>
      {setCTIPR_Id === 9 ? (
        <div style={{ display: 'flex' }}>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '25px' }}
          >
            {/* <img src={upload} width='50px' alt='upload'/>
            <h4>Subir documento</h4> */}
            <input
              type="file"
              required
              multiple
              style={{ position: 'absolute', left: '-1000em' }}
              className="form-file"
            />
            <a style={{ display: 'flex' }} onClick={doClick}>
              <img src={upload} width="50px" alt="upload" />
              <h4>Subir documento</h4>
            </a>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '25px' }}
          >
            <PhotoCamera style={{ color: '#3898EC', fontSize: '40px' }} />
            <h4>Tomar foto</h4>
          </div>
        </div>
      ) : null}
      {cSUBE_Id === 2 ? (
        <div
          style={{
            width: '82%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'initial',
          }}
          onClick={() => setOpen(true)}
        >
          <h4 style={{ color: '#3898EC' }}>Áreas</h4>
          <div className={classes.root}>
            {subArea &&
              subArea.map(
                ({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                  status ? (
                    <Chip
                      label={cSUBA_Descripcion}
                      style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                      onDelete={() => deleteSubarea(cSUBA_Id, cARE_Id, cSUBA_Descripcion)}
                      deleteIcon={<Clear />}
                      key={index}
                    />
                  ) : null
              )}
          </div>
        </div>
      ) : null}
      {!flag ? (
        <ButtonNavigation
          text="Guardar y continuar"
          backgroundColor="#3898EC"
          border="#3898EC"
          color="#FFFFFF"
          // width="82%"
          onClick={save}
        />
      ) : null}
      <Areas_Inv
        areas={areas}
        subArea={subArea}
        open={open}
        setOpen={() => setOpen(false)}
        onChangue={onchange}
      />
    </div>
  );
}
