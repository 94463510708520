import React, { useEffect, useState, useContext, Fragment } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET, DYNAMIC_INSERT, DYNAMIC_UPDATE } from '../../mutation/mutation';
import Comentarios from './Comentarios';
import { MyContext } from '../Context';
import { ArrowBackIos } from '@material-ui/icons';
export default function Entorno({ onClick1, onGoBack, session, cEMP_Id }) {
  const context = useContext(MyContext);
  console.log('Entorno log -> Context ->', context.state);

  const [entorno, setEntorno] = useState();
  const [input, setInput] = useState({
    table: 'cENT_Entorno',
    columns: ['cENT_Descripcion', 'cENT_Id'],
  });
  const [cENT_Descripcion, setDescripcion] = useState();
  const [cTEM_Descripcion, setCTem_Descripcion] = useState();
  const [cTIPR_Descripcion, setCTIPR_Descripcion] = useState();
  const [cTEM_Id, setCTem_Id] = useState(0);
  const [comentarios, setComentarios] = useState(false);
  const [cSUBE_Id, setCSUBE_Id] = useState(0);
  const [cSUBE_Descripcion, setCSUBE_Descripcion] = useState();
  const [cTIPR_Id, setCTIPR_Id] = useState();

  const [getEntorno] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setEntorno(JSON.parse(dynamicGet.response));
      console.log(dynamicGet);
    },
  });

  const [saveComments] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log('RESPONSE INSER COMMENT ----->>>', dynamicInsert);
      console.log(
        'SAVED COMMENT ID',
        JSON.parse(dynamicInsert.response).cCOMA_Id
      );
      context.setCommentsAcuerdos({
        cCOMA_Id: JSON.parse(dynamicInsert.response).cCOMA_Id,
      });
      onClick1();
    },
    onError(err) {
      console.log('Error Save Entorno CommentId', err);
    },
  });

  const [updateComments] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
    },
    onError(err) {
      console.log('Error Save SegEntorno CommentId', err);
    },
  });

  const asyncInsertComments = async (cACU_Id) => {
    await saveComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: ['cACU_Id', 'cCOMA_Descripcion'],
          detailValues: [
            {
              values: [
                String(cACU_Id),
                JSON.stringify(context.state.commentsAcuerdos),
              ],
            },
          ],
        },
      },
    });

    console.log("COMENTARIOS INSERTADOS");
    console.log("ENTORNO: ", context.state.saveAcuerdos.cENT_Descripcion, cTEM_Descripcion);
    onClick1({cENT_Descripcion: context.state.saveAcuerdos.cENT_Descripcion, cTEM_Descripcion: cTEM_Descripcion});
  }

  const asyncUpdateComments = async() => {
    await updateComments({
      variables: {
        input: {
          table: 'cCOMA_ComentariosAcuerdo',
          columns: [
            {
              setkey: 'cCOMA_Descripcion',
              setval: JSON.stringify(context.state.commentsAcuerdos),
            },
          ],
          conditions: [
            {
              valone: 'cCOMA_Id',
              valtwo: '' + context.state.commentsAcuerdos.cCOMA_Id,
              condition: '=',
            },
          ],
        },
      },
    });

    console.log("ENTORNO: ", context.state.saveAcuerdos.cENT_Descripcion, cTEM_Descripcion);
    onClick1({cENT_Descripcion: context.state.saveAcuerdos.cENT_Descripcion, cTEM_Descripcion: cTEM_Descripcion});
  }

  const [saveEntorno] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log('RESPONSE INSER REU ----->>>', dynamicInsert);
      const cACU_Id = JSON.parse(dynamicInsert.response).cACU_Id;
      context.setSaveAcuerdos({
        ...context.state.saveAcuerdos,
        cACU_Id: cACU_Id,
      });

      console.log("REQUEST INSERT COMENTARIOS");
      asyncInsertComments(cACU_Id);
      
      
    },
    onError(err) {
      console.log('Error Save Entorno Acu_Id', err);
      onClick1();
    },
  });

  const [updateAcuerdos] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log('update acuerdo ----->>>', dynamicUpdate);

      let Comentarios = context.state.commentsAcuerdos;

      if(context.state.saveAcuerdos.cENT_Id === 'Ninguno'){
        Comentarios.entorno = 'NULL';
        Comentarios.segEntorno = 'NULL';
      }

      asyncUpdateComments();
    },
    onError(err) {
      onClick1();
      console.log('Error update acuerdo -->>', err);
    },
  });

  const save = async ( cENT_Id = '', cENT_Descripcion = '', cTEM_Id = '', cTEM_Descripcion = '') => {
    let input = {
      table: 'cACU_CuerdosVieje',
      columns: [],
      detailValues: [
        {
          values: [],
        },
      ],
    };
    if (context.state.saveAcuerdos.cTIPRE_Id !== '') {
      input.columns.push('cTIPRE_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cTIPRE_Id
      );
    }
    if (context.state.saveAcuerdos.CVIS_Id !== '') {
      input.columns.push('cVIS_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.CVIS_Id
      );
    }
    if (context.state.saveAcuerdos.cEMP_Id !== '' || (cEMP_Id != '' && cEMP_Id != undefined)) {
      input.columns.push('cEMP_Id');
      input.detailValues[0].values.push(
        '' + (context.state.saveAcuerdos.cEMP_Id || cEMP_Id)
      );
    }
    if (
      context.state.reuProgramadaData.cREU_Id ||
      context.state.saveAcuerdos.cREU_Id ||
      context.state.acuerdosProps.cREU_Id
    ) {
      const reuProgramada = context.state.reuProgramadaData.cREU_Id;
      const acuerdos = context.state.saveAcuerdos.cREU_Id;
      const props = context.state.acuerdosProps.cREU_Id;
      console.log('ReuP', reuProgramada);
      console.log('SAcuerdos', acuerdos);
      console.log('AProps', props);
      const cREU_Id = reuProgramada || acuerdos || props;
      console.log('cREU_Id -->', cREU_Id);
      input.columns.push('cREU_Id');
      input.detailValues[0].values.push(String(cREU_Id));
    }

    
    if(cENT_Descripcion === 'Ninguno' || cTEM_Descripcion === 'Continuar'){
      context.setCommentsAcuerdos(
        {
          ...context.state.commentsAcuerdos,
          entorno: '',
          segEntorno: '',
        }
      )
    }

    if (cENT_Id !== '') {
      input.columns.push('cENT_Id');
      input.detailValues[0].values.push(
        '' + (cENT_Id)
      );
    }
    else if (context.state.saveAcuerdos.cENT_Id !== ''){
      input.columns.push('cENT_Id');
      input.detailValues[0].values.push(
        '' + (context.state.saveAcuerdos.cENT_Id)
      );
    }

    if(cTEM_Id != ''){
      setCTem_Descripcion(cTEM_Descripcion);

      input.columns.push('cTEM_Id');
      input.detailValues[0].values.push(
        '' + (cTEM_Id)
      );
    }
    else if(context.state.saveAcuerdos.cTEM_Id != ''){
      input.columns.push('cTEM_Id');
      input.detailValues[0].values.push(
        '' + (cTEM_Descripcion != 'Continuar' ? context.state.saveAcuerdos.cTEM_Id : 'NULL')
      );
    }

    // if (context.state.saveAcuerdos.cTEM_Id !== '') {
    //   input.columns.push('cTEM_Id');
    //   input.detailValues[0].values.push(
    //     '' + (cENT_Descripcion != 'Ninguno' ? context.state.saveAcuerdos.cTEM_Id : 'NULL')
    //   );
    // }

    if (context.state.saveAcuerdos.cSUBE_Id !== '') {
      input.columns.push('cSUBE_Id');
      input.detailValues[0].values.push(
        '' + ((cENT_Descripcion != 'Ninguno' && cTEM_Descripcion != 'Continuar') ? context.state.saveAcuerdos.cSUBE_Id : 'NULL')
      );
    }
    if (context.state.saveAcuerdos.cTIPR_Id !== '') {
      input.columns.push('cTIPR_Id');
      input.detailValues[0].values.push(
        '' + context.state.saveAcuerdos.cTIPR_Id
      );
    }
    if (context.state.saveAcuerdos.cSUBE_Descripcion !== '') {
      input.columns.push('cSUBE_Descripcion');
      input.detailValues[0].values.push(
        '' + ((cENT_Descripcion != 'Ninguno' && cTEM_Descripcion != 'Continuar') ? context.state.saveAcuerdos.cSUBE_Descripcion : 'NULL')
      );
    }
    
    console.log("SAVEACUERDOS INPUT", input);
    console.log("cACU_Id", context.state.saveAcuerdos.cACU_Id);

    if(context.state.saveAcuerdos.cACU_Id !== undefined && context.state.saveAcuerdos.cACU_Id !== ''){
      console.log("ACTUALIZANDO REGISTRO - ENTORNO");
      await actualizaAcuerdos(input);
    }
    else{
      console.log('nuevo acuerdo');
      await saveEntorno({ variables: { input } });
    }
  };

  const actualizaAcuerdos = async(data) => {

    // remover columnas no actualizables
    let columns =  data.columns;
    let values =  data.detailValues[0].values;

    console.log("ACTUALIZADO", data);

    if(columns.indexOf('cEMP_Id') !== -1){
      const index = columns.indexOf('cEMP_Id');
      columns.splice(index, 1);
      values.splice(index, 1);
    }

    if(columns.indexOf('cTIPRE_Id') !== -1){
      const index = columns.indexOf('cTIPRE_Id');
      columns.splice(index, 1);
      values.splice(index, 1);
    }

    if(columns.indexOf('cVIS_Id') !== -1){
      const index = columns.indexOf('cVIS_Id');
      columns.splice(index, 1);
      values.splice(index, 1);
    }

    if(columns.indexOf('cREU_Id') !== -1){
      const index = columns.indexOf('cREU_Id');
      columns.splice(index, 1);
      values.splice(index, 1);
    }

    console.log("COLUMNS AND VALUES", columns, values);

    const input = {
      table: 'cACU_CuerdosVieje',
      columns: columns
      .map((str, index) => ({
        setkey: `${str}`,
        setval: values[index],
      })),
      conditions: [
        {
          valone: 'cACU_Id',
          valtwo: '' + context.state.saveAcuerdos.cACU_Id,
          condition: '=',
        },
      ],
    };
    console.log('actualiza acuerdos input: ', input)
    await updateAcuerdos({ variables: { input } });
    // contextSet.setRelacionData(relacionDatos)
  }

  /**
   * @name goBackOnEntorno
   * @param {Object} lastInput the input to reemake the query
   */
  const goBackOnEntorno = (id) => {
    console.log('BACK_ID', id);
    switch (id) {
      case 1:
        setInput({
          table: 'cENT_Entorno',
          columns: ['cENT_Descripcion', 'cENT_Id'],
        });
        break;
      case 2:
        setInput({
          table: 'cTEM_TemasEntorno',
          columns: ['cTEM_Descripcion', 'cTEM_Id'],
          // conditions: [
          //   {
          //     valone: 'cENT_Id',
          //     valtwo: `${cENT_Id}`,
          //     condition: '=',
          //   },
          // ],
        });
        break;
      case 3:
        setInput({
          table: 'cSUBE_SubtemasEntorno',
          columns: ['cSUBE_Id', 'cTEM_Id', 'cSUBE_Descripcion'],
          conditions: [
            {
              valone: 'cTEM_Id',
              valtwo: `${cTEM_Id}`,
              condition: '=',
            },
          ],
        });
        setComentarios(false);
        break;
      case 4:
        break;
      default:
        break;
    }
  };

  const changueRamo = ({ cENT_Id, cENT_Descripcion }) => {
    console.log('CHANGE_LAST', 'cENT_Entorno');
    setInput({
      table: 'cTEM_TemasEntorno',
      columns: ['cTEM_Descripcion', 'cTEM_Id'],
      // conditions: [
      //   {
      //     valone: 'cENT_Id',
      //     valtwo: `${cENT_Id}`,
      //     condition: '=',
      //   },
      // ],
    });

    setDescripcion(cENT_Descripcion);
    context.setSaveAcuerdos({
      ...context.state.saveAcuerdos,
      cENT_Id: cENT_Id,
      cENT_Descripcion: cENT_Descripcion,
    });
  };

  const changueTema = ({ cTEM_Descripcion, cTEM_Id }) => {
    setCTem_Descripcion(cTEM_Descripcion);
    setCTem_Id(cTEM_Id);
    setInput({
      table: 'cSUBE_SubtemasEntorno',
      columns: ['cSUBE_Id', 'cTEM_Id', 'cSUBE_Descripcion'],
      conditions: [
        {
          valone: 'cTEM_Id',
          valtwo: `${cTEM_Id}`,
          condition: '=',
        },
      ],
    });
    context.setSaveAcuerdos({
      ...context.state.saveAcuerdos,
      cTEM_Id: cTEM_Id
    });
  };

  // const changueTemaTest = ({ cTEM_Id, cSUBE_Descripcion }) => {
  //   setCTem_Id(cTEM_Id);
  //   setCSUBE_Descripcion(cSUBE_Descripcion);
  //   setInput({
  //     table: 'cTIPR_TipoRegistro',
  //     columns: ['cTIPR_Id', 'cSUBE_Id', 'cTIPR_Descripcion'],
  //     conditions: [
  //       {
  //         valone: 'cSUBE_Id',
  //         valtwo: `${cSUBE_Id}`,
  //         condition: '=',
  //       },
  //     ],
  //   });
  //   context.setSaveAcuerdos({ cTEM_Id: cTEM_Id });
  // };

  const changueTemaF = (cSUBE_Id, cSUBE_Descripcion, cTIPR_Id) => {
    console.log(`SUBEID IF: SUBEID: ${cSUBE_Id}, cTIPR_Id: ${cTIPR_Id}`);
    if (
      (cSUBE_Id == 23 || cSUBE_Id == 24 || cSUBE_Id == 25 || cSUBE_Id == 26) &&
      !cTIPR_Id
    ) {
      console.log(` PRIMER IF: SUBEID: ${cSUBE_Id}, cTIPR_Id: ${cTIPR_Id}`);
      console.log('Entra donde no se setea a true el setcomentarios()');
      setCTem_Id(cTEM_Id);
      setCSUBE_Id(cSUBE_Id);
      setCSUBE_Descripcion(cSUBE_Descripcion);
      setInput({
        table: 'cTIPR_TipoRegistro',
        columns: ['cTIPR_Id', 'cSUBE_Id', 'cTIPR_Descripcion'],
        conditions: [
          {
            valone: 'cSUBE_Id',
            valtwo: `${cSUBE_Id}`,
            condition: '=',
          },
        ],
      });
    }
    else if (cSUBE_Id == 20 || cSUBE_Id == 21 || cSUBE_Id == 22 || cTIPR_Id > 0) {
      console.log(` SEGUNDO IF: SUBEID: ${cSUBE_Id}, cTIPR_Id: ${cTIPR_Id}`);
      console.log('Entro a condicion de set comentarios en true');
      setCTIPR_Id(cTIPR_Id);
      setComentarios(true);
      setCSUBE_Id(cSUBE_Id);
      setCSUBE_Descripcion(cSUBE_Descripcion);
      context.setSaveAcuerdos({
        ...context.state.saveAcuerdos,
        cSUBE_Id: cSUBE_Id,
        cSUBE_Descripcion: cSUBE_Descripcion,
      });
      if (parseInt(cSUBE_Id, 10) >= 30) {
        setInput({
          table: 'cTIPR_TipoRegistro',
          columns: ['cTIPR_Id', 'cSUBE_Id', 'cTIPR_Descripcion'],
          conditions: [
            {
              valone: 'cSUBE_Id',
              valtwo: `${cSUBE_Id}`,
              condition: '=',
            },
          ],
        });
      }
    } else {
      setComentarios(true);
      setCSUBE_Id(cSUBE_Id);
      setCSUBE_Descripcion(cSUBE_Descripcion);
      context.setSaveAcuerdos({
        ...context.state.saveAcuerdos,
        cSUBE_Id: cSUBE_Id,
        cSUBE_Descripcion: cSUBE_Descripcion,
      });
    }
    console.log('NO ENTRO A NINGUN IF');
  };

  const changueTipo = (cTIPR_Id, cTIPR_Descripcion) => {
    context.setSaveAcuerdos({
      ...context.state.saveAcuerdos,
      cTIPR_Id: cTIPR_Id,
      cTIPR_Descripcion
    });
    setCSUBE_Id(0);
    setCTIPR_Id(cTIPR_Id);
  };

  useEffect(() => {
    console.log('CHANGE_ENTORNO');
    
    getEntorno({
      variables: {
        input,
      },
    });
  }, [input, getEntorno]);

  useEffect(() => {
    console.log('cEMP', cEMP_Id);

    context.setSaveAcuerdos({
      ...context.state.saveAcuerdos,
      cEMP_Id: cEMP_Id
    })
  }, [cEMP_Id]);

  return (
    <div>
      {entorno && entorno.length >= 0 && entorno[0].cENT_Descripcion ? (
        <Fragment>
          <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <h2>Entorno</h2>
          <h3>¿De qué ramo deseas hacer tu registro?</h3>
        </Fragment>
      ) : null}
      {entorno && entorno.length >= 0 && entorno[0].cTEM_Descripcion ? ( //entorno con asignacion 1
        <Fragment>
          <div onClick={() => goBackOnEntorno(1)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4>&gt; {cENT_Descripcion}</h4>
          </div>
          <h4>¿De qué tema deseas hacer tu registro?</h4>
        </Fragment>
      ) : null}

      {entorno &&
      entorno.length >= 0 &&
      entorno[0].cTIPR_Descripcion &&
      !comentarios ? (
        <Fragment>
          <div onClick={() => goBackOnEntorno(3)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4> &gt; {cENT_Descripcion}</h4>
            <h4> &gt; {cTEM_Descripcion}</h4>
            <h4> &gt; {cSUBE_Descripcion}</h4>
          </div>
          {cSUBE_Id == 23 ? <h4>¿Que tipo de diversificacion?</h4> : null}
          {cSUBE_Id == 24 ? (
            <h4>¿Qué deseas registrar sobre Programa de Reaseguro?</h4>
          ) : null}
          {cSUBE_Id == 25 ? (
            <h4>¿Qué deseas registrar sobre Cambios Organizacionales?</h4>
          ) : null}
          {cSUBE_Id == 26 ? (
            <h4>¿Qué deseas registrar sobre Presentación a Patria?</h4>
          ) : null}
        </Fragment>
      ) : null}

      {entorno &&
      entorno.length >= 0 &&
      entorno[0].cSUBE_Id &&
      !comentarios &&
      !entorno[0].cTIPR_Descripcion ? ( //entorno con asignacion 2
        <Fragment>
          <div onClick={() => goBackOnEntorno(2)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4> &gt; {cENT_Descripcion}</h4>
            <h4> &gt; {cTEM_Descripcion}</h4>
          </div>
          <h4>¿De qué tema deseas hacer tu registro?</h4>
        </Fragment>
      ) : null}
      {comentarios && (
        <Fragment>
          <div onClick={() => goBackOnEntorno(3)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4> &gt; {cENT_Descripcion}</h4>
            <h4> &gt; {cTEM_Descripcion}</h4>
            <h4> &gt; {cSUBE_Descripcion}</h4>
          </div>
          {/* {cSUBE_Id == 23 ? <h4>¿Que tipo de diversificacion?</h4> : null}
          {cSUBE_Id == 24 ? <h4>¿Qué deseas registrar sobre Programa de Reaseguro</h4>: null}
          {cSUBE_Id == 25 ? <h4>¿Qué deseas registrar sobre Cambios Organizacionales</h4>: null}
          {cSUBE_Id == 26 ? <h4>¿Qué deseas registrar sobre Presentación a Patria</h4>: null} */}
          {parseInt(cSUBE_Id, 10) >= 30 &&
          (cSUBE_Id != 23 ||
            cSUBE_Id != 24 ||
            cSUBE_Id != 25 ||
            cSUBE_Id != 26) ? (
            <h4>¿De qué tema deseas hacer tu registro?</h4>
          ) : (
            <h4>Escribe un comentario sobre {cSUBE_Descripcion}</h4>
          )}
        </Fragment>
      )}
      <div
        style={{
          width: '25%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          alignItems: 'flex-start',
        }}
      >
        {entorno && entorno.length >= 0 && entorno[0].cENT_Descripcion ? ( //linea de tipo de ramo - ¿de que ramo deseas hacer tu registro?
          <Fragment>
            {entorno.map((entor, index) => (
              <ButtonNavigation
                style={{ cursor: 'pointer' }}
                text={entor.cENT_Descripcion}
                key={index}
                onClick={ async() => {
                  if (entor.cENT_Descripcion !== 'Ninguno') {
                    changueRamo(entor)
                  }
                  else {
                    await context.setSaveAcuerdos(
                      {...context.state.saveAcuerdos,
                        cENT_Id: entor.cENT_Id,
                        cENT_Descripcion: entor.cENT_Descripcion,
                        cSUBE_Descripcion: '',
                        cSUBE_Id: '',
                        cTEM_Id: '',}
                    );
                    save(entor.cENT_Id, entor.cENT_Descripcion);
                  }
                }}
              />
            ))}
          </Fragment>
        ) : null}
        {entorno && entorno.length >= 0 && entorno[0].cTEM_Descripcion ? ( //Linea de modificacion de diapositiva 10
          <Fragment>
            {entorno.map((entor, index) => (
              <ButtonNavigation
                style={{ cursor: 'pointer' }}
                text={entor.cTEM_Descripcion}
                key={index}
                onClick={async() => {
                  if (entor.cTEM_Descripcion !== 'Continuar') {
                    changueTema(entor)
                  }
                  else {
                    await context.setSaveAcuerdos(
                      {...context.state.saveAcuerdos,
                        cTEM_Id: entor.cTEM_Id,
                        cSUBE_Descripcion: '',
                        cSUBE_Id: '',}
                    );
                    save('', '', entor.cTEM_Id, entor.cTEM_Descripcion);
                  }
                }}
              />
            ))}
          </Fragment>
        ) : null}
        {/* {entorno && entorno.length >= 0 && entorno[0].cTIPR_Descripcion ? (
          <>
            {
              <Fragment>
                <ButtonNavigation
                  text={entorno[0].cTIPR_Descripcion}
                  key={entorno[0].cTIPR_Id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => changueTemaF(cSUBE_Id, cTIPR_Descripcion)}
                />
                <ButtonNavigation
                  text={entorno[1].cTIPR_Descripcion}
                  key={entorno[1].cTIPR_Id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => changueTemaF(cSUBE_Id, cTIPR_Descripcion)}
                />
              </Fragment>
            }
          </>
        ) : null} */}
        {entorno &&
        entorno.length >= 0 &&
        entorno[0].cTIPR_Descripcion &&
        !comentarios ? ( //Linea despues de clickear diversificacion-reaseguro-cambios organizacionales-presentacionpatria
          <>
            {entorno.map((entorno, i) => (
              <ButtonNavigation
                text={entorno.cTIPR_Descripcion}
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  changueTemaF(
                    entorno.cSUBE_Id,
                    entorno.cTIPR_Descripcion,
                    entorno.cTIPR_Id
                  )
                }
              />
            ))}
          </>
        ) : null}
        {/* ENTORNO NUEVO AGREGADO INICIO*/}
        {/* {entorno && entorno.length >= 0 && cSUBE_Id == 23 ? ( //Linea de modificacion de diapositiva 10/2
          <Fragment>
            {entorno.map((entor, index) => (
              <>
               <ButtonNavigation
               style={{ cursor: 'pointer' }}
               text={entor.cTIPR_Descripcion}
               key={index}
               onClick={() => changueTema()}
             />
             <h3>ENTORNO PRUEBA</h3>
             </>
            ))}
          </Fragment>
        ) : null} */}
        {/* ENTORNO NUEVO AGREGADO FIN */}
        {entorno &&
        entorno.length >= 0 &&
        entorno[0].cSUBE_Id &&
        !comentarios &&
        !entorno[0].cTIPR_Id ? (
          <Fragment>
            {entorno.map(({ cSUBE_Id, cSUBE_Descripcion, cTEM_Id }, index) => {
              if (cSUBE_Id === 12) {
                return (
                  <>
                    {/* <ButtonNavigation
                      style={{ cursor: 'pointer' }}
                      text={'Primas No Vida'}
                      key={index}
                      onClick={() => changueTemaF(26, 'Primas No Vida', 0)}
                    /> */}
                    <ButtonNavigation
                      style={{ cursor: 'pointer' }}
                      text={cSUBE_Descripcion}
                      key={index}
                      onClick={() =>
                        changueTemaF(cSUBE_Id, cSUBE_Descripcion, 0)
                      }
                    />
                  </>
                );
              } else if (cSUBE_Id !== 26) {
                //LINEA DESPUES DE SELECCIONAR DE QUE TEMA SE HARA EL REGISTRO "apetito riesgo, diver, etc"
                return (
                  <>
                    <ButtonNavigation
                      style={{ cursor: 'pointer' }}
                      text={cSUBE_Descripcion}
                      key={index}
                      onClick={() =>
                        changueTemaF(cSUBE_Id, cSUBE_Descripcion, 0)
                      }
                    />
                  </>
                );
              }
              return (
                <ButtonNavigation
                  style={{ cursor: 'pointer' }}
                  text={cSUBE_Descripcion}
                  key={index}
                  onClick={() => changueTemaF(cSUBE_Id, cSUBE_Descripcion, 0)}
                />
              );
            })}
          </Fragment>
        ) : null}
        <div
          style={{
            width: '324%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {comentarios && parseInt(cSUBE_Id, 10) < 30 ? (
            <Comentarios
              cSUBE_Id={cSUBE_Id}
              setCTIPR_Id={cTIPR_Id}
              flag={false}
              onClick1={save}
              setState={(value) => {
                context.setCommentsAcuerdos({ entorno: value });
              }}
              preSelect={JSON.stringify({
                cSUBA_Id: 3,
                cARE_Id: 1,
                cSUBA_Descripcion: 'Planeación y Coordinación',
              })}
            />
          ) : parseInt(cSUBE_Id, 10) >= 30 ? (
            <Fragment>
              {entorno.map(({ cTIPR_Id, cTIPR_Descripcion }, index) => (
                <ButtonNavigation
                  style={{ cursor: 'pointer' }}
                  text={cTIPR_Descripcion}
                  key={index}
                  onClick={() => changueTipo(cTIPR_Id, cTIPR_Descripcion)}
                />
              ))}
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
}

//     comentarios?
//     <Comentarios ramo={cENT_Descripcion} tema1={cTEM_Descripcion} tema2={tem} idComentario={idComentario}/>:null
//   }
// </div>

// const changueTemaFF = (cSUBE_Id, cSUBE_Descripcion, cTIPR_Descripcion, cTIPR_Id) => {
//   console.log(`SUBEID: ${cSUBE_Id}, SUBE DescripciON: ${cSUBE_Descripcion}, cTIPR_Descripcion ${cTIPR_Descripcion}, CTIPRID: ${cTIPR_Id}`)
//   setCTIPR_Descripcion(cTIPR_Descripcion)
//   setCTIPR_Id(cTIPR_Id);
//   setComentarios(true);
//   setCSUBE_Id(0);
//   setCSUBE_Descripcion(cSUBE_Descripcion);
//   context.setSaveAcuerdos({
//     cSUBE_Id: cSUBE_Id,
//     cSUBE_Descripcion: cSUBE_Descripcion,
//     cTIPR_Descripcion,
//     cTIPR_Id
//   });
//   if (parseInt(cSUBE_Id, 10) >= 30) {
//     setInput({
//       table: 'cTIPR_TipoRegistro',
//       columns: ['cTIPR_Id', 'cSUBE_Id', 'cTIPR_Descripcion'],
//       conditions: [
//         {
//           valone: 'cSUBE_Id',
//           valtwo: `${cSUBE_Id}`,
//           condition: '=',
//         },
//       ],
//     });
//   }
// };
