import React, {Fragment} from 'react';
import {AcordeonContainerBody} from '../StylesComponents/common-styled';
import FormTypography from './FormsTypography';
import {Typography } from '@material-ui/core';


const FormAlojamiento=({title,value,lugar,pos})=>{  
  const handleInfo = () => {
    if (pos || pos === 0) {
      return value[pos];
    } else {
      return value;
    }  
  }
  const {cALO_Hotel,cALO_URLAlojamientoCot,cALO_Precio}=handleInfo();
  return(
    <Fragment>
      <div style={{marginTop:'20px',width: '90%', marginBottom:'2%'}}>
        <h3 style={{textAlign:'center', fontWeight:650, fontSize:'19px' }}>{title}</h3>
      </div>
      <AcordeonContainerBody id='inputs-viaticos'>
        <FormTypography text='Hotel:' direction="row">
          <Typography><strong>{cALO_Hotel || ""}</strong></Typography>
        </FormTypography>
        <FormTypography text='Lugar:' direction="row">
          <Typography><strong>{lugar}</strong></Typography>
        </FormTypography>
      </AcordeonContainerBody>
      <AcordeonContainerBody id='inputs-viaticos'>
        <FormTypography text='Precio de alojamiento:' direction="row">
        <Typography><strong>{`${cALO_Precio}`}</strong></Typography>
        </FormTypography>
        <FormTypography text='URL de alojamiento:' direction="row">
        <Typography><strong>{cALO_URLAlojamientoCot}</strong></Typography>
        </FormTypography>
      </AcordeonContainerBody>
      </Fragment>
  )
}

export default FormAlojamiento;