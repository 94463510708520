import React, { useState, useEffect, useContext, Fragment } from 'react';
import Homebar from './Homebar';
import Acordeon from './Acordeon';
import SearchAutoComplete from './SearchAutoComplete';
import SearchInvitadosSirec from './SearchInvitadosSirec';
import ButtonDelete from './ButtonDelete';
import DialogAlert from './DiaologAlert';
import SteperReuniones from './StepperReuniones';
import UserAutoComplete from './UserAutoComplete';
import AddNewInvited from './Viaticos/AddNewInvited';
import Loader from 'react-js-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Page,
  PageContainer,
  Title,
  Subtitle,
  AcordeonContainer,
  AcordeonContainerBody,
  ContainerTwoInputs,
  InputTwo,
  BottonStyledSeccion,
} from './StylesComponents/common-styled';
import {
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  NativeSelect,
  Checkbox,
  Icon,
  TextareaAutosize,
} from '@material-ui/core';
import {
  Person,
  Clear,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckCircle,
  Error,
  Email,
  RemoveCircle,
  TramRounded,
} from '@material-ui/icons';
import { useLazyQuery, useQuery, useMutation } from 'react-apollo';
import {
  GETCOUNTRIES_SIREC,
  GETMESSAGE_SIREC,
  GETCITIES_SIREC,
  GETEMPLOYES_SIREC,
  GETRETROCESSIONARY_SIREC,
  GETCOMPANI_SIREC,
  GETCORREDOR_SIREC,
  GETINVITADOS_SIREC,
  GETCORREDOR_SIREC_ById,
  GETCOMPANI_SIREC_ById,
  GETRETROCESSIONARY_SIREC_ById,
  GET_TEXTMESSAGE_SIREC,
  GET_CIUDADESBYID,
  GET_CIUDAD_BYID,
  GET_PAIS_BYID,
} from '../query/query';
import {
  CREATE_EVENT,
  CREATE_EVENTCOMPANY,
  CREATE_EVENTCORREDOR,
  CREATE_EVENTEJECUTIVO,
  CREATE_EVENTINVITADOS,
  CREATE_EVENTRETROCESIONARIO,
  FIRE_PUSH_NOTIFICATION,
  UPDATE_EVENT,
  UPDATE_INVITADOS,
  DYNAMIC_INSERT,
} from '../mutation/mutation';
import 'antd/dist/antd.css';
import './components.css';
import { MyContext } from './Context';
import { withRouter } from 'react-router-dom';
import {
  useStyles,
  BootstrapInput,
  StyledTableCell,
  tableStyles,
} from './StylesComponents/material-ui-styled';
import {
  navigation,
  tipo_reunion,
  fechaActual,
  horaActual,
  horaMas,
  getHtmlInvitadoReunion,
} from '../funciones-auxiliares/programar-reunion';
import { createEvent, getEvent, updateEvent } from '../services/GraphService';
const moment = require('moment-timezone');

export const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
const useSizeDimmensions = () => {
  const [size, setSize] = useState(getWidth());
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      console.log('width', getWidth());
      timeoutId = setTimeout(() => {
        setSize(getWidth());
      }, 100);
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);
  return size;
};

function getSteps() {
  return [
    'Título de la reunión',
    'Detalles de la reunión',
    'Lugar de reunión',
    'Agregar invitados',
    'Asistentes a la reunión',
  ];
}

const ProgramarReunion = ({ session, history }) => {
  const [idsSearch, setIdsSearch] = useState({
    cCOR_Id: 0,
    cRET_Id: 0,
  });
  const [orgCorredor, setOrgCorredor] = useState(0);
  const [orgCompany, setOrgCompany] = useState(0);

  const context = useContext(MyContext);
  let nameUser = session.obtenerUsuario
    ? `${session.obtenerUsuario.cEMP_Nombre}`
    : history.push('/crm');
  console.log('ses', session.obtenerUsuario);
  let idUser = session.obtenerUsuario ? session.obtenerUsuario.cEMP_Id : null;
  //-----------Queries
  useQuery(GETCOUNTRIES_SIREC, {
    onCompleted({ idCatCountries }) {
      let idCatCwithoutMex = idCatCountries.filter((country) => {
        if (
          country.cPAI_NOMBRE !== 'México' &&
          country.cPAI_NOMBRE !== 'Sin País'
        ) {
          return country;
        }

        return false;
      });
      let idCatCwithMex = idCatCountries.filter((country) => {
        if (
          country.cPAI_NOMBRE === 'México' ||
          country.cPAI_NOMBRE === 'Sin País'
        ) {
          return country;
        }
        return false;
      });
      let response = idCatCwithoutMex.sort((a, b) =>
        a.cPAI_NOMBRE < b.cPAI_NOMBRE ? -1 : 1
      );
      response = [...idCatCwithMex, ...response];
      setDataCountries(response);
    },
  });

  useQuery(GETMESSAGE_SIREC, {
    onCompleted({ idCatMessageTemplate }) {
      setDataMessage(idCatMessageTemplate);
      console.log(idCatMessageTemplate);
    },
  });

  useQuery(GETEMPLOYES_SIREC, {
    onCompleted({ idEmployeSirec }) {
      setDataEmployes(idEmployeSirec);
    },
  });

  const [getCitiesSirec] = useLazyQuery(GETCITIES_SIREC, {
    onCompleted({ idCatCity }) {
      let response = idCatCity.sort((a, b) =>
        a.cEDO_Nombre < b.cEDO_Nombre ? -1 : 1
      );
      setDataCities(response);
    },
  });

  const [getCities] = useLazyQuery(GET_CIUDADESBYID, {
    onCompleted({ getCiudadById }) {
      setDataCiudades(getCiudadById);
    },
  });

  const [getNPais] = useLazyQuery(GET_PAIS_BYID, {
    onCompleted({ getPais }) {
      sessionStorage.setItem('pais', getPais);
    },
  });

  const [getNCiudad] = useLazyQuery(GET_CIUDAD_BYID, {
    onCompleted({ getCiudad }) {
      console.log(getCiudad);
      sessionStorage.setItem('ciudad', getCiudad);
    },
  });

  // const [getRetrocessionarySirec] = useLazyQuery(GETRETROCESSIONARY_SIREC, {
  //   onCompleted({ idRetrocessionarySirec }) {
  //     setRetrocessionary(idRetrocessionarySirec);
  //     console.log(idRetrocessionarySirec);
  //   },
  // });

  const [getRetrocessionarySirec_ById] = useLazyQuery(
    GETRETROCESSIONARY_SIREC_ById,
    {
      onCompleted({ idRetrocessionarySirecById }) {
        console.log(idRetrocessionarySirecById);
        setRetrocessionary(idRetrocessionarySirecById);
      },
    }
  );

  const [getCompanySirec] = useLazyQuery(GETCOMPANI_SIREC, {
    onCompleted({ idCompanySirec }) {
      setCompany(idCompanySirec);
    },
  });

  const [getCompanySirec_ById] = useLazyQuery(GETCOMPANI_SIREC_ById, {
    onCompleted({ idCompanySirecById }) {
      console.log(idCompanySirecById);
      setCompany(idCompanySirecById);
    },
  });

  const [getCorredorSirec] = useLazyQuery(GETCORREDOR_SIREC, {
    onCompleted({ idCorredorSirec }) {
      console.log('getCorredorSirec', idCorredorSirec);
      setCorredor(idCorredorSirec);
    },
  });

  const [getCorredorSirec_ById] = useLazyQuery(GETCORREDOR_SIREC_ById, {
    onCompleted({ idCorredorSirecById }) {
      console.log('idCorredorSirecByIdi', idCorredorSirecById);
      setCorredor(idCorredorSirecById);
    },
  });

  const [getCargosRetrosecionary] = useLazyQuery(GETINVITADOS_SIREC, {
    fetchPolicy: 'no-cache',
    onCompleted({ idInvitadosSirec }) {
      const cargos = idInvitadosSirec.map((invitado) =>
        invitado ? { ...invitado, status: false } : null
      );
      console.log('Cargos ->>>', cargos);
      setCargosSirec([
        // ...CargosSirec.filter(
        //   (invitado) =>
        //     (invitado.cORG_Id === orgCorredor || invitado.cORG_Id === orgCompany)
        // ),
        ...cargos,
      ]);
      // setCargosSirec([...cargos]);
      sessionStorage.setItem(
        'rEjexORG',
        JSON.stringify([...CargosSirec, ...cargos])
      );
    },
  });

  const [getTextMessage] = useLazyQuery(GET_TEXTMESSAGE_SIREC, {
    onCompleted({ getTextMessageById }) {
      let response = '';
      let corredor_company = `${
        Corredor[0]?.cCOR_RazSocial || '' || Company[0]?.cCIA_RazSocial || ''
      }${Company[0]?.cCIA_RazSocial ? ' y ' + Company[0]?.cCIA_RazSocial : ''}`;
      //console.log('VAAALLOR',Corredor[0].cCOR_RazSocial)
      response = getTextMessageById.cPLA_textPlantilla;
      response = response
        .replace('[NOMBRE REU]', Subject)
        .replace('[COMPANY]', corredor_company)
        .replace('[DATE I]', FechaInicio)
        .replace('[DATE F]', FechaFinal)
        .replace('[HOUR]', HoraZona);
      setTextMessage(response);
    },
    onError(error) {
      console.log(error);
    },
  });

  //------------Mutations
  const [crearEvento] = useMutation(CREATE_EVENT, {
    onCompleted({ createEvents }) {
      sessionStorage.setItem('cREU_Id', createEvents.cREU_Id);
      // PushNotification({
      //   variables: {
      //     typeNotification: 'newReunion',
      //     typeUserNotificate: getCemp_id(),
      //     cREU_Id: createEvents.cREU_Id,
      //   },
      // });
    },
  });

  const [crearEventoCompany] = useMutation(CREATE_EVENTCOMPANY, {
    onCompleted({ createEventsCompany }) {
      console.log(createEventsCompany);
    },
  });

  const [crearEventoCorredor] = useMutation(CREATE_EVENTCORREDOR, {
    onCompleted({ createEventsCorredor }) {
      console.log(createEventsCorredor);
    },
  });

  const [crearEventoEjecutivo] = useMutation(CREATE_EVENTEJECUTIVO, {
    onCompleted({ createEventsEjecutivo }) {
      console.log(createEventsEjecutivo);
    },
  });

  const [crearEventoInvitados] = useMutation(CREATE_EVENTINVITADOS, {
    onCompleted({ createEventsInvitados }) {
      console.log('Eventos', createEventsInvitados);
    },
  });

  const [crearEventoRetrocesionario] = useMutation(
    CREATE_EVENTRETROCESIONARIO,
    {
      onCompleted({ createEventsRetrocesionario }) {
        console.log(createEventsRetrocesionario);
      },
    }
  );

  const [updateInvitados] = useMutation(UPDATE_INVITADOS, {
    onCompleted({ updateEventsInvitados }) {
      console.log(updateEventsInvitados);
    },
  });

  const [editarEvento] = useMutation(UPDATE_EVENT, {
    onCompleted({ updateEvents }) {
      console.log(updateEvents);
    },
  });
  const [firePushNotification] = useMutation(FIRE_PUSH_NOTIFICATION, {
    onCompleted({ firePushNotification }) {
      console.log(firePushNotification);
    },
    onError(error) {
      console.log(error);
    },
  });

  //States
  const [FechaInicio, setFechaInicio] = useState(
    context.state.cREU_FechaInicio === ''
      ? fechaActual()
      : context.state.cREU_FechaInicio
  );
  const [HoraInicio, setHoraInicio] = useState(
    context.state.cREU_HoraInicio === ''
      ? horaActual()
      : context.state.cREU_HoraInicio
  );
  const [FechaFinal, setFechaFinal] = useState(
    context.state.cREU_FechaFin === ''
      ? fechaActual()
      : context.state.cREU_FechaFin
  );
  const [HoraFinal, setHoraFinal] = useState(
    context.state.cREU_HoraFin === ''
      ? horaMas(horaActual())
      : context.state.cREU_HoraFin
  );
  const [loading, setLoading] = useState(false);
  const [activeAddInvited, setActiveAddInvited] = useState(false);
  const [HoraZona, setHoraZona] = useState('');
  const [Subject, setSubject] = useState(context.state.cREU_Titulo);
  const [IdContries, setIdContries] = useState(0);
  const [textMessage, setTextMessage] = useState('');
  const [DataCountries, setDataCountries] = useState('');
  const [DataMessage, setDataMessage] = useState('');
  const [DataCities, setDataCities] = useState('');
  const [DataEmployes, setDataEmployes] = useState('');
  const [Invitados, setInvitados] = useState(context.state.invitadosReunion);
  const [Retrocessionary, setRetrocessionary] = useState([]);
  const [Company, setCompany] = useState([]);
  const [Corredor, setCorredor] = useState([]);
  const [CargosSirec, setCargosSirec] = useState(context.state.rEjexORG);
  const [cCIA_Id, setcCIA_Id] = useState([]);
  const [cCIA_Version, setcCIA_Version] = useState([]);
  const [cCOR_Id, setcCOR_Id] = useState([]);
  const [cCOR_Version, setcCOR_Version] = useState([]);
  const [cRET_Id, setcRET_ID] = useState([]);
  const [cRET_Version, setcRET_Version] = useState([]);
  const [linkConferencia, setlinkConferencia] = useState(
    context.state.cREU_ConferenciaURL
  );
  const [dataCuidades, setDataCiudades] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [showModalErr, setShowModalErr] = useState(false);
  const [errorMsgSave, setErrorMsgSave] = useState('')
  const [secAcordeon, setSecAcordeon] = useState({
    secTitulo: false,
    secDetalles: false,
    secLugar: false,
    secInvitados: false,
    secAsistentes: false,
  });
  const [form, setForm] = useState({
    nombre: '',
    puesto: '',
    correo: '',
  });
  const [validInputs, setValidInputs] = useState({
    estado: false,
    país: false,
    plantilla: false,
    'fecha de inicio': false,
    'Hora de inicio': false,
    'Fecha fin': false,
    'hora fin': false,
    lugar: false,
    titulo: false,
    'zona horaria': false,
    'Fecha actual país': false,
    'hora actual país': false,
    'tipo de reunion': false,
    // 'url conferencia': false,
    'ciudad': false,
    corredor: false,
    compañia: false,
    invitados: false
  })
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [cREU_Lugar, setcREU_Lugar] = useState(context.state.cREU_Lugar || '');
  const [aux_cEDO_Id, setaux_cEDO_Id] = useState(context.state.cEDO_Id || '0');
  const [aux_cCIUD_Id, setaux_cCIUD_Id] = useState(
    context.state.cCIUD_Id || '0'
  );

  const [aux_cPAI_Id, setaux_cPAI_Id] = useState(context.state.cPAI_Id || '0');
  const [aux_claveCorredor, setaux_claveCorredor] = useState('');
  const [aux_claveCompany, setaux_claveCompany] = useState('');
  const [aux_cREU_TipoReunion, setaux_cREU_TipoReunion] = useState(
    context.state.cREU_TipoReunion || ''
  );
  const [aux_cPLA_Id, setaux_cPLA_Id] = useState(context.state.cPLA_Id || '');
  const [clicked, setClicked] = React.useState(false);

  const HandleExpand = (seccion) => {
    const aux = secAcordeon[seccion];
    setSecAcordeon({ ...secAcordeon, [seccion]: !aux });
  };
  const [insertInvited] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log('insertInvited ', dynamicInsert);
    },
    onError(e) {
      console.log(e);
    },
  });
  const HandleExpandStepper = (step) => {
    const aux = {
      secTitulo: false,
      secDetalles: false,
      secLugar: false,
      secInvitados: false,
      secAsistentes: false,
    };
    switch (step) {
      case 0:
        setSecAcordeon({ ...aux, secTitulo: true });
        window.scrollTo(0, 0);
        break;
      case 1:
        setSecAcordeon({ ...aux, secDetalles: true });
        document.getElementById('detalles-reunion').scrollIntoView();
        break;
      case 2:
        setSecAcordeon({ ...aux, secLugar: true });
        document.getElementById('lugar-reunion').scrollIntoView();
        break;
      case 3:
        setSecAcordeon({ ...aux, secInvitados: true });
        document.getElementById('invitados-reunion').scrollIntoView();
        break;
      case 4:
        setSecAcordeon({ ...aux, secAsistentes: true });
        document.getElementById('asistentes-reunion').scrollIntoView();
        break;
      default:
        break;
    }
  };
  const handleComplete = () => {
    if (
      sessionStorage.getItem('cREU_Titulo') !== null &&
      sessionStorage.getItem('cREU_FechaActualPais')
    ) {
      const newCompleted = completed;
      newCompleted[0] = true;
      setCompleted(newCompleted);
      handleNext();
    }
    if (
      sessionStorage.getItem('cEMP') !== null ||
      sessionStorage.getItem('cEMP_Edit') !== null
    ) {
      const newCompleted = completed;
      newCompleted[1] = true;
      setCompleted(newCompleted);
      handleNext();
    }
    if (
      sessionStorage.getItem('cREU_Lugar') !== null &&
      sessionStorage.getItem('cPAI_Id') !== null &&
      sessionStorage.getItem('cEDO_Id') !== null &&
      sessionStorage.getItem('cREU_FechaActualPais') !== null
    ) {
      const newCompleted = completed;
      newCompleted[2] = true;
      setCompleted(newCompleted);
      handleNext();
    }
    if (
      sessionStorage.getItem('cRET_Id') !== null ||
      sessionStorage.getItem('cCOR_Id') !== null ||
      sessionStorage.getItem('cCIA_Id') !== null
    ) {
      const newCompleted = completed;
      newCompleted[3] = true;
      setCompleted(newCompleted);
      handleNext();
    }
    if (
      sessionStorage.getItem('cREU_TipoReunion') !== null &&
      sessionStorage.getItem('cPLA_Id') !== null
    ) {
      const newCompleted = completed;
      newCompleted[4] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };
  const handleOpenLoader = () => {
    setLoading(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.push('/crm/secciones/reuniones');
    //window.location.href='/crm/secciones/reuniones'
  };
  const handleCloseModalErr = () => {
    setShowModalErr(false)
  }

  const totalSteps = () => {
    return getSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? getSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    HandleExpandStepper(step);
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const guardarInvitados = (id) => {
    const { nombre, puesto, correo } = form;

    insertInvited({
      variables: {
        input: {
          table: 'cINVR_InvitadosReunion',
          columns: ['cREU_Id', 'cINVR_email', 'cINVR_nombre', 'cINVR_puesto'],
          detailValues: [
            {
              values: [
                sessionStorage.getItem('cREU_Id'),
                correo,
                nombre,
                puesto,
              ],
            },
          ],
        },
      },
    });
    crearEventoInvitados({
      variables: {
        cREU_Id: parseInt(sessionStorage.getItem('cREU_Id'), 10),
        cEMP_Id: id,
      },
    });
  };

  const pushInvitados = (invitado) => {
    setInvitados([...Invitados, invitado]);
    sessionStorage.setItem('cEMP', JSON.stringify([...Invitados, invitado]));
    handleComplete();
  };
  const deleteInvitados = (invitado) => {
    const edit = searchEditInvitados(invitado);
    if (edit === undefined) {
      const aux = Invitados.filter((item) => item.name !== invitado);
      sessionStorage.setItem('cEMP', JSON.stringify(aux));
      setInvitados(aux);
    } else {
      sessionStorage.setItem(
        'cEMP_Edit',
        JSON.stringify(
          Invitados.map((item) =>
            item.name === invitado && item.status_db
              ? { ...item, status_db: !item.status_db }
              : item
          )
        )
      );
      const aux = Invitados.filter((item) => item.name !== invitado);
      setInvitados(aux);
    }
  };

  const searchEditInvitados = (invitado) => {
    const aux = Invitados.find(
      (Invitado) => Invitado.name === invitado && Invitado.status_db
    );
    return aux;
  };
  const editInvitados = (indexEdit, statusEdit) => {
    setInvitados(
      Invitados.map((invitado, index) =>
        index === indexEdit ? { ...invitado, status: statusEdit } : invitado
      )
    );
    sessionStorage.setItem(
      'cEMP',
      JSON.stringify(
        Invitados.map((invitado, index) =>
          index === indexEdit ? { ...invitado, status: statusEdit } : invitado
        )
      )
    );
  };

  const editEje = (indexEdit, statusEdit) => {
    setCargosSirec(
      CargosSirec.map((cargo, index) =>
        index === indexEdit ? { ...cargo, status: statusEdit } : cargo
      )
    );
    sessionStorage.setItem(
      'rEjexORG',
      JSON.stringify(
        CargosSirec.map((cargo, index) =>
          index === indexEdit ? { ...cargo, status: statusEdit } : cargo
        )
      )
    );
  };
  const getInvitadosSirec = async (ID, Tipo, version) => {  
    console.log('getInvitadosSirec', ID, Tipo, version); 
    getCargosRetrosecionary({
      variables: { cTORG_Id: Tipo, cORG_Id: ID },
    });
    if (Tipo === 1 || Tipo === 3) {      
      if (version.cRET_Version === undefined) {        
        sessionStorage.setItem('cCIA_Id', JSON.stringify([...cCIA_Id, ID]));
        setcCIA_Id([...cCIA_Id, ID]);
        sessionStorage.setItem(
          'cCIA_Version',
          JSON.stringify([...cCIA_Version, version.cCIA_Version])
        );
        sessionStorage.setItem('aseguradora', version.cCIA_RazSocial);
        setcCIA_Version([...cCIA_Version, version.cCIA_Version]);
      } else {
        sessionStorage.setItem('cRET_Id', JSON.stringify([...cRET_Id, ID]));
        setcRET_ID([...cRET_Id, ID]);
        sessionStorage.setItem(
          'cRET_Version',
          JSON.stringify([...cRET_Version, version.cRET_Version])
        );
        sessionStorage.setItem('aseguradora', version.cRET_RazSocial);
        setcRET_Version([...cRET_Version, version.cRET_Version]);
      }
    } else {      
      sessionStorage.setItem('cCOR_Id', JSON.stringify([...cCOR_Id, ID]));
        setcCOR_Id([...cCOR_Id, ID]);
        sessionStorage.setItem(
          'cCOR_Version',
          JSON.stringify([...cCOR_Version, version.cCOR_Version])
        );
        sessionStorage.setItem('aseguradora', version.cCOR_RazSocial);
        setcCOR_Version([...cCOR_Version, version.cCOR_Version]);      
    }
    handleComplete();
  };
  const onChange = (e) => {
    switch (e.target.id) {
      case 'date-inicio':
        console.log(e.target.value);
        setFechaInicio(e.target.value);
        setFechaFinal(e.target.value);
        sessionStorage.setItem('cREU_FechaInicio', e.target.value);
        sessionStorage.setItem('cREU_FechaFin', e.target.value);
        getHourforZona();
        break;
      case 'date-final':
        setFechaFinal(e.target.value);
        console.log(FechaFinal);
        sessionStorage.setItem('cREU_FechaFin', e.target.value);
        break;
      case 'hora-inicio':
        setHoraInicio(e.target.value);
        setHoraFinal(horaMas(e.target.value));
        sessionStorage.setItem('cREU_HoraInicio', e.target.value);
        sessionStorage.setItem('cREU_HoraFin', horaMas(e.target.value));
        getHourforZona();
        break;
      case 'hora-final':
        setHoraFinal(e.target.value);
        sessionStorage.setItem('cREU_HoraFin', e.target.value);
        break;
      case 'name-reunion':
        setSubject(e.target.value);
        sessionStorage.setItem('cREU_Titulo', e.target.value);
        handleComplete();
        break;
      case 'ciudad-reunion':
        setIdContries(e.target.value);
        getNCiudad({
          variables: {
            cPAI_Id: parseInt(sessionStorage.getItem('cPAI_Id'), 10),
            cEDO_Id: parseInt(e.target.value, 10),
          },
        });
        console.log('Countries', IdContries);
        sessionStorage.setItem('cEDO_Id', e.target.value);
        setaux_cEDO_Id(e.target.value);
        handleComplete();
        break;

      case 'zona-horaria':
        sessionStorage.setItem('cREU_ZonaHoraria', e.target.value);
        getHourforZona();
        break;
      case 'pais-reunion':
        console.log('valueeee=====>', e.target.value);
        sessionStorage.setItem('cPAI_Id', e.target.value);
        sessionStorage.setItem('cCIUD_Id', e.target.value);
        getNPais({ variables: { cPAI_Id: parseInt(e.target.value, 10) } });
        getCitiesSirec({
          variables: { cPAI_Id: parseInt(e.target.value, 10) },
        });
        getCities({ variables: { cPAI_Id: parseInt(e.target.value, 10) } });
       /*  getRetrocessionarySirec({
          variables: { cPAI_Id: parseInt(e.target.value, 10) },
        });
        getCompanySirec({
          variables: { cPAI_Id: parseInt(e.target.value, 10) },
        });
        getCorredorSirec({
          variables: { cPAI_Id: parseInt(e.target.value, 10) },
        }); */
        setaux_cPAI_Id(e.target.value);
        handleComplete();
        break;
      case 'lugar-reunion':
        sessionStorage.setItem('cREU_Lugar', e.target.value);
        setcREU_Lugar(e.target.value);
        handleComplete();
        break;
      case 'link-conferencia':
        setlinkConferencia(e.target.value);
        sessionStorage.setItem('cREU_ConferenciaURL', e.target.value);
        break;
      case 'tipo-reunion':
        console.log(e.target.value);
        sessionStorage.setItem('cREU_TipoReunion', e.target.value);
        setaux_cREU_TipoReunion(e.target.value);
        handleComplete();
        break;
      case 'plantilla-mensaje':
        getTextMessage({
          variables: { cPLA_Id: parseInt(e.target.value, 10) },
        });
        sessionStorage.setItem('cPLA_Id', e.target.value);
        setaux_cPLA_Id(e.target.value);
        handleComplete();
        break;
      case 'clave-reunion-corredor':
        getCorredorSirec_ById({
          variables: { cCOR_Id: parseInt(e.target.value, 10) },
        });
        setaux_claveCorredor(parseInt(e.target.value, 10));
        setIdsSearch({
          ...idsSearch,
          cCOR_Id: parseInt(e.target.value, 10),
        });
        if (e.target.value === '' || e.target.value === null){
          setCargosSirec(CargosSirec.filter(obj => obj.cTORG_Id != 2));          
        }
        break;
      case 'text-mensaje':
        setTextMessage(e.target.value);
        sessionStorage.setItem('textMessage', e.target.value);
        break;
      case 'ciudades-reunion':
        sessionStorage.setItem('cCIUD_Id', e.target.value);
        setaux_cCIUD_Id(e.target.value);

        break;

      /*     case 'ciudades-reunion':
                    setIdContries(e.target.value);
                   
                    console.log("Countries",IdContries);
                    sessionStorage.setItem('cEDO_Id',e.target.value)
                    setaux_cCIUD_Id(e.target.value)
                    
                    break; */

      default:
        break;
    }
  };
  const onChangeCompany = ({ target }) => {
    getCompanySirec_ById({
      variables: { cCIA_Id: parseInt(target.value, 10) },
    });
    setaux_claveCompany(parseInt(target.value, 10));
    setIdsSearch({
      ...idsSearch,
      cRET_Id: parseInt(target.value, 10),
    });
    getRetrocessionarySirec_ById({
      variables: { cRET_Id: parseInt(target.value, 10) },
    });
    handleComplete();
    if (target.value === '' || target.value === null) {
      setCargosSirec(CargosSirec.filter(obj => obj.cTORG_Id != 1 && obj.cTORG_Id != 3));
    }
  };
  const getHourforZona = () => {
    const m = moment.tz(
      `${sessionStorage.getItem('cREU_FechaInicio')} ${sessionStorage.getItem(
        'cREU_HoraInicio'
      )}`,
      'America/Mexico_City'
    );
    sessionStorage.setItem(
      'cREU_FechaActualPais',
      m.tz(sessionStorage.getItem('cREU_ZonaHoraria')).format().slice(0, 10)
    );
    sessionStorage.setItem(
      'cREU_HoraActualPais',
      m.tz(sessionStorage.getItem('cREU_ZonaHoraria')).format().slice(11, 16)
    );
    setHoraZona(sessionStorage.getItem('cREU_HoraActualPais'));
    handleComplete();
  };
  const editUser = (cEMP_Id) => {
    sessionStorage.setItem('cEMP_Id', cEMP_Id);
  };
  const getCemp_id = () => {
    let invitados = JSON.parse(sessionStorage.getItem('cEMP'));
    let aux = '';
    console.log('invitados=====>', JSON.parse(sessionStorage.getItem('cEMP')));
    if (invitados !== null) {
      invitados.forEach((data, index) => {
        if (index === 0) {
          aux = `${data.cEMP_Id}`;
        } else {
          aux = `${aux},${data.cEMP_Id}`;
        }
      });
    }

    return aux;
  };
  const crearEventOutlook = async () => {
    const inicio = new Date(
      `${sessionStorage.getItem('cREU_FechaInicio')} ${sessionStorage.getItem(
        'cREU_HoraInicio'
      )}`
    );
    const fin = new Date(
      `${sessionStorage.getItem('cREU_FechaFin')} ${sessionStorage.getItem(
        'cREU_HoraFin'
      )}`
    );
    const saveInvitados = JSON.parse(sessionStorage.getItem('cEMP'));
    const editInvitados = JSON.parse(sessionStorage.getItem('cEMP_Edit'));
    const event = {
      evento: {
        subject: sessionStorage.getItem('cREU_Titulo'),
        body: {
          contentType: 'HTML',
          content: getHtmlInvitadoReunion(
            'Invitación a reunión',
            'Has sido invitado a una reunión',
            sessionStorage.getItem('cREU_Titulo'),
            sessionStorage.getItem('cREU_FechaInicio'),
            sessionStorage.getItem('aseguradora'),
            `${sessionStorage.getItem('pais')},${sessionStorage.getItem(
              'ciudad'
            )}`,
            sessionStorage.getItem('textMessage') || textMessage
          ),
        },
        start: {
          dateTime: inicio,
          timeZone: sessionStorage.getItem('cREU_ZonaHoraria'),
        },
        end: {
          dateTime: fin,
          timeZone: sessionStorage.getItem('cREU_ZonaHoraria'),
        },
        location: {
          displayName: sessionStorage.getItem('cREU_Lugar'),
        },
        attendees: [
          //   {
          //     emailAddress: {
          //       address:"cesar_ausangate@hotmail.com",
          //       name: "Cesar Prueba"
          //     },
          //   },
        ],
      },
    };
    if (saveInvitados) {
      saveInvitados.forEach((data) => {
        const aux = {
          emailAddress: {
            address: data.correo,
            name: data.name,
          },
          type: 'required',
        };
        event.evento.attendees.push(aux);
      });
    }
    if (editInvitados) {
      console.log('Editar ');
      console.log(editInvitados);
      editInvitados.forEach((data) => {
        if (data.status_db) {
          const aux = {
            emailAddress: {
              address: data.correo,
              name: data.name,
            },
            type: 'required',
          };
          event.evento.attendees.push(aux);
        }
      });
      await updateEvent(
        context.state.AuthComponentProps.token,
        event.evento,
        sessionStorage.getItem('cREU_IdOutlook')
      )
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      //   await OUTLOOK_SERVICE.updateEvent(event,sessionStorage.getItem('cREU_IdOutlook'))
      //   .then((data)=>console.log(data))
      //   .catch((err)=>console.log(err));
    } else {
      console.log(event);
      await createEvent(context.state.AuthComponentProps.token, event.evento)
        .then((data) => {
          sessionStorage.setItem('cREU_IdOutlook', data.id);
        })
        .catch((err) => console.log(err));
    }
  };

  const validateEachField = (obj) => {
      const array = Object.values(obj);
      const keysObj = Object.keys(obj);
      let response = []
      array.forEach((field, index) => {
        if(field === null || field === undefined || field === '') {
          let name = keysObj[index]
          response.push(name)
          setValidInputs((validInputs) => ({
            ...validInputs,
            [name]: true,
          }))
        }
      })
      return response.toString(); 
  }
  const guardarData = async () => {
    setClicked(true);
    const cREU_Id = sessionStorage.getItem('cREU_Id');
    const cCIUD_Id = sessionStorage.getItem('cCIUD_Id');
    const cEMP_Id = sessionStorage.getItem('cEMP_Id');
    const cEDO_Id = sessionStorage.getItem('cEDO_Id');
    const cPAI_Id = sessionStorage.getItem('cPAI_Id');
    const cPLA_Id = sessionStorage.getItem('cPLA_Id');
    const cREU_ConferenciaURL = sessionStorage.getItem('cREU_ConferenciaURL');
    const cREU_FechaInicio = sessionStorage.getItem('cREU_FechaInicio');
    const cREU_HoraInicio = sessionStorage.getItem('cREU_HoraInicio');
    const cREU_FechaFin = sessionStorage.getItem('cREU_FechaFin');
    const cREU_HoraFin = sessionStorage.getItem('cREU_HoraFin');
    const cREU_Lugar = sessionStorage.getItem('cREU_Lugar');
    const cREU_Titulo = sessionStorage.getItem('cREU_Titulo');
    const cREU_ZonaHoraria = sessionStorage.getItem('cREU_ZonaHoraria');
    const cREU_FechaActualPais = sessionStorage.getItem('cREU_FechaActualPais');
    const cREU_HoraActualPais = sessionStorage.getItem('cREU_HoraActualPais');
    const cREU_TipoReunion = sessionStorage.getItem('cREU_TipoReunion');
    const saveInvitados = JSON.parse(sessionStorage.getItem('cEMP'));
    
    if (context.state.cREU_Id === null) {
      //Guardar
      const savecCIA_Id = JSON.parse(sessionStorage.getItem('cCIA_Id'));
      const savecCIA_Version = JSON.parse(sessionStorage.getItem('cCIA_Version'));
      const savecCOR_Id = JSON.parse(sessionStorage.getItem('cCOR_Id'));
      const savecCOR_Version = JSON.parse(sessionStorage.getItem('cCOR_Version'));
      const savecRET_Id = JSON.parse(sessionStorage.getItem('cRET_Id'));
      const savecRET_Version = JSON.parse(sessionStorage.getItem('cRET_Version'));
      const saverEjexORG = JSON.parse(sessionStorage.getItem('rEjexORG'));

      if ( cEMP_Id === null || cEDO_Id === null || cPAI_Id === null || cPLA_Id === null ||
        cREU_FechaInicio === null || cREU_HoraInicio === null || cREU_FechaFin === null ||
        cREU_HoraFin === null ||cREU_Lugar === null || cREU_Titulo === null ||
        cREU_ZonaHoraria === null ||cREU_FechaActualPais === null || cREU_HoraActualPais === null ||
        cREU_TipoReunion === null || cCIUD_Id === null
      ) {        
        const array = {
          cEMP_Id, estado: cEDO_Id,
           país: cPAI_Id, plantilla: cPLA_Id,  'fecha de inicio': cREU_FechaInicio,  'Hora de inicio': cREU_HoraInicio,  'Fecha fin': cREU_FechaFin, 
          'hora fin': cREU_HoraFin, lugar: cREU_Lugar,  titulo: cREU_Titulo, 'zona horaria': cREU_ZonaHoraria, 'Fecha actual país': cREU_FechaActualPais,  
          'hora actual país': cREU_HoraActualPais, 'tipo de reunion': cREU_TipoReunion, 'ciudad': cCIUD_Id,
          corredor: savecCOR_Id, compañia: savecRET_Id, invitados: saveInvitados
        }
        setErrorMsgSave(` Campos vacíos: ${validateEachField(array)}`)  
        setShowModalErr(true);
        setClicked(false);
      } else {
        console.log('VALOR DE LA CONSULTA', {
          variables: {
            cEMP_Id: parseInt(cEMP_Id, 10),
            cEDO_Id: parseInt(cEDO_Id, 10),            
            cPAI_Id: parseInt(cPAI_Id, 10),
            cPLA_Id: parseInt(cPLA_Id, 10),
            cREU_ConferenciaURL: cREU_ConferenciaURL,
            cREU_FechaInicio: cREU_FechaInicio,
            cREU_HoraInicio: cREU_HoraInicio,
            cREU_FechaFin: cREU_FechaFin,
            cREU_HoraFin: cREU_HoraFin,
            cREU_Lugar: cREU_Lugar,
            cREU_Titulo: cREU_Titulo,
            cREU_ZonaHoraria: cREU_ZonaHoraria,
            cREU_FechaActualPais: cREU_FechaActualPais,
            cREU_HoraActualPais: cREU_HoraActualPais,
            cREU_TipoReunion: cREU_TipoReunion,
            cREU_IdOutlook: sessionStorage.getItem('cREU_IdOutlook'),
            cCIUD_Id: parseInt(cCIUD_Id, 10)
          },
        });
        await crearEventOutlook();
        handleOpenLoader();
        const cREU_IdOutlook = sessionStorage.getItem('cREU_IdOutlook')
        await crearEvento({
          variables: {
            cEMP_Id: parseInt(cEMP_Id, 10),
            cEDO_Id: parseInt(cEDO_Id, 10),            
            cPAI_Id: parseInt(cPAI_Id, 10),
            cPLA_Id: parseInt(cPLA_Id, 10),
            cREU_ConferenciaURL: cREU_ConferenciaURL,
            cREU_FechaInicio: cREU_FechaInicio,
            cREU_HoraInicio: cREU_HoraInicio,
            cREU_FechaFin: cREU_FechaFin,
            cREU_HoraFin: cREU_HoraFin,
            cREU_Lugar: cREU_Lugar,
            cREU_Titulo: cREU_Titulo,
            cREU_ZonaHoraria: cREU_ZonaHoraria,
            cREU_FechaActualPais: cREU_FechaActualPais,
            cREU_HoraActualPais: cREU_HoraActualPais,
            cREU_TipoReunion: cREU_TipoReunion,
            cREU_IdOutlook: cREU_IdOutlook || '',
            cCIUD_Id: parseInt(cCIUD_Id, 10)
          },
        }).then((data) => console.log('loading', loading));
        if (saveInvitados !== null) {
          saveInvitados.forEach(async (invitados) => {
            await guardarInvitados(parseInt(invitados.cEMP_Id), 10);
          });
        }
        if (savecCOR_Id !== null && savecCOR_Version !== null) {
          savecCOR_Id.forEach(async (corredor, index) => {
            await crearEventoCorredor({
              variables: {
                cREU_Id: parseInt(sessionStorage.getItem('cREU_Id'), 10),
                cCOR_Id: savecCOR_Id[index],
                cCOR_Version: savecCOR_Version[index],
              },
            });
          });
        }
        if (savecCIA_Id !== null && savecCIA_Version !== null) {
          savecCIA_Id.forEach(async (company, index) => {
            await crearEventoCompany({
              variables: {
                cREU_Id: parseInt(sessionStorage.getItem('cREU_Id'), 10),
                cCIA_Id: savecCIA_Id[index],
                cCIA_Version: savecCIA_Version[index],
              },
            });
          });
        }
        if (savecRET_Id !== null && savecRET_Version !== null) {
          savecRET_Id.forEach(async (retro, index) => {
            await crearEventoRetrocesionario({
              variables: {
                cREU_Id: parseInt(sessionStorage.getItem('cREU_Id'), 10),
                cRET_Id: savecRET_Id[index],
                cRET_Version: savecRET_Version[index],
              },
            });
          });
        }
        if (saverEjexORG !== null) {
          saverEjexORG.forEach(async (ejecutivo) => {
            if (ejecutivo.status) {
              await crearEventoEjecutivo({
                variables: {
                  cREU_Id: parseInt(sessionStorage.getItem('cREU_Id')),
                  cTORG_Id: ejecutivo.cTORG_Id,
                  cORG_Id: ejecutivo.cORG_Id,
                  cORG_Ver: ejecutivo.cORG_Ver,
                  cEje_Id: ejecutivo.cEje_Id,
                },
              });
            }
          });
        }
        context.clearState();
        sessionStorage.clear();
        //shistory.push('/crm/secciones/reuniones')
        setOpenDialog(true);
        setLoading(false);
      }
    } else {
      //Editar

      const editInvitado = JSON.parse(sessionStorage.getItem('cEMP_Edit'));
      await crearEventOutlook();

      await editarEvento({
        variables: {
          cREU_Id: parseInt(cREU_Id, 10),
          cEMP_Id: parseInt(cEMP_Id, 10),
          cEDO_Id: parseInt(cEDO_Id, 10),
          cPAI_Id: parseInt(cPAI_Id, 10),
          cPLA_Id: parseInt(cPLA_Id, 10),
          cREU_ConferenciaURL: cREU_ConferenciaURL,
          cREU_FechaInicio: cREU_FechaInicio,
          cREU_HoraInicio: cREU_HoraInicio,
          cREU_FechaFin: cREU_FechaFin,
          cREU_HoraFin: cREU_HoraFin,
          cREU_Lugar: cREU_Lugar,
          cREU_Titulo: cREU_Titulo,
          cREU_ZonaHoraria: cREU_ZonaHoraria,
          cREU_FechaActualPais: cREU_FechaActualPais,
          cREU_HoraActualPais: cREU_HoraActualPais,
          cREU_TipoReunion: cREU_TipoReunion,
          cCIUD_Id: parseInt(cCIUD_Id, 10)
        },
      });

      if (editInvitado !== null) {
        editInvitado.forEach(async (invitado) => {
          if (!invitado.status_db) {
            await updateInvitados({
              variables: {
                cREU_Id: parseInt(cREU_Id, 10),
                cEMP_Id: parseInt(invitado.cEMP_Id),
                rREUXEMP_Status: '0',
              },
            });
          }
        });
      }
      if (saveInvitados !== null) {
        saveInvitados.forEach(async (invitados) => {
          await guardarInvitados(parseInt(invitados.cEMP_Id), 10);
        });
      }

      setOpenDialog(true);
      context.clearState();
      sessionStorage.clear();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const descartarData = () => {
    sessionStorage.clear();
    history.push('/crm/secciones/reuniones');
    context.clearState();
  };

  const borrarData = () => {
    setSubject('');
    setHoraInicio(horaActual());
    setFechaInicio(fechaActual());
    setHoraFinal(horaActual());
    setFechaFinal(fechaActual());
    setInvitados([]);
    setDataCities([]);
    setlinkConferencia('');
    setCargosSirec([]);
    sessionStorage.clear();
    context.clearState();
    handleReset();
  };

  useEffect(() => {
    if (context.state.cREU_Id === null) {
      sessionStorage.setItem('cREU_FechaInicio', fechaActual());
      sessionStorage.setItem('cREU_HoraInicio', horaActual());
      sessionStorage.setItem('cREU_FechaFin', fechaActual());
      sessionStorage.setItem('cREU_HoraFin', horaMas(horaActual()));
      sessionStorage.setItem('cREU_ZonaHoraria', 'America/Mexico_city');
      sessionStorage.setItem('cEMP_Id', idUser);
    } else {
      sessionStorage.setItem('cEDO_Id', context.state.cEDO_Id);
      sessionStorage.setItem('cEMP_Id', context.state.cEMP_Id);
      sessionStorage.setItem('cPAI_Id', context.state.cPAI_Id);
      sessionStorage.setItem('cCIUD_Id', context.state.cCIUD_Id);
      sessionStorage.setItem('cPLA_Id', context.state.cPLA_Id);
      sessionStorage.setItem('cREU_Id', context.state.cREU_Id);
      sessionStorage.setItem(
        'cREU_ConferenciaURL',
        context.state.cREU_ConferenciaURL
      );
      sessionStorage.setItem(
        'cREU_FechaActualPais',
        context.state.cREU_FechaActualPais
      );
      sessionStorage.setItem('cREU_FechaFin', context.state.cREU_FechaFin);
      sessionStorage.setItem(
        'cREU_FechaInicio',
        context.state.cREU_FechaInicio
      );
      sessionStorage.setItem(
        'cREU_HoraActualPais',
        context.state.cREU_HoraActualPais
      );
      sessionStorage.setItem('cREU_HoraFin', context.state.cREU_HoraFin);
      sessionStorage.setItem('cREU_HoraInicio', context.state.cREU_HoraInicio);
      sessionStorage.setItem('cREU_Lugar', context.state.cREU_Lugar);
      sessionStorage.setItem(
        'cREU_TipoReunion',
        context.state.cREU_TipoReunion
      );
      sessionStorage.setItem('cREU_Titulo', context.state.cREU_Titulo);
      sessionStorage.setItem(
        'cREU_ZonaHoraria',
        context.state.cREU_ZonaHoraria
      );
      sessionStorage.setItem('cREU_IdOutlook', context.state.cREU_IdOutlook);
      sessionStorage.setItem(
        'cEMP_Edit',
        JSON.stringify(context.state.invitadosReunion)
      );
      getCitiesSirec({ variables: { cPAI_Id: context.state.cPAI_Id } });
      getCities({ variables: { cPAI_Id: parseInt(context.state.cPAI_Id, 10) } });
    /*   getRetrocessionarySirec({
        variables: { cPAI_Id: context.state.cPAI_Id },
      }); */
      /* getCompanySirec({ variables: { cPAI_Id: context.state.cPAI_Id } });
      getCorredorSirec({ variables: { cPAI_Id: context.state.cPAI_Id } }); */
      getTextMessage({ variables: { cPLA_Id: context.state.cPLA_Id } });
      getHourforZona();
      console.log('===>Invitados', Invitados);
      getEvent(
        context.state.AuthComponentProps.token,
        context.state.cREU_IdOutlook
      )
        .then(({ attendees }) => {
          console.log('Attendess===>', attendees);
          let aux = context.state.invitadosReunion;
          for (let i = 0; i < attendees.length; i++) {
            for (let j = 0; j < aux.length; j++) {
              if (aux[j].correo === attendees[i].emailAddress.address) {
                if (attendees[i].status.response === 'none') {
                  aux[j] = {
                    ...aux[j],
                    statusOutlook: 0,
                  };
                } else if (
                  attendees[i].status.response === 'tentativelyAccepted'
                ) {
                  aux[j] = {
                    ...aux[j],
                    statusOutlook: 2,
                  };
                } else if (attendees[i].status.response === 'accepted') {
                  aux[j] = {
                    ...aux[j],
                    statusOutlook: 3,
                  };
                } else {
                  aux[j] = {
                    ...aux[j],
                    statusOutlook: 1,
                  };
                }
              }
            }
          }
          console.log('Sttatus====>', aux);
          setInvitados(aux);
        })
        .catch((err) => console.log('Error evento----->', err));
    }
    window.scrollTo(0, 0);
    handleComplete();
  }, [
    context,
    getCitiesSirec,
    getCompanySirec,
    getCorredorSirec,
    // getRetrocessionarySirec,
    getTextMessage,
    idUser,
  ]);
  useEffect(() => {
    console.log(context.state.cCIUD_Id);
    if (context.state.cCIUD_Id && dataCuidades.length > 0) {
      setaux_cCIUD_Id(context.state.cCIUD_Id)
    }
  }, [dataCuidades])
  const PushNotification = ({ variables }) => {
    const { typeNotification, typeUserNotificate, cREU_Id } = variables;
    let input = {
      cEMP_Id: session.obtenerUsuario.cEMP_Id,
      typeNotification,
      typeUserNotificate,
      cREU_Id,
    };
    firePushNotification({ variables: { input } });
  };

  useEffect(() => {
    if (
      context.state.AuthComponentProps.isAuthenticated === null ||
      context.state.AuthComponentProps.isAuthenticated === false
    ) {
      history.push('/crm/secciones');
    }
  }, []);
  const classes = useStyles();
  const tableClasses = tableStyles();

  return (
    <Page id="ProgramarReunion">
      {nameUser}

      <DialogAlert
        title=""
        text="Exito al guardar reunión"
        textButtonA="Aceptar"
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <DialogAlert
        title="Error al guardar"
        text={errorMsgSave}
        textButtonA="Aceptar"
        open={showModalErr}
        handleClose={handleCloseModalErr}
      />

      <Homebar navigation={navigation} />
      <PageContainer position="fixed" marginTop="88px" marginBottom="388px">
        <Title>Configuración de la reunión</Title>
        <Subtitle>
          Personaliza tu reunión y los detalles de los asistentes
        </Subtitle>
        <SteperReuniones
          activeStep={activeStep}
          completed={completed}
          handleComplete={handleComplete}
          getSteps={getSteps}
          handleStep={handleStep}
        />
      </PageContainer>
      <div className="seccion-reuniones">
        <Typography>Título de la reunión ó viaje</Typography>
        <TextField
          id="name-reunion"
          fullWidth
          placeholder="Ingresa nombre"
          value={Subject}
          variant="outlined"
          onChange={onChange}          
          className={`${Subject !== '' ? 'inputActive' : validInputs.titulo ? 'inputError' : ''}`}
        />
      </div>
      <AcordeonContainer>
        <Acordeon
          imgSrc="/images/Icons/calendario.png"
          label="Selección de fecha y hora de la reunión"
          margin={'0px 10px 0px 0px'}
          expanded={secAcordeon.secTitulo}
          setExpanded={HandleExpand}
          seccion="secTitulo"
        >
          <AcordeonContainerBody>
            <ContainerTwoInputs>
              <InputTwo
                id="date-inicio"
                label="Fecha inicio"
                type="date"
                variant="filled"
                value={FechaInicio}
                error={validInputs['fecha de inicio']}
                onChange={onChange}                
              />
              <InputTwo
                id="hora-inicio"
                label="Hora inicio"
                type="time"
                variant="filled"
                value={HoraInicio}
                error={validInputs['Hora de inicio']}
                onChange={onChange}
              />
            </ContainerTwoInputs>
            <Typography style={{ textAlign: 'center' }}>-</Typography>
            <ContainerTwoInputs>
              <InputTwo
                id="date-final"
                label="Fecha final"
                type="date"
                variant="filled"
                value={FechaFinal}
                error={validInputs['Fecha fin']}
                onChange={onChange}
              />
              <InputTwo
                id="hora-final"
                label="Hora final"
                type="time"
                variant="filled"
                value={HoraFinal}
                error={validInputs['hora fin']}
                onChange={onChange}
              />
            </ContainerTwoInputs>
          </AcordeonContainerBody>
        </Acordeon>
      </AcordeonContainer>
      <AcordeonContainer id="detalles-reunion">
        <Acordeon
          imgSrc="/images/Icons/Detalles.png"
          label="Detalles de la reunión"
          margin={'0px 10px 0px 0px'}
          expanded={secAcordeon.secDetalles}
          setExpanded={HandleExpand}
          seccion="secDetalles"
        >
          <AcordeonContainerBody flexDirection="column" marginBottom="20px">
            <Typography>Persona quien realiza reunión</Typography>
            {/* <TextField id="user-name" variant="outlined" defaultValue={nameUser} style={{width:"100%"}} disabled/> */}
            <UserAutoComplete
              User={nameUser}
              Employes={DataEmployes}
              editUser={editUser}
            />
          </AcordeonContainerBody>
          <AcordeonContainerBody flexDirection="column" marginBottom="20px">
            <Typography>Invitados equipo Patria</Typography>
            {DataEmployes && (
              <SearchAutoComplete
                Employes={DataEmployes}
                getInvitado={pushInvitados}
                validInputs={validInputs}
              />
            )}
          </AcordeonContainerBody>
          <TableContainer component={Paper} style={{ width: '90%' }}>
            {Invitados && (
              <Table>
                <TableBody>
                  {Invitados.map((invitado, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-invitados-r">
                        <Person />
                      </TableCell>
                      <TableCell className="table-invitados-r">
                        {invitado.name}
                      </TableCell>
                      <TableCell className="table-invitados-r">
                        {invitado.correo}
                      </TableCell>
                      <TableCell className="table-invitados">
                        <ButtonDelete
                          Icon={<Clear />}
                          name={invitado.name}
                          deleteFunction={deleteInvitados}
                        />
                      </TableCell>
                      <TableCell className="table-invitados-r">
                        <Checkbox
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<RadioButtonChecked />}
                          color="default"
                          onChange={(e) =>
                            editInvitados(index, e.target.checked)
                          }
                          checked={invitado.status}
                        />
                      </TableCell>
                      <TableCell className="table-invitados">
                        Enviar notificación
                      </TableCell>
                      <TableCell className="table-invitados-r">
                        {invitado.status ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Error />
                        )}
                      </TableCell>
                      {context.state.cREU_Id !== null ? (
                        <Fragment>
                          <TableCell className="table-invitados">
                            Status
                          </TableCell>
                          <TableCell className="table-invitados-r">
                            {invitado.statusOutlook === 3 ? (
                              <CheckCircle style={{ color: 'green' }} />
                            ) : invitado.statusOutlook === 2 ? (
                              <CheckCircle style={{ color: 'yellow' }} />
                            ) : invitado.statusOutlook === 1 ? (
                              <CheckCircle style={{ color: 'gray' }} />
                            ) : invitado.statusOutlook === 0 ? (
                              <RemoveCircle color="secondary" />
                            ) : null}
                          </TableCell>
                        </Fragment>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Acordeon>
      </AcordeonContainer>
      <AcordeonContainer id="lugar-reunion">
        <Acordeon
          imgSrc="/images/Icons/ubicacion.png"
          label="Lugar de reunión"
          margin={'0px 10px 0px 0px'}
          expanded={secAcordeon.secLugar}
          setExpanded={HandleExpand}
          seccion="secLugar"
        >
          <AcordeonContainerBody
            marginBottom="20px"
            style={{ flexWrap: 'wrap' }}
          >
            <ContainerTwoInputs flexDirection="column">
              <Typography>Lugar</Typography>
              <NativeSelect
                input={<BootstrapInput />}
                style={{ width: '100%' }}
                id="lugar-reunion"
                onChange={onChange}
                value={cREU_Lugar}
                className={`${cREU_Lugar !== '' && cREU_Lugar !== undefined ? 'inputActive' : validInputs.lugar ? 'inputError' : ''}`}
              >
                <option value="">Seleccionar</option>
                <option value="Hotel">Hotel</option>
                <option value="Restaurante">Restaurante</option>
                <option value="Oficina">Oficina</option>
                <option value="Otro">Otro</option>
              </NativeSelect>
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column">
              <Typography>País</Typography>
              <NativeSelect
                input={<BootstrapInput />}
                style={{ width: '100%' }}
                id="pais-reunion"
                onChange={onChange}
                value={parseInt(aux_cPAI_Id, 10)}
                className={`${aux_cPAI_Id !== '0' && aux_cPAI_Id !== '' && aux_cPAI_Id !== undefined ? 'inputActive' : validInputs.país ? 'inputError' : ''}`}                
              >
                {DataCountries &&
                  DataCountries.map((countrie, index) => (
                    <option key={index} value={countrie.cPAI_Id}>
                      {countrie.cPAI_NOMBRE}
                    </option>
                  ))}
              </NativeSelect>
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column">
              <Typography>Estado</Typography>
              <NativeSelect
                input={<BootstrapInput />}
                style={{ width: '100%' }}
                id="ciudad-reunion"
                onChange={onChange}
                value={parseInt(aux_cEDO_Id, 10)}
                className={`${aux_cEDO_Id !== '0' && aux_cEDO_Id !== '' && aux_cEDO_Id !== undefined ? 'inputActive' : validInputs.estado ? 'inputError' : ''}`}
              >
                <option value="0">Seleccionar</option>
                {DataCities &&
                  DataCities.map((city, index) => (
                    <option key={index} value={city.cEDO_Id}>
                      {city.cEDO_Nombre}
                    </option>
                  ))}
              </NativeSelect>
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column">
              <Typography>Ciudad </Typography>
              <NativeSelect
                input={<BootstrapInput />}
                style={{ width: '100%' }}
                id="ciudades-reunion"
                onChange={onChange}
                value={parseInt(aux_cCIUD_Id, 10)}
                className={`${aux_cCIUD_Id !== '0' && aux_cCIUD_Id !== '' && aux_cCIUD_Id !== undefined ? 'inputActive' : validInputs.ciudad ? 'inputError' : ''}`}
              >
                <option value="0">Seleccionar</option>
                {dataCuidades &&
                  dataCuidades.map((city, index) => (
                    <option key={index} value={city.cCIUD_Id}>
                      {city.cCIUD_Nombre}
                    </option>
                  ))}
              </NativeSelect>
            </ContainerTwoInputs>
          </AcordeonContainerBody>
          <AcordeonContainerBody marginBottom="20px">
            <ContainerTwoInputs flexDirection="column">
              <Typography>Zona horaria del país que visita</Typography>
              <NativeSelect
                input={<BootstrapInput />}
                style={{ width: '100%' }}
                onChange={onChange}
                id="zona-horaria"
                value={sessionStorage.getItem('cREU_ZonaHoraria')}
                className={`${sessionStorage.getItem('cREU_ZonaHoraria') !== undefined && sessionStorage.getItem('cREU_ZonaHoraria') !== null ? 'inputActive' : validInputs['zona horaria'] ? 'inputError' : ''}`}
                // className="inputActive"
              >
                <option value="">Elige una opción</option>
                <option value="America/Mexico_city">America/México City</option>
                {moment.tz.names().map((zona, index) => (
                  <option key={index} value={zona}>
                    {zona}
                  </option>
                ))}
              </NativeSelect>
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column">
              <Typography>Hora de cita en país de visita</Typography>
              <TextField
                id="cita-país"
                placeholder="14:00"
                variant="outlined"
                style={{ width: '100%' }}
                value={HoraZona === 'ida' ? null : HoraZona}
                size="small"
                className={`${HoraZona !== '' && HoraZona !== null ? 'inputActive' : validInputs['hora actual país'] ? 'inputError' : ''}`}
              />
            </ContainerTwoInputs>
          </AcordeonContainerBody>
          <AcordeonContainer>
            <Typography>Link de conferencia</Typography>
            <TextField
              id="link-conferencia"
              fullWidth
              variant="outlined"
              placeholder="zoom.com/1234"
              onChange={onChange}
              value={linkConferencia === 'null' ? '' : linkConferencia}
              className={`${linkConferencia !== '' && linkConferencia != null ? 'inputActive' : ''}`}              
            />
          </AcordeonContainer>
        </Acordeon>
      </AcordeonContainer>
      <AcordeonContainer id="invitados-reunion" style={{ top: '15px' }}>
        <Acordeon
          imgSrc="/images/Icons/equipo.png"
          label="Agregar invitados y asistentes en la reunión"
          margin="0px 10px 0px 0px"
          expanded={secAcordeon.secInvitados}
          setExpanded={HandleExpand}
          seccion="secInvitados"
        >
          <AcordeonContainerBody marginBottom="20px">
            <ContainerTwoInputs
              flexDirection="column"
              width="25%"
              marginTop="11px"
            >
              <Typography>Clave</Typography>
              <TextField
                id="clave-reunion-corredor"
                fullWidth
                inputProps={{
                  style: {
                    marginTop: useSizeDimmensions() > 550 ? '-93px' : '11px',
                  },
                }}
                variant="outlined"
                placeholder=""
                onChange={onChange}
                type="number"
                value={
                  idsSearch.cCOR_Id != 0 ? idsSearch.cCOR_Id : aux_claveCorredor
                }
                // disabledSearch={false}
                //  disabled={''}
                // disabled={disabledCorredor}
                className={`${aux_claveCorredor !== '' && !isNaN(aux_claveCorredor) ? 'inputActive' : validInputs.corredor ? 'inputError' : ''}`}              
                // className={`${
                //   aux_claveCorredor !== '' && !isNaN(aux_claveCorredor)
                //     ? 'inputActive'
                //     : ''
                // }`}
              />
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column" width="65%">
              <Typography>Corredor</Typography>

              <SearchInvitadosSirec
                data={[...Corredor]}
                setCargosSirec={setCargosSirec}
                CargosSirec={CargosSirec}
                getIdCargos={getInvitadosSirec}
                tipo={'2'}
                setIdsSearch={setIdsSearch}
                idsSearch={idsSearch}
                disabled={false}
                // disabledSearch={false}
                nameInput="corredor"
                setOrgCorredor={setOrgCorredor}
                // disabledSearch={disabledCorredor}
              />
            </ContainerTwoInputs>
          </AcordeonContainerBody>
          <AcordeonContainerBody marginBottom="20px">
            <ContainerTwoInputs flexDirection="column" width="25%">
              <Typography>Clave</Typography>
              <TextField
                id="clave-reunion-company"
                fullWidth
                inputProps={{
                  style: {
                    marginTop: useSizeDimmensions() > 550 ? '-93px' : '11px',
                  },
                }}
                variant="outlined"
                placeholder=""
                onChange={onChangeCompany}
                type="number"
                value={
                  idsSearch.cRET_Id != 0 ? idsSearch.cRET_Id : aux_claveCompany
                }
                // disabled={disabledCompany}
                disabled={false}
                // disabledSearch={false}
                className={`${aux_claveCompany !== '' && !isNaN(aux_claveCompany) ? 'inputActive' : validInputs['compañia'] ? 'inputError' : ''}`}               
              />
            </ContainerTwoInputs>
            <ContainerTwoInputs flexDirection="column" width="65%">
              <Typography>Compañía</Typography>
              <SearchInvitadosSirec
                data={[...Retrocessionary, ...Company]}
                setCargosSirec={setCargosSirec}
                CargosSirec={CargosSirec}
                retrocessionary={[...Retrocessionary]}
                setRetrocessionary={setRetrocessionary}
                company={[...Company]}
                setCompany={setCompany}
                getIdCargos={getInvitadosSirec}
                tipo={'1-3'}
                // disabledSearch={false}
                disabled={false}
                nameInput="company"
                setIdsSearch={setIdsSearch}
                idsSearch={idsSearch}
                setOrgCompany={setOrgCompany}
                // disabledSearch={disabledCompany}
              />
            </ContainerTwoInputs>
          </AcordeonContainerBody>
          {useSizeDimmensions() > 550 ? (
            <TableContainer
              component={Paper}
              className={tableClasses.container}
            >
              <Table className={classes.table} aria-label="invitados table">
                <TableHead>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Nombre</StyledTableCell>
                  <StyledTableCell>Correo</StyledTableCell>
                  <StyledTableCell>Cargo o puesto</StyledTableCell>
                  <StyledTableCell>
                    <Email style={{ color: 'white' }} />
                  </StyledTableCell>
                </TableHead>
                <TableBody>
                  {CargosSirec &&
                    CargosSirec.map((invitado, index) => (
                      <TableRow key={index}>
                        {console.log(window.screen.width)}
                        <TableCell style={{ textAlign: 'center' }}>
                          <Checkbox
                            color="default"
                            onChange={(e) => editEje(index, e.target.checked)}
                          />
                        </TableCell>
                        <TableCell style={{ textTransform: 'capitalize' }}>
                          {invitado.cEje_Nombre}
                        </TableCell>
                        <TableCell>{invitado.cEje_Mail}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {invitado.cEje_Cargo}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<RadioButtonChecked />}
                            color="default"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer
              component={Paper}
              className={tableClasses.container}
              style={{ width: '100%' }}
            >
              <Table className={classes.table} aria-label="invitados table">
                <TableHead>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Contactos</StyledTableCell>
                  <StyledTableCell>
                    <Email />
                  </StyledTableCell>
                </TableHead>
                <TableBody>
                  {CargosSirec &&
                    CargosSirec.map((invitado, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ textAlign: 'center', padding: '5px' }}
                        >
                          <Checkbox
                            color="default"
                            onChange={(e) => editEje(index, e.target.checked)}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'left',
                            fontSize: '10px',
                            padding: '5px',
                          }}
                        >{`${invitado.cEje_Nombre} ${invitado.cEje_Mail} ${invitado.cEje_Cargo}`}</TableCell>
                        <TableCell
                          style={{ textAlign: 'center', padding: '5px' }}
                        >
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<RadioButtonChecked />}
                            color="default"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <ContainerTwoInputs justifyContent="flex-end" width="90%">
            {activeAddInvited ? null : (
              <div style={{ display: 'contents' }}>
                <Typography style={{ marginRight: '5px', color: '#9E9EBD' }}>
                  Agregar registro nuevo
                </Typography>
                <Icon
                  onClick={() => {
                    setActiveAddInvited(true);
                  }}
                >
                  add_circle
                </Icon>
              </div>
            )}
            {activeAddInvited && (
              <AddNewInvited
                setActiveAddInvited={setActiveAddInvited}
                activeAddInvited={activeAddInvited}
                form={form}
                handlechange={handlechange}
                setCargosSirec={setCargosSirec}
                CargosSirec={CargosSirec}
              />
            )}
          </ContainerTwoInputs>
        </Acordeon>
      </AcordeonContainer>
      <AcordeonContainer id="asistentes-reunion">
        <Acordeon
          imgSrc="/images/Icons/Icon_asistentes_500-01.png"
          label=" Asistentes a la reunión"
          margin="0px 10px 0px 0px"
          expanded={secAcordeon.secAsistentes}
          setExpanded={HandleExpand}
          seccion="secAsistentes"
        >
          <AcordeonContainerBody
            marginBottom="20px"
            flexDirection="column"
            alignItems="center"
          >
            <AcordeonContainerBody marginBottom="20px">
              <ContainerTwoInputs flexDirection="column">
                <Typography>Tipo de reunión</Typography>
                <NativeSelect
                  input={<BootstrapInput />}
                  style={{ width: '100%' }}
                  id="tipo-reunion"
                  onChange={onChange}
                  value={aux_cREU_TipoReunion}
                  className={`${aux_cREU_TipoReunion !== '' && aux_cREU_TipoReunion !== undefined ?
                    'inputActive' : validInputs['tipo de reunion'] ? 'inputError' : ''}`}                 
                >
                  <option value="">Seleccionar</option>
                  {tipo_reunion.map((tipo, index) => (
                    <option key={index} value={tipo.title}>
                      {tipo.title}
                    </option>
                  ))}
                </NativeSelect>
              </ContainerTwoInputs>
              <ContainerTwoInputs flexDirection="column">
                <Typography>Plantilla de mensaje</Typography>
                <NativeSelect
                  input={<BootstrapInput />}
                  style={{ width: '100%' }}
                  id="plantilla-mensaje"
                  onChange={onChange}
                  value={aux_cPLA_Id}
                  className={`${aux_cPLA_Id !== '' && aux_cPLA_Id !== undefined ? 'inputActive' : validInputs.plantilla ? 'inputError' : ''}`}                               
                >
                  <option value="">Seleccionar</option>
                  {DataMessage &&
                    DataMessage.map((message, index) => (
                      <option key={index} value={message.cPLA_Id}>
                        {message.cPLA_Nombre_}
                      </option>
                    ))}
                </NativeSelect>
              </ContainerTwoInputs>
            </AcordeonContainerBody>
            <TextareaAutosize
              rowsMin={10}
              value={textMessage}
              style={{ width: '90%' }}
              onChange={onChange}
              id="text-mensaje"
            />
          </AcordeonContainerBody>
        </Acordeon>
      </AcordeonContainer>
      <AcordeonContainerBody
        marginTop="10px"
        style={{
          marginBottom: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!loading ? (
          <>
            <ContainerTwoInputs width="32%" margin="5px">
              <BottonStyledSeccion
                backgroundColor="#DDDDDD"
                color="#fff"
                width="100%"
                height="40px"
                onClick={borrarData}
              >
                Borrar
              </BottonStyledSeccion>
            </ContainerTwoInputs>
            <ContainerTwoInputs width="32%" margin="5px">
              <BottonStyledSeccion
                backgroundColor="#C8C8C8"
                color="#fff"
                width="100%"
                height="40px"
                onClick={descartarData}
              >
                Descartar
              </BottonStyledSeccion>
            </ContainerTwoInputs>
            <ContainerTwoInputs width="32%" margin="5px" order="-2">
              <BottonStyledSeccion
                backgroundColor="#3898EC"
                color="#fff"
                width="100%"
                height="40px"
                onClick={() => {
                  guardarData();                
                }}
                disabled={clicked}
              >
                Guardar
              </BottonStyledSeccion>
            </ContainerTwoInputs>
          </>
        ) : (
          <CircularProgress />
        )}
      </AcordeonContainerBody>
    </Page>
  );
};

export default withRouter(ProgramarReunion);
