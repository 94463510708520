import React, { Fragment } from 'react';

const DivContainer = ({ children, title, subtitle, flexDirection }) => {
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: flexDirection ? flexDirection : 'column',
          marginBottom: '20px',
        }}
      >
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
      </div>
      <div style={{ width: '90%', display: 'contents' }}>{children}</div>
    </Fragment>
  );
};

export default DivContainer;
