const graph = require('@microsoft/microsoft-graph-client');
const { meetingRegistration, quotes, reviewQuotes, proposalStatus, proposalComments, commentsDirector, asistentToTraveler,
    directBossAuthorization, annexTwelve, noticeCancellation, accountantAuthorization, directAreaAuthorization, stuns, passed, rejected,
    travelerToAccountingCheecking, accountingToTravelerCheecking, travelerToDirectorCheecking,
    travelerToAccountingFinished, travelerToAreaDirectFinished, commentsTraveler, authorizedExpenses,
    directorToAccountingCheecking } = require('../components/Notificaciones/PlantillasNotify');
const TEMPLATES_BY_SECTION = {
    sectionOne: meetingRegistration,
    sectionTwo: quotes,
    sectionThree: reviewQuotes,
    sectionFour: proposalStatus,
    sectionFour_two: proposalComments,
    sectionFour_three: commentsDirector,
    sectionFive: asistentToTraveler,
    sectionSix: directBossAuthorization,
    sectionSix_two: annexTwelve,
    sectionSix_three: noticeCancellation,
    sectionSeven: accountantAuthorization,
    sectionSeven_two: directAreaAuthorization,
    sectionSeven_three: proposalComments,
    sectionEight: stuns,
    sectionEight_two: passed,
    sectionNine: passed,
    sectionNine_two: rejected,
    sectionOne_Comp: travelerToAccountingCheecking,
    sectionTwo_Comp: accountingToTravelerCheecking,
    sectionThree_Comp: travelerToDirectorCheecking,
    sectionFour_one_Comp: travelerToAccountingFinished,
    sectionFour_two_Comp: travelerToAreaDirectFinished,
    sectionFour_three_Comp: commentsTraveler,
    sectionFive_one_Comp: directorToAccountingCheecking,
    sectionSix_Comp: authorizedExpenses,

}

const getAuthenticatedClient = (accessToken) => {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken);
        }
    });
    return client;
}

export const sendEmail = async ({ subject, section, accessToken, emailTo = [] }) => {
    const toRecipients = [];
    emailTo.forEach((email) => {
        toRecipients.push({
            emailAddress: {
                address: email
            }
        })
    })
    try {
        const sendMail = {
            message: {
                subject,
                body: {
                    contentType: 'HTML',
                    content: TEMPLATES_BY_SECTION[section]()
                },
                toRecipients,
            }
        };
        const client = getAuthenticatedClient(accessToken);
        const response = await client.api('/me/sendMail').post(sendMail)
        console.log(response)
        return response;
    } catch (error) {
        console.log('error mail', error)
    }
}