import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useLazyQuery } from 'react-apollo';
import {
  GETRETROCESSIONARY_SIREC_ById,
  GETCORREDOR_SIREC_ById,
  GETCOMPANI_SIREC_ById
} from '../../query/query';
const useStyles = makeStyles((theme) => ({
  option: {
    color: 'rgb(0, 0, 0)',
    backgroundColor: '#fff',
  },
}));
const SearchInvitadosSirec = ({
  inputValue,
  data,
  getIdCargos,
  tipo,
  disabledSearch,
  nameInput,
  placeholder,
  idsSearch,
  setIdsSearch,
  retrocessionary = [],
  company = [],
  setOrgCorredor,
  setOrgCompany,
  setCompany,
  setRetrocessionary,
  setCargosSirec,
  CargosSirec
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(inputValue);
  const [dataOption, setDataOption] = useState(data);
  console.log('DATAAAA', data);
  const [getRetrocessionarySirec_ById] = useLazyQuery(
    GETRETROCESSIONARY_SIREC_ById,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ idRetrocessionarySirecById }) {
        console.log(idRetrocessionarySirecById);
        setDataOption([...dataOption, ...idRetrocessionarySirecById]);
        setRetrocessionary(idRetrocessionarySirecById)
      },
    }
  );
  const [getCompanySirec_ById] = useLazyQuery(GETCOMPANI_SIREC_ById, {
    fetchPolicy: 'no-cache',
    onCompleted({ idCompanySirecById }) {
      console.log(idCompanySirecById);
      setDataOption([...dataOption, ...idCompanySirecById]);
      setCompany(idCompanySirecById)
    },
  });

  const [getCorredorSirec_ById] = useLazyQuery(GETCORREDOR_SIREC_ById, {
    fetchPolicy: 'no-cache',
    onCompleted({ idCorredorSirecById }) {
      console.log(idCorredorSirecById);
      setDataOption(idCorredorSirecById);
    },
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValue(value);
    if (name === 'corredor') {
      if(value === ""){
        setCargosSirec(CargosSirec.filter(obj => obj.cTORG_Id != 2)); 
        setIdsSearch({
          ...idsSearch,
          cCOR_Id: "",          
        })
      }else{
        getCorredorSirec_ById({ variables: { cCOR_RazSocial: value } });
      }
    } else if (name === 'company') {
      if(value === ""){
        setCargosSirec(CargosSirec.filter(obj => obj.cTORG_Id != 1 && obj.cTORG_Id != 3));
        setIdsSearch({
          ...idsSearch,          
          cRET_Id: "",
        })
      } else {
        getRetrocessionarySirec_ById({ variables: { cRET_RazSocial: value } });
        setTimeout(() => {
          getCompanySirec_ById({
            variables: {
              cCIA_RazSocial: value
            }
          })
        }, 1500);
      }
    }
  };
  useEffect(() => {
    setDataOption(data);
  }, [data]);
  return (
    <Autocomplete
      freeSolo
      disableClearable
      inputValue={value}
      // onChange={(e,Data)=>{
      //         getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id,tipo,Data)
      //         console.log('Datas',Data)
      //         setValue(Data.cRET_RazSocial);
      //     }
      // }
      onChange={(e, Data) => {
        if (Data !== null) {
          const hasRetro = retrocessionary?.filter(
            ({ cRET_RazSocial }) => cRET_RazSocial == Data.cRET_RazSocial
          );
          const hasComp = company?.filter(
            ({ cRET_RazSocial }) => cRET_RazSocial == Data.cRET_RazSocial
          );
          if (hasRetro.length > 0) {
            if (nameInput == 'corredor')
              setOrgCorredor(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            if (nameInput == 'company')
              setOrgCompany(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id, 3, Data);
          } else if (hasComp.length > 0) {
            if (nameInput == 'corredor')
              setOrgCorredor(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            if (nameInput == 'company')
              setOrgCompany(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id, 1, Data);
          } else {
            if (nameInput == 'corredor')
              setOrgCorredor(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            if (nameInput == 'company')
              setOrgCompany(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id);
            getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id, 2, Data);
          }

          console.log('Datas', Data);
          setIdsSearch({
            ...idsSearch,
            cCOR_Id: Data?.cCOR_Id,
            cRET_Id: Data?.cRET_Id || Data?.cCIA_Id,
          });
          setValue(Data.cRET_RazSocial?.toUpperCase());
        } else {
          setValue('');
        }
      }}
      classes={{
        option: classes.option,
      }}
      disabled={disabledSearch}
      options={dataOption}
      getOptionLabel={(option) =>
        option.cRET_RazSocial ||
        option.cCIA_RazSocial ||
        option.cCOR_RazSocial ||
        ''
      }
      renderInput={(params) => (
        <TextField
          {...params}
          name={nameInput}
          onChange={(e) => handleChange(e)}
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: 'search' }}
          placeholder={placeholder}
          id="search-invitados"
          className={`${
            value !== '' && value !== undefined ? 'inputActive' : ''
          }`}
        />
      )}
    />
  );
};
export default SearchInvitadosSirec;
