import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-apollo';
import { useMediaQuery } from '@material-ui/core';
import { DINAMIC_GET } from '../../../mutation/mutation';
const EMAIL_TEST = 'bryan23_1999@hotmail.com';
const VALUE_TEST = `${EMAIL_TEST}|BRYAN`;
const EMAIL_TEST_L = 'l17970146@tecomatlan.tecnm.mx';
const VALUE_TEST_L = `${EMAIL_TEST_L}|Luis Gustavo`;
const RolSelection = ({ profile = '', width = '50%', marginLeft = '' }) => {
  const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');  
  const [options, setOptions] = useState([])
  const [getOptionsByRol] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        const response = JSON.parse(dynamicGet.response);
        setOptions(response);
      }
    }
  })
  const handleChange = (e) => {
    const { value } = e.target;
    if (value !== '') {
      const arrayValue = String(value).split('|');
      const mail = String(arrayValue[0]).trim();
      const name = arrayValue[1];
      sessionStorage.setItem('emailTo', mail);
      sessionStorage.setItem('nameRol', name)
    }
  }
  useEffect(() => {
    if (profile !== '') {
      getOptionsByRol({
        variables: {
          input: {
            table: 'cEMP_Empleado as cEMP, rEMPXGRP as rEMP',
            columns: ['cEMP_Nombre', 'cEMP_Mail', 'cGRP_Id'],
            conditions: [
              {
                valone: 'rEMP.cGRP_Id',
                condition: '=',
                valtwo: String(profile),
              },
              {
                logic: 'AND',
                valone: 'cEMP.cEMP_Id',
                condition: '=',
                valtwo: 'rEMP.cEMP_Id',
                validColumn: true,
              },
              {
                logic: 'AND',
                valone: 'cEMP.cEMP_Status',
                condition: '=',
                valtwo: '1',
              },
            ],
          }
        }
      })
    }
  }, [profile])
  if (options.length > 0) {
    return (
      <div style={{ marginTop: 20, marginBottom: 20, marginLeft: isMobile ? null : marginLeft }}>
        <p style={{ fontSize: 20 }}>Enviar correo a: </p>
        <select style={{ width: isMobile ? '100%' : width, height: 40, fontSize: isMobile ? null : 18, borderRadius: 5 }} onChange={handleChange}>
        <option value=''>--Elige un correo--</option>
          {options.map((obj) => (
            <option key={obj.cEMP_Mail} value={`${obj.cEMP_Mail}|${obj.cEMP_Nombre}`}>{obj.cEMP_Mail}</option>
          ))}
          <option value={VALUE_TEST} >{EMAIL_TEST}</option>
          <option value={VALUE_TEST_L} >{EMAIL_TEST_L}</option>
        </select >
      </div>
    )
  }
  return null
}
export default RolSelection;