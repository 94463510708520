export const navigation = [{
  title: 'Reuniones',
  route: '/crm/secciones/reuniones'
},
/* {
  title: 'Snapshot',
  route: '/crm/secciones/reuniones/snapshots'
}, */
{
  title: 'Viaticos',
  route: '/crm/secciones/viaticos'
},
/* {
  title: 'Suscripción',
  route: '/crm/secciones/suscripcion-siniestro'
}, */
{
  title: 'Acuerdos',
  route: '/crm/secciones/reuniones/acuerdos-viaje'
},
{
  title: 'Secciones',
  route: '/crm/secciones'
},
]

export const tipo_reunion = [
  {
    title: 'Visita a oficinas'
  },
  {
    title: 'Desayuno, almuerzo o cena en restaurante'
  },
  {
    title: 'Juntas de trabajo o foros sectoriales'
  },
  {
    title: 'Llamada telefónica'
  },
  {
    title: 'Videoconferencia'
  },
  {
    title: 'Encuentro casual no programado'
  },
  {
    title: 'Otro'
  },
]

const formatDate = (day) => {
  if (day < 10) {
    day = '0' + day;
  }
  return day;
}
export const fechaActual = () => {
  let date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  dd = formatDate(dd);
  mm = formatDate(mm);

  return `${yyyy}-${mm}-${dd}`;
}
export const horaActual = () => {
  let date = new Date();
  let hh = date.getHours();
  let mm = date.getMinutes();

  hh = formatDate(hh);
  mm = formatDate(mm);
  console.log(`${hh}:${mm}`)
  return `${hh}:${mm}`;
}

export const horaMas = (hora) => {
  console.log(hora)
  const date = new Date(`2020-08-05 ${hora}`);
  console.log(date)
  date.setHours(date.getHours() + 1);
  let hh = date.getHours()
  let mm = date.getMinutes();
  hh = formatDate(hh);
  mm = formatDate(mm);
  return `${hh}:${mm}`
}

export const getHtmlNewReunion = (text, text2, title, fecha, aseguradora, lugar) => {
  return `
  <!DOCTYPE html>
  <html style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;" lang="es">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  <body style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;"> 
        <tr style="border-collapse:collapse;"> 
         <td valign="top" style="padding:0;Margin:0; height: 113px;">  
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;"> 
            <tr style="border-collapse:collapse;"> 
             <td style="padding:0;Margin:0;background-color: #1D539C;" align="center"> 
              <table class="es-header-body" width="100%" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent; max-width: 540px !important;">
                <tr style="border-collapse:collapse;"> 
                  <td align="right" style="padding:0;Margin:0;width:19.63% !important;">
                    <img src="https://dev-patria.alimx.mx/images/LogoPatriaW.png" alt="" width="35px">
                  </td>
                 <td align="center" style="padding:0;Margin:0;padding-top:25.5px;"> 
                  <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                    <tr style="border-collapse:collapse;"> 
                     <td width="100%" valign="top" align="center" style="padding:0;Margin:0;max-width: 540px;"> 
                      <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;">  
                        <tr style="border-collapse:collapse;"> 
                         <td align="center" style="padding:0;Margin:0;padding-top:26px;">
                          <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo1.png" alt="Insert alt text here" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="correo 1" width="80">
                         </td> 
                        </tr> 
                      </table>
                     </td> 
                    </tr> 
                  </table>
                 </td>
                 <td align="left" style="padding:0;Margin:0;width:19.63%;"></td>
                </tr> 
              </table>
             </td> 
            </tr> 
          </table>
          <table class="es-content" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 407px !important"> 
            <tr style="border-collapse:collapse;"> 
             <td align="center" style="padding:0;Margin:0;"> 
              <table class="es-content-body" width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;max-width: 540px !important;"> 
                <tr style="border-collapse:collapse;"> 
                 <td align="left" style="padding:0;Margin:0;"> 
                  <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                    <tr style="border-collapse:collapse;"> 
                     <td width="100%" valign="top" align="center" style="padding:0;Margin:0; max-width: 540px;"> 
                      <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px; max-width: 540px;"> 
                        <tr style="border-collapse:collapse;"> 
                         <td align="center" style="padding:0;Margin:0;padding-bottom:30px;">
                          <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo2.png" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="Insert alt text here" width="80">
                         </td> 
                        </tr> 
                      </table>
                     </td> 
                    </tr> 
                  </table>
                 </td> 
                </tr> 
              </table>
             </td> 
            </tr>
             <tr style="border-collapse:collapse;height: 42px !important;">
              <td style="height: 42px !important;" align="center">
                <h3 style="margin: 0;">${text}</h3>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 42px !important;">
              <td style="height: 42px !important;" align="center">
                <h4 style="margin: 0;">${text2}</h4>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Titulo</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${title}</h5>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${fecha}</h5>
              </td>
            </tr> 
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Visita a</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${aseguradora}</h5>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px; margin: 0;">Lugar</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px; margin: 0;">${lugar}</h5>
              </td>
            </tr>
          </table>
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;"> 
            <tr style="border-collapse: collapse;">
              <td width="100%" style="background-color: #1D539C;" align="center">
                <table class="es-content-body" width="120px" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color: transparent;">
                  <tr style="border-collapse: collapse;">
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/facebook.png" alt="facebook" width="8px">
                    </td>
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/twiteer.png" alt="twitter" width="20px">
                    </td>
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/youtube.png" alt="youtube" width="20px">
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <table style="width: 100%;">
                      <tr style="border-collapse: collapse;">
                        <td align="center">
                          <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
         </td>
        </tr>
      </table> 
    </div>
  </body>
  </html>
  `
}

export const getHtmlInvitadoReunion = (text, text2, title, fecha, aseguradora, lugar, plantilla) => {
  return `
  <!DOCTYPE html>
  <html style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;" lang="es">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  <body style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
    <div style="width: 100%; background-color: #fff;">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;"> 
        <tr style="border-collapse:collapse;"> 
         <td valign="top" style="padding:0;Margin:0; height: 113px;">  
          <table class="es-header" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;"> 
            <tr style="border-collapse:collapse;"> 
             <td style="padding:0;Margin:0;background-color: #1D539C;" align="center"> 
              <table class="es-header-body" width="100%" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent; max-width: 540px !important;">
                <tr style="border-collapse:collapse;"> 
                  <td align="right" style="padding:0;Margin:0;width:19.63% !important;">
                    <img src="https://dev-patria.alimx.mx/images/LogoPatriaW.png" alt="" width="35px">
                  </td>
                 <td align="center" style="padding:0;Margin:0;padding-top:25.5px;"> 
                  <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                    <tr style="border-collapse:collapse;"> 
                     <td width="100%" valign="top" align="center" style="padding:0;Margin:0;max-width: 540px;"> 
                      <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;">  
                        <tr style="border-collapse:collapse;"> 
                         <td align="center" style="padding:0;Margin:0;padding-top:26px;">
                          <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo1.png" alt="Insert alt text here" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="Insert alt text here" width="80">
                         </td> 
                        </tr> 
                      </table>
                     </td> 
                    </tr> 
                  </table>
                 </td>
                 <td align="left" style="padding:0;Margin:0;width:19.63%;"></td>
                </tr> 
              </table>
             </td> 
            </tr> 
          </table>
          <table class="es-content" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 487px !important"> 
            <tr style="border-collapse:collapse;"> 
             <td align="center" style="padding:0;Margin:0;"> 
              <table class="es-content-body" width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;max-width: 540px !important;"> 
                <tr style="border-collapse:collapse;"> 
                 <td align="left" style="padding:0;Margin:0;"> 
                  <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                    <tr style="border-collapse:collapse;"> 
                     <td width="100%" valign="top" align="center" style="padding:0;Margin:0; max-width: 540px;"> 
                      <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px; max-width: 540px;"> 
                        <tr style="border-collapse:collapse;"> 
                         <td align="center" style="padding:0;Margin:0;padding-bottom:30px;">
                          <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo2.png" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="Insert alt text here" width="80">
                         </td> 
                        </tr> 
                      </table>
                     </td> 
                    </tr> 
                  </table>
                 </td> 
                </tr> 
              </table>
             </td> 
            </tr>
             <tr style="border-collapse:collapse;height: 42px !important;">
              <td style="height: 42px !important;" align="center">
                <h3 style="margin: 0;">${text}</h3>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 42px !important;">
              <td style="height: 42px !important;" align="center">
                <h4 style="margin: 0;">${text2}</h4>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Titulo</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${title}</h5>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Fecha de reunión</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${fecha}</h5>
              </td>
            </tr> 
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px;margin: 0;">Visita a</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px;margin: 0;">${aseguradora}</h5>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 20px !important;">
              <td style="height: 20px !important;" align="center">
                <p style="font-size: 10px; margin: 0;">Lugar</p>
              </td>
            </tr>
            <tr style="border-collapse:collapse; height: 22px !important;">
              <td style="height: 22px !important;" align="center">
                <h5 style="font-size: 10px; margin: 0;">${lugar}</h5>
              </td>
            </tr>
            <tr style="border-collapse:collapse;">
              <td style="height: 22px !important; width: 70%;" align="center">
                <table style="width: 70%;">
                  <tr>
                    <td>
                      <pre style="text-align: justify;">
                        ${plantilla}
                      </pre>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;"> 
            <tr style="border-collapse: collapse;">
              <td width="100%" style="background-color: #1D539C;" align="center">
                <table class="es-content-body" width="120px" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color: transparent;">
                  <tr style="border-collapse: collapse;">
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/facebook.png" alt="facebook" width="8px">
                    </td>
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/twiteer.png" alt="twitter" width="20px">
                    </td>
                    <td width="33.4%" align="center">
                      <img src="https://dev-patria.alimx.mx/images/youtube.png" alt="youtube" width="20px">
                    </td>
                  </tr>
                  <tr style="border-collapse: collapse;">
                    <table style="width: 100%;">
                      <tr style="border-collapse: collapse;">
                        <td align="center">
                          <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
         </td>
        </tr>
      </table> 
    </div>
  </body>
  </html>
  `
}

export const getHtmlViaticos = (text1, text2, reunion, status, iconS, avion, title, fecha, lugar, motivo, nombre, empleado) => {
  return `
  <!DOCTYPE html>
<html style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0;" lang="es">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body style="width:100%;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;margin:0; text-align: -webkit-center;">
  <div style="width: 100%; background-color: #fff;">
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:113px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 113px !important;"> 
      <tr style="border-collapse:collapse;"> 
       <td valign="top" style="padding:0;Margin:0; height: 113px;">  
        <table class="es-header" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;max-width: 540px;"> 
          <tr style="border-collapse:collapse;"> 
           <td style="padding:0;Margin:0;background-color: #1D539C;" align="center"> 
            <table class="es-header-body" width="100%" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent; max-width: 540px !important;">
              <tr style="border-collapse:collapse;"> 
                <td align="right" style="padding:0;Margin:0;width:19.63% !important;">
                  <img src="https://dev-patria.alimx.mx/images/LogoPatriaW.png" alt="" width="35px">
                </td>
               <td align="center" style="padding:0;Margin:0;padding-top:25.5px;"> 
                <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                  <tr style="border-collapse:collapse;"> 
                   <td width="100%" valign="top" align="center" style="padding:0;Margin:0;max-width: 540px;"> 
                    <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;">  
                      <tr style="border-collapse:collapse;"> 
                       <td align="center" style="padding:0;Margin:0;padding-top:26px;">
                        <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo1.png" alt="correo1" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="Insert alt text here" width="80">
                       </td> 
                      </tr> 
                    </table>
                   </td> 
                  </tr> 
                </table>
               </td>
               <td align="left" style="padding:0;Margin:0;width:19.63%;"></td>
              </tr> 
            </table>
           </td> 
          </tr> 
        </table>
        <table class="es-content" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%; max-width: 540px; max-height: 487px !important"> 
          <tr style="border-collapse:collapse;"> 
           <td align="center" style="padding:0;Margin:0;"> 
            <table class="es-content-body" width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;max-width: 540px !important;"> 
              <tr style="border-collapse:collapse;"> 
               <td align="left" style="padding:0;Margin:0;"> 
                <table width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;max-width: 540px;"> 
                  <tr style="border-collapse:collapse;"> 
                   <td width="100%" valign="top" align="center" style="padding:0;Margin:0; max-width: 540px;"> 
                    <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px; max-width: 540px;"> 
                      <tr style="border-collapse:collapse;"> 
                       <td align="center" style="padding:0;Margin:0;padding-bottom:30px;">
                        <img class="adapt-img" src="https://dev-patria.alimx.mx/images/correo2.png" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" title="correo2" width="80">
                       </td> 
                      </tr> 
                    </table>
                   </td> 
                  </tr> 
                </table>
               </td> 
              </tr> 
            </table>
           </td> 
          </tr>
           <tr style="border-collapse:collapse;height: 42px !important;">
            <td style="height: 42px !important;" align="center">
              <h3 style="margin: 0;">${text1}</h3>
            </td>
          </tr>
          <tr style="border-collapse:collapse; height: 42px !important;">
            <td style="height: 42px !important;" align="center">
              <h4 style="margin: 0;">${text2}</h4>
            </td>
          </tr>
          <tr style="border-collapse:collapse; height: 20px !important;">
            <td style="height: 15px !important;" align="center">
              <table style="width: 70%;border-bottom: 1px solid#c8c8c8;">
                <tr style="border-collapse:collapse;">
                  <td align="center">
                    <h5 style="margin: 0;">
                      ${reunion}
                    </h5>
                  </td>
                  <td align="center">
                    <table width="100%">
                      <tr style="border-collapse:collapse;">
                        <td align="right">
                          <h5 style="margin: 0;">
                            ${status}
                          </h5>
                        </td>
                        <td align="left">
                          <img src="https://dev-patria.alimx.mx/images/${iconS}.svg" alt="revision" width="15px" style="margin: 0;">
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-collapse:collapse;">
            <td align="center">
              <table style="width: 70%;">
                <tr style="border-collapse:collapse;">
                  <td align="center">
                    <img src="https://dev-patria.alimx.mx/images/${avion}.svg" alt="avion2" width="110">
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-collapse:collapse;">
            <td align="center">
              <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
                <tr style="border-collapse: collapse;">
                  <td align="left">
                    <h5 style="margin: 0;">
                      ${title}
                    </h5>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-collapse:collapse;">
            <td align="center">
              <table style="width: 70%;">
                <tr style="border-collapse: collapse;">
                  <td style="width: 40%;" align="center">
                    <img src="https://dev-patria.alimx.mx/images/calendar.svg" alt="calendar" width="39">
                  </td>
                  <td style="width: 60%; align-items: center;">
                    <table style="width: auto;">
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px;margin: 0;">
                            ${fecha}
                          </p>
                        </td>
                      </tr>
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px; margin: 0;">
                            ${lugar}
                          </p>
                        </td>
                      </tr>
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px; margin: 0;">
                            ${motivo}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr> 
          <tr style="border-collapse:collapse;">
            <td align="center">
              <table style="border-bottom: 1px solid #c8c8c8;width: 70%;">
                <tr style="border-collapse: collapse;">
                  <td align="left">
                    <h5 style="margin: 0;">
                      ${nombre}
                    </h5>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-collapse:collapse;">
            <td align="center">
              <table style="width: 70%;">
                <tr style="border-collapse: collapse;">
                  <td style="width: 40%;" align="center">
                    <img src="https://dev-patria.alimx.mx/images/user.svg" alt="user" width="39">
                  </td>
                  <td style="width: 60%; align-items: center;">
                    <table style="width: auto;">
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px;margin: 0;">
                            No. empleado ${empleado}
                          </p>
                        </td>
                      </tr>
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px; margin: 0;">
                            Suscripción
                          </p>
                        </td>
                      </tr>
                      <tr style="border-collapse: collapse;">
                        <td align="left" style="height: 15px;">
                          <p style="font-size: 10px; margin: 0;">
                            Patria Re
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-collapse:collapse; height: 45px !important;">
            <td style="height: 45px !important;" align="center">
            </td>
          </tr>
        </table>
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:80px;background-repeat:repeat;background-position:center top; max-width: 540px !important; max-height: 80px !important;"> 
          <tr style="border-collapse: collapse;">
            <td width="100%" style="background-color: #1D539C;" align="center">
              <table class="es-content-body" width="120px" cellspacing="0" cellpadding="0" align="center" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color: transparent;">
                <tr style="border-collapse: collapse;">
                  <td width="33.4%" align="center">
                    <img src="https://dev-patria.alimx.mx/images/facebook.png" alt="facebook" width="8px">
                  </td>
                  <td width="33.4%" align="center">
                    <img src="https://dev-patria.alimx.mx/images/twiteer.png" alt="twitter" width="20px">
                  </td>
                  <td width="33.4%" align="center">
                    <img src="https://dev-patria.alimx.mx/images/youtube.png" alt="youtube" width="20px">
                  </td>
                </tr>
                <tr style="border-collapse: collapse;">
                  <table style="width: 100%;">
                    <tr style="border-collapse: collapse;">
                      <td align="center">
                        <p style="font-size: 10px; margin: 0;color: #fff;">Reaseguradora Patria, S.A</p>
                      </td>
                    </tr>
                  </table>
                </tr>
              </table>
            </td>
          </tr>
        </table>
       </td>
      </tr>
    </table> 
  </div>
</body>
</html>
  `
}

