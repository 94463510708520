import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const columns = [
    {
        title: 'Acción',
        field: 'action',
        editable: 'never',
    },
    {
        title: 'Concepto',
        field: 'concepto',
        editable: 'never',
    },
    { title: 'N° Personas', field: 'personas', editable: 'onUpdate' },
    { title: 'N° de días', field: 'nDias', editable: 'onUpdate' },
    { title: 'Importe en USD', field: 'importeUSD', editable: 'onUpdate' },
    { title: 'Tipo de cambio*', field: 'tipoCambio', editable: 'never' },
    { title: 'Importe en MXP', field: 'importeMXP', editable: 'onUpdate' },
    { title: 'Excede límites de política', field: 'limit', editable: 'never' },
];


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});
const labelProps = {
    style: {
        fontSize: 13
    }
}
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
        return __val__;
    }
    return '';
};

export default function EditableTableExpenses({ dollar, filterPolInter, filterPolNational, dataTable, setDataTable }) {
    const classes = useStyles();
    const [rowEditable, setRowEditable] = useState(undefined);
    // const [dataTable, setDataTable] = useState([
    //     { concepto: 'Hospedaje', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
    //     { concepto: 'Renta de automóvil', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
    //     { concepto: 'Lavandería', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
    //     { concepto: 'Alimentación', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
    //     { concepto: 'Propinas', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' },
    //     { concepto: 'Comisión por disposición de efectivo', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: '' },
    //     { concepto: 'Gastos de representación', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No hay limite' },
    //     { concepto: 'Otros (especificar)', personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No hay limite' },
    //     { concepto: 'Total', personas: '', nDias: '', importeUSD: 0, tipoCambio: '', importeMXP: 0, limit: 'No' },
    // ]);
    const handleChange = ({ target }, index) => {
        const { value, name } = target;
        if (name === 'importeUSD' || name === 'importeMXP') {
            const amount = String(value).replace('$', '').replace(',', '');
            // const newAmount = formatter.format(filterFloat(amount));
            dataTable[index] = {
                ...dataTable[index],
                [name]: amount
            };
        } else {
            dataTable[index][name] = value;            
        }
        setDataTable([...dataTable]);
    }
    const clearRow = (index) => {
        const emptyInputs = { personas: '', nDias: '', importeUSD: '', tipoCambio: '', importeMXP: '', limit: 'No' };
        dataTable[index] = { ...dataTable[index], ...emptyInputs };
        setDataTable([...dataTable]);
        setRowEditable(undefined);
    }
    const getTotalUSD = () => {
        let totalUSD = 0
        dataTable.forEach(({ importeUSD, concepto }) => {
            if (importeUSD !== '' && concepto !== 'Total') {
                const amountUSD = parseFloat(String(importeUSD).replace('$', '').replace(',', ''));
                totalUSD += amountUSD;
            } else {
                totalUSD += 0;
            }
        });
        console.log('TOTAL USD', totalUSD)
        return totalUSD;
    }
    const getTotalMXP = () => {
        let totalMXP = 0;
        dataTable.forEach(({ importeMXP, concepto }) => {
            if (importeMXP !== '' && concepto !== 'Total') {
                const amountMXP = parseFloat(String(importeMXP).replace('$', '').replace(',', ''));
                totalMXP += amountMXP;
            } else {
                totalMXP += 0;
            }
        })
        return totalMXP;
    }

    const saveInformation = (currentRow, index) => {
        const newData = currentRow;
        const importeUSD = String(newData.importeUSD).replace('$', '').replace(',', '');
        const dollarPrice = dollar?.getTipoCambio[0];
        if (importeUSD !== '') {
            dataTable[8].importeUSD = formatter.format(filterFloat(getTotalUSD()));
            const exceedsPolicies = filterPolInter(newData.concepto, importeUSD, newData.nDias);
            const usdToMxp = (parseFloat(dollarPrice.mesanterior) * parseFloat(importeUSD)).toFixed(2);
            newData.importeMXP = formatter.format(filterFloat(usdToMxp));
            newData.tipoCambio = dollarPrice.mesanterior;
            const totalMXP = String((getTotalMXP()).toFixed(2))
            dataTable[8].importeMXP = formatter.format(filterFloat(totalMXP));
            if (exceedsPolicies) {
                newData.limit = 'Sí';
            } else {
                newData.limit = 'No';
            }
        } else {
            const importeMXP = String(newData.importeMXP).replace('$', '').replace(',', '');
            if (importeMXP !== '') {
                const totalMXP = String(getTotalMXP().toFixed(2))
                dataTable[8].importeMXP = formatter.format(filterFloat(totalMXP));
                const exceedsPolicies = filterPolNational(newData.concepto, importeMXP, newData.nDias);
                if (exceedsPolicies) {
                    newData.limit = 'Sí';
                } else {
                    newData.limit = 'No';
                }
            };
        }
        if (newData.importeUSD) {
            newData.importeUSD = formatter.format(filterFloat(newData.importeUSD));
        }
        else if (newData.importeMXP) {
            newData.importeMXP = formatter.format(filterFloat(newData.importeMXP));
        }
        dataTable[index] = newData;
        const limitTotal = dataTable.some(({ limit, concepto }) => concepto !== 'Total' && limit === 'Sí');
        if (limitTotal) {
            dataTable[8].limit = 'Sí';
        } else {
            dataTable[8].limit = 'No';
        }
        setRowEditable(undefined);
    }
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {columns.map(({ title }) => (
                            <StyledTableCell>{title}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.map((row, index) => {
                        if (row.concepto !== 'Total') {
                            return (
                                <StyledTableRow key={row.concepto}>
                                    <StyledTableCell component="th" scope="row">
                                        {(rowEditable !== undefined) && rowEditable === index ? (
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <DoneOutlinedIcon onClick={() => saveInformation(row, index)} />
                                                <ClearOutlinedIcon onClick={() => clearRow(index)} />
                                            </div>
                                        ) : (
                                            <CreateOutlinedIcon onClick={() => setRowEditable(index)} />
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.concepto}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {rowEditable === index ? (
                                            <TextField
                                                id={`${row.personas}_${index}`}
                                                value={row.personas}
                                                name='personas'
                                                label="Num. personas"
                                                InputLabelProps={labelProps}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        ) : row.personas}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {rowEditable === index ? (
                                            <TextField
                                                id={`${row.nDias}_${index}`}
                                                value={row.nDias}
                                                name='nDias'
                                                label="Num. días"
                                                InputLabelProps={labelProps}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        ) : row.nDias}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {rowEditable === index ? (
                                            <TextField
                                                id={`${row.importeUSD}_${index}`}
                                                value={row.importeUSD}
                                                name='importeUSD'
                                                label="Importe"
                                                InputLabelProps={labelProps}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        ) : row.importeUSD}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.tipoCambio}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {rowEditable === index ? (
                                            <TextField
                                                id={`${row.importeMXP}_${index}`}
                                                value={row.importeMXP}
                                                name='importeMXP'
                                                label="Importe"
                                                InputLabelProps={labelProps}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        ) : row.importeMXP}

                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.limit}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        }
                        return (
                            <StyledTableRow key={row.concepto}>
                                <StyledTableCell component="th" scope="row">

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.concepto}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.personas}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.nDias}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.importeUSD}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.tipoCambio}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.importeMXP}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.limit}
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
