import {
  Checkbox,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useQuery, useMutation } from 'react-apollo';
import { useLazyQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField'
import {
  GETCOUNTRIES_SIREC,
  GETCITIES_SIREC,
  GET_CIUDADESBYID,
} from '../../query/query';
import { DYNAMIC_INSERT, DINAMIC_GET } from '../../mutation/mutation';

import '../components.css';
import { ContainerTwoInputs } from '../StylesComponents/common-styled';
import { BootstrapInput } from '../StylesComponents/material-ui-styled';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ResumenReuCasual from './common/ResumentReuCasual';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ArrowBackIos, Search } from '@material-ui/icons';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { MyContext } from '../Context';

const tipoReunion = [
  {
    tipo: 'Visita',
    id: 1,
  },
  {
    tipo: 'Llamada',
    id: 2,
  },
  {
    tipo: 'Evento Sector',
    id: 3,
  }, //cREU_TipoReunion
];

const sector = [
  {
    tipo: 'Presencial',
    id: 1,
  },
  {
    tipo: 'Virtual',
    id: 2,
  }, //cREu_lugar
];

const sede = [
  {
    tipo: 'Oficinas',
    id: 1,
  },
  {
    tipo: 'Hotel',
    id: 2,
  },
  {
    tipo: 'Restaurante',
    id: 3,
  },
  {
    tipo: 'Lugar Público',
    id: 4,
  },
];

const comida = [
  {
    tipo: 'Desayuno',
    id: 1,
  },
  {
    tipo: 'Almuerzo',
    id: 2,
  },
  {
    tipo: 'Comida',
    id: 3,
  },
  {
    tipo: 'Cena',
    id: 4,
  },
];

const NewReuCasual = ({ onClick1, onGoBack, user }) => {
  const context = useContext(MyContext);
  const [reunion, setReunion] = useState({
    cREU_FechaInicio: '',
    cREU_TipoReunion: '',
    cREU_Lugar: '',
    cREU_Sede: '',
    cPAI_Id: '',
    cCIUD_Id: '',
    cEDO_Id: '',
    cREU_Titulo: '',
    cREU_Comida: '',
    cREU_HoraInicio: '',
    cREU_HoraFin: '',
    cASISR_Id: '',
    infoInvitado: '',
    invitados: [],
    cCIA_Id: '',
    cCOR_Id: '',
    cRET_Id: '',
  });
  const [invitados, setInvitados] = useState([]);
  const [value, setValue] = useState('');
  const [dataCountries, setDataCountries] = useState([]);
  const [dataCities, setDataCities] = useState([]);
  const [dataCountryCities, setDataCountryCitites] = useState([]);
  const [idCountrie, setIdCountrie] = useState(0);
  const [idCities, setIdCities] = useState(1);
  const [idCountryCity, setCountryCity] = useState(0);
  const [info, setInfo] = useState([]);
  const [infoValue, setInfoValue] = useState('');
  const [showResumen, setShowResumen] = useState(false);
  const [tipoInvitado, setTipoInvitado] = useState([]);
  const [input, setInput] = useState({
    table: 'rEjexORG',
    columns: [
      'cTORG_Id',
      'cORG_Id',
      'cORG_Ver',
      'cEje_Id',
      'cEje_Nombre',
      'cEje_Cargo',
      'cEje_Mail',
    ],
    conditions: [
      {
        valone: 'cORG_Id',
        valtwo: '-1',
        condition: '=',
      },
      {
        valone: 'cTORG_Id',
        valtwo: '-1',
        condition: '=',
        logic: 'AND',
      },
    ],
  });

  useQuery(GETCOUNTRIES_SIREC, {
    onCompleted({ idCatCountries }) {      
      let idCatCwithoutMex = idCatCountries.filter((country) => {
        if (
          country.cPAI_NOMBRE !== 'México' &&
          country.cPAI_NOMBRE !== 'Sin País'
        ) {
          return country;
        }

        return false;
      });
      let idCatCwithMex = idCatCountries.filter((country) => {
        if (
          country.cPAI_NOMBRE === 'México' ||
          country.cPAI_NOMBRE === 'Sin País'
        ) {
          return country;
        }
        return false;
      });
      let response = idCatCwithoutMex.sort((a, b) =>
        a.cPAI_NOMBRE < b.cPAI_NOMBRE ? -1 : 1
      );
      response = [...idCatCwithMex, ...response];
      setDataCountries(response);
    },
  });

  const [getCitiesSirec] = useLazyQuery(GETCITIES_SIREC, {
    onCompleted({ idCatCity }) {
      if (idCatCity.length > 0) {
        setIdCities(idCatCity[0].cEDO_Id);
        setDataCities(idCatCity);
        setReunion({ ...reunion, cEDO_Id: idCatCity[0].cEDO_Id });
      }
     
    },
  });

  const [getCountryCities] = useLazyQuery(GET_CIUDADESBYID, {
    onCompleted({ getCiudadById }) {
      if (getCiudadById.length > 0) {
        setDataCountryCitites(getCiudadById);
        setCountryCity(getCiudadById[0]?.cCIUD_Id);
        setReunion({ ...reunion, cCIUD_Id: getCiudadById[0].cCIUD_Id });
      } else {
        console.log('SIN DATA getCountryCities =', getCiudadById)
      }
    },
  });

  const [getInfo] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log('RESPONSE', JSON.parse(dynamicGet.response))
      setInfo(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error)
    }
  });

  const [getInvitados] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      
      if(dynamicGet.message !== 'Not found') {
        setInvitados(JSON.parse(dynamicGet.response));
        console.log(invitados)
      } else {
        setInvitados([])
      }
    },
    onError({ message }){
      console.log('getInvitados Error', message);
    }
  });

  const [getTipoInvitado] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.response !== '') {
        setTipoInvitado(
          JSON.parse(dynamicGet.response).map((tipo) => ({
            id: tipo.cASISR_Id,
            name: tipo.cASISR_Descripcion,
          }))
        );
      }
    },
  });

  const [saveInvitado] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.statusCode === 200) {
        onClick1();
      }
    },
    onError(error) {
      console.log('error de mutacion : ', error);
    },
  });
  const [saveREUXrEjexORG] = useMutation(DYNAMIC_INSERT, {
    onCompleted ({ dynamicInsert }) {
      if (dynamicInsert.statusCode === 200) {
        console.log('RESPONSE SAVE INVITADO', JSON.parse(dynamicInsert.response))
      }
    },
    onError(err){
      console.log(err)
    }
  })
  const [saverREUXCIA] = useMutation(DYNAMIC_INSERT, {
    onCompleted ({ dynamicInsert }) {
      if (dynamicInsert.statusCode === 200) {
        console.log('RESPONSE SAVE rREUXCIA', JSON.parse(dynamicInsert.response))
      }
    },
    onError(err){
      console.log(err)
    }
  })
  const saveInvitadosAndCed = (cREU_Id) => {
    let input = {
      table: 'rREUXrEjexORG',
      columns: ['cREU_Id', 'cTORG_Id', 'cORG_Id', 'cEje_Id'],
      detailValues: []
    }
    const invitados = reunion.invitados;
    if (invitados.length > 0) {
      invitados.forEach(({ cEje_Id, cTORG_Id, cORG_Id }) => {
        const values = [String(cREU_Id), String(cTORG_Id), String(cORG_Id), String(cEje_Id)];
        input.detailValues.push({ values })
      })
      console.log('saveREUXrEjexORG', input)
      saveREUXrEjexORG({ variables: { input } })                 
    }
    if (reunion.cASISR_Id === 1) {
      saverREUXCIA({
        variables: {
          input: {
            table: 'rREUXCIA',
            columns: ['cREU_Id', 'cCIA_Id'],
            detailValues: [
              {
                values: [String(cREU_Id), String(reunion.cCIA_Id)]
              }
            ]
          }
        }
      })
    } else if (reunion.cASISR_Id === 2) {
      saverREUXCIA({
        variables: {
          input: {
            table: 'rREUXCOR',
            columns: ['cREU_Id', 'cCOR_Id'],
            detailValues: [
              {
                values: [String(cREU_Id), String(reunion.cCOR_Id)]
              }
            ]
          }
        }
      })
    } else if (reunion.cASISR_Id === 3) {
      saverREUXCIA({
        variables: {
          input: {
            table: 'rREUXRET',
            columns: ['cREU_Id', 'cRET_Id'],
            detailValues: [
              {
                values: [String(cREU_Id), String(reunion.cRET_Id)]
              }
            ]
          }
        }
      })
    }
  }

  const [saveReunion] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.statusCode === 200) {        
        onClick1();
        const cREU_Id = JSON.parse(dynamicInsert.response).cREU_Id;
        context.setAcuerdosProps({
          cREU_Id,
        });
        context.setSaveAcuerdos({
          ...context.state.saveAcuerdos,
          cREU_Id,
        });
        console.log(context)
        if (reunion.cASISR_Id === 1 || reunion.cASISR_Id === 2 || reunion.cASISR_Id === 3) {
          saveInvitadosAndCed(cREU_Id);
        }
        if (reunion.infoInvitado != '') {
          let input = {
            table: 'cAUTCONS_AutoridadConsultor',
            columns: ['cREU_Id', 'cAUTCONS_Nombre', 'cAUTCONS_Tipo'],
            detailValues: [
              {
                values: [
                  String(cREU_Id),
                  String(reunion.infoInvitado),
                  String(reunion.cASISR_Id),
                ],
              },
            ],
          };
          console.log('INPUT -->>', input);
          saveInvitado({
            variables: { input },
          });
        } 
      } else {
        onClick1();
        console.log('no entro al primer if');
      }
    },
    onError(error) {
      console.log('error de mutacion : ', error);
    },
  });

  const limpiarInput = () => {
    setInfoValue('');    
  };

  const getCedente = (id) => {    
    let input;
    if (id === 3) {
      input = {
        table: 'cRET_Retrocesionario',
        columns: ['cRET_Id', 'cRET_RazSocial'],
        conditions: [
          {
            valone: 'cRET_Status',
            valtwo: `true`,
            condition: '=',
          },
        ],
      };
    }
    if (id === 2) {
      input = {
        table: 'cCOR_Corredor',
        columns: ['cCOR_Id', 'cCOR_RazSocial'],
        conditions: [
          {
            valone: 'cCOR_Status',
            valtwo: `true`,
            condition: '=',
          },
        ],
      };
    }
    if (id === 1) {      
      input = {
        table: ' cCIA_Compañia',
        columns: ['cCIA_Id', 'cCIA_RazSocial'],
        conditions: [
          {
            valone: 'cCIA_Status',
            valtwo: `true`,
            condition: '=',
          },
        ],
      };
    }
    if (id <= 3) {
      getInfo({
        variables: {
          input: input,
        },
      });
    }
  };

  const onChange = ({ target }) => {
    if (target.id === 'pais-reunion') {
      let auxValue = String(target.value).split(',');
      setReunion({
        ...reunion,
        cPAI_Id: parseInt(auxValue[0], 10),
        cREU_Lugar: auxValue[1],
      });
      setIdCountrie(parseInt(target.value, 10));
      getCitiesSirec({ variables: { cPAI_Id: parseInt(target.value, 10) } });
      getCountryCities({ variables: { cPAI_Id: parseInt(target.value, 10) } });
    }
    if (target.id === 'estado-reunion') {
      setReunion({ ...reunion, cEDO_Id: parseInt(target.value, 10) });
      setIdCities(parseInt(target.value, 10));
    }
    if (target.id === 'ciudad-reunion') {
      setReunion({ ...reunion, cCIUD_Id: parseInt(target.value, 10) });
      setCountryCity(parseInt(target.value, 10));
    }
  };

  const onSave = () => {
    try {      
      let input = {
        table: 'cREU_Reunion',
        columns: [
          'cEMP_Id',
          'cREU_Status',
          'cREU_FechaInicio',
          'cREU_FechaFin',
          'cREU_HoraInicio',
          'cREU_HoraFin',
          'cREU_TipoReunion',
          'cREU_Titulo',
        ],
        detailValues: [
          {
            values: [
              String(user.cEMP_Id),
              String(1),
              String(reunion.cREU_FechaInicio),
              String(reunion.cREU_FechaInicio),
              String(reunion.cREU_HoraInicio),
              String(reunion.cREU_HoraFin),
              String(reunion.cREU_TipoReunion),
              String(reunion.cREU_Titulo),
            ],
          },
        ],
      };

      if (reunion.cREU_Sede !== '') {
        input.columns.push('cREU_Lugar');
        input.detailValues[0].values.push(String(reunion.cREU_Sede));
      }
      
      if (reunion.cREU_Comida !== '') {
        input.columns.push('cREU_Comida');
        input.detailValues[0].values.push(String(reunion.cREU_Comida));
      }
      
      if (reunion.cASISR_Id !== '') {
        input.columns.push('cASISR_Id');
        input.detailValues[0].values.push(String(reunion.cASISR_Id));
      }
      
      if (reunion.cPAI_Id !== '') {
        input.columns.push('cPAI_Id');
        input.detailValues[0].values.push(String(reunion.cPAI_Id));
      }
      
      if (reunion.cEDO_Id !== '') {
        input.columns.push('cEDO_Id');
        input.detailValues[0].values.push(String(reunion.cEDO_Id));
      }
      
      if (reunion.cCIUD_Id !== '') {
        input.columns.push('cCIUD_Id');
        input.detailValues[0].values.push(String(reunion.cCIUD_Id));
      }
      
      // if (reunion.invitados.length > 0) {
      //   input.columns.push('cREU_invitado');
      //   input.detailValues[0].values.push(String(`${infoValue}:[${String(reunion.invitados)}]`));
      // } else if (infoValue !== '') {
      //   input.columns.push('cREU_invitado');
      //   input.detailValues[0].values.push(infoValue);
      // }
  
      console.log('input', input);
      saveReunion({ variables: { input } });     
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackButton = () => {
    switch(value){
      case '': 
        onClick1('regresarTipoReu');
      break;
      case 'titulo-reunion': 
      setValue('');
      break;
      case 'reunion': 
      setValue('titulo-reunion');
      break;
      case 'sector': 
      setValue('reunion');
      break;
      case 'lugar': 
      setValue('reunion');
      break;
      case 'sede': 
      setValue('reunion');
      break;
      case 'comida': 
      setValue('sede');
      break;
      case 'tipoInvitado': 
      setValue('sede');
      break;
      case 'nameCedente': 
      setValue('tipoInvitado');
      break;
      case 'autoridadConsultor': 
      setValue('tipoInvitado');
      break;
      default:
      return setValue('');
    }
  }

  // useEffect(() => {
  //   console.log("REGISTRO DE REUNION", reunion);
  // }, [reunion])

  useEffect(() => {
    getInvitados({
      variables: {
        input: input,
      },
    });
  }, [input]);

  useEffect(() => {
    getTipoInvitado({
      variables: {
        input: {
          table: 'cASISR_AsistentesReunion',
          columns: ['*'],
        },
      },
    });
  }, []);

  return (
    <Fragment>
      {!showResumen ? (
        <div style={{ width: '100%' }} className="registro-container">
          <div onClick={() => {
            // onGoBack()
            handleBackButton();
            }} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Nuevo Reporte de Visita </h4>
            <h4 style={{ color: '#858585' }}> &gt; Reunión Casual</h4>
          </div>
          {value === '' && (
            <DivContainer
              title="Reunión Casual"
              subtitle="¿Cuándo se llevó a cabo la reunión?"
            >
              <div style={{ width: '50%', display: 'grid' }}>
                <Typography>Fecha</Typography>
                <TextField
                  id="reunion"
                  type="date"
                  variant="outlined"
                  style={{ marginBottom: '20px' }}
                  value={reunion.cREU_FechaInicio}
                  onChange={({ target }) =>
                    setReunion({ ...reunion, cREU_FechaInicio: target.value })
                  }
                />
                <Typography>Hora Inicio</Typography>
                <TextField
                  id="reunion"
                  type="time"
                  variant="outlined"
                  placeholder="Hora Inicio"
                  style={{ marginBottom: '20px' }}
                  value={reunion.cREU_HoraInicio}
                  onChange={({ target }) =>
                    setReunion({ ...reunion, cREU_HoraInicio: target.value })
                  }
                />
                <Typography>Hora Fin</Typography>
                <TextField
                  id="reunion"
                  type="time"
                  variant="outlined"
                  style={{ marginBottom: '20px' }}
                  placeholder="Hora Fin"
                  value={reunion.cREU_HoraFin}
                  onChange={({ target }) =>
                    setReunion({ ...reunion, cREU_HoraFin: target.value })
                  }
                />
              </div>
              <ButtonNavigation
                text="Guardar"
                width="25%"
                backgroundColor="#3898EC"
                border="#3898EC"
                color="#FFFFFF"
                onClick={() =>
                  reunion.cREU_FechaInicio !== '' &&
                  reunion.cREU_HoraInicio &&
                  reunion.cREU_HoraFin &&
                  setValue('titulo-reunion')
                }
                id="button-casual"
              />
            </DivContainer>
          )}
          {value === 'titulo-reunion' && (
            <DivContainer
              title="Reunión Casual"
              subtitle="Título de la Reunión"
            >
              <TextField
                id="titulo-reunion"
                variant="outlined"
                style={{ marginBottom: '20px', width: '25%' }}
                value={reunion.cREU_Titulo}
                onChange={({ target }) =>
                  setReunion({ ...reunion, cREU_Titulo: target.value })
                }
              />
              <ButtonNavigation
                text="Guardar"
                width="25%"
                backgroundColor="#3898EC"
                border="#3898EC"
                color="#FFFFFF"
                onClick={() =>
                  reunion.cREU_Titulo !== '' && setValue('reunion')
                }
                id="button-casual"
              />
            </DivContainer>
          )}
          {value === 'reunion' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle="La reunión fue un(a)"
            >
              {tipoReunion.map((reu, index) => (
                <ButtonNavigation
                  text={reu.tipo}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setReunion({ ...reunion, cREU_TipoReunion: reu.tipo });
                    reu.id === 3
                      ? setValue('sector')
                      : reu.id === 2
                      ? setValue('sede')
                      : setValue('lugar');
                  }}
                />
              ))}
            </DivContainer>
          ) : null}
          {value === 'sector' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle="El evento fue presencial o virtual"
            >
              {sector.map((sec, index) => (
                <ButtonNavigation
                  text={sec.tipo}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setReunion({ ...reunion, cREU_Lugar: sec.tipo });
                    setValue('lugar');
                  }}
                />
              ))}
            </DivContainer>
          ) : null}
          {value === 'lugar' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle="¿En dónde se llevo a cabo la reunión?"
            >
              <ContainerTwoInputs flexDirection="column">
                <Typography>País</Typography>
                <NativeSelect
                  input={<BootstrapInput />}
                  style={{ width: '100%' }}
                  id="pais-reunion"
                  onChange={onChange}
                >
                  {dataCountries.map(({ cPAI_Id, cPAI_NOMBRE }, index) => (
                    <option key={index} value={`${cPAI_Id},${cPAI_NOMBRE}`}>
                      {cPAI_NOMBRE}
                    </option>
                  ))}
                </NativeSelect>
              </ContainerTwoInputs>
              <ContainerTwoInputs flexDirection="column">
                <Typography>Estado</Typography>
                <NativeSelect
                  input={<BootstrapInput />}
                  style={{ width: '100%' }}
                  id="estado-reunion"
                  onChange={onChange}
                >
                  {dataCities.map(({ cEDO_Id, cEDO_Nombre }, index) => (
                    <option key={index} value={cEDO_Id}>
                      {cEDO_Nombre}
                    </option>
                  ))}
                </NativeSelect>
              </ContainerTwoInputs>
              <ContainerTwoInputs flexDirection="column">
                <Typography>Ciudad</Typography>
                <NativeSelect
                  input={<BootstrapInput />}
                  style={{ width: '100%', marginBottom: '50px' }}
                  id="ciudad-reunion"
                  onChange={onChange}
                >
                  {dataCountryCities.map(
                    ({ cCIUD_Id, cCIUD_Nombre }, index) => (
                      <option key={index} value={`${cCIUD_Id},${cCIUD_Nombre}`}>
                        {cCIUD_Nombre}
                      </option>
                    )
                  )}
                </NativeSelect>
              </ContainerTwoInputs>
              <ButtonNavigation
                text="Guardar"
                width="25%"
                backgroundColor="#3898EC"
                border="#3898EC"
                color="#FFFFFF"
                onClick={() =>
                  idCities !== 0 && idCountrie !== 0 && idCountryCity !== 0
                    ? setValue('sede')
                    : null
                }
                id="button-casual"
              />
            </DivContainer>
          ) : null}
          {value === 'sede' && (
            <DivContainer title="Reunión Casual" subtitle="¿Cuál fue la sede?">
              {sede.map((sed, index) => (
                <ButtonNavigation
                  text={sed.tipo}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setReunion({
                      ...reunion,
                      // cREU_Lugar: reunion.cREU_Lugar + ',' + sed.tipo,
                      cREU_Sede: sed.tipo,
                    });
                    setValue(sed.id === 3 ? 'comida' : 'tipoInvitado');
                  }}
                />
              ))}
            </DivContainer>
          )}
          {value === 'comida' ? (
            <DivContainer title="Reunión Casual" subtitle="¿Qué comida?">
              {comida.map((com, index) => (
                <ButtonNavigation
                  text={com.tipo}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    setReunion({ ...reunion, cREU_Comida: com.tipo });
                    setValue('tipoInvitado');
                  }}
                />
              ))}
            </DivContainer>
          ) : null}
          {value === 'tipoInvitado' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle="¿Con quién se tuvo la reunión?"
            >
              {tipoInvitado.map(({ id, name }, index) => (
                <ButtonNavigation
                  text={name}
                  width="25%"
                  id="button-casual"
                  key={index}
                  onClick={() => {
                    if (id === 4 || id === 5) {
                      setValue('autoridadConsultor');
                      setReunion({
                        ...reunion,
                        cASISR_Id: id,
                        tipoInvitado: name,
                      });
                    } else {
                      setValue('nameCedente');
                      getCedente(id);
                      setReunion({
                        ...reunion,
                        cASISR_Id: id,
                        tipoInvitado: name,
                      });
                    }
                  }}
                />
              ))}
            </DivContainer>
          ) : null}
          {value === 'nameCedente' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle={`¿Cuál es el nombre del ${reunion.tipoInvitado}?`}
            >
              <Autocomplete
                freeSolo
                onChange={(e, data) => {                  
                  if (data) {
                    const { cRET_Id, cCOR_Id, cCIA_Id, cRET_RazSocial, cCIA_RazSocial } = data
                    console.log('DATA AUT', data)
                    console.log('textContent Invitados', e.target.textContent)
                    if (e.target.textContent) {
                      const auxCondition = input.conditions;
                      if (cRET_Id !== undefined) {
                        setInfoValue(cRET_RazSocial)
                        console.log('se obtuvo:', e.target.textContent)
                        setReunion({ ...reunion, cRET_Id})
                        setInput({
                          ...input,
                          conditions: auxCondition.map((condition, index) => {
                            if (index === 0) {
                              return { ...condition, valtwo: '' + cRET_Id };
                            } else {
                              return { ...condition, valtwo: '3' };
                            }
                          }),
                        });
                      }
                      if (cCOR_Id !== undefined) {
                        console.log(cCOR_Id)
                        console.log('se obtuvo:', e.target.textContent)
                        setInfoValue(e.target.textContent)
                        setReunion({ ...reunion, cCOR_Id})
                        setInput({
                          ...input,
                          conditions: auxCondition.map((condition, index) => {
                            console.log(input)
                            //console.log(condition)
                            if (index === 0) {
                              return { ...condition, valtwo: '' + cCOR_Id };
                            } else {
                              return { ...condition, valtwo: '2' };
                            }
                          }),
                        });
                      }
                      if (cCIA_Id !== undefined) {
                        setInfoValue(cCIA_RazSocial)
                        console.log(cCIA_RazSocial)
                        console.log('se obtuvo:', e.target.textContent)
                        setReunion({ ...reunion, cCIA_Id})
                        setInput({
                          ...input,
                          conditions: auxCondition.map((condition, index) => {
                            if (index === 0) {
                              console.log(infoValue)
                              return { ...condition, valtwo: '' + cCIA_Id };
                            } else {
                              console.log(infoValue)
                              return { ...condition, valtwo: '1' };
                            }

                          }),
                        });
                      }
                      // limpiarInput();
                    } else {
                      setInfoValue(e.target.textContent)
                    }
                  } else {
                    setInfoValue(e.target.textContent)
                  }
                }}
                inputValue={infoValue}
                options={info}
                style={{ width: '82%' }}
                getOptionLabel={({
                  cRET_RazSocial,
                  cCOR_RazSocial,
                  cCIA_RazSocial,
                }) => cRET_RazSocial || cCOR_RazSocial || cCIA_RazSocial}
                renderInput={(params) => ( 
                  
                  <TextField
                    {...params}
                    onChange={ (e) => {
                      setInfoValue(e.target.value);
                      console.log('Infoval: ', infoValue)
                      console.log(e.target)
                      console.log(info.map((e)=>{
                        return e.cCOR_RazSocial
                      }))
                    }}
                    margin="normal"
                    label={`Buscar ${reunion.tipoInvitado}`}
                    variant="outlined"
                    style={{
                      border: infoValue != '' 
                      ? invitados.length > 0 ? '' : '1px solid red'
                      : ''
                    }}
                    InputProps={{...params.InputProps, type: 'search', endAdornment: false}}
                  />)}
              />
              <h4>¿Quiénes participaron en la reunión?</h4>
              <TableContainer style={{width:'82%'}}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Correo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invitados.map((invitado, index) => (
                      <TableRow key={index}>
                        <TableRow>
                          <Checkbox
                            value={`${invitado.cEje_Nombre}`}
                            onChange={({ target }) => {
                              const { checked, value } = target;
                              const { cEje_Id, cTORG_Id, cORG_Id, cEje_Nombre } = invitado;
                              if (checked) {
                                const existsName = reunion.invitados.findIndex((obj) => obj.cEje_Nombre === value);
                                if (existsName === -1){
                                  setReunion({
                                    ...reunion,
                                    invitados: [...reunion.invitados, {cEje_Id, cTORG_Id, cORG_Id, cEje_Nombre}]
                                    // target.checked ? target.value : infoValue
                                  })
                                }
                              } else {
                                const existsName = reunion.invitados.findIndex((obj) => obj.cEje_Nombre === value);
                                if (existsName !== -1) {
                                  reunion.invitados.splice(existsName, 1);
                                }
                              }
                              
                            }}
                          />
                        </TableRow>
                        <TableCell>{invitado.cEje_Nombre}</TableCell>
                        <TableCell>{invitado.cEje_Mail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ButtonNavigation
                text="Guardar"
                marginTop="5%"
                width="25%"
                backgroundColor="#3898EC"
                border="#3898EC"
                color="#FFFFFF"
                onClick={() => {
                  if (infoValue) {
                    setShowResumen(true);
                  }
                }}
                // onClick={() => infoValue && onClick1()}
                id="button-casual"
              />
            </DivContainer>
          ) : null}
          {value === 'autoridadConsultor' ? (
            <DivContainer
              title="Reunión Casual"
              subtitle={`¿Cuál es el nombre del ${reunion.tipoInvitado}?`}
            >
              <TextField
                onChange={(e) => {
                  setReunion({ ...reunion, infoInvitado: e.target.value });
                }}
                margin="normal"
                placeholder={`Agrega el nombre aqui`}
                variant="outlined"
              />
              <ButtonNavigation
                text="Guardar"
                width="25%"
                backgroundColor="#3898EC"
                border="#3898EC"
                color="#FFFFFF"
                onClick={() => {
                  if (reunion.infoInvitado != '') {
                    setShowResumen(true);
                  }
                }}
                // onClick={() => infoValue && onClick1()}
                id="button-casual"
              />
            </DivContainer>
          ) : null}
        </div>
      ) : (
        <ResumenReuCasual
          datReu={reunion}
          nameCedente={infoValue === '' ? reunion.infoInvitado : infoValue}
          eventClick={onClick1}
          setShowResumen={setShowResumen}
          setValue={setValue}
          onSave={onSave}
        />
      )}
    </Fragment>
  );
};

export default NewReuCasual;
