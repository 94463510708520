import React, { useState, Fragment, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';

const RadioCustom = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ButtonsInformacion = ({
  title,
  text1,
  text2,
  text3,
  handleType,
  cTIp_Id = null,
  setCard,
}) => {
  const [value, setValue] = useState(text1);
  const handleChange = (event) => {
   
    if (title !== 'Información de transporte') {
      setCard(event.target.value);
      setValue(event.target.value);
    } else {
      handleType(event.target.value);
      setValue(event.target.value);
    }
    sessionStorage.setItem('card', event.target.value)
  };
  useEffect(() => {
    if (title === 'Información de transporte') {
      switch (cTIp_Id) {
        case 1:
          setValue(text1);
          break;
        case 2:
          setValue(text2);
          break;
        case 3:
          setValue(text3);
          break;       
        default:
          setValue(text1);
          break;
      }
    }   
  }, [])
  return (
    <Fragment>
      <div style={{ marginTop: '20px',width: '90%' }}>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold'}}>{title}</h3>
      </div>
      {cTIp_Id ? (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="tarvels"
            name="travels"
            value={value}
            onChange={handleChange}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value={text1}
              control={<RadioCustom />}
              label={text1}
              disabled={cTIp_Id === 1 ? false : true}
            />
            <FormControlLabel
              value={text2}
              control={<RadioCustom />}
              label={text2}
              disabled={cTIp_Id === 2 ? false : true}
            />
            <FormControlLabel
              value={text3}
              control={<RadioCustom />}
              label={text3}
              disabled={cTIp_Id === 3 ? false : true}
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="tarvels"
            name="travels"
            value={value}
            onChange={handleChange}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value={text1}
              control={<RadioCustom />}
              label={text1}
            />
            <FormControlLabel
              value={text2}
              control={<RadioCustom />}
              label={text2}
            />
            {title === 'Información de transporte' && (
                 <FormControlLabel
                 value={text3}
                 control={<RadioCustom />}
                 label={text3}
               />
            )}         
          </RadioGroup>
        </FormControl>
      )}
    </Fragment>
  );
};

export default ButtonsInformacion;
