import React, { useState, useEffect, useContext, Fragment } from 'react';
import {AcordeonContainerBody} from '../StylesComponents/common-styled';
import { Typography } from '@material-ui/core';
import FormTypography from './FormsTypography';
import { MyContext } from '../Context';
import { totalMateriales } from '../../funciones-auxiliares/viaticos';


const useFormMateriales=({title,cambio})=>{

  const [materiales,setMateriales]=useState(
    [
      {
        cMAT_Descripcion:'',
        cMAT_Precio:'',
        cMAT_Cantidad: '',
      }
    ]
  );
  const [requerimientoTI,setRequerimientoTI]=useState(
    [
      {
        cREQT_Descripcion:'',
        cREQT_Precio:'',
        cREQT_Cantidad: '',
      }
    ]
  );

  const [promocionales,setPromocionales]=useState(
    [
      {
        cPRO_Descripcion:'',
        cPRO_Precio:'',
        cPRO_Cantidad: '',
      }
    ]
  );
  const context=useContext(MyContext);
  useEffect(()=>{
    setMateriales(
      [
        {
          cMAT_Descripcion:'',
          cMAT_Precio:''
        }
      ]
    );
    setRequerimientoTI(
      [
        {
          cREQT_Descripcion:'',
          cREQT_Precio:''
        }
      ]
    );
  
    setPromocionales(
      [
        {
          cPRO_Descripcion:'',
          cPRO_Precio:'',
        }
      ]
    );
    if(context.state.viaticosProps.cREU_Id!==""){
      const materialesV=context.state.viaticosProps.materiales.map(material=>{
        return({
          ...material,
          cMAT_Descripcion:material.cMAT_Descripcion,
          cMAT_Precio:material.cMAT_Precio,
          cMAT_Cantidad: material.cMAT_Materiales
        })
      })
      const promocionalesV=context.state.viaticosProps.promocionales.map(promocionalV=>{
        return({
          ...promocionalV,
          cPRO_Descripcion:promocionalV.cPRO_Descripcion,
          cPRO_Precio:promocionalV.cPRO_Precio,
          cPRO_Cantidad:promocionalV.cPRO_Promocional,
        })
      })
      const requerimientosTiV=context.state.viaticosProps.requerimientoTI.map(requerimientoTiV=>{
        return({
          ...requerimientoTiV,
          cREQT_Descripcion:requerimientoTiV.cREQT_Descripcion,
          cREQT_Precio:requerimientoTiV.cREQT_Precio,
          cREQT_Cantidad: requerimientoTiV.cREQT_Requerimiento
        })
      })
      setMateriales(materialesV);
      setPromocionales(promocionalesV);
      setRequerimientoTI(requerimientosTiV);
      console.log("Context Material====",materialesV)
      
    }
    console.log('FormMateriales=====>',context.state.viaticosProps)
  },[cambio,context.state.viaticosProps])

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-materiales9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };

  return(
    <Fragment>
      <div style={{marginTop:'20px',width: '90%', marginBottom:'2%'}}>
        <h3 style={{textAlign:'center', fontWeight:650, fontSize:'19px' }}>{title}</h3>
      </div>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {materiales.map((material,index)=>
              <FormTypography text='Material' direction='row'>
                <Typography><strong>{material.cMAT_Descripcion}</strong></Typography>
              </FormTypography>
          )}
        </AcordeonContainerBody>
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {promocionales.map((prom,index)=>
              <FormTypography text='Promocionales' direction="row">
                <Typography><strong>{prom.cPRO_Descripcion}</strong></Typography>
              </FormTypography>
          )}
        </AcordeonContainerBody>
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {requerimientoTI.map((req,index)=>
            <FormTypography text='Requerimiento TI' direction="row">
                <Typography><strong>{req.cREQT_Descripcion}</strong></Typography>
            </FormTypography>
          )}
        </AcordeonContainerBody>
        <FormTypography text='Precio de equipo y materiales' direction="row">
            <Typography><strong>{formatter.format(filterFloat(totalMateriales(materiales,promocionales,requerimientoTI)) )}</strong></Typography>
        </FormTypography>
      </AcordeonContainerBody>
    </Fragment>
  )
}

export default useFormMateriales;