import React, { useContext } from 'react'
import ButtonNavigation from "../Viaticos/ButtonNavigation";
import comprobarGasto from '../../assets/images/icons/calculadora.svg';
import ticket from '../../assets/images/icons/tickets.svg';
import { MyContext } from '../Context';

const ButtonsJefe = ({ actionBtn, handleClick, viaticos = [] }) => {
    const context = useContext(MyContext);
    const thereAreChecks = viaticos.length > 0;
    return (
        <>
            <ButtonNavigation
                text="Autorizaciones pendientes"
                img={ticket}
                style={{
                    padding: '0 30px',
                    display: 'grid',
                    gridTemplateColumns: '50fr 50fr',
                    textAlign: 'left',
                }}
                justifyContent="space-between"
                backgroundColor={actionBtn.reviewPol ? '#3898EC' : null}
                border={actionBtn.reviewPol ? '#3898EC' : null}
                color={actionBtn.reviewPol ? '#FFF' : null}
                onClick={() => handleClick('reviewPol', true)}
            />
            {thereAreChecks && (
                <ButtonNavigation
                    text="Comprobaciones pendientes"
                    img={comprobarGasto}
                    style={{
                        padding: '0 30px',
                        display: 'grid',
                        gridTemplateColumns: '50fr 50fr',
                        textAlign: 'left',
                    }}
                    justifyContent="space-between"
                    backgroundColor={actionBtn.requestCheckTraveler ? '#3898EC' : null}
                    border={actionBtn.requestCheckTraveler ? '#3898EC' : null}
                    color={actionBtn.requestCheckTraveler ? '#FFF' : null}
                    onClick={() => {
                        handleClick('requestCheckTraveler', true);
                    }}
                />
            )}
        </>
    )
}

export default ButtonsJefe