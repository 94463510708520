import React, { useEffect, useState, useContext, Fragment } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import {
    PageContainer,
    ContainerTwoInputs,
} from '../StylesComponents/common-styled';

import Card from '../Viaticos/Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import ButtonsInformacion from '../Viaticos/ButtonsInformacion';
import FormTravels from '../Viaticos/FormTravels';
import FormAlojamiento from '../Viaticos/FormAlojamiento';
import FormMateriales from '../Viaticos/FormMateriales';
import FormObservaciones from '../Viaticos/FormObservaciones';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { getcTOBS_Id } from '../../funciones-auxiliares/viaticos';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
    GET_EVENTOSBYVIATICOS,
    GET_EVENTO_BY_CREU_ID,
} from '../../query/query';
import { SAVE_VIATICOS } from '../../mutation/mutation';
import { AddCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { MyContext } from '../Context';
import moment from 'moment';
import DialogAlert from '../DiaologAlert';
import { sendMailAttendee } from '../../services/GraphService';
import RolSelection from '../Acuerdos/common/RolSelection';
import MultipleSelect from '../Viaticos/MultipleSelect';
import CardListReuniones from './CardListReuniones';
import { useMediaQuery } from '@material-ui/core';
const Solicitud = ({ cEMP_Id, handleComponent, cVIT_Status_VIAT = '' }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');
    const [reuniones, setReuniones] = useState([]);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [data, setData] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [data2, setData2] = useState({
        titulo: '',
        texto1: '',
        texto2: '',
        texto3: '',
    });
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [typeOfTrip, setTypeOfTrip] = useState('Ida y vuelta');
    const [showCards, setShowCards] = useState(false);
    const [selectedReu, setSelectedReu] = useState([]);
    const [viaje, setViaje] = useState([
        {
            id: Math.floor(Math.random() * 100),
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
        {
            id: Math.floor(Math.random() * 100),
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
    ]);
    const [alojamiento, setAlojamiento] = useState([
        {
            hotel: '',
            urlCotizacion: '',
            fecha: '',
            precio: '',
        },
    ]);

    const [cTIP_Id, setCTIP_Id] = useState(1);

    const [getEventsByViaticos, { loading }] = useLazyQuery(GET_EVENTOSBYVIATICOS, {
        fetchPolicy: 'no-cache',
        onCompleted({ getReunionbyViaticos }) {
            setReuniones(getReunionbyViaticos);
        },
    });
    const [meetings, setMeetings] = useState([]);
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            setShowCards(true);
            // setData({
            //     titulo: getReunionBycREUID.cREU_Titulo,
            //     texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
            //     texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
            //     texto3: getReunionBycREUID.cREU_TipoReunion,
            // });
            // setData2({
            //     titulo: getReunionBycREUID.Nombre_Empleado,
            //     texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
            //     texto2: 'Suscripción',
            //     texto3: 'Patria RE',
            // });
            // sessionStorage.setItem('cREU_Titulo', getReunionBycREUID.cREU_Titulo)
            // sessionStorage.setItem('cREU_Id', getReunionBycREUID.cREU_Id);
        },
    });
    const context = useContext(MyContext);

    const setALojamientoAux = () => {
        setAlojamiento([
            {
                hotel: '',
                urlCotizacion: '',
                fecha: '',
                precio: '',
            },
        ]);
    };

    const handleTipoViatico = (tipo) => {
        setTypeOfTrip(tipo);
        let newViaje = [
            {
                origen: viaje[0].origen,
                destino: viaje[0].destino,
                aerolinea: viaje[0].aerolinea,
                vuelo: viaje[0].vuelo,
                cotizacion: viaje[0].cotizacion,
                fecha: viaje[0].fecha,
                horario: viaje[0].horario,
                precio: viaje[0].precio,
            },
        ];
        if (tipo === 'Ida y vuelta') {
            if (context.state.viaticosProps.cVIT_Id !== '') {
                newViaje = [
                    context.state.viaticosProps.viajes[0],
                    context.state.viaticosProps.viajes[1],
                ];
            } else {
                newViaje = [
                    ...newViaje,
                    {
                        origen: '',
                        destino: '',
                        aerolinea: '',
                        vuelo: '',
                        cotizacion: '',
                        fecha: '',
                        horario: '',
                        precio: '',
                    },
                ];
            }
            sessionStorage.setItem('cTIP_Id', 1);
            setViaje(newViaje);
            sessionStorage.setItem('travels', JSON.stringify(newViaje));
            setCTIP_Id(1);
        } else if (tipo === 'Solo ida') {
            if (context.state.viaticosProps.cVIT_Id !== '') {
                newViaje = [context.state.viaticosProps.viajes[0]];
            }
            sessionStorage.setItem('cTIP_Id', 2);
            setViaje(newViaje);
            sessionStorage.setItem('travels', JSON.stringify(newViaje));
            setALojamientoAux();
            setCTIP_Id(2);
        } else {
            if (context.state.viaticosProps.cVIT_Id !== '') {
                newViaje = context.state.viaticosProps.viajes;
            }
            sessionStorage.setItem('cTIP_Id', 3);
            sessionStorage.setItem('travels', JSON.stringify(newViaje));
            setViaje(newViaje);
            setCTIP_Id(3);
        }
    };
    const isValidDate = (date) => {
        return moment(date).isValid();
    };

    const isValidYear = (date) => {
        const year = new Date().getFullYear();
        const dateYear = moment(date).year();

        if (dateYear < year) return false;
        else return true;
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });
    const filterFloat = (__val__) => {
        const preg = /^([0-9]+\.?[0-9]{0,2})$/;
        if (preg.test(__val__) === true) {
            return __val__;
        }
        return '';
    };
    const handleChange = (e) => {
        const idElement = String(e.target.id).split('_')[1]
        if (e.target.name === 'fecha') {
            if (isValidDate(e.target.value) && isValidYear(e.target.value)) {
                setViaje(
                    viaje.map((data, index) => {
                        if (index === parseInt(idElement, 10)) {
                            //Se cambio ==
                            data = { ...data, [e.target.name]: e.target.value };
                        }
                        return data;
                    })
                );
                sessionStorage.setItem(
                    'travels',
                    JSON.stringify(
                        viaje.map((data, index) => {
                            if (index === parseInt(idElement, 10)) {
                                data = { ...data, [e.target.name]: e.target.value };
                            }
                            return data;
                        })
                    )
                );
            }
        } else {
            setViaje(
                viaje.map((data, index) => {
                    if (index === parseInt(idElement, 10)) {
                        //Se cambio ==
                        if (e.target.name === 'precio') {
                            let val = e.target.value
                                .replace('$', '')
                                .replace(/,/g, '')
                                .trim();
                            val = formatter.format(filterFloat(val));

                            data = { ...data, [e.target.name]: val };
                        } else {
                            data = { ...data, [e.target.name]: e.target.value };
                        }
                    }
                    return data;
                })
            );
            sessionStorage.setItem(
                'travels',
                JSON.stringify(
                    viaje.map((data, index) => {
                        if (index === parseInt(idElement, 10)) {
                            if (e.target.name === 'precio') {
                                let val = e.target.value
                                    .replace('$', '')
                                    .replace(/,/g, '')
                                    .trim();
                                val = formatter.format(filterFloat(val));

                                data = { ...data, [e.target.name]: val };
                            } else {
                                data = { ...data, [e.target.name]: e.target.value };
                            }
                        }
                        return data;
                    })
                )
            );
        }
    };

    const handleChangueInput = (value, name, id) => {
        console.log('NAME', name);
        const idElement = String(id).split('_')[1]
        const v = viaje.map((data, index) => {
            if (index === parseInt(idElement)) {
                if (name === 'fecha') {
                    console.log('FECHA');
                    if (isValidDate(value) && isValidYear(value)) {
                        console.log(value, 'IS_VALID');
                        data = { ...data, [name]: value };
                    }
                } else {
                    data = { ...data, [name]: value };
                }
            }
            return data;
        });
        setViaje(v);
        sessionStorage.setItem('travels', JSON.stringify(v));
    };

    const handleChangeAlojamiento = (e) => {
        const idElement = String(e.target.id).split('_')[1]
        const alog = alojamiento.map((data, index) => {
            if (index === parseInt(idElement)) {
                if (e.target.name === 'precio') {
                    let val = e.target.value.replace('$', '').replace(/,/g, '').trim();
                    val = formatter.format(filterFloat(val));

                    data = { ...data, [e.target.name]: val };
                } else {
                    data = { ...data, [e.target.name]: e.target.value };
                }
            }
            return data;
        });
        setAlojamiento(alog);
        sessionStorage.setItem('accommodation', JSON.stringify(alog));
    };

    const addEvent = () => {
        const newTramo = [
            ...viaje,
            {
                origen: '',
                destino: '',
                aerolinea: '',
                vuelo: '',
                cotizacion: '',
                fecha: '',
                horario: '',
                precio: '',
            },
        ]
        setViaje(newTramo);
        const lengthTrip = newTramo.length - 1;
        const newAlo = [
            ...alojamiento,
            {
                hotel: '',
                urlCotizacion: '',
                fecha: '',
                precio: '',
            },
        ]
        newAlo.length = lengthTrip;
        setAlojamiento(newAlo);
    };

    const deleteEvent = (index) => {
        const arr = viaje;
        arr.splice(index, 1);        
        setViaje([...arr]);
        if (alojamiento.length > 1) {
            alojamiento.pop();
            setAlojamiento(alojamiento);
        }        
    };
    // const editEvent = (evento) => {
    //     if (evento !== undefined) {
    //         setShowCards(true);
    //         setData({
    //             titulo: evento.cREU_Titulo,
    //             texto1: `${evento.cREU_FechaInicio}-${evento.cREU_FechaFin}`,
    //             texto2: `${evento.Pais}, ${evento.Estado}`,
    //             texto3: evento.cREU_TipoReunion,
    //         });
    //         setData2({
    //             titulo: evento.Nombre_Empleado,
    //             texto1: `No. empleado ${cEMP_Id.cEMP_Id}`,
    //             texto2: 'Suscripción',
    //             texto3: 'Patria RE',
    //         });
    //         sessionStorage.setItem('cREU_Id', evento.cREU_Id);
    //         sessionStorage.setItem('cREU_Titulo', evento.cREU_Titulo)
    //     }
    // };

    const [createTravelExpenses] = useMutation(SAVE_VIATICOS, {
        onCompleted({ createTravelExpenses }) {
            if (createTravelExpenses.message !== null) {
            } else {
                // handleComponent();
            }
        },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });
    const validateFields = () => {
        let countError = 0
        try {
            viaje.forEach((fields, index) => {
                const keysObj = Object.keys(fields)
                const values = Object.values(fields)
                values.forEach((field, indexF) => {
                    if (field === null || field === undefined || field === '') {
                        let name = keysObj[indexF]
                        document.getElementById(`${name}_${index}`).style.border = '2px solid red'
                        document.getElementById(`${name}_${index}`).style.outline = 'none'
                        countError++;
                    }
                })
            })
            if (typeOfTrip !== 'Solo ida') {
                alojamiento.forEach((fields, index) => {
                    const keysObj = Object.keys(fields)
                    const values = Object.values(fields)
                    values.forEach((field, indexF) => {
                        if (field === null || field === undefined || field === '') {
                            let name = keysObj[indexF];
                            document.getElementById(`${name}_${index}_alo`).style.border = '2px solid red'
                            document.getElementById(`${name}_${index}_alo`).style.outline = 'none'
                            countError++;
                        }
                    })
                })
            }
            return countError > 0 ? true : false
        } catch (error) {
            console.log('ERROR validateFields: ', error)
            return false
        }
    }

    const save = () => {
        try {
            if (validateFields()) {
                setShowModalErr(true);
                return
            }
            const travels = JSON.parse(sessionStorage.getItem('travels'));
            const accommodation = JSON.parse(sessionStorage.getItem('accommodation'));
            let materials = JSON.parse(sessionStorage.getItem('materials'));
            let ITrequirement = JSON.parse(sessionStorage.getItem('ITrequiremen'));
            let promotional = JSON.parse(sessionStorage.getItem('promotionals'));
            const observations = JSON.parse(sessionStorage.getItem('observations'));
            const input = {
                cREU_Id: selectedReu,
                cTIP_Id: parseInt(sessionStorage.getItem('cTIP_Id'), 10),
                travels: travels?.map(
                    (
                        {
                            origen,
                            destino,
                            aerolinea,
                            vuelo,
                            cotizacion,
                            fecha,
                            horario,
                            precio,
                        },
                        index
                    ) => {
                        return {
                            cVIA_Origen: origen,
                            cVIA_Destino: destino,
                            cVIA_Areolinea: aerolinea,
                            cVIA_NoVuelo: vuelo,
                            cVIA_URLVueloCot: cotizacion,
                            cVIA_HorarioVuelo: horario.split(' ').join(''),
                            cVIA_FechaVuelo: fecha.split('/').join('-'),
                            cVIA_PrecioVuelo: precio,
                            cTVI_Id: index % 2 === 0 && index !== travels.length ? 1 : 2,
                        };
                    }
                ),
                accommodation: accommodation?.map(
                    ({ hotel, urlCotizacion, fecha, precio }, index) => {
                        return {
                            cALO_Hotel: hotel,
                            cALO_URLAlojamientoCot: urlCotizacion,
                            cALO_Precio: precio,
                            cALO_FechaAlojamiento: fecha.split('/').join('-'),
                        };
                    }
                ),
                materials: materials
                    ? materials?.map(({ cMAT_Cantidad = "", cMAT_Descripcion = "", cMAT_Precio = "" }) => {
                        return { cMAT_Materiales: cMAT_Cantidad, cMAT_Descripcion, cMAT_Precio };
                    })
                    : [],
                ITrequirement: ITrequirement
                    ? ITrequirement?.map(({ cREQT_Cantidad = "", cREQT_Descripcion = "", cREQT_Precio = "" }) => {
                        return {
                            cREQT_Descripcion,
                            cREQT_Requerimiento: cREQT_Cantidad,
                            cREQT_Precio,
                        };
                    })
                    : [],
                promotional: promotional
                    ? promotional?.map(({ cPRO_Cantidad ="", cPRO_Descripcion = "", cPRO_Precio = "",  }) => {
                        return {
                            cPRO_Descripcion,
                            cPRO_Precio,
                            cPRO_Promocional: cPRO_Cantidad,
                        };
                    })
                    : [],
                observations: observations ?
                    [
                        {
                            cOBS_Descripcion: observations[0].cOBS_Descripcion,
                            cEMP_Id: observations[0].cEMP_Id,
                            cTOBS_Id: getcTOBS_Id(cEMP_Id.profile),
                        },
                    ] : [],
            };
            createTravelExpenses({ variables: { input } }).then(({ data }) => {
                if (data.createTravelExpenses.message === null) {
                    sendMailToAttendee()
                    setMsgModalErr({
                        title: 'Información guardada',
                        text: ''
                    })
                    setShowModalErr(true);
                    setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' })
                }
            });
        } catch (error) {
            console.error(error.message);
        }
    };

    const getViaticos = () => {
        switch (context.state.viaticosProps.cTIP_Id) {
            case 1:
                setTypeOfTrip('Ida y vuelta');
                break;
            case 2:
                setTypeOfTrip('Solo ida');
                break;
            case 3:
                setTypeOfTrip('Múltiples destinos');
                break;
            default:
                console.log('Nada');
        }
        const viajes = context.state.viaticosProps.viajes?.map((viaje) => {
            return {
                ...viaje,
                origen: viaje.cVIA_Origen,
                destino: viaje.cVIA_Destino,
                aerolinea: viaje.cVIA_Areolinea,
                vuelo: viaje.cVIA_NoVuelo,
                cotizacion: viaje.cVIA_URLVueloCot,
                fecha: viaje.cVIA_FechaVuelo.slice(0, 10),
                horario: viaje.cVIA_HorarioVuelo.slice(11, 16),
                precio: formatter.format(filterFloat(viaje.cVIA_PrecioVuelo)),
            };
        });
        const alojamientos = context.state.viaticosProps.alojamientos?.map(
            (alojamiento) => {
                return {
                    ...alojamiento,
                    hotel: alojamiento.cALO_Hotel,
                    urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
                    fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
                    precio: formatter.format(filterFloat(alojamiento.cALO_Precio)),
                };
            }
        );
        setViaje(viajes);
        setAlojamiento(alojamientos);
    };
    const sendMailToAttendee = async () => {
        const token = context.state.AuthComponentProps.token;
        const response = await sendMailAttendee({
            accessToken: token,
            attendeeName: 'Asistente',
            emailTo: sessionStorage.getItem('emailTo'), //mesorianor@patriare.com.mx 
            meeting: sessionStorage.getItem('cREU_Titulo'),
            userName: JSON.parse(localStorage.getItem('user'))?.user.displayName,
            data: data,
            data2: data2,
            numReu: parseInt(sessionStorage.getItem('cREU_Id'), 10)

        });
        console.log('MAIL', response)
    }
    useEffect(() => {
        sessionStorage.clear();
        getEventsByViaticos({
            variables: {
                cEMP_Id: cEMP_Id.cEMP_Id,
                cREU_FechaInicio: new Date().toISOString().slice(0, 10),
            },
        });
        sessionStorage.setItem('cTIP_Id', 1);
        const idReu = parseInt(sessionStorage.getItem('cREU_Id')) || 0
        if (context.state.viaticosProps.cVIT_Id !== '' || idReu !== 0) {
            getViaticos();           
        }
    }, []);
    useEffect(() => {
        if (selectedReu.length > 0){
            getEventBycREU({
                variables: { cREU_Id: selectedReu},
            });
        }
    }, [selectedReu]);

    if (loading) {
        return (
            <>
                <div style={{ width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                    <CircularProgress disableShrink size={100} />
                </div>
            </>
        )
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation">
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <div style={{ display: 'flex' }}>
                <ContainerTwoInputs
                    flexDirection="column"
                    width="25%"
                    margin="40px 0px 0px 0px"
                >
                    {context.state.viaticosProps.cVIT_Id === '' ? (
                        <div style={{ display: 'flex' }}>
                            <Typography>ID o título de la reunión</Typography>
                            <MultipleSelect
                                options={reuniones}
                                placeholder='Reuniones'
                                selectedOptions={setSelectedReu}
                                values={selectedReu}
                                width='160%'
                            />

                        </div>
                    ) : null}
                </ContainerTwoInputs>
            </div>
            <br />
            <CardListReuniones meetings={meetings} setEmpleado={setData2} setReunion={setData} />
            <br />
            {(showCards) && (
                <ContainerTwoInputs
                    width="100%"
                    margin="20px 0px"
                    id="viaticos-solicitud"
                >

                    <Card data={data} img={calendario} />
                    <Card data={data2} img={user} />
                </ContainerTwoInputs>
            )}
            <br />
            <h3>Importante</h3>
            <p>
                Antes de continuar con el registro de tu viaje no olvides visitar esta
                página para conocer las recomendaciones para tu viaje
            </p>
            <a
                href="https://guiadelviajero.sre.gob.mx/index.php/informacion-por-destino"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#3898EC', textAlign: 'center' }}
            >
                Visitar Guia del viajero
            </a>
            <ButtonsInformacion
                title="Información de transporte"
                text1="Ida y vuelta"
                text2="Solo ida"
                text3="Múltiples destinos"
                handleType={handleTipoViatico}
                cTIp_Id={context.state.viaticosProps.cTIP_Id ? context.state.viaticosProps.cTIP_Id : null}
            />
            {viaje &&
                viaje.map((viaj, index) => {
                    return (
                        <div key={index}>
                            <FormTravels
                                title={
                                    cTIP_Id === 1 && index === 0
                                        ? 'Viaje de ida'
                                        : cTIP_Id === 1 && index === 1
                                            ? 'Viaje de vuelta'
                                            : cTIP_Id === 3
                                                ? `Tramo ${index + 1}`
                                                : 'Viaje de ida'
                                }
                                handleChangue={handleChange}
                                id={cTIP_Id === 1 ? index : cTIP_Id === 2 ? 0 : index}
                                valueInputs={viaje}
                                handleChangueSelect={handleChangueInput}
                            />
                            {typeOfTrip === 'Múltiples destinos' && viaje.length > 1 && (
                                <button
                                    style={{ position: 'absolute', margin: '5px 0' }}
                                    type="button"
                                    onClick={() => deleteEvent(index)}
                                >
                                    Eliminar Tramo
                                </button>
                            )}
                        </div>
                    );
                })}
            {typeOfTrip === 'Múltiples destinos' ? (
                <div
                    style={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}
                >
                    <ContainerTwoInputs width="140px" justifyContent="end">
                        <AddCircle style={{ color: green[500] }} onClick={addEvent} />
                        <p>Agregar tramo</p>
                    </ContainerTwoInputs>
                </div>
            ) : null}
            {alojamiento &&
                alojamiento.map((aloj, index) => {
                    return cTIP_Id !== 2 ? (
                        <FormAlojamiento
                            key={index}
                            marginRight='10%'
                            title={
                                cTIP_Id === 1 && index === 0
                                    ? 'Información de alojamiento'
                                    : `Alojamiento - Tramo ${index + 1}`
                            }
                            id={cTIP_Id === 1 ? 0 : index}
                            valueInputs={alojamiento}
                            handleChangue={handleChangeAlojamiento}
                        />
                    ) : null;
                })}
            {typeOfTrip === 'Múltiples destinos' ? (
                <div
                    style={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}
                >
                    <ContainerTwoInputs width="140px" justifyContent="end">
                        <AddCircle style={{ color: green[500] }} onClick={addEvent} />
                        <p>Agregar tramo</p>
                    </ContainerTwoInputs>
                </div>
            ) : null}
            <FormMateriales title="Equipo o materiales para viaje" cambio={typeOfTrip} />
            <FormObservaciones title="Observaciones" cEMP_Id={cEMP_Id.cEMP_Id} />
            <div
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
            >
                <RolSelection profile='62' />
                <ButtonNavigation
                    text='Guardar'
                    width={isMobile ? '100%':  "45%"}
                    backgroundColor="#3898EC"
                    border="none"
                    color="#fff"
                    justifyContent="center"
                    onClick={save}
                    style={styleDisabled}
                />
            </div>
        </PageContainer>
    );
};

export default Solicitud;