import React, { useState, useEffect } from 'react';
import Homebar from './Homebar';

import { navigation } from '../funciones-auxiliares/programar-reunion';
import { Page } from './StylesComponents/common-styled';
import './components.css';
import FirstColumnSnapshot from './SnapshotComponents/FirstColumn';
import SecondColumnSnapshot from './SnapshotComponents/SecondColumn';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DINAMIC_GET, GET_DATA_SNAPSHOT } from '../mutation/mutation';
import IndicadoresMacro from './SnapshotComponents/IndicadoresMacro';
import CircularProgress from '@material-ui/core/CircularProgress'

import logoP from '../assets/images/images/logo_patria.png';
import logo2 from '../assets/images/images/SnapShot_1.png';
import logo3 from '../assets/images/images/SnapShot_2.png';
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
        return __val__;
    }
    return '';
};
const validateNumber = (num) => {
    if (num === undefined || num === 'null' || num === null) {
        return 0
    } else {
        return parseFloat(num)
    }
}
const Snapshot = () => {
    const [yearInit, setYearInit] = useState('2021')
    const [yearEnd, setYearEnd] = useState('2021');
    const [optionsSelected, setOptionsSelected] = useState({
        cTER_Nom: '',
        cPAI_Nomb: '',
        cTER_id: '',
        cPAI_id: '',
        cGRM_Nomb: '',
        cGORG_Nomb: '',
    })

    const [dataTableDirectorio, setDataTableDirectorio] = useState([]);
    const [dataExportCsv, setDataExportCsv] = useState([]);
    const [territorioOptions, setTerritorioOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [operationOptions, setOperationOptions] = useState([]);
    const [idCedentesOptions, setIdCedenteOptions] = useState([]);
    const [grupoOrgOptions, setGrupoOrgOptions] = useState([]);
    const [contCedentesOptions, setContCedentesOptions] = useState([]);
    const [loadedQueries, setLoadedQueries] = useState(0);
    // data graficas
    const [dataBarISR, setDataBarISR] = useState({});
    const [dataSnapTabla, setDataSnapTabla] = useState([])
    const [dataSnapTablaUpdate, setDataSnapTablaUpdate] = useState([])
    const [dataSnapNegUpdate, setDataSnapNegUpdate] = useState([])
    const [dataSnapNegociosVig, setDataSnapNegociosVig] = useState([])
    const [isretenido, setIsretenido] = useState([])
    const [dataTopTreeMap, setDataTopTreeMap] = useState({});
    const [dataTreeMap, setDataTreeMap] = useState([]); //data ramos
    const [dataEstadisticas, setDataEstadisticas] = useState({});

    const [contNegociosVig, setcontNegociosVig] = useState({
        proporcionales: 0,
        noProporcionales: 0,
        facultativo: 0,
    });
    const [dataCsvNegociosVig, setDataCsvNegociosVig] = useState([]);
    const [dataTableNegociosVig, setDataTableNegociosVig] = useState([]);
    // messages 
    const [loadISR, setLoadISR] = useState('Cargando...')
    const [loadEstadisticas, setLoadEstadisticas] = useState('Cargando...')
    const [loadingTreeMap, setLoadingTreeMap] = useState('Cargando...');
    const [cargando, setCargando] = useState(true);
    // BOOLEANS
    const [loadingDirectorio, setLoadingDirectorio] = useState(false)


    const [getDirectorio] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            const { message, response, statusCode } = dynamicGet;
            if (response !== '') {
                const data = JSON.parse(response)
                setDataTableDirectorio(data);
                setDataExportCsv(
                    data.map(({
                        cEje_Nombre,
                        cEje_Cargo,
                        cEje_Mail,
                        cEje_Telefono,
                        cEje_Celular,
                    }) => {
                        return {
                            Nombre: cEje_Nombre,
                            Cargo: cEje_Cargo,
                            Mail: cEje_Mail,
                            Teléfono: cEje_Telefono,
                            Celular: cEje_Celular,
                        }
                    })
                );
                setLoadingDirectorio(false)
            } else {
                setDataTableDirectorio([]);
                setDataExportCsv([])
                setLoadingDirectorio(false)
            }

        },
        onError(err) {
            console.error('getDirectorio Error: ', err);
        },
    });
    const [getTerritorio] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            let jsonResponse = (dynamicGet.response).replace("/", "");
            console.log("RESPONSE FROM TERRITORIOS: ", jsonResponse)
            setTerritorioOptions(JSON.parse(jsonResponse));
            setLoadedQueries(loadedQueries + 1);
        },
        onError(err) {
            console.log('getTerritorio Error:', err);
        },
    });
    const [getCountryOptions] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            const response = JSON.parse(dynamicGet.response);
            setCountryOptions(response);
            setLoadedQueries(loadedQueries + 1);
        },
        onError(err) {
            console.log('getCountryOptions Error:', err);
        },
    });
    const [getOperationOptions] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            setOperationOptions(JSON.parse(dynamicGet.response));
            setLoadedQueries(loadedQueries + 1);
        },
        onError(err) {
            console.log('getOperationOptions Error:', err);
        },
    });
    const [getIdCedenteOptions] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                const orderCed = response.sort((a, b) =>
                    a.cCIA_RazSocial < b.cCIA_RazSocial ? -1 : 1
                );
                setIdCedenteOptions(orderCed);
                setLoadedQueries(loadedQueries + 1);
            } else {
                setIdCedenteOptions([]);
            }
        },
        onError(err) {
            console.log('getIdCedenteOptions Error:', err);
        },
    });
    const [getGrupoOrg] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            setGrupoOrgOptions(JSON.parse(dynamicGet.response));
            setLoadedQueries(loadedQueries + 1);
        },
        onError(err) {
            console.log('getGrupoOrg Error:', err);
        },
    });
    const [getContCedentes] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                const orderCed = response.sort((a, b) =>
                    a.CEDENTES < b.CEDENTES ? -1 : 1
                );
                setContCedentesOptions(orderCed);
                setLoadedQueries(loadedQueries + 1);
            } else {
                setContCedentesOptions([]);
            }

        },
        onError(err) {
            console.log('getContCedentes Error:', err);
        },
    });
    const [getDataSnapTabla] = useMutation(GET_DATA_SNAPSHOT, {
        fetchPolicy: 'no-cache',
        onCompleted({ pruebaR }) {
            console.log("pruebaR SnapTabla", pruebaR);
            if (pruebaR.message !== `No existe informacion para el año ${yearInit}`) {
                setDataSnapTabla(pruebaR);
                handlerData(pruebaR)
                setLoadingTreeMap('')
                setLoadEstadisticas('')
            } else {
                setLoadingTreeMap(`No existe informacion para el año ${yearInit}`)
                setLoadEstadisticas(`No existe informacion para el año ${yearInit}`)
            }

            // setCargando(false)
        },
        onError(err) {
            console.log('Error: getDataSnapTabla', err)
        }
    })
    const [getDataSnapShotRisr] = useMutation(GET_DATA_SNAPSHOT, {
        fetchPolicy: 'no-cache',
        onCompleted({ pruebaR }) {
            console.log("pruebaRRisr", pruebaR);
            if (pruebaR.message !== `No existe informacion para el año ${yearInit}` && pruebaR[0]["aRISR_AnioRet"] !== undefined) {

                // RISR_MntNal - monto recuperado
                // ISR - monto retenido
                let labelsISR = [...new Set(pruebaR.map(function(x) { return x.aRISR_AnioRet }))];
                let dataPerYear = [];
                for(let i = 0; i<labelsISR.length; i++){
                    let dataYear = pruebaR.filter(data => data.aRISR_AnioRet == labelsISR[i]);
                    let sumatoria = 0;
                    dataYear.forEach(({ RISR_MntNal }) => {
                        sumatoria += parseFloat(RISR_MntNal);
                    });

                    dataPerYear.push(sumatoria);
                }

                console.log("ISR: ",pruebaR);

                setDataBarISR({
                    labels: labelsISR,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Monto recuperado',
                            data: dataPerYear,
                            borderColor: '#225F93',
                            backgroundColor: '#00539B'
                        },
                        {
                            type: 'bar',
                            label: 'Monto retenido',
                            data: isretenido,
                            borderColor: '#225F93',
                            backgroundColor: '#5091CD'
                        },
                    ]
                })
                setLoadISR('')
            } else {
                setLoadISR(`No existe informacion para el año ${yearInit}`)
                if (isretenido.length > 0) {
                    console.log("ISR RETENIDO: ", isretenido);
                    setDataBarISR({
                        labels: [yearInit],
                        datasets: [
                            {
                                type: 'bar',
                                label: 'Monto retenido',
                                data: isretenido,
                                borderColor: '#225F93',
                                backgroundColor: '#5091CD'
                            },
                        ]
                    })
                    setLoadISR('')
                }
            }
        }
    })
    const [getDataSnapNegociosVig] = useMutation(GET_DATA_SNAPSHOT, {
        fetchPolicy: 'no-cache',
        onCompleted({ pruebaR }) {
            if (pruebaR.message !== `No existe informacion para el año ${yearInit}`) {
                setDataSnapNegociosVig(pruebaR);
                handlerDataNegocios(pruebaR)
            }
        },
        onError(err) {
            console.log('Error: getDataSnapNegociosVig', err)
        }
    })
    // ############ FUNCTIONS ############
    const handleChangeByYear = (target) => {
        const { name, value } = target;
        if (name === 'yearInit') {
            setYearInit(value)
        } else {
            setYearEnd(value)
        }
        setLoadingTreeMap('Cargando...')
        setLoadEstadisticas('Cargando...')
        setLoadISR('Cargando...')
        setCargando(true);
    }
    const filterCountryByTer = ({ target }) => {
        const { name, value } = target;
        const cTER_Id = String(value).split('|')[0]
        getCountryOptions({
            variables: {
                input: {
                    table: 'cPAI_Pais',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cTER_Id',
                            valtwo: cTER_Id,
                            condition: '=',
                        },
                    ],
                },
            },
        });
    }
    const filterCedentesByCountry = ({ target }) => {
        const { name, value } = target;
        const id = String(value).split('|')[0];
        getIdCedenteOptions({
            variables: {
                input: {
                    table: 'cCIA_Compañia',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cCIA_Status',
                            valtwo: `true`,
                            condition: '=',
                        },
                        {
                            valone: 'cPAI_Id',
                            valtwo: id,
                            condition: '=',
                            logic: 'AND'
                        },
                    ],
                },
            },
        });
        getContCedentes({
            variables: {
                input: {
                    table: 'contCedentes',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: '[ID PAIS]',
                            valtwo: id,
                            condition: '=',
                        },
                    ],
                },
            },
        });
    }
    const filterDirectoryByCedente = ({ target }) => {
        const { name, value } = target; 
        setLoadingDirectorio(true)
        getDirectorio({
            variables: {
                input: {
                    table: 'rEjexORG',
                    columns: ['cORG_Id', 'cORG_Ver', 'cEje_Nombre', 'cEje_Cargo', 'cEje_Telefono', 'cEje_Celular', 'cEje_Mail'],
                    conditions: [
                        {
                            valone: 'cTORG_Id',
                            valtwo: '1',
                            condition: '=',
                        },
                        {
                            valone: 'cORG_Id',
                            valtwo: String(value),
                            condition: '=',
                            logic: 'AND'
                        },
                    ],
                },
            },
        });
    }
    // asigna a los states los valores en la primer carga
    const handlerData = (data) => {
        let result = {
            danios: 0,
            fianzas: 0,
            vida: 0,
            prim_tom: 0,
            costo_tom: 0,
            resultado: 0,
            ISR: 0,
            Prima_ced: 0,
            Costo_ced: 0,
            Sin_Tom: 0,
            Sin_ced: 0,
        }
        let labelAnios = [...new Set(data.map(function (x) { return x.AnioCont }))];
        let isrPerYear = [];
        let dataPerYear = {
            prim_tom: [],
            costo_tom: [],
            resultado: []
        };


        data.forEach(({
            cGRM_Nombre, Prima_tom, AnioCont, Resul, Costo_tom, ISR, Prima_ced, Costo_ced, Sin_Tom,
            Sin_ced,
        }) => {
            if (cGRM_Nombre.trim() == 'Daños') {
                result.danios += parseFloat(Prima_tom)
            } else if (cGRM_Nombre.trim() == 'Fianzas') {
                result.fianzas += parseFloat(Prima_tom)
            } else {
                result.vida += parseFloat(Prima_tom)
            }
            if (!labelAnios.includes(AnioCont)) {
                labelAnios.push(AnioCont)
            }
            result.prim_tom += parseFloat(Prima_tom)
            result.costo_tom += parseFloat(Costo_tom)
            result.resultado += parseFloat(Resul)
            result.Prima_ced += parseFloat(Prima_ced)
            result.Sin_Tom += parseFloat(Sin_Tom)
            result.Costo_ced += parseFloat(Costo_ced)
            result.Sin_ced += parseFloat(Sin_ced)
            result.ISR += parseFloat(ISR)

        })
        const { danios, fianzas, vida, costo_tom, prim_tom, resultado, ISR, Prima_ced,
            Sin_Tom, Sin_ced, Costo_ced } = result;
        
        setDataTopTreeMap({
            prima_tomada: formatter.format(filterFloat(Math.round(prim_tom))),
            prima_cedida: formatter.format(filterFloat(Math.round(Prima_ced))),
            siniestro_tomado: formatter.format(filterFloat(Math.round(Sin_Tom))),
            siniestro_cedido: formatter.format(filterFloat(Math.round(Sin_ced))),
            costo_tomado: formatter.format(filterFloat(Math.round(costo_tom))),
            costo_cedido: formatter.format(filterFloat(Math.round(Costo_ced))),
            resultado: formatter.format(filterFloat(Math.round(resultado))),
        });
        setDataTreeMap([
            {
                id: 'Estadistica',
                Daños: Math.round(danios),
                DañosColor: '#01549c',
                Fianzas: Math.round(fianzas),
                FianzasColor: '#f26224',
                Vida: Math.round(vida),
                VidaColor: '#dbc756',
            }
        ])

        for (let i = 0; i < labelAnios.length; i++) {
            let dataYear = data.filter(data => data.AnioCont == labelAnios[i]);
            dataYear.forEach(({
                cGRM_Nombre, Prima_tom, AnioCont, Resul, Costo_tom, ISR, Prima_ced, Costo_ced, Sin_Tom,
                Sin_ced,
            }) => {
                if (cGRM_Nombre.trim() == 'Daños') {
                    result.danios += parseFloat(Prima_tom)
                } else if (cGRM_Nombre.trim() == 'Fianzas') {
                    result.fianzas += parseFloat(Prima_tom)
                } else {
                    result.vida += parseFloat(Prima_tom)
                }
                if (!labelAnios.includes(AnioCont)) {
                    labelAnios.push(AnioCont)
                }
                result.prim_tom += parseFloat(Prima_tom)
                result.costo_tom += parseFloat(Costo_tom)
                result.resultado += parseFloat(Resul)
                result.Prima_ced += parseFloat(Prima_ced)
                result.Sin_Tom += parseFloat(Sin_Tom)
                result.Costo_ced += parseFloat(Costo_ced)
                result.Sin_ced += parseFloat(Sin_ced)
                result.ISR += parseFloat(ISR)

            })
            const { costo_tom, prim_tom, resultado } = result;
            dataPerYear.prim_tom.push(prim_tom);
            dataPerYear.costo_tom.push(costo_tom);
            dataPerYear.resultado.push(resultado);
            isrPerYear.push(ISR);
        }

        setIsretenido(isrPerYear);

        setDataEstadisticas({
            labels: labelAnios,
            datasets: [
                {
                    type: 'bar',
                    label: 'Prima tomada',
                    data: dataPerYear.prim_tom,
                    borderColor: '#225F93',
                    backgroundColor: '#225F93'
                },
                {
                    type: 'bar',
                    label: 'Costo tomado',
                    data: dataPerYear.costo_tom,
                    borderColor: '#4F91CB',
                    backgroundColor: '#4F91CB'
                },
                {
                    type: 'bar',
                    label: 'Resultado',
                    data: dataPerYear.resultado,
                    borderColor: '#FDBC2E',
                    backgroundColor: '#FDBC2E'
                },
            ]
        })

        getDataSnapShotRisr({
            variables: {
                functionName: "isr", initialDate: yearInit, endDate: yearEnd
            }
        });

        console.log(dataEstadisticas);
    }
    const formatString = (str) => {
        return String(str).toLocaleLowerCase().trim();
    }
    const filterData = (data) => {
        let result = {
            danios: 0,
            fianzas: 0,
            vida: 0,
            prim_tom: 0,
            costo_tom: 0,
            resultado: 0,
            ISR: 0,
            Prima_ced: 0,
            Costo_ced: 0,
            Sin_Tom: 0,
            Sin_ced: 0,
        }
        
        let labelAnios = [...new Set(data.map(function (x) { return x.AnioCont }))];
        let dataPerYear = {
            prim_tom: [],
            costo_tom: [],
            resultado: []
        };

        data.forEach(({
            cGRM_Nombre, Prima_tom, AnioCont, Resul, Costo_tom, ISR, Prima_ced, Costo_ced, Sin_Tom, Sin_ced
        }) => {
            if (cGRM_Nombre.trim() == 'Daños') {
                result.danios += parseFloat(Prima_tom)
            } else if (cGRM_Nombre.trim() == 'Fianzas') {
                result.fianzas += parseFloat(Prima_tom)
            } else {
                result.vida += parseFloat(Prima_tom)
            }
            if (!labelAnios.includes(AnioCont)) {
                labelAnios.push(AnioCont)
            }
            result.prim_tom += parseFloat(Prima_tom)
            result.costo_tom += parseFloat(Costo_tom)
            result.resultado += parseFloat(Resul)
            result.Prima_ced += parseFloat(Prima_ced)
            result.Sin_Tom += parseFloat(Sin_Tom)
            result.Costo_ced += parseFloat(Costo_ced)
            result.Sin_ced += parseFloat(Sin_ced)
            result.ISR += parseFloat(ISR)
        })
        const { danios, fianzas, vida, costo_tom, prim_tom,
            resultado, Prima_ced, Sin_Tom, Sin_ced, Costo_ced } = result;
        setDataTopTreeMap({
            prima_tomada: formatter.format(filterFloat(Math.round(prim_tom))),
            prima_cedida: formatter.format(filterFloat(Math.round(Prima_ced))),
            siniestro_tomado: formatter.format(filterFloat(Math.round(Sin_Tom))),
            siniestro_cedido: formatter.format(filterFloat(Math.round(Sin_ced))),
            costo_tomado: formatter.format(filterFloat(Math.round(costo_tom))),
            costo_cedido: formatter.format(filterFloat(Math.round(Costo_ced))),
            resultado: formatter.format(filterFloat(Math.round(resultado))),
        });
        setDataTreeMap([
            {
                id: 'Estadistica',
                Daños: Math.round(danios),
                DañosColor: '#01549c',
                Fianzas: Math.round(fianzas),
                FianzasColor: '#f26224',
                Vida: Math.round(vida),
                VidaColor: '#dbc756',
            }
        ]);
        
        for (let i = 0; i < labelAnios.length; i++) {
            let dataYear = data.filter(data => data.AnioCont == labelAnios[i]);
            dataYear.forEach(({
                cGRM_Nombre, Prima_tom, AnioCont, Resul, Costo_tom, ISR, Prima_ced, Costo_ced, Sin_Tom,
                Sin_ced,
            }) => {
                if (cGRM_Nombre.trim() == 'Daños') {
                    result.danios += parseFloat(Prima_tom)
                } else if (cGRM_Nombre.trim() == 'Fianzas') {
                    result.fianzas += parseFloat(Prima_tom)
                } else {
                    result.vida += parseFloat(Prima_tom)
                }
                if (!labelAnios.includes(AnioCont)) {
                    labelAnios.push(AnioCont)
                }
                result.prim_tom += parseFloat(Prima_tom)
                result.costo_tom += parseFloat(Costo_tom)
                result.resultado += parseFloat(Resul)
                result.Prima_ced += parseFloat(Prima_ced)
                result.Sin_Tom += parseFloat(Sin_Tom)
                result.Costo_ced += parseFloat(Costo_ced)
                result.Sin_ced += parseFloat(Sin_ced)
                result.ISR += parseFloat(ISR)

            })
            const { costo_tom, prim_tom, resultado } = result;
            dataPerYear.prim_tom.push(prim_tom);
            dataPerYear.costo_tom.push(costo_tom);
            dataPerYear.resultado.push(resultado);
        }

        setDataEstadisticas({
            labels: labelAnios,
            datasets: [
                {
                    type: 'bar',
                    label: 'Prima tomada',
                    data: dataPerYear.prim_tom,
                    borderColor: '#225F93',
                    backgroundColor: '#225F93'
                },
                {
                    type: 'bar',
                    label: 'Costo tomado',
                    data: dataPerYear.costo_tom,
                    borderColor: '#4F91CB',
                    backgroundColor: '#4F91CB'
                },
                {
                    type: 'bar',
                    label: 'Resultado',
                    data: dataPerYear.resultado,
                    borderColor: '#FDBC2E',
                    backgroundColor: '#FDBC2E'
                },
            ]
        });

    }
    const handlerDataNegocios = (data) => {

        let count1 = 0;
        let count2 = 0;
        let count3 = 0;
        data.forEach(({
            cTRE_Nombre
        }) => {
            if (cTRE_Nombre.trim() === 'Proporcional') {
                count1 += 1;
            } else if (cTRE_Nombre.trim() === 'No Proporcional') {
                count2 += 1;
            } else if (cTRE_Nombre.trim() === 'Facultativo') {
                count3 += 1;
            }
        })
        setcontNegociosVig({
            proporcionales: count1,
            noProporcionales: count2,
            facultativo: count3,
        });
        setDataCsvNegociosVig(
            data.map(
                ({
                    Ref,
                    cCOR_RazSocial,
                    cGRM_Nombre,
                    Costo_ced,
                    Costo_tom,
                    cTRE_Nombre,
                    FecFin,
                    FecIni,
                    Prima_ced,
                    Prima_tom,
                    RefOrig,
                    Resul,
                    Sin_ced,
                    Sin_tom,
                }) => {
                    return {
                        Corredor: cCOR_RazSocial?.replace('null', ''),
                        Operación: cGRM_Nombre?.replace('null', ''),
                        'Costo cedido': Costo_ced?.replace('null', ''),
                        'Costo tomado': Costo_tom?.replace('null', ''),
                        'Tipo de reaseguro': cTRE_Nombre?.replace('null', ''),
                        'Fecha Inicio': FecIni?.replace('null', ''),
                        'Fecha Fin': FecFin?.replace('null', ''),
                        'Prima cedida': Prima_ced?.replace('null', ''),
                        'Prima Tomada': Prima_tom?.replace('null', ''),
                        'Nombre del negocio': RefOrig?.replace('null', ''),
                        Referencia: Ref?.replace('null', ''),
                        Resultado: Resul?.replace('null', ''),
                        'Sinestros cedidos': Sin_ced?.replace('null', ''),
                        'Siniestros tomados': Sin_tom?.replace('null', ''),
                    }
                }))
        setDataTableNegociosVig(
            data.map(
                ({
                    Ref,
                    cCOR_RazSocial,
                    cGRM_Nombre,
                    Costo_ced,
                    Costo_tom,
                    cTRE_Nombre,
                    FecFin,
                    FecIni,
                    Prima_ced,
                    Prima_tom,
                    RefOrig,
                    Resul,
                    Sin_ced,
                    Sin_tom,
                }) => {
                    return {
                        cCOR_RazSocial: cCOR_RazSocial?.replace('null', ''),
                        cGRM_Nombre: cGRM_Nombre?.replace('null', ''),
                        Costo_ced: Costo_ced?.replace('null', ''),
                        Costo_tom: Costo_tom?.replace('null', ''),
                        cTRE_Nombre: cTRE_Nombre?.replace('null', ''),
                        FecFin: FecFin?.replace('null', ''),
                        FecIni: FecIni?.replace('null', ''),
                        Prima_ced: Prima_ced?.replace('null', ''),
                        Prima_tom: Prima_tom?.replace('null', ''),
                        RefOrig: RefOrig?.replace('null', ''),
                        Ref: Ref?.replace('null', ''),
                        Resul: Resul?.replace('null', ''),
                        Sin_ced: Sin_ced?.replace('null', ''),
                        Sin_tom: Sin_tom?.replace('null', ''),
                    };
                }
            )
        );
    }
    const filterByTer = (e) => {
        const { name, value, id } = e.target; 
        if (value === '' || value === '0|Sin Territorio ') {
            handlerData(dataSnapTabla);
            handlerDataNegocios(dataSnapNegociosVig)
            setDataExportCsv([]);
            setCountryOptions([]);
            setIdCedenteOptions([]);
            setContCedentesOptions([]);
            setDataExportCsv([]);
            setDataExportCsv([]);

        } else {
            const cTER_Nom = String(value).split('|')[1];
            const cTER_id = String(value).split('|')[0];
            setOptionsSelected({
                ...optionsSelected,
                cTER_Nom,
                cTER_id,
            })
            const data = dataSnapTabla.filter(({ cTer_Nombre }) => formatString(cTer_Nombre) === formatString(cTER_Nom));
            const dataNegocios = dataSnapNegociosVig.filter(({ cTER_Id }) => formatString(cTER_Id) == formatString(cTER_id));
            setDataSnapTablaUpdate(data);
            setDataSnapNegUpdate(dataNegocios)
            filterData(data);
            handlerDataNegocios(dataNegocios);
        }
    }
    const filterByCountry = (e) => {
        const { name, value, id } = e.target;
        if (value !== '' && value !== '0|Sin País') {
            const cPAI_Nomb = String(value).split('|')[1];
            const cPAI_id = String(value).split('|')[0];
            setOptionsSelected({
                ...optionsSelected,
                cPAI_Nomb,
                cPAI_id,
            })
            let data = []
            let dataNegocios = []
            const { cTER_Nom, cTER_id } = optionsSelected;
            if (cTER_Nom !== '') {
                data = dataSnapTabla.filter(({ cTer_Nombre, cPAI_Nombre }) => {
                    return formatString(cTer_Nombre) === formatString(cTER_Nom) && formatString(cPAI_Nombre) === formatString(cPAI_Nomb)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ cTER_Id, cPAI_Id }) => {
                    return formatString(cTER_Id) == formatString(cTER_id) && formatString(cPAI_id) == formatString(cPAI_Id)
                });
                setDataSnapTablaUpdate(data);
                setDataSnapNegUpdate(dataNegocios)

            } else {
                data = dataSnapTabla.filter(({ cPAI_Nombre }) => {
                    return formatString(cPAI_Nombre) === formatString(cPAI_Nomb)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ cPAI_Id }) => {
                    return formatString(cPAI_id) == formatString(cPAI_Id)
                });
                setDataSnapTablaUpdate(data);
                setDataSnapNegUpdate(dataNegocios)

            }
            filterData(data);
            handlerDataNegocios(dataNegocios);
        }
    }
    const filterByBranch = (e) => {
        const { name, value, id } = e.target;
        if (value !== '' && value !== 'todas') {
            setOptionsSelected({
                ...optionsSelected,
                cGRM_Nomb: value,
            })
            let data = []
            let dataNegocios = []
            const { cPAI_Nomb, cTER_Nom, cTER_id, cPAI_id } = optionsSelected;
            if (cTER_Nom == '') {
                data = dataSnapTabla.filter(({ cGRM_Nombre }) => {
                    return formatString(cGRM_Nombre) === formatString(value)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ cGRM_Nombre }) => {
                    return formatString(cGRM_Nombre) == formatString(value)
                });
            } else {
                data = dataSnapTabla.filter(({ cTer_Nombre, cPAI_Nombre, cGRM_Nombre }) => {
                    return formatString(cGRM_Nombre) === formatString(value) &&
                        formatString(cTer_Nombre) === formatString(cTER_Nom) && formatString(cPAI_Nombre) === formatString(cPAI_Nomb)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ cTER_Id, cPAI_Id, cGRM_Nombre }) => {
                    return formatString(cGRM_Nombre) == formatString(value) &&
                        formatString(cTER_Id) == formatString(cTER_id) && formatString(cPAI_Id) == formatString(cPAI_id)
                });
            }
            filterData(data);
            handlerDataNegocios(dataNegocios);
        } else if (value == 'todas') {
            filterData(dataSnapTablaUpdate);
            handlerDataNegocios(dataSnapNegUpdate);
        }

    }
    const filterEconomicBlock = (e) => {
        const { name, value, id } = e.target;
        const cGORG_Nomb = String(value)
        const { cPAI_Nomb, cTER_Nom, cGRM_Nomb } = optionsSelected
        let data = []
        if (cGORG_Nomb != '') {
            setOptionsSelected({
                ...optionsSelected,
                cGORG_Nomb,
            })
            if (cTER_Nom == '') {
                data = dataSnapTabla.filter(({ cGORG_Nombre }) => {
                    return formatString(cGORG_Nombre) === formatString(cGORG_Nomb)
                });

            } else {
                data = dataSnapTabla.filter(({ cTer_Nombre, cPAI_Nombre, cGRM_Nombre, cGORG_Nombre }) => {
                    return formatString(cGRM_Nombre) === formatString(cGRM_Nomb) &&
                        formatString(cTer_Nombre) === formatString(cTER_Nom) && formatString(cPAI_Nombre) === formatString(cPAI_Nomb) &&
                        formatString(cGORG_Nombre) === formatString(cGORG_Nomb)
                });

            }
            filterData(data);
        }
    }

    const filterByCedente = (e) => {
        const { value } = e.target;
        let data = []
        let dataNegocios = []
        // cCIA_IdOrig - tablasdos
        // Cia_Id - racumulado
        const { cPAI_Nomb, cTER_Nom, cTER_id, cPAI_id, cGRM_Nomb, cGORG_Nomb } = optionsSelected;
        if (value != '') {
            if (cTER_Nom == '') {
                data = dataSnapTabla.filter(({ cCIA_IdOrig }) => {
                    return formatString(cCIA_IdOrig) === formatString(value)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ Cia_Id }) => {
                    return formatString(Cia_Id) == formatString(value)
                });
            } else {
                data = dataSnapTabla.filter(({ cTer_Nombre, cPAI_Nombre, cGRM_Nombre, cCIA_IdOrig, cGORG_Nombre }) => {
                    return formatString(cGRM_Nombre) === formatString(cGRM_Nomb) &&
                        formatString(cGORG_Nombre) === formatString(cGORG_Nomb) &&
                        formatString(cTer_Nombre) === formatString(cTER_Nom) && formatString(cPAI_Nombre) === formatString(cPAI_Nomb) &&
                        formatString(cCIA_IdOrig) === formatString(value)
                });
                dataNegocios = dataSnapNegociosVig.filter(({ cTER_Id, cPAI_Id, cGRM_Nombre, Cia_Id }) => {
                    return formatString(cGRM_Nombre) == formatString(cGRM_Nomb) &&
                        formatString(cTER_Id) == formatString(cTER_id) && formatString(cPAI_Id) == formatString(cPAI_id)
                        && formatString(Cia_Id) == formatString(value)
                });
            }
            filterData(data);
            handlerDataNegocios(dataNegocios);
        }
    }
    useEffect(() => {
        const firstStrike = async () => {
            setCargando(true);
            await getTerritorio({
                variables: {
                    input: {
                        table: 'cTER_Territorio',
                        columns: ['*'],
                    },
                },
            });
            await getOperationOptions({
                variables: {
                    input: {
                        table: 'cGRM_GrupoRamo',
                        columns: ['cGRM_Nombre'],
                    },
                },
            });
            await getGrupoOrg({
                variables: {
                    input: {
                        table: 'cGORG_GrupoOrg',
                        columns: ['*'],
                    },
                },
            });

            await getDataSnapTabla({
                variables: {
                    functionName: "tablaDos", initialDate: yearInit, endDate: yearEnd
                }
            });

            await getDataSnapNegociosVig({
                variables: {
                    functionName: "racumulado", initialDate: yearInit, endDate: yearEnd
                }
            });

        }

        firstStrike()
            .then(() => {
                setCargando(false);
            }).catch(err => {
                setCargando(false);
                console.log(err)
            });

    }, [yearInit, yearEnd]);
    // useEffect(() => {

    // }, [yearInit, yearEnd])
    if ((dataSnapTabla.length <= 0 && dataSnapNegociosVig.length <= 0 && cargando == true) || cargando == true) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                <CircularProgress disableShrink size={100} />
            </div>
        )
    }
    return (
        <Page>
            <Homebar navigation={navigation} />
            <div className="snapshot">
                <div>
                    <div
                        style={{
                            display: 'grid',
                            gridColumns: '1fr 1fr 1fr 1fr',
                            gridAutoFlow: 'column',
                        }}
                    >
                        <img src={logoP} alt="logo_patria" width={68} />
                        <h2
                            style={{
                                color: '#01539c',
                                gridColumn: '2/7',
                                marginLeft: ' auto',
                                marginRight: '57px',
                            }}
                        >
                            Plataforma CRM
                        </h2>
                        <img src={logo2} width={68} marginLeft=" auto" />
                        <img src={logo3} width={68} />
                        <p style={{ fontWeight: 'bold' }}>*Cifras en moneda nacional</p>
                    </div>
                </div>
                <br />
                <div id='main-snapshot'>
                    <div className="snapshot__content">
                        <FirstColumnSnapshot
                            territorioOptions={territorioOptions}
                            countryOptions={countryOptions}
                            operationOptions={operationOptions}
                            idCedentesOptions={idCedentesOptions}
                            grupoOrgOptions={grupoOrgOptions}
                            contCedentesOptions={contCedentesOptions}
                            directorio={dataTableDirectorio}
                            dataCsvDirectorio={dataExportCsv}
                            loadingDirectorio={loadingDirectorio}
                            // filtros
                            filterCountryByTer={filterCountryByTer}
                            filterCedentesByCountry={filterCedentesByCountry}
                            filterDirectoryByCedente={filterDirectoryByCedente}
                            handleChangeByYear={handleChangeByYear}
                            // filtros para graficas
                            filterByTer={filterByTer}
                            filterByCountry={filterByCountry}
                            filterByBranch={filterByBranch}
                            filterByCedente={filterByCedente}
                            filterEconomicBlock={filterEconomicBlock}
                        />
                        <SecondColumnSnapshot
                            dataTopTreeMap={dataTopTreeMap}
                            treeMap={dataTreeMap}
                            loadTreeMap={loadingTreeMap}
                            dataEstadisticas={dataEstadisticas}
                            loadEstadisticas={loadEstadisticas}
                            proporcionales={contNegociosVig.proporcionales || 0}
                            noProporcionales={contNegociosVig.noProporcionales || 0}
                            facultativo={contNegociosVig.facultativo || 0}
                            dataTableNegociosVig={dataTableNegociosVig}
                            dataCsvNegociosVig={dataCsvNegociosVig}
                            dataISR={dataBarISR}
                            loadISR={loadISR}

                        />
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Snapshot;