import React from 'react';
import { DivNavigationButton } from '../StylesComponents/common-styled';
import ButtonNavigation from '../Viaticos/ButtonNavigation';

import '../components.css';

export default function RegistroVisita({
  name,
  onClick1,
  onClickReuniones,
  onClickDash,
}) {
  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h1 style={{ margin: '0', fontFamily: 'sans-serif', fontSize: 24 }}>
            <strong>Hola, {name}</strong>
          </h1>
        </div>
        <h2 style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
          ¿Qué deseas registrar?
        </h2>
      </div>
      <DivNavigationButton
        style={{ alignItems: 'flex-start', width: '40%' }}
        className="navigation-button-registro"
      >
        <ButtonNavigation
          text="Nuevo Reporte de Visita"
          backgroundColor="#FFF"
          border="solid #E5E5E5"
          color="auto"
          onClick={onClick1}
          style={{ cursor: 'pointer' }}
        />
        {/* <ButtonNavigation
          text="Actualizar el Seguimiento de un Reporte"
          style={{ cursor: 'pointer' }}
          onClick={onClickReuniones}
        />
        <ButtonNavigation
          text="Actualizar Datos de una Cedente"
          style={{ cursor: 'pointer' }}
          onClick={onClickDash}
        /> */}
      </DivNavigationButton>
    </div>
  );
}
