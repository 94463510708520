import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
    PageContainer,
    Subtitle,
    ContainerTwoInputs,
} from '../StylesComponents/common-styled';

import Card from '../Viaticos/Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import ButtonsInformacion from '../Viaticos/ButtonsInformacion';
import { FormControl, InputLabel, MenuItem, Select, TextareaAutosize, Typography } from '@material-ui/core';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import {
    getcTOBS_Id,
    getRol,
    totalViaticos,
    totalMateriales,
} from '../../funciones-auxiliares/viaticos';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
    GET_EVENTOSBYVIATICOS,
    GET_EVENTO_BY_CREU_ID,
} from '../../query/query';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE, DINAMIC_GET } from '../../mutation/mutation';
import { MyContext } from '../Context';

import InfoViaje from '../Viaticos/InfoViaje';
import InfoAlojamiento from '../Viaticos/InfoAlojamiento';
import InfoMateriales from '../Viaticos/InfoMateriales';
import DialogAlert from '../DiaologAlert';
import { sendEmail } from '../../services/SendEmails';
import RolSelection from '../Acuerdos/common/RolSelection';
import CardListReuniones from './CardListReuniones';
const ROLESVSRESPONSE = {
    Autorizar: '62',
    'Regresar al viajero': '',
    'Regresar al asistente de viajes': '62',
    'Rechazar': '' //Viajero
}
const DirectorReview = ({ cEMP_Id, handleComponent }) => {
    const context = useContext(MyContext);
    const [directorResponse, setDirectorResponse] = useState('');
    const [cotizacion, setCotizacion] = useState('');
    const [data, setData] = useState({});
    const [data2, setData2] = useState({});
    const [value, setValue] = useState(''); //Ida y vuelta
    const [dinamicTittle, setDinamicTittle] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const tramos = [{ titulo: 'Tramo 1' }];
    const [meetings, setMeetings] = useState([]);
    const [viaje, setViaje] = useState([
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
        {
            origen: '',
            destino: '',
            aerolinea: '',
            vuelo: '',
            cotizacion: '',
            fecha: '',
            horario: '',
            precio: '',
        },
    ]);
    const [alojamiento, setAlojamiento] = useState([
        {
            hotel: '',
            urlCotizacion: '',
            fecha: '',
            precio: '',
        },
    ]);

    const [materiales, setMateriales] = useState([
        {
            cMAT_Descripcion: '',
            cMAT_Precio: '',
        },
    ]);
    const [requerimientoTI, setRequerimientoTI] = useState([
        {
            cREQT_Descripcion: '',
            cREQT_Precio: '',
        },
    ]);

    const [promocionales, setPromocionales] = useState([
        {
            cPRO_Descripcion: '',
            cPRO_Precio: '',
        },
    ]);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            // sessionStorage.setItem('cREU_Titulo', getReunionBycREUID.cREU_Titulo);
            // sessionStorage.setItem('emailToTraveler', getReunionBycREUID.cEMP_Mail);
            // setData({
            //     titulo: getReunionBycREUID.cREU_Titulo,
            //     texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
            //     texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
            //     texto3: getReunionBycREUID.cREU_TipoReunion,
            // });
            // setData2({
            //     titulo: getReunionBycREUID.Nombre_Empleado,
            //     texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
            //     texto2: 'Suscripción',
            //     texto3: 'Patria RE',
            // });
        },
    });

    const [insertObservations] = useMutation(DYNAMIC_INSERT, {
        onCompleted({ dynamicInsert }) {
            if (dynamicInsert.statusCode === 200) {
                handleComponent();
            }
        },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });

    const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
        onCompleted({ dynamicUpdate }) { },
        onError({ graphQLErrors, networkError }) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });
    const [getCotizacion] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.message === 'success') {
                const response = JSON.parse(dynamicGet.response);
                if (response.length > 0) {
                    setCotizacion(response[0].cCOTVIT_FileName)
                }
            }
        },
        onError(error) {
            console.log(error)
        }
    })
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const formatTotalPrice = () => {
        return formatter.format(filterFloat(totalViaticos(
            viaje,
            alojamiento,
            totalMateriales(materiales, promocionales, requerimientoTI))))
    }
    const sendResponseDirector = async () => {
        const token = context.state.AuthComponentProps.token;
        try {
            const table = 'cVIT_Viaticos';
            const AUTORIZADO = '4';
            const RECHAZADO = '3';
            const conditions = [
                {
                    valone: 'cVIT_Id',
                    valtwo: String(context.state.viaticosProps.cVIT_Id),
                    condition: '=',
                }
            ]

            if (directorResponse === 'Autorizar') {
                setDinamicTittle('Autorizado');
                await updateStatus({
                    variables: {
                        input: {
                            table,
                            columns: [
                                {
                                    setkey: 'cVIT_Status_VIAT',
                                    setval: AUTORIZADO,
                                },
                                {
                                    setkey: 'cVIT_Avance',
                                    setval: '4',
                                },
                            ],
                            conditions,
                        }
                    }
                })
                sessionStorage.setItem('statusVIt', 'autorizada');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionFour'
                });

            } else if (directorResponse === 'Rechazar') {
                setDinamicTittle('Rechazado');
                await updateStatus({
                    variables: {
                        input: {
                            table,
                            columns: [
                                {
                                    setkey: 'cVIT_Status_VIAT',
                                    setval: RECHAZADO,
                                }
                            ],
                            conditions,
                        }
                    }
                });
                sessionStorage.setItem('statusVIt', 'rechazada');
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionFour'
                });
            } else if (directorResponse === 'Regresar al viajero') {
                setDinamicTittle('Enviado correctamente al Solicitante');
                await updateStatus({
                    variables: {
                        input: {
                            table,
                            columns: [
                                {
                                    setkey: 'cVIT_Avance',
                                    setval: '2',
                                },
                            ],
                            conditions,
                        }
                    }
                });
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailToTraveler')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionFour_two'
                });
            } else if (directorResponse === 'Regresar al asistente de viajes') {
                setDinamicTittle('Enviado correctamente al Asistente');
                await updateStatus({
                    variables: {
                        input: {
                            table,
                            columns: [
                                {
                                    setkey: 'cVIT_Status_VIAT',
                                    setval: '1',
                                },
                                {
                                    setkey: 'cVIT_Avance',
                                    setval: '1',
                                },
                            ],
                            conditions,
                        }
                    }
                });
                await sendEmail({
                    accessToken: token,
                    emailTo: [sessionStorage.getItem('emailTo')],
                    subject: 'Solicitud de viaticos',
                    section: 'sectionFour_three'
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    const saveObservations = async () => {
        let input = {
            table: 'cOBS_ObservacionesViaticos',
            columns: ['cVIT_Id', 'cEMP_Id', 'cOBS_Descripcion', 'cTOBS_Id'],
            detailValues: [
                {
                    values: [
                        String(context.state.viaticosProps.cVIT_Id),
                        String(cEMP_Id.cEMP_Id),
                        sessionStorage.getItem('obsevaciones'),
                        String(getcTOBS_Id(cEMP_Id.profile)),
                    ],
                },
            ],
        };
        await insertObservations({ variables: { input } });
    }
    const validateSelectionEmail = () => {
        const emailTo = sessionStorage.getItem('emailTo');
        // const emailToTraveler = sessionStorage.getItem('emailToTraveler');
        if (directorResponse === 'Autorizar' || directorResponse === 'Regresar al asistente de viajes') {
            if (emailTo) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
    const saveDirector = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' });
        const emailSelected = validateSelectionEmail();
        if (!emailSelected) {
            setDinamicTittle('Selecciona un correo');
            setOpenDialog(true);
            setStyleDisabled({})
            return;
        }
        const email = sessionStorage.getItem('emailTo') ? sessionStorage.getItem('emailTo') : sessionStorage.getItem('emailToTraveler');
        if (directorResponse !== '') {
            if (email) {
                let observaciones = sessionStorage.getItem('obsevaciones');
                if (directorResponse === 'Regresar al viajero' || directorResponse === 'Regresar al asistente de viajes' || directorResponse === 'Rechazar') {
                    if (observaciones === null || observaciones === undefined) {
                        setDinamicTittle('Campo obligatorio: comentarios');
                        setOpenDialog(true);
                        setStyleDisabled({});
                        return;                                 
                    } else {
                        await saveObservations();
                    }
                } else if (observaciones) {
                    await saveObservations();
                }
                await sendResponseDirector();
                handleComponent();
                setOpenDialog(true);
            }
        } else {
            setDinamicTittle('Selecciona una autorización');
            setOpenDialog(true);
            setStyleDisabled({})

        }
    };

    const getViaticos = () => {
        const viajes = context.state.viaticosProps.viajes.map((viaje) => {
            return {
                ...viaje,
                origen: viaje.cVIA_Origen,
                destino: viaje.cVIA_Destino,
                aerolinea: viaje.cVIA_Areolinea,
                vuelo: viaje.cVIA_NoVuelo,
                cotizacion: viaje.cVIA_URLVueloCot,
                fecha: viaje.cVIA_FechaVuelo.slice(0, 10),
                horario: viaje.cVIA_HorarioVuelo.slice(11, 16),
                precio: viaje.cVIA_PrecioVuelo,
            };
        });
        const alojamientos = context.state.viaticosProps.alojamientos.map(
            (alojamiento) => {
                return {
                    ...alojamiento,
                    hotel: alojamiento.cALO_Hotel,
                    urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
                    fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
                    precio: alojamiento.cALO_Precio,
                };
            }
        );
        const materialesV = context.state.viaticosProps.materiales.map(
            (material) => {
                return {
                    ...material,
                    cMAT_Descripcion: material.cMAT_Descripcion,
                    cMAT_Precio: material.cMAT_Precio,
                };
            }
        );
        const promocionalesV = context.state.viaticosProps.promocionales.map(
            (promocionalV) => {
                return {
                    ...promocionalV,
                    cPRO_Descripcion: promocionalV.cPRO_Descripcion,
                    cPRO_Precio: promocionalV.cPRO_Precio,
                };
            }
        );
        const requerimientosTiV = context.state.viaticosProps.requerimientoTI.map(
            (requerimientoTiV) => {
                return {
                    ...requerimientoTiV,
                    cREQT_Descripcion: requerimientoTiV.cREQT_Descripcion,
                    cREQT_Precio: requerimientoTiV.cREQT_Precio,
                };
            }
        );
        setMateriales(materialesV);
        setPromocionales(promocionalesV);
        setRequerimientoTI(requerimientosTiV);
        setViaje(viajes);
        setAlojamiento(alojamientos);
        switch (context.state.viaticosProps.cTIP_Id) {
            case 1:
                setValue('Ida y vuelta');
                break;
            case 2:
                setValue('Solo ida');
                break;
            case 3:
                setValue('Múltiples destinos');
                break;
            default:
                console.log('default switch');
        }
    };

    const handleChange = ({ target }) => {
        sessionStorage.setItem('obsevaciones', target.value);
    };
    const handleChangeAuth = ({ value }) => {
        setDirectorResponse(value);
        if (value === 'Regresar al viajero' || value === 'Rechazar') {
            sessionStorage.removeItem('emailTo');
        }
    }

    useEffect(() => {
        sessionStorage.clear();
        sessionStorage.setItem('cTIP_Id', 1);
        if (context.state.viaticosProps.cVIT_Id !== '') {
            getViaticos();
            getCotizacion({
                variables: {
                    input: {
                        table: 'cCOTVIT_Cotizaciones',
                        columns: ['cCOTVIT_FileName'],
                        conditions: [
                            {
                                valone: 'cVIT_Id',
                                valtwo: String(context.state.viaticosProps.cVIT_Id),
                                condition: '=',
                            }
                        ]
                    }
                }
            })
            getEventBycREU({
                variables: { cREU_Id: context.state.viaticosProps.reuniones },
            });
        }
    }, []);

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    const filterFloat = (__val__) => {
        const preg = /^([0-9]+\.?[0-9]{0,2})$/;
        if (preg.test(__val__) === true) {
            return __val__;
        }
        return "";
    };
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation">
            <DialogAlert
                title=""
                text={dinamicTittle}
                textButtonA="Aceptar"
                open={openDialog}
                handleClose={handleCloseDialog}
            />
            <Subtitle color="#000">
                Revisa solicitud de los viáticos de viaje
            </Subtitle>
            <CardListReuniones meetings={meetings} setEmpleado={setData2} setReunion={setData} />
            <ContainerTwoInputs
                width="100%"
                margin="20px 0px"
                id="viaticos-solicitud"
            >
                <Card data={data} img={calendario} />
                <Card data={data2} img={user} />
            </ContainerTwoInputs>
            <ButtonsInformacion
                title="Información de transporte"
                text1="Ida y vuelta"
                text2="Solo ida"
                text3="Múltiples destinos"
                cTIp_Id={context.state.viaticosProps.cTIP_Id}
            />
            {value === 'Solo ida' && (
                <InfoViaje title="Viaje de ida" info={viaje} pos={0} />
            )}
            {value === 'Ida y vuelta' ? (
                viaje.map((item, index) => (
                    <InfoViaje title="Viaje de vuelta" info={viaje} pos={index} />
                ))
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {viaje.map((tramo, index) => (
                        <InfoViaje
                            title={`Tramo ${index + 1}`}
                            info={viaje}
                            pos={index}
                        />
                    ))}
                </Fragment>
            ) : null}
            {value === 'Múltiples destinos' ? (
                <Fragment>
                    {alojamiento.map((tramo, index) => (
                        <InfoAlojamiento
                            title={`Alojamiento - Tramo ${index + 1}`}
                            pos={index}
                            value={alojamiento}
                        />
                    ))}
                </Fragment>
            ) : value === 'Ida y vuelta' ? (
                <InfoAlojamiento
                    title="Información de alojamiento"
                    pos={0}
                    value={alojamiento}
                />
            ) : null}
            <InfoMateriales title="Equipo o materiales para viaje" cambio={value} />
            <div style={{ marginTop: '20px' }}>
                <h3>Total del viaje</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography>Precio:</Typography>
                    <Typography
                        style={{ color: '#3898EC', marginLeft: '20px' }}
                    >
                        {formatTotalPrice()}
                    </Typography>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <h3>Cotizaciones</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography>Cotizaciones elegidas por el viajero:</Typography>
                    <Typography
                        style={{ color: '#3898EC', marginLeft: '20px', width: "100%" }}
                    >
                        <a href={`https://dev-patria.alimx.mx/api/download/files/${cotizacion}`}>{cotizacion}</a>
                    </Typography>
                </div>
            </div>
            {context.state.viaticosProps.observaciones.map((data) => {
                return (
                    <Fragment>
                        <div style={{ marginTop: '20px' }}>
                            <h3 style={{ textAlign: 'left' }}>{`Observaciones - ${getRol(
                                data.cTOBS_Id
                            )}`}</h3>
                        </div>
                        <TextareaAutosize
                            rowsMin={10}
                            style={{ width: '90%' }}
                            id="text-mensaje"
                            name="area"
                            value={`${data.cEMP_Nombre}:\n ${data.cOBS_Descripcion}`}
                            disabled
                        />
                    </Fragment>
                );
            })}
            <div style={{ marginTop: '20px' }}>
                <h3 style={{ textAlign: 'left' }}>Observaciones</h3>
            </div>
            <TextareaAutosize
                rowsMin={10}
                style={{ width: '90%' }}
                id="text-mensaje"
                name="observaciones"
                onChange={handleChange}
                value={data.cOBS_Descripcion}
                placeholder="Escribe comentarios del viaje ..."
            />
            <div
                style={{
                    marginTop: '20px',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <FormControl style={{ width: '50%', margin: 20 }}>
                    <InputLabel id="auth-lbl">Autorización</InputLabel>
                    <Select
                        labelId="auth-lbl"
                        id="auth"
                        value={directorResponse}
                        label="Autorización"
                        onChange={({ target }) => handleChangeAuth(target)}
                    >
                        <MenuItem value="Autorizar">Autorizar</MenuItem>
                        <MenuItem value="Regresar al viajero">Regresar al viajero</MenuItem>
                        <MenuItem value="Regresar al asistente de viajes">Regresar al asistente de viajes</MenuItem>
                        <MenuItem value="Rechazar">Rechazar</MenuItem>
                    </Select>
                </FormControl>
                {(directorResponse !== '' && (directorResponse !== 'Regresar al viajero' && directorResponse !== 'Rechazar')) && (
                    <RolSelection profile={ROLESVSRESPONSE[directorResponse]} width='100%' />
                )}
                <ButtonNavigation
                    text="Aceptar"
                    width="50%"
                    backgroundColor="#3898EC"
                    border="none"
                    color="#fff"
                    justifyContent="center"
                    disabled={true}
                    onClick={() => saveDirector()}
                    id="aceptar"
                    style={styleDisabled}
                />
            </div>
        </PageContainer>
    );
}
export default DirectorReview;