import React, { Fragment } from 'react';
import { AcordeonContainerBody } from '../StylesComponents/common-styled';
import { Typography } from '@material-ui/core';
import FormTypography from './FormsTypography';

const infoViaje = ({ title, info, pos }) => {
  console.log('info===', info)
  const handleInfo = () => {
    if (pos || pos === 0) {
      return info[pos];
    } else {
      return info;
    }
  }
  const { cVIA_Origen, cVIA_Destino, cVIA_Areolinea, cVIA_NoVuelo, cVIA_URLVueloCot, cVIA_HorarioVuelo, cVIA_PrecioVuelo } = handleInfo()

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };
  return (
    <Fragment>
      <div style={{ marginTop: '22px',width: '90%', marginBottom:'2%' }}>
        <h3 style={{ textAlign: 'center', fontWeight:650, fontSize:'19px' }}>{title}</h3>
      </div>
      {
        info ?
          <Fragment>
            <AcordeonContainerBody>
              <FormTypography text="Origen:" direction="row">
                <Typography><strong>{cVIA_Origen}</strong></Typography>
              </FormTypography>
              <FormTypography text="Destino:" direction="row">
                <Typography><strong>{cVIA_Destino}</strong></Typography>
              </FormTypography>
            </AcordeonContainerBody>
            <AcordeonContainerBody>
              <FormTypography text="Aerolinea:" direction="row">
                <Typography><strong>{cVIA_Areolinea}</strong></Typography>
              </FormTypography>
              <FormTypography text="No. de vuelo:" direction="row">
                <Typography><strong>{cVIA_NoVuelo}</strong></Typography>
              </FormTypography>
            </AcordeonContainerBody>
            <AcordeonContainerBody>
              <FormTypography text="Horario de vuelo:" direction="row">
                <Typography><strong>{`${cVIA_HorarioVuelo ? cVIA_HorarioVuelo.slice(11, 16) : ''} hrs`}</strong></Typography>
              </FormTypography>
              <FormTypography text="Precio de vuelo:" direction="row">
                <Typography><strong>{` ${cVIA_PrecioVuelo}`}</strong></Typography>
              </FormTypography>
            </AcordeonContainerBody>
            <AcordeonContainerBody>
              <FormTypography text="URL de cotización:" direction="row">
                <Typography><strong>{cVIA_URLVueloCot}</strong></Typography>
              </FormTypography>
            </AcordeonContainerBody>

          </Fragment> : null
      }
    </Fragment>
  )
}

export default infoViaje;