import React, { useEffect, useState } from 'react'
import { DINAMIC_GET } from '../../mutation/mutation';
import { useMutation } from 'react-apollo';

export const useGetOptionsSeguimiento = () => {
    const [optionsStatus, setOptionsStatus] = useState([]); // cESTS_EstatusSeguimiento
    const [optionsTipoSeg, setOptionsTipoSeg] = useState([]); //cRES_ReporteSeguimiento y reporte de seguimiento
    const [getOptionsStatus, { loading: loadingStatus }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setOptionsStatus(response);
            }
        }
    });
    const [getOptionsTipoSeg, { loading: loadingTipoSeg }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setOptionsTipoSeg(response);
            }
        }
    });
    useEffect(() => {
        getOptionsStatus({
            variables: {
                input: {
                    table: 'cESTS_EstatusSeguimiento',
                    columns: ['*']
                }
            }
        });
        getOptionsTipoSeg({
            variables: {
                input: {
                    table: 'cRES_ReporteSeguimiento',
                    columns: ['*']
                }
            }
        })
    }, []);

    return {
        optionsTipoSeg,
        optionsStatus,
        loading: loadingStatus && loadingTipoSeg
    }
}

export const useGetAnswersSeg = ({ cACU_Id }) => {
    const [answers, setAnswers] = useState([]);
    const [getAnswers, { loading }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setAnswers(response);
            }
        }
    });
    useEffect(() => {
        getAnswers({
            variables: {
                input: {
                    table: 'cSEGE_SeguimientoEntorno',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cACU_Id',
                            valtwo: String(cACU_Id),
                            condition: '='
                        }
                    ]
                }
            }
        })
    }, [])
    return {
        answers,
        loadingAnswers: loading

    }

}
export const useGetOptionsRelacion = () => {
    const [optionsActionsSeg, setOptionsActionsSeg] = useState([]);
    const [optionsStatusSeg, setOptionsStatusSeg] = useState([]);
    const [optionsSegRel, setOptionsSegRel] = useState([]);
    const [optionsTemasSeg, setOptionsTemasSeg] = useState([])

    const [getOptionsActions, { loading: loadingActions }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setOptionsActionsSeg(response);
            }
        }
    });
    const [getOptionsStatus, { loading: loadingStatus }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setOptionsStatusSeg(response);
            }
        }
    });
    const [getOptionsSegRel, { loading: loadingSeg }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setOptionsSegRel(response);
            }
        }
    });
    const [getTemasSeguimiento, { loading: loadingTemas }] = useMutation(DINAMIC_GET, {
        fetchPolicy: 'no-cache',
        onCompleted({ dynamicGet }) {
            setOptionsTemasSeg(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });
    useEffect(() => {
        getOptionsActions({
            variables: {
                input: {
                    table: 'cACCSR_AccionesSeguimientoRelacion',
                    columns: ['*'],
                }
            }
        });
        getOptionsStatus({
            variables: {
                input: {
                    table: 'cESTS_EstatusSeguimiento',
                    columns: ['*']
                }
            }
        });
        getOptionsSegRel({
            variables: {
                input: {
                    table: 'cSEGR_SeguimientoRelacion',
                    columns: ['*']
                }
            }
        });
        getTemasSeguimiento({
            variables: {
                input: {
                    table: 'cTEMSR_TemasSeguimientoRelacion',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cTEMSR_Tipo',
                            valtwo: `2`,
                            condition: '=',
                        },
                    ],
                },
            },
        });
    }, []);
    return {
        optionsActionsSeg,
        optionsStatusSeg,
        optionsSegRel,
        optionsTemasSeg,
        loading: loadingActions && loadingStatus && loadingSeg && loadingTemas
    }
}

export const useGetAnswersSegRel = ({ cACU_Id }) => {
    const [answers, setAnswers] = useState([]);
    const [getAnswers, { loading }] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            if (dynamicGet.response !== '') {
                const response = JSON.parse(dynamicGet.response);
                setAnswers(response);
            }
        }
    });
    useEffect(() => {
        getAnswers({
            variables: {
                input: {
                    table: 'cSEGRP_SeguimientoRelacionPatria2',
                    columns: ['*'],
                    conditions: [
                        {
                            valone: 'cACU_Id',
                            valtwo: String(cACU_Id),
                            condition: '='
                        }
                    ]
                }
            }
        })
    }, [])
    return {
        answers,
        loadingAnswers: loading

    }

}