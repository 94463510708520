import React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';


const SimpleSelect = ({ width = '', marginLeft = '', options = [], placeholder = '', value = '', setValue = '' }) => {
    const handleChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <Box style={{ width, marginLeft }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={placeholder}
                    onChange={handleChange}
                >
                    {options.map(({ cREU_Id, cREU_Titulo }) => (
                        <MenuItem key={cREU_Id} value={cREU_Id}>{cREU_Titulo}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default SimpleSelect;