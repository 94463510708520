import { gql } from '@apollo/client';

export const GETCOUNTRIES_SIREC = gql`
  query {
    idCatCountries {
      cPAI_Id
      cPAI_NOMBRE
    }
  }
`;

export const GETMESSAGE_SIREC = gql`
  query {
    idCatMessageTemplate {
      cPLA_Id
      cPLA_Nombre_
      cPLA_textPlantilla
    }
  }
`;

export const USUARIO_ACTUAL = gql`
  query {
    obtenerUsuario {
      cEMP_Nombre
      cEMP_Id
      profile
    }
  }
`;

export const GETCITIES_SIREC = gql`
  query getCities($cPAI_Id: Int) {
    idCatCity(cPAI_Id: $cPAI_Id) {
      cPAI_Id
      cEDO_Id
      cEDO_Nombre
    }
  }
`;

export const GETEMPLOYES_SIREC = gql`
  query getEmployes {
    idEmployeSirec {
      cEMP_Id
      cEMP_Nombre
      cEMP_ApePaterno
      cEMP_ApeMaterno
      cEMP_Mail
      cEMP_Status
    }
  }
`;

export const GETRETROCESSIONARY_SIREC = gql`
  query idRetrocessionarySirec($cPAI_Id: Int) {
    idRetrocessionarySirec(cPAI_Id: $cPAI_Id) {
      cRET_Id
      cRET_Version
      cRET_RazSocial
      cPAI_Id
      cRET_Status
    }
  }
`;

export const GETCOMPANI_SIREC = gql`
  query idCompanySirec($cPAI_Id: Int) {
    idCompanySirec(cPAI_Id: $cPAI_Id) {
      cCIA_Id
      cCIA_Version
      cCIA_RazSocial
      cPAI_Id
      cCIA_Status
    }
  }
`;

export const GETCORREDOR_SIREC = gql`
  query idCorredorSirec($cPAI_Id: Int) {
    idCorredorSirec(cPAI_Id: $cPAI_Id) {
      cCOR_Id
      cCOR_Version
      cCOR_RazSocial
      cPAI_Id
      cCOR_Status
    }
  }
`;

export const GETINVITADOS_SIREC = gql`
  query idInvitadosSirec($cTORG_Id: Int, $cORG_Id: Int) {
    idInvitadosSirec(cTORG_Id: $cTORG_Id, cORG_Id: $cORG_Id) {
      cTORG_Id
      cORG_Id
      cORG_Ver
      cEje_Id
      cEje_Nombre
      cEje_Cargo
      cEje_Telefono
      cEje_Celular
      cEje_Mail
      cEje_MedioContacto
    }
  }
`;

export const GETRETROCESSIONARY_SIREC_ById = gql`
  query idRetrocessionarySirecById($cRET_Id: Int, $cRET_RazSocial: String) {
    idRetrocessionarySirecById(
      cRET_Id: $cRET_Id
      cRET_RazSocial: $cRET_RazSocial
    ) {
      cRET_Id
      cRET_Version
      cRET_RazSocial
      cPAI_Id
      cRET_Status
    }
  }
`;

export const GETCORREDOR_SIREC_ById = gql`
  query idCorredorSirecById($cCOR_Id: Int, $cCOR_RazSocial: String) {
    idCorredorSirecById(cCOR_Id: $cCOR_Id, cCOR_RazSocial: $cCOR_RazSocial) {
      cCOR_Id
      cCOR_Version
      cCOR_RazSocial
      cPAI_Id
      cCOR_Status
    }
  }
`;

export const GETCOMPANI_SIREC_ById = gql`
  query idCompanySirecBy($cCIA_Id: Int, $cCIA_RazSocial: String) {
    idCompanySirecById(cCIA_Id: $cCIA_Id, cCIA_RazSocial: $cCIA_RazSocial) {
      cCIA_Id
      cCIA_Version
      cCIA_RazSocial
      cPAI_Id
      cCIA_Status
    }
  }
`;

export const GETIDREUNION_SIREC = gql`
  query getIdReunion {
    obtenerIdReunion {
      cREU_Id
    }
  }
`;

export const GET_TEXTMESSAGE_SIREC = gql`
  query getTextMessageById($cPLA_Id: Int) {
    getTextMessageById(cPLA_Id: $cPLA_Id) {
      cPLA_textPlantilla
    }
  }
`;

export const GET_CALENDAREVENTS = gql`
  query getCalendarEvents($cEMP_Id: Int!) {
    getCalendarEvents(cEMP_Id: $cEMP_Id) {
      cREU_Id
      Fecha
      Hora
      Title
      Lugar
      cREU_IdOutlook
    }
  }
`;

export const GET_FECHASEVENTS = gql`
  query getCalendarEvents($cEMP_Id: Int!) {
    getCalendarEvents(cEMP_Id: $cEMP_Id) {
      Title
      Start
      End
      cREU_Id
    }
  }
`;

export const GET_REUNION = gql`
  query getReunion($cREU_Id: Int) {
    getReunion(cREU_Id: $cREU_Id) {
      cREU_Id
      cEMP_Id
      cEDO_Id
      cPAI_Id
      cCIUD_Id
      cPLA_Id
      cREU_ConferenciaURL
      cREU_FechaInicio
      cREU_HoraInicio
      cREU_FechaFin
      cREU_HoraFin
      cREU_Lugar
      cREU_Titulo
      cREU_ZonaHoraria
      cREU_FechaActualPais
      cREU_HoraActualPais
      cREU_TipoReunion
      cREU_IdOutlook
      invitadosReunion {
        cEMP_Id
        name
        correo
        status_db
        status
      }
      rEjexORG {
        cTORG_Id
        cORG_Id
        cEje_Id
        cEje_Nombre
        cEje_Cargo
        cEje_Mail
      }
    }
  }
`;

export const GET_CIUDADESBYID = gql`
  query getCiudadById($cPAI_Id: Int!) {
    getCiudadById(cPAI_Id: $cPAI_Id) {
      cCIUD_Id
      cCIUD_Nombre
    }
  }
`;

export const GET_EVENTOSBYVIATICOS = gql`
  query getEventos($cEMP_Id: Int!, $cREU_FechaInicio: String!) {
    getReunionbyViaticos(
      cEMP_Id: $cEMP_Id
      cREU_FechaInicio: $cREU_FechaInicio
    ) {
      cREU_Id
      cREU_Titulo
      cREU_FechaInicio
      cREU_FechaFin
      cREU_TipoReunion
      Pais
      Estado
      Nombre_Empleado
    }
  }
`;

export const GET_REU_WITH_EMP = gql`
  query getReunionByEmpId($cEmpId: Int) {
    getReunionByEmpId(cEMP_Id: $cEmpId) {
      cREU_Id
      cREU_Titulo
      cREU_FechaInicio
      cREU_FechaFin
      cREU_TipoReunion
      Pais
      Estado
      Nombre_Empleado
    }
  }
`;

export const GET_TRAVEL_EXPENSES_BY_IDUSER = gql`
  query getTravelExpensesbyUser(
    $cEMP_Id: Int
    $cREU_FechaInicio: String!
    $cVIT_Status_VIAT: Int
  ) {
    getTravelExpensesbyUser(
      cEMP_Id: $cEMP_Id
      cREU_FechaInicio: $cREU_FechaInicio
      cVIT_Status_VIAT: $cVIT_Status_VIAT
    )
  }
`;

export const GET_TRAVEL_EXPENSES_BY_ID = gql`
  query getTravelExpenses($cVIT_Id: Int) {
    getTravelExpenses(cVIT_Id: $cVIT_Id)
  }
`;

export const GET_EVENTO_BY_CREU_ID = gql`
  query getEventoBycREU_Id($cREU_Id: [Int]) {
    getReunionBycREUID(cREU_Id: $cREU_Id) {
      cREU_Id
      cREU_Titulo
      cREU_FechaInicio
      cREU_FechaFin
      cREU_TipoReunion
      cEMP_Id
      Pais
      Estado
      Nombre_Empleado,
      cEMP_Mail,
    }
  }
`;

export const GET_TRAVEL_EXPENSES_ALL = gql`
  query getTravelExpensesAll(
    $cREU_FechaInicio: String!
    $cVIT_Status_VIAT: Int
    $cVIT_Status_COMP: Int
    $cVIT_Avance: Int
  ) {
    getTravelExpensesAll(
      cREU_FechaInicio: $cREU_FechaInicio
      cVIT_Status_VIAT: $cVIT_Status_VIAT
      cVIT_Status_COMP: $cVIT_Status_COMP
      cVIT_Avance: $cVIT_Avance
    )
  }
`;

export const GET_CIUDAD_BYID = gql`
  query getCiudad($cPAI_Id: Int, $cEDO_Id: Int) {
    getCiudad(cPAI_Id: $cPAI_Id, cEDO_Id: $cEDO_Id)
  }
`;

export const GET_PAIS_BYID = gql`
  query getPais($cPAI_Id: Int) {
    getPais(cPAI_Id: $cPAI_Id)
  }
`;
export const GET_INFOUSER_BYTYPE = gql`
  query getUserByStatus($cGRP_Nombre: String) {
    getUserByStatus(cGRP_Nombre: $cGRP_Nombre) {
      cEMP_Nombre
      cEMP_Mail
      cGRP_Id
      cGRP_Nombre
    }
  }
`;

export const GET_REUNIONES_BYFECHA = gql`
  query getReunionByFecha($cEMP_Id: Int!, $cREU_FechaInicio: String) {
    getReunionesByfecha(
      cEMP_Id: $cEMP_Id
      cREU_FechaInicio: $cREU_FechaInicio
    ) {
      cREU_Id
      cREU_FechaInicio
      cREU_HoraInicio
      cREU_Titulo
      cREU_Lugar
    }
  }
`;

export const GET_DATA_LATINO = gql`
  query getDataExcel {
    getDataExcel
  }
`;
export const GET_DATA_CRM = gql`
  query getDataCRM($typeQuery: String) {
    getDataCRM(typeQuery: $typeQuery)
  }
`;
export const GET_SPENDING_LIMIT = gql`
  query getSpendingLimitByUser($cEMP_Id: Int) {
    getSpendingLimitByUser(cEMP_Id: $cEMP_Id)
  }
`
export const GET_TIPO_CAMBIO = gql`
  query getTipoCambio($currentDate: String, $country: String) {
    getTipoCambio(currentDate: $currentDate, country: $country)
  }
`
export const GET_CHECKING_COMP = gql`
  query getCheckingByStatusComp($cVIT_Status_COMP: Int) {
    getCheckingByStatusComp(cVIT_Status_COMP: $cVIT_Status_COMP)
  }
`