import React, { Fragment, useState, useContext } from 'react';
import { useMutation } from 'react-apollo';

import { MyContext } from "../../Context"
import ImputResumen from '../ImputResumen';
import { useQuery } from 'react-apollo';
import {GETCOUNTRIES_SIREC} from '../../../query/query';
import { Check, Edit } from '@material-ui/icons'
import { Button } from '@material-ui/core';
import {DYNAMIC_UPDATE, GET_TIENE_ACUERDO, GET_ACUERDOS_BY_REU} from '../../../mutation/mutation';

import DialogAlert from '../../../components/DiaologAlert';

const DatosReuProgramada = ({seguirEntorno, irEdicion}) => {
    const [disabled, setDisabled] = useState({
        titulo: true,
        lugar: true,
        fecha: true,
        hora: true,
    })
    const [contentModal, setContentModal] = useState({
        title: '',
        message: '',
        open: false
      });
    const context = useContext(MyContext);

      const sede = [
        {
          tipo: 'Oficinas',
          id: 1,
        },
        {
          tipo: 'Hotel',
          id: 2,
        },
        {
          tipo: 'Restaurante',
          id: 3,
        },
        {
          tipo: 'Lugar Público',
          id: 4,
        },
      ];

      const [tieneAcuerdo, setTieneAcuerdo] = useState(false);

      const [updateReunion] = useMutation(DYNAMIC_UPDATE, {
        onCompleted({ dynamicUpdate }) {
          console.log('RESPONSE UPDATE COMMENT ----->>>', dynamicUpdate);
          getAcuerdos({
            variables: {
            cREU_Id: String(context.state.reuProgramadaData.cREU_Id),
          }})
        },
        onError(err) {
          console.log('Error', err);
        },
      });

      const [getAcuerdos] = useMutation(GET_ACUERDOS_BY_REU, {
        onCompleted({ getAcuerdos }) {
          console.log('getAcuerdos Executed', getAcuerdos);
          if (getAcuerdos) {
            const acuerdos = getAcuerdos || [];
            if(acuerdos.length > 0){
                getTieneAcuerdos({
                    variables: {
                        ids: [...acuerdos],
                        status: '',
                    },
                });
            }
            else{
                // sin acuerdos ir a entorno
                seguirEntorno();
            }
          } else {
            // ir a entorno
            seguirEntorno();
          }
        },
        onError(err) {
          console.log(err.name, err.message);
          console.error('Error ACU ->', err);
        },
      });

      const [getTieneAcuerdos] = useMutation(GET_TIENE_ACUERDO, {
        onCompleted({ getTieneAcuerdo }) {
          console.log('RESPONSE STATUS REUNION ----->>>', getTieneAcuerdo);
          setTieneAcuerdo(JSON.parse(getTieneAcuerdo.response));
          setContentModal({...contentModal, 
            open: (getTieneAcuerdo.response === 'true' ? true : false),
            title: 'Ya existe un acuerdo para esta reunión',
            message: '¿deseas ir a consulta de reportes para editar?'
        })
        },
        onError(err) {
          console.log('Error', err);
        },
      });

    const actualizaReunion = async() => {

        let savingData = [];

        if (context.state.reuProgramadaData.cREU_Titulo !== '') {
            savingData.push({
                setkey: 'cREU_Titulo',
                setval: context.state.reuProgramadaData.cREU_Titulo
            });
        }
        else{
            console.log("El campo 'titulo' es requerido");
            return;
        }

        if (context.state.reuProgramadaData.cREU_Lugar !== '') {
            savingData.push({
                setkey: 'cREU_Lugar',
                setval: context.state.reuProgramadaData.cREU_Lugar
            });
        }
        else{
            console.log("El campo 'lugar' es requerido");
            return;
        }

        if (context.state.reuProgramadaData.cREU_FechaInicio !== '') {
            savingData.push({
                setkey: 'cREU_FechaInicio',
                setval: context.state.reuProgramadaData.cREU_FechaInicio
            });
        }
        else{
            console.log("El campo 'fecha inicio' es requerido");
            return;
        }

        if (context.state.reuProgramadaData.cREU_HoraInicio !== '') {
            savingData.push({
                setkey: 'cREU_HoraInicio',
                setval: context.state.reuProgramadaData.cREU_HoraInicio
            });
        }
        else{
            console.log("El campo 'hora inicio' es requerido");
            return;
        }

        console.log(context.state.reuProgramadaData.cREU_Id, savingData);

        await updateReunion({
            variables: {
                input: {
                    table: 'cREU_Reunion',
                    columns: savingData,
                    conditions: [
                        {
                            valone: 'cREU_Id',
                            valtwo: '' + context.state.reuProgramadaData.cREU_Id,
                            condition: '=',
                        },
                    ],
                },
            },
        });
    }

    return (
        <Fragment>
            <DialogAlert
                title={contentModal.title}
                text={contentModal.message}
                textButtonA="Cerrar"
                textButtonB='Aceptar'
                open={contentModal.open}
                handleClose={() => {
                    // cierra modal
                    setContentModal({...contentModal, open: false});
                }}
                enableConfirmBtn={true}
                handleConfirm={() => {
                    // redireccionar a edicion
                    context.clearState();
                    irEdicion(true);
                }}
            />
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                <ImputResumen
                    title="Título"
                    disabled={disabled.titulo}
                    setDisabled={() => setDisabled({ ...disabled, titulo: !disabled.titulo })}
                    value={context?.state.reuProgramadaData.cREU_Titulo}
                    setValue={(value) =>
                        context?.setDataReuProgramada({ cREU_Titulo: value })
                    }
                    flexDirection="column"
                    heightTxtArea="40px"
                />
                {/* <ImputResumen
                    title="Lugar"
                    disabled={disabled.lugar}
                    setDisabled={() => setDisabled({ ...disabled, lugar: !disabled.lugar })}
                    value={context?.state.reuProgramadaData.cREU_Lugar}
                    setValue={(value) =>
                        context?.setDataReuProgramada({ cREU_Lugar: value })
                    }
                    flexDirection="column"
                    heightTxtArea="40px"
                /> */}
                <div style={{width:'75%'}}>
                    <div style={{ display: 'flex', borderBottom: '1px solid #000', justifyContent: 'space-between', alignItems: 'baseline', flexDirection: 'column' }}>
                        <h4>
                            Lugar
                        </h4>
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span onClick={() => setDisabled({ ...disabled, lugar: !disabled.lugar })}>
                            {
                                disabled.lugar ?
                                    <Edit /> :
                                    <Check />
                            }
                        </span>
                    </div>
                    <select onChange={(e) => {
                        context.setDataReuProgramada({ cREU_Lugar: e.target.value })
                    }}
                    style={{ height: 40, width: '100%' }}
                    disabled={disabled.lugar}
                    >
                        {sede.map((sed, index) => (
                            <option value={sed.tipo} selected={sed.tipo === context?.state.reuProgramadaData.cREU_Lugar ? true : false}>{sed.tipo}</option>
                        ))}
                    </select>
                </div>
                <ImputResumen
                    title="Fecha"
                    disabled={disabled.fecha}
                    setDisabled={() => setDisabled({ ...disabled, fecha: !disabled.fecha })}
                    value={context?.state.reuProgramadaData.cREU_FechaInicio}
                    setValue={(value) =>
                        context?.setDataReuProgramada({ cREU_FechaInicio: value })
                    }
                    flexDirection="column"
                    heightTxtArea="40px"
                />
                <ImputResumen
                    title="Hora"
                    disabled={disabled.hora}
                    setDisabled={() => setDisabled({ ...disabled, hora: !disabled.hora })}
                    value={context?.state.reuProgramadaData.cREU_HoraInicio}
                    setValue={(value) =>
                        context?.setDataReuProgramada({ cREU_HoraInicio: value })
                    }
                    flexDirection="column"
                    heightTxtArea="40px"
                />
            </div>
            <br></br>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                <Button
                    type="primary"
                    style={{
                        marginRight: 20,
                        width: 236,
                        color: '#fff',
                        backgroundColor: '#3898EC'
                    }}
                    size="large"
                    onClick={() => {
                        actualizaReunion();
                    }}
                >Guardar</Button>
            </div>
        </Fragment>
    );
}

export default DatosReuProgramada;