import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import Login from './components/Login';
import HomeUser from './components/HomeUser';
import Viaticos from './components/Viaticos';
import Reuniones from './components/Reuniones';
import ProgramarReunion from './components/ProgramarReunion';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Session from './components/Session';
import Context from './components/Context';
import { Acuerdos } from './components/Acuerdos';
import Snapshot from './components/SnapshotV2';
import UserRolSelection from './components/UserRolSelection';
const App = ({ refetch, session }) => {
  const [newSession, setNewSession] = useState(session);
  const msg = session?.obtenerUsuario ? 'prod' :  <Redirect to="/crm/login" />

  return (
    <Router>
      <Context>
        <Switch>
          <Route exact path="/crm" render={() => <Home session={newSession} />} />
          <Route
            exact
            path="/crm/login"
            render={() => <Login refetch={refetch} />}
          />
          <Route
            exact
            path="/crm/secciones"
            render={() => <HomeUser session={newSession} />}
          />
          <Route
            exact
            path="/crm/secciones/viaticos"
            render={() => <Viaticos session={newSession} />}
          />
          <Route
            exact
            path="/crm/secciones/reuniones"
            render={() => <Reuniones session={newSession} />}
          />
          <Route
            exact
            path="/crm/secciones/reuniones/programar-reunion"
            render={() => <ProgramarReunion session={newSession} />}
          />
          <Route exact path='/crm/rol' render={() => <UserRolSelection session={session} setNewSession={setNewSession} />}/>
          {/* <Route
            exact
            path="/crm/secciones/reuniones/snapshots"
            render={(props) => <Snapshot {...props} />}
          />
          <Route exact path="/crm/secciones/reuniones/suscripcion-siniestros" /> */}
          <Route
            exact
            path="/crm/secciones/reuniones/acuerdos-viaje"
            render={(props) => <Acuerdos session={newSession} {...props} />}
          />
          <Route exact path="/crm/secciones/reuniones/reuniones-programadas" />
        </Switch>
      </Context>
    </Router>
  );
};

const RootSession = Session(App);

export { RootSession };
