import React from 'react';
import MaterialTable from 'material-table';
import DeleteEvent from '../../DeleteEvent';
import IconRemove from '../../IconRemove';
import IconEdit from '../../IconEdit';
import VisibilityIcon from '@material-ui/icons/Visibility';
const TableReuniones = ({ data = [], loading, handleEdit, handleOpenRemove, handleViewEvent }) => {

    return (
        <MaterialTable
            isLoading={loading}
            // options={{
            //     search: true,
            // }}
            title="Reuniones Agendadas"
            columns={[
                { title: 'Fecha', field: 'Fecha' },
                { title: 'Hora', field: 'Hora' },
                { title: 'Reunión', field: 'Title' },
                { title: 'Lugar', field: 'Lugar' },
                { title: 'Id Reunión', field: 'cREU_Id' },
                {
                    title: 'Editar',
                    field: 'Editar',
                    render: ({ cREU_Id, cREU_IdOutlook, Title }) => {
                        return (
                            <div>
                                <DeleteEvent
                                    Icon={<IconEdit />}
                                    onClick={handleEdit}
                                    cREU_Id={cREU_Id}
                                />
                                <DeleteEvent
                                    Icon={<IconRemove />}
                                    onClick={handleOpenRemove}
                                    cREU_Id={cREU_Id}
                                    cREU_IdOutlook={cREU_IdOutlook}
                                />
                                <DeleteEvent
                                    Icon={<VisibilityIcon />}
                                    onClick={handleViewEvent}
                                    cREU_IdOutlook={cREU_IdOutlook}
                                    cREU_Id={cREU_Id}
                                />
                            </div>
                        );
                    },
                },
            ]}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Buscar',                    
                }               
            }}
            data={data}
        />
    );
}

export default TableReuniones;