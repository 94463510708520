import React, { useState, useContext, Fragment, useEffect } from 'react';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextareaAutosize } from '@material-ui/core';
import { PageContainer, ContainerTwoInputs } from '../StylesComponents/common-styled';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { MyContext } from '../Context';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE } from '../../mutation/mutation';
import { GET_SPENDING_LIMIT, GET_EVENTO_BY_CREU_ID, GET_CHECKING_COMP } from '../../query/query';
import { sendEmail } from '../../services/SendEmails';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import CardListReuniones from './CardListReuniones';
import DialogAlert from '../DiaologAlert';
import Card from '../Viaticos/Card';
import UploadFile from './UploadFile';
import { useMediaQuery } from '@material-ui/core';
const AccountingMovements = ({ onClick }) => {
    const isMobile = useMediaQuery('(min-width:300px) and (max-width:500px)');  
    const context = useContext(MyContext);
    const [showModalErr, setShowModalErr] = useState(false);
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Error al guardar',
        text: 'Campos vacíos'
    });
    const [showCards, setShowCards] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [empleado, setEmpleado] = useState([]);
    const [reunion, setReunion] = useState([]);
    const { loading, data } = useQuery(GET_CHECKING_COMP, {
        variables: {
            cVIT_Status_COMP: 2
        }
    });
    const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
        onCompleted({ getReunionBycREUID }) {
            setMeetings(getReunionBycREUID);
            setShowCards(true);
        },
    });
    const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
        onError(err) {
            console.log(err)
        }
    });
    const updateVit = async () => {
        const cVIT_Id = String(context.state.viaticosProps.cVIT_Id);
        const viaticsIds = data?.getCheckingByStatusComp[cVIT_Id].viaticos;
        viaticsIds.forEach(async (id) => {
            let columns = [
                {
                    setkey: 'cVIT_Avance',
                    setval: '12',
                },
                {
                    setkey: 'cVIT_Status_COMP',
                    setval: '3',
                },
            ];
            let conditions = [
                {
                    valone: 'cVIT_Id',
                    valtwo: String(id),
                    condition: '=',
                    logic: 'AND',
                },
            ];
            await updateStatus({
                variables: {
                    input: {
                        table: 'cVIT_Viaticos',
                        columns,
                        conditions
                    }
                }
            })
        });
    }
    const sendTicket = async () => {
        const emailToTraveler = sessionStorage.getItem('emailToTraveler');
        const token = context.state.AuthComponentProps.token;
        await updateVit();
        await sendEmail({
            accessToken: token,
            emailTo: [emailToTraveler],
            subject: 'Comprobación de gastos',
            section: 'sectionTwo_Comp'
        });
        setMsgModalErr({
            title: 'Información guardada',
            text: 'Ticket enviado al viajero'
        })
        setShowModalErr(true);
    }
    useEffect(() => {
        const cVIT_Id = context.state.viaticosProps.cVIT_Id;
        if (cVIT_Id && !loading) {
            const reuniones = data?.getCheckingByStatusComp[cVIT_Id].reuniones;
            console.log('reuniones', reuniones);
            getEventBycREU({
                variables: { cREU_Id: reuniones },
            });
        }
    }, [loading]);
    if (loading) {
        return (
            <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                    <CircularProgress disableShrink size={100} />
                </div>
            </>
        )
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => {
                    setShowModalErr(false);
                    onClick();
                }}
            />
            <Box m={0} marginTop={0}>
                <CardListReuniones meetings={meetings} setEmpleado={setEmpleado} setReunion={setReunion} />
                {(showCards) && (
                    <ContainerTwoInputs
                        width="100%"
                        margin="20px 0px"
                        id="viaticos-solicitud"
                    >

                        <Card data={reunion} img={calendario} />
                        <Card data={empleado} img={user} />
                    </ContainerTwoInputs>
                )}
                <p style={{ fontSize: 18 }}>Tarjeta seleccionada: <span style={{ fontWeight: 'bolder' }}>{data?.getCheckingByStatusComp[context.state.viaticosProps.cVIT_Id].card}</span></p>
                <UploadFile name='movimientos' viaticsIds={data?.getCheckingByStatusComp[context.state.viaticosProps.cVIT_Id].viaticos} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <ButtonNavigation
                        text="Enviar"
                        width={isMobile ? "100%" : "50%"}
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        justifyContent="center"
                        onClick={sendTicket}
                    />
                </div>
            </Box>
        </PageContainer>
    )
}

export default AccountingMovements