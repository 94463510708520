import React, { useState, Fragment } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatBold,
  FormatUnderlined,
  FormatItalic,
} from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    width: '90%',
    backgroundColor: '#EEF3F4',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const ToggleButtons = ({ width = '75%' }) => {
  const [alignment, setAlignment] = useState('justify');
  const [formats, setFormats] = useState(['bold']);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Paper elevation={0} className={classes.paper} id="text-mensaje" style={{width}}>
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text alignment"
        >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBold style={{ color: '#000' }} />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="justify" aria-label="justified">
            <FormatAlignJustify style={{ color: '#000' }} />
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            <FormatAlignCenter style={{ color: '#000' }} />
          </ToggleButton>
          <ToggleButton value="left" aria-label="left aligned">
            <FormatAlignLeft style={{ color: '#000' }} />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text alignment"
        >
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlined style={{ color: '#000' }} />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalic style={{ color: '#000' }} />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </Fragment>
  );
};

export default ToggleButtons;
