import { Chip } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { Clear } from '@material-ui/icons';
import '../../components.css';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../../mutation/mutation';
import Areas_Inv from '../Areas_Inv.js';
import { MyContext } from '../../Context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function AreasInvV2({
  openModal,
  onClick1,
  preSelect = {},
  flag = false,
}) {
  const [areas, setAreas] = useState();
  const [subArea, setSubArea] = useState([]);
  const [open, setOpen] = useState(openModal);
  const [countQuery, setCountQuery] = useState(0);
  const [countAux, setCountAux] = useState(false);
 
  const classes = useStyles();

  const context = useContext(MyContext);

  const [saveIdResponseQuestions, setSaveIdResponseQuestions] = useState({
    areas: context.state.saveSegRelPatria.areas,
    subAreas: context.state.saveSegRelPatria.subAreas,
  });

  const [getArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setAreas(JSON.parse(dynamicGet.response));
    },
  });

  const [getSubArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      let response = JSON.parse(dynamicGet.response);
      let areasEntorno = context.state.saveSegRelPatria.areas;
      let subAreasEntorno = context.state.saveSegRelPatria.subAreas;

      console.log("AREASINV", context.state.saveSegRelPatria.areas, context.state.saveSegRelPatria.subAreas);
      
      let arraySubAreas = response.map((subAreas, index) => {
        if(subAreasEntorno.indexOf(subAreas.cSUBA_Id) != -1){
          return {...subAreas, status: true}
        }
        else{
          return {...subAreas, status: false };
        }
      });
      setSubArea([
        ...arraySubAreas,
        {
          cARE_Id: 5,
          cSUBA_Descripcion: 'Otra Área',
          cSUBA_Id: 1,
          status: areasEntorno.indexOf(5) != -1 ? true : false,
        },
        {
          cARE_Id: 6,
          cSUBA_Descripcion: 'Ninguna',
          cSUBA_Id: 1,
          status: areasEntorno.indexOf(6) != -1 ? true : false,
        },
        {
          cARE_Id: 7,
          cSUBA_Descripcion: 'PLD / Oficial de Cumplimiento',
          cSUBA_Id: 1,
          status: areasEntorno.indexOf(7) != -1 ? true : false,
        },
        {
          cARE_Id: 8,
          cSUBA_Descripcion: 'Planeación y Coordinación',
          cSUBA_Id: 1,
          status: areasEntorno.indexOf(8) != -1 ? true : false,
        }
      ]);
      setCountQuery(1);
      setCountAux(true);
    },
  });

  const onchange = async({ value }) => {
    const { cARE_Id, cSUBA_Descripcion, cSUBA_Id } = JSON.parse(value);

    console.log("INPUT AREAS", value, saveIdResponseQuestions.areas.indexOf(cARE_Id));
    
    // saveIdResponseQuestions.areas.push(cARE_Id);

    // setSaveIdResponseQuestions({
    //   ...saveIdResponseQuestions,
    //   cARE_Id,
    //   areas: saveIdResponseQuestions.areas
    // });
    
    if(saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id) === -1){
      saveIdResponseQuestions.subAreas.push(cSUBA_Id);
      saveIdResponseQuestions.areas.push(cARE_Id);
      setSaveIdResponseQuestions({
        ...saveIdResponseQuestions,
        cSUBA_Id,
        subAreas: saveIdResponseQuestions.subAreas,
        areas: saveIdResponseQuestions.areas
      });
    }
    else{
      if (cSUBA_Descripcion === 'Otra Área' || cSUBA_Descripcion === 'Ninguna' || cSUBA_Descripcion === 'PLD / Oficial de Cumplimiento' || cSUBA_Descripcion === 'Planeación y Coordinación') {
        if(saveIdResponseQuestions.areas.indexOf(cARE_Id) === -1){
          saveIdResponseQuestions.subAreas.push(cSUBA_Id);
          saveIdResponseQuestions.areas.push(cARE_Id);
          setSaveIdResponseQuestions({
            ...saveIdResponseQuestions,
            cSUBA_Id,
            subAreas: saveIdResponseQuestions.subAreas,
            areas: saveIdResponseQuestions.areas
          });
        }
        else{
          let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
          let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
        
          saveIdResponseQuestions.subAreas.splice(i, 1);
          saveIdResponseQuestions.areas.splice(i1, 1);
        }
      }
      else{
        let i = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
        let i1 = saveIdResponseQuestions.areas.indexOf(cARE_Id);
      
        saveIdResponseQuestions.subAreas.splice(i, 1);
        saveIdResponseQuestions.areas.splice(i1, 1);
      }
    }
    
    console.log("areas", saveIdResponseQuestions.areas, "subAreas", saveIdResponseQuestions.subAreas);

    // await context.setSaveAcuerdos({
    //   ...context.state.saveAcuerdos,
    //   cARE_Id,
    //   cSUBA_Id,
    //   areas: saveIdResponseQuestions.areas,
    //   subAreas: saveIdResponseQuestions.subAreas
    // });

    await context.setSaveSegRelPatria({
      ...context.state.saveSegRelPatria,
      areas: saveIdResponseQuestions.areas,
      subAreas: saveIdResponseQuestions.subAreas
    });

    console.log("RELPATRIA:", context.state.saveSegRelPatria);

    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else if (cARE_Id === 5 && cSUBA_Id === 5) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );
  };

  const deleteSubarea = async(cSUBA_Id, cARE_Id) => {
    

    const index = saveIdResponseQuestions.areas.indexOf(cARE_Id);
    const index2 = saveIdResponseQuestions.subAreas.indexOf(cSUBA_Id);
    if (index > -1) { // only splice array when item is found
      saveIdResponseQuestions.areas.splice(index, 1); // 2nd parameter means remove one item only
    }

    if (index2 > -1) { // only splice array when item is found
      saveIdResponseQuestions.subAreas.splice(index2, 1); // 2nd parameter means remove one item only
    }

    // await context.setSaveAcuerdos({
    //   ...context.state.saveAcuerdos,
    //   cARE_Id,
    //   cSUBA_Id,
    //   areas: saveIdResponseQuestions.areas,
    //   subAreas: saveIdResponseQuestions.subAreas
    // });

    await context.setSaveSegRelPatria({
      ...context.state.saveSegRelPatria,
      areas: saveIdResponseQuestions.areas,
      subAreas: saveIdResponseQuestions.subAreas
    });

    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id === cARE_Id && sArea.cSUBA_Id === cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );
  };

  useEffect(() => {
    if (countQuery === 1 && flag) {
      onchange({ value: preSelect });
      setCountQuery(2);
    }
  }, [countAux]);

  useEffect(() => {
    getArea({
      variables: {
        input: {
          table: 'cARE_AREASCRM',
          columns: ['cARE_Id', 'cARE_Descripcion'],
        },
      },
    });
    getSubArea({
      variables: {
        input: {
          table: 'cSUBA_SubAreasCRM',
          columns: ['cSUBA_Id', 'cARE_Id', 'cSUBA_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div
      // style={{ width: '100%', textAlign: '-webkit-center' }}
      className="registro-container"
    >
      {
        // cSUBE_Id===2?
        openModal ? (
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'initial',
            }}
            onClick={() => setOpen(true)}
          >
            <h4 style={{ color: '#3898EC' }}>Áreas</h4>
            <div className={classes.root}>
              {subArea &&
                subArea.map(
                  ({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                    status ? (
                      <Chip
                        label={cSUBA_Descripcion}
                        style={{ backgroundColor: '#3898EC', color: '#FFFF' }}
                        onDelete={() => deleteSubarea(cSUBA_Id, cARE_Id)}
                        deleteIcon={<Clear />}
                        key={index}
                      />
                    ) : null
                )}
            </div>
          </div>
        ) : null
      }
      {/* {!flag ? <ButtonNavigation text='Guardar y continuar' backgroundColor='#3898EC' border='#3898EC' color='#FFFFFF' width='75%' onClick={save}/> : null} */}
      <Areas_Inv
        areas={areas}
        subArea={subArea}
        open={open}
        setOpen={() => setOpen(false)}
        onChangue={onchange}
      />
    </div>
  );
}
