import React, { useState, Fragment } from 'react';
import { useGetViaticos } from '../../helpers/hooks/useViaticos';
import CardProgress from '../Viaticos/CardProgress';
import DialogViat from '../Viaticos/DialogViat';
import ModalReviewViaticos from './ModalReviewViaticos';


const RequestsInProgress = ({ cEMP_Id, handleComponent, cVIT_Avance, cVIT_Status_VIAT, title, getAllExpenses, cVIT_Status_COMP }) => {
    const { data: viaticos } = useGetViaticos({cEMP_Id, getAllExpenses, cVIT_Avance, cVIT_Status_VIAT, cVIT_Status_COMP });
    const [dialog, setDialog] = useState(false);
    const [openModalReuniones, setOpenModalReuniones] = useState(false);
    const [listReuniones, setListReuniones] = useState([])

    const handleClose = () => {
        setDialog(false);
    }

    return (
        <Fragment>
            <ModalReviewViaticos openDirectory={openModalReuniones} setOpenDirectory={setOpenModalReuniones} data={listReuniones} />
            <div style={{ width: '70%', minHeight: '65vh', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}>
                    <h4>{title}</h4>
                </div>
                {
                    viaticos.length === 0 ?
                        <Fragment>
                            <CardProgress />
                            <CardProgress />
                        </Fragment>

                        :
                        viaticos.map(data => {
                            const { cREU_Titulo, cVIA_Destino} = data.reuniones[0];
                            let inicio = data.cREU_FechaInicio;
                            let fin = data.cREU_FechaFin;                            
                            return (
                                <CardProgress
                                    key={data.cVIT_Id}
                                    idViaticos={data.cVIT_Id}
                                    nombre={cEMP_Id.cEMP_Nombre}
                                    idReunion={cREU_Titulo}
                                    fechas={`${inicio} - ${fin}`}
                                    pais={cVIA_Destino}
                                    status={data.cVIT_Status_VIAT}
                                    progress={data.cVIT_Avance}
                                    handleClick={() => {
                                        handleComponent(data.cVIT_Status_VIAT, data.cVIT_Avance);
                                    }}
                                    openModal={() => {
                                        setListReuniones(data.reuniones);
                                        setOpenModalReuniones(true);
                                    }}
                                    showListReu={data.reuniones.length > 0}
                                />
                            )
                        }

                        )
                }
                <DialogViat open={dialog} handleClose={handleClose} />
            </div>
        </Fragment>


    )
}

export default RequestsInProgress;