import React, { useState } from 'react';
import { ListTable, GridContainer } from '../SnapshotComponents/Styles.styled'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TableIndicadoresMacro from './TableIndicadoresMacro';
import SliderFondoMone from './SliderFondoMone';


const IndicadoresMacro = () => {
    const [value, setValue] = useState('finPeriodo');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (

        <GridContainer gridTemplateCol='1fr'>
            <h4 className="snapshot__title" style={{ fontSize: 15 }}>Fondo Monetario Internacional</h4>

            <GridContainer gridTemplateCol="repeat(2,1fr)">
                <ListTable border=" " width="450px">
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{ background: '#01539c', color: 'white', padding: '2.5px' }}>Indicador</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel
                                value="finPeriodo"
                                control={<Radio style={{ color: "black" }} />}
                                label="Inflación precios al consumidor al final de periodo"
                            />
                            <FormControlLabel
                                value="promedioConsumidor"
                                control={<Radio style={{ color: "black" }} />}
                                label="Inflación, precios promedio al consumidor"
                            />
                            <FormControlLabel
                                value="pibPrecioActual"
                                control={<Radio style={{ color: "black" }} />}
                                label="Producto interno bruto, precios actuales"
                            />
                            <FormControlLabel
                                value="pibPrecioConstante"
                                control={<Radio style={{ color: "black" }} />}
                                label="Producto interno bruto, precios constantes"
                            />
                            <FormControlLabel
                                value="tasaDesempleo"
                                control={<Radio style={{ color: "black" }} />}
                                label="Tasa de desempleo"
                            />
                        </RadioGroup>
                    </FormControl>
                </ListTable>
                <GridContainer gridTemplateCol="repeat(2, 1fr)">
                    <ListTable border=" ">
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend" style={{ background: '#01539c', color: 'white', padding: '2.5px' }}>Unidades</FormLabel>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Cambio porcentual"
                                labelPlacement="end"
                            />
                        </FormControl>
                    </ListTable>
                    <ListTable border=" ">
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend" style={{ background: '#01539c', color: 'white', padding: '2.5px', width: '100%' }}>Escala</FormLabel>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label=" "
                                labelPlacement="end"
                            />
                        </FormControl>
                    </ListTable>
                    <ListTable border=" ">
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend" style={{ background: '#01539c', color: 'white', padding: '2.5px' }}>País</FormLabel>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Cambio porcentual"
                                labelPlacement="end"
                            />
                        </FormControl>
                    </ListTable>
                    <ListTable border=" ">
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend" style={{ background: '#01539c', color: 'white', padding: '2.5px' }}>Territorio</FormLabel>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label=" "
                                labelPlacement="end"
                            />
                        </FormControl>
                    </ListTable>
                </GridContainer>
            </GridContainer>
            <GridContainer gridTemplateCol='repeat(1fr)'>
                <GridContainer gridTemplateCol='repeat(2,1fr)'>
                
                    <SliderFondoMone/>
                    <p style={{ display: 'flex', justifyContent: 'flex-end', textAlign:'center' }}>
                        *International Monetary <br />Fund. World Economic <br />
                        Outlook Database. April <br />2020
                    </p>
                </GridContainer>

            </GridContainer>
            <TableIndicadoresMacro />
        </GridContainer>

    );

}

export default IndicadoresMacro;