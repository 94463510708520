import React, { useState, useContext, useEffect } from 'react';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { PageContainer } from '../StylesComponents/common-styled';
import { useMutation } from 'react-apollo';
import { DYNAMIC_UPDATE } from '../../mutation/mutation';
import { MyContext } from '../Context';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DialogAlert from '../DiaologAlert';
const TravelerConfirmation = ({ cEMP }) => {
    const context = useContext(MyContext);
    const [updateStatus] = useMutation(DYNAMIC_UPDATE);
    const [confirmationOffice, setConfirmationOffice] = useState('');
    const [confirmationTI, setConfirmationTI] = useState('');
    const [confirmationPromo, setConfirmationPromo] = useState('');
    const [showModalErr, setShowModalErr] = useState(false);
    const [styleDisabled, setStyleDisabled] = useState({})
    const [msgModalErr, setMsgModalErr] = useState({
        title: 'Proceso exitoso',
        text: 'Se ha finalizado el viático'
    });
    const updateVit = async () => {
        setStyleDisabled({ pointerEvents: 'none', backgroundColor: '#D5D5D5' })
        let columns = [
            {
                setkey: 'cVIT_Avance',
                setval: '10',
            },
            {
                setkey: 'cVIT_Status_COMP',
                setval: '1',
            }
        ];
        let conditions = [
            {
                valone: 'cVIT_Id',
                valtwo: String(context.state.viaticosProps.cVIT_Id),
                condition: '=',
                logic: 'AND',
            },
        ];
        await updateStatus({
            variables: {
                input: {
                    table: 'cVIT_Viaticos',
                    columns,
                    conditions
                }
            }
        });
        setShowModalErr(true);        
    }
    return (
        <PageContainer marginTop="20px" width="70%" id="button-navigation" style={{ marginBottom: 40 }}>
            <DialogAlert
                title={msgModalErr.title}
                text={msgModalErr.text}
                textButtonA="Aceptar"
                open={showModalErr}
                handleClose={() => setShowModalErr(false)}
            />
            <Box m={0} marginTop={0}>
                <FormControl style={{ width: '100%', marginTop: 50 }}>
                    <InputLabel id="mat-ser-lbl">Confirmación de material Servicios de oficina</InputLabel>
                    <Select
                        labelId="mat-ser-lbl"
                        id="mat-ser"
                        value={confirmationOffice}
                        label="Confirmación de material Servicios de oficina"
                        onChange={({ target }) => setConfirmationOffice(target.value)}
                    >
                        <MenuItem value="">--Selecciona una opción--</MenuItem>
                        <MenuItem value="Dos o más días hábiles antes del viaje">Dos o más días hábiles antes del viaje</MenuItem>
                        <MenuItem value="Un día hábil antes del viaje">Un día hábil antes del viaje</MenuItem>
                        <MenuItem value="El mismo día del viaje">El mismo día del viaje</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ width: '100%', marginTop: 50 }}>
                    <InputLabel id="mat-ti-lbl">Confirmación de material TI</InputLabel>
                    <Select
                        labelId="mat-ti-lbl"
                        id="mat-ti"
                        value={confirmationTI}
                        label="Confirmación de material TI"
                        onChange={({ target }) => setConfirmationTI(target.value)}
                    >
                        <MenuItem value="">--Selecciona una opción--</MenuItem>
                        <MenuItem value="Dos o más días hábiles antes del viaje">Dos o más días hábiles antes del viaje</MenuItem>
                        <MenuItem value="Un día hábil antes del viaje">Un día hábil antes del viaje</MenuItem>
                        <MenuItem value="El mismo día del viaje">El mismo día del viaje</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ width: '100%', marginTop: 50 }}>
                    <InputLabel id="mat-promo-lbl">Confirmación de promocionales Planeación</InputLabel>
                    <Select
                        labelId="mat-promo-lbl"
                        id="mat-promo"
                        value={confirmationPromo}
                        label="Confirmación de promocionales Planeación"
                        onChange={({ target }) => setConfirmationPromo(target.value)}
                    >
                        <MenuItem value="">--Selecciona una opción--</MenuItem>
                        <MenuItem value="Dos o más días hábiles antes del viaje">Dos o más días hábiles antes del viaje</MenuItem>
                        <MenuItem value="Un día hábil antes del viaje">Un día hábil antes del viaje</MenuItem>
                        <MenuItem value="El mismo día del viaje">El mismo día del viaje</MenuItem>
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                    <ButtonNavigation
                        text="Guardar"
                        width="50%"
                        backgroundColor="#3898EC"
                        border="none"
                        color="#fff"
                        justifyContent="center"
                        onClick={updateVit}
                        style={styleDisabled}
                    />
                </div>
            </Box>
        </PageContainer>
    )
}

export default TravelerConfirmation