import { TextareaAutosize } from '@material-ui/core';
import React, { useState, useContext, Fragment } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { Edit } from '@material-ui/icons';
import { MyContext } from '../Context';
import '../components.css';
import { useHistory } from 'react-router-dom';

export default function CedenteCancelar({ onClick }) {
  const [text, setText] = useState();
  const [click, setClick] = useState(false);
  const [cancelada, setCancelada] = useState(false);
  const context = useContext(MyContext);
  const history = useHistory();

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {click === false ? ( //Se Cambio ==
        <Fragment>
          <h3>¿Por qué se canceló la cita?</h3>
          <TextareaAutosize
            rowsMin={5}
            placeholder="Ingresa tus comentarios"
            style={{ width: '100%', marginBottom: '20px' }}
            onChange={({ target }) => setText(target.value)}
            value={text}
          />
          <ButtonNavigation
            width="40%"
            backgroundColor="#3898EC"
            color="#FFF"
            border="#3898EC"
            text="Guardar"
            id="button-navigation"
            onClick={() => {
              context.getData({ cancelacion: { message: text } });
              setClick(true);
            }}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div
            style={{
              display: 'flex',
              padding: '15px',
              justifyContent: 'space-between',
              width: '100%',
              minHeight: '50px',
              marginBottom: '45px',
              backgroundColor: '#E5E5E5',
            }}
          >
            <div style={{ width: '90%' }}>
              <h4>{text}</h4>
            </div>
            <div onClick={() => setClick(false)}>
              <Edit />
            </div>
          </div>
          {cancelada ? (
            <Fragment>
              <h2>Reunión cancelada</h2>
              <ButtonNavigation
                width="40%"
                backgroundColor="#3898EC"
                color="#FFF"
                border="#3898EC"
                text="Volver al inicio"
                id="button-navigation"
                onClick={onClick}
              />
            </Fragment>
          ) : (
            <Fragment>
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h4>¿Deseas reagendar la reunión?</h4>
                <ButtonNavigation
                  width="40%"
                  backgroundColor="#3898EC"
                  color="#FFF"
                  border="#3898EC"
                  text="Sí, reagendar la reunión"
                  id="button-navigation"
                  onClick={() => {
                    localStorage.setItem('loginOutlook', 'true');
                    setTimeout(() => {
                      history.push('/crm/secciones/reuniones');
                    }, 1000);
                  }}
                />
                <ButtonNavigation
                  width="40%"
                  text="No por el momento"
                  id="button-navigation"
                  onClick={() => setCancelada(true)}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
