import React, { useState, Fragment } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  option: {
    color: 'rgb(0, 0, 0)',
    backgroundColor: '#fff',
  },
}));

const SearchAutoComplete = ({ Employes, getInvitado, validInputs }) => {
  const [value, setValue] = useState('');
  const classes = useStyles();
  const limpiarInput = () => {
    setValue('');
  };
  return (
    <Fragment>
      <Autocomplete
        freeSolo
        disableClearable
        onChange={(e, Data) => {
          const invitado = {
            cEMP_Id: Data.cEMP_Id,
            name: `${Data.cEMP_Nombre.trim()} ${Data.cEMP_ApePaterno.trim()}`,
            correo: Data.cEMP_Mail.trim(),
            status: true,
          };
          getInvitado(invitado);
          limpiarInput();
        }}
        classes={{
          option: classes.option,
        }}
        inputValue={value}
        options={Employes}
        //options={Data.map((option) => `${option.cEMP_Nombre.trim()} ${option.cEMP_ApePaterno.trim()} ${option.cEMP_ApeMaterno.trim()}`)}
        getOptionLabel={(option) =>
          `${option.cEMP_Nombre.trim()} ${option.cEMP_ApePaterno.trim()} ${option.cEMP_ApeMaterno.trim()}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setValue(e.target.value)}
            margin="normal"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: <Search />,
            }}
            placeholder="Buscar nombre"
            id="search-invitados"
            className={`${value !== '' && value !== undefined ? 'inputActive' : validInputs.invitados ? 'inputError' : ''}`}                          
          />
        )}
      />
    </Fragment>
  );
};
export default SearchAutoComplete;
